import { useCallback, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { GetCategoriesSortColumnName } from '@npco/mp-gql-types'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  BREAKPOINT,
  ButtonGhost,
  ButtonGhostIconOnly,
  Flex,
  PageTemplate,
  PopperItem,
  Tabs,
} from '@npco/zeller-design-system'

import { ReactComponent as CogIcon } from 'assets/svg/cogwheel.svg'
import { useIsMobileResolution } from 'hooks/useIsMobileResolution'
import { ROOT } from 'const/routes'
import { BreadcrumbsBase } from 'components/Breadcrumbs/BreadcrumbsBase'
import { HeaderSearch } from 'components/HeaderSearch/HeaderSearch'
import { ManageDropdown } from 'components/ManageDropdown/ManageDropdown'

import { useItemsBreadcrumbs } from '../../hooks/useItemsBreadcrumbs'
import { TableTabStateEnum } from '../../ItemManagement.types'
import { DEFAULT_GET_LIMIT } from '../../ItemManagement.utils'
import { useItemManagementContext } from '../ItemManagementContext/ItemManagementContext'
import { useExportItemsModal } from '../ItemsExport/hooks/useExportItemsModal'
import { useTabs } from './hooks/useTabs'
import { translations } from './ItemManagementLayout.i18n'
import * as styled from './ItemManagementLayout.styled'

export const ItemManagementLayout = () => {
  const breadcrumbParts = useItemsBreadcrumbs()
  const isMobile = useIsMobileResolution(BREAKPOINT.XS)
  const { handleDownload } = useExportItemsModal()

  const {
    categories: {
      data: categories,
      filterInput: categoriesFilterInput,
      getCategories,
      hasNoResults: hasNoCategories,
      isDefaultFilters: isDefaultCategoriesFilters,
      isDefaultSort: isDefaultCategoriesSort,
      openMobileFilters: openCategoriesMobileFilters,
    },
    items: {
      data: items,
      filterInput: itemsFilterInput,
      getItems,
      hasNoResults: hasNoItems,
      isDefaultFilters: isDefaultItemsFilters,
      isDefaultSort: isDefaultItemsSort,
      openMobileFilters: openItemsMobileFilters,
    },
    getFilterCategories,
    setTextSearchFilter,
  } = useItemManagementContext()

  const t = useTranslations(translations)
  const { tabs, currentTab } = useTabs()
  const navigate = useNavigate()
  const location = useLocation()
  const shortEntityId = useSelectedShortEntityUuid()
  const shouldHideSearch =
    hasNoItems &&
    hasNoCategories &&
    isDefaultItemsFilters &&
    isDefaultCategoriesFilters

  const tableTabState: TableTabStateEnum = location.pathname.includes(
    'categories'
  )
    ? TableTabStateEnum.CATEGORIES
    : TableTabStateEnum.ITEMS

  const newPath =
    ROOT.ORGS.ORG(shortEntityId).ITEM_MANAGEMENT[tableTabState].NEW.path

  // NOTE: we only show the empty state when no items or categories so need to
  // request both endpoints when first mounting the item management layout
  useEffect(() => {
    getItems({
      variables: {
        filter: itemsFilterInput,
        limit: DEFAULT_GET_LIMIT,
        nextToken: undefined,
        sort: undefined,
      },
    })
    getCategories({
      variables: {
        filter: categoriesFilterInput,
        limit: DEFAULT_GET_LIMIT,
        nextToken: undefined,
        sort: undefined,
      },
    })

    // NOTE: preloading category filters
    getFilterCategories({
      variables: {
        sort: { ascending: true, columnName: GetCategoriesSortColumnName.Name },
        limit: DEFAULT_GET_LIMIT,
        nextToken: undefined,
        filter: undefined,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openMobileFilters = useCallback(() => {
    if (tableTabState === TableTabStateEnum.CATEGORIES) {
      openCategoriesMobileFilters()
    } else {
      openItemsMobileFilters()
    }
  }, [openCategoriesMobileFilters, openItemsMobileFilters, tableTabState])

  const textSearchFilter =
    categoriesFilterInput?.textSearchFilter ??
    itemsFilterInput?.textSearchFilter ??
    ''

  const isActiveFilterIndicatorVisible = isMobile
    ? !isDefaultCategoriesFilters ||
      !isDefaultItemsFilters ||
      !isDefaultCategoriesSort ||
      !isDefaultItemsSort
    : Boolean(textSearchFilter)

  const isDeleteAllItemsDisabled = !categories?.length && !items?.length

  return (
    <PageTemplate
      HeaderPrimaryRow={
        <PageTemplate.HeaderPrimaryRow>
          <Flex alignItems="center" flex={1} gap="8px">
            <Flex flex={1}>
              <BreadcrumbsBase breadcrumbParts={breadcrumbParts} />
            </Flex>
            <Flex gap="8px">
              {!shouldHideSearch && (
                <HeaderSearch
                  isActive={isActiveFilterIndicatorVisible}
                  isMobile={isMobile}
                  openMobileFiltersModal={openMobileFilters}
                  placeholder={t('searchPlaceholder')}
                  setTextSearchFilterInput={setTextSearchFilter}
                  textSearchFilter={textSearchFilter}
                />
              )}
              {isMobile ? (
                <ButtonGhostIconOnly
                  icon={CogIcon}
                  data-testid="items-settings-mobile"
                  id="items-settings"
                  onClick={() =>
                    navigate(
                      ROOT.ORGS.ORG(shortEntityId).ITEM_MANAGEMENT.SETTINGS.path
                    )
                  }
                />
              ) : (
                <ButtonGhost
                  data-testid="items-settings"
                  id="items-settings"
                  onClick={() =>
                    navigate(
                      ROOT.ORGS.ORG(shortEntityId).ITEM_MANAGEMENT.SETTINGS.path
                    )
                  }
                >
                  {t('manageSettings')}
                </ButtonGhost>
              )}
              <ManageDropdown>
                {({ setIsOpen }) => (
                  <Box p="8px">
                    <PopperItem
                      role="menuitem"
                      title={t('manageAddItem')}
                      aria-label={t('addItemAriaLabel')}
                      data-testid="items-add-item"
                      onClick={() => {
                        setIsOpen(false)
                        navigate(newPath, {
                          state: { modalToLaunch: TableTabStateEnum.ITEMS },
                        })
                      }}
                    />
                    <PopperItem
                      role="menuitem"
                      title={t('manageImportItems')}
                      aria-label={t('importItemAriaLabel')}
                      onClick={() => {
                        setIsOpen(false)
                        navigate(
                          ROOT.ORGS.ORG(shortEntityId).ITEM_MANAGEMENT.ITEMS
                            .IMPORT.path
                        )
                      }}
                    />
                    <PopperItem
                      role="menuitem"
                      title={t('manageExportItems')}
                      aria-label={t('exportItemsAriaLabel')}
                      onClick={() => {
                        setIsOpen(false)
                        handleDownload()
                      }}
                    />
                    <PopperItem
                      role="menuitem"
                      title={t('manageAddCategory')}
                      aria-label={t('addCategoryAriaLabel')}
                      data-testid="items-categories"
                      onClick={() => {
                        setIsOpen(false)
                        navigate(newPath, {
                          state: {
                            modalToLaunch: TableTabStateEnum.CATEGORIES,
                          },
                        })
                      }}
                    />
                    <styled.DeleteAllPopperItem
                      role="menuitem"
                      isDisabled={isDeleteAllItemsDisabled}
                      title={t('manageDeleteAllItems')}
                      aria-label={t('deleteAllItemsAriaLabel')}
                      aria-disabled={isDeleteAllItemsDisabled}
                      data-testid="items-delete-all"
                      onClick={() => {
                        setIsOpen(false)
                        navigate(
                          ROOT.ORGS.ORG(shortEntityId).ITEM_MANAGEMENT.ITEMS
                            .DELETE_ALL.path
                        )
                      }}
                    />
                  </Box>
                )}
              </ManageDropdown>
            </Flex>
          </Flex>
        </PageTemplate.HeaderPrimaryRow>
      }
      HeaderTertiaryRow={
        Boolean(tabs.length) && (
          <PageTemplate.HeaderTertiaryRow>
            <Tabs currentTab={currentTab}>
              {tabs.map((tab) => (
                <Tabs.Item key={tab.value} {...tab} />
              ))}
            </Tabs>
          </PageTemplate.HeaderTertiaryRow>
        )
      }
    >
      <Outlet />
    </PageTemplate>
  )
}
