import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import {
  GetCategoriesFilterInput,
  GetCategoriesSortInput,
  GetItemFilterInput,
  GetItemsSortInput,
} from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { Subset } from 'types/utils'

import { CacheLocalStateEntityScopedVariable } from '../../../graphql/cache.types'
import { GetItemManagementLocalState } from '../graphql/getItemManagementLocalState'
import { ItemManagementDefaultLocalState } from '../ItemManagement.constants'
import { CacheLocalStateItemManagement } from '../ItemManagement.types'

export const useItemManagementLocalCache = () => {
  const { cache } = useApolloClient()
  const entityUuid = useSelectedEntityUuid()

  const updateCache = useCallback(
    (next: Subset<CacheLocalStateItemManagement>) =>
      // NOTE: a local custom merge function preserves any other state under the
      // local key so we only need to provide the updates under itemManagement
      // apps/src/graphql/cache.ts:138
      cache.updateQuery<
        Subset<CacheLocalStateItemManagement> | null,
        CacheLocalStateEntityScopedVariable
      >(
        {
          query: GetItemManagementLocalState,
          variables: { entityUuid },
        },
        (previous) => ({
          local: {
            itemManagement: {
              ...previous?.local?.itemManagement,
              ...next.local?.itemManagement,
            },
          },
        })
      ),
    [cache, entityUuid]
  )
  const setCategoriesFilterInput = useCallback(
    (nextFilter?: GetCategoriesFilterInput | null) => {
      updateCache({
        local: {
          itemManagement: {
            categories: {
              filterInput: nextFilter,
            },
          },
        },
      })
    },
    [updateCache]
  )

  const setCategoriesSortInput = useCallback(
    (nextSort?: NonNullable<GetCategoriesSortInput> | null) => {
      updateCache({
        local: {
          itemManagement: {
            categories: {
              sortInput: nextSort,
            },
          },
        },
      })
    },
    [updateCache]
  )

  const setItemsFilterInput = useCallback(
    (nextFilter?: GetItemFilterInput | null) => {
      updateCache({
        local: {
          itemManagement: {
            items: {
              filterInput: nextFilter,
            },
          },
        },
      })
    },
    [updateCache]
  )

  const setItemsSortInput = useCallback(
    (nextSort: NonNullable<GetItemsSortInput> | null) => {
      updateCache({
        local: {
          itemManagement: {
            items: {
              sortInput: nextSort,
            },
          },
        },
      })
    },
    [updateCache]
  )

  const setTextSearchFilter = useCallback(
    (nextSearch: string) => {
      updateCache({
        local: {
          itemManagement: {
            categories: {
              filterInput: {
                textSearchFilter: nextSearch,
              },
            },
            items: {
              filterInput: {
                textSearchFilter: nextSearch,
              },
            },
          },
        },
      })
    },
    [updateCache]
  )

  const setHasSeenItemOnboarding = useCallback(
    (state: boolean) => {
      updateCache({
        local: {
          itemManagement: {
            hasSeenItemOnboarding: state,
          },
        },
      })
    },
    [updateCache]
  )

  const resetState = useCallback(() => {
    updateCache({
      local: {
        itemManagement: ItemManagementDefaultLocalState,
      },
    })
  }, [updateCache])

  return {
    resetState,
    setCategoriesFilterInput,
    setCategoriesSortInput,
    setHasSeenItemOnboarding,
    setItemsFilterInput,
    setItemsSortInput,
    setTextSearchFilter,
  }
}
