import { gql } from '@apollo/client'

export const SaveTags = gql`
  mutation SaveTags($tags: [String!], $debitCardTransactionUuid: ID!) {
    updateDebitCardTransactionAnnotations(
      input: {
        tags: $tags
        debitCardTransactionUuid: $debitCardTransactionUuid
      }
    )
  }
`

export const UpdateCategory = gql`
  mutation UpdateCategory(
    $category: EntityCategories
    $subcategory: ID
    $debitCardTransactionUuid: ID!
  ) {
    updateDebitCardTransactionAnnotations(
      input: {
        category: $category
        subcategory: $subcategory
        debitCardTransactionUuid: $debitCardTransactionUuid
      }
    )
  }
`

export const SaveNote = gql`
  mutation SaveNote(
    $note: String!
    $debitCardTransactionUuid: ID!
    $entityUuid: ID!
  ) {
    updateDebitCardTransactionAnnotations(
      input: {
        note: $note
        debitCardTransactionUuid: $debitCardTransactionUuid
      }
      entityUuid: $entityUuid
    )
  }
`

export const SaveAccountingDetails = gql`
  mutation SaveAccountingDetails(
    $accountingCategory: String!
    $debitCardTransactionUuid: ID!
  ) {
    updateDebitCardTransactionAnnotations(
      input: {
        accountingCategory: $accountingCategory
        debitCardTransactionUuid: $debitCardTransactionUuid
      }
    )
  }
`

export const DeleteAttachment = gql`
  mutation DeleteAttachment($documentUuid: String!, $transactionUuid: ID!) {
    removeDebitCardTxnAttachment(
      documentUuid: $documentUuid
      transactionUuid: $transactionUuid
    )
  }
`
