import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  DecisionModal,
  ModalBasic,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { SpinnerWrapped } from 'components/Spinner'
import { translationsShared } from 'translations'

import { useItemsAnalytics } from '../../../../hooks/useItemsAnalytics'
import { CategoryDeleteModalFragment } from './CategoryDeleteModal.generated'
import { translations } from './CategoryDeleteModal.i18n'
import { CategoryDeleteModalItemsReassign } from './CategoryDeleteModalItemsReassign'
import { useGetItemsWithOneCategoryQuery } from './graphql/getItemsWithOneCategory.generated'
import { useDeleteCategory } from './hooks/useDeleteCategory'

interface CategoryDeleteModalProps extends CategoryDeleteModalFragment {
  onClose: () => void
}

export const CategoryDeleteModal = ({
  id,
  name,
  onClose: handleClose,
}: CategoryDeleteModalProps) => {
  const { trackDeleteCategory } = useItemsAnalytics()

  const { data, loading: isLoadingItems } = useGetItemsWithOneCategoryQuery({
    variables: { categoryUuid: id },
    fetchPolicy: 'cache-and-network',
  })

  const navigate = useNavigate()
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const { deleteCategory, isDeletingCategory } = useDeleteCategory()
  const shortEntityId = useSelectedShortEntityUuid()

  const handleConfirmClick = async (newCategoryId?: string) => {
    try {
      await deleteCategory(id, newCategoryId)
      showSuccessToast(t('success', { name }))
      handleClose()

      trackDeleteCategory()

      navigate(ROOT.ORGS.ORG(shortEntityId).ITEM_MANAGEMENT.CATEGORIES.path)
    } catch (error) {
      showErrorToast(t('error', { name }))
    }
  }

  if (isLoadingItems) {
    return (
      <ModalBasic isOpen onCancel={handleClose} title={t('modalTitle')}>
        <ModalBasic.Body>
          <SpinnerWrapped py="8px" justifyContent="center" />
        </ModalBasic.Body>
      </ModalBasic>
    )
  }

  if (!data?.getItemsWithOneCategory?.items?.length) {
    return (
      <DecisionModal
        isLoadingPrimary={isDeletingCategory}
        isOpen
        onCancel={handleClose}
        // NOTE: onClickPrimary={handleConfirmClick} passes the click event
        // to the function. The code below excludes any values to be passed
        // to tell handleConfirmClick that there's so no newCategoryId to use
        // when deleting a category.
        onClickPrimary={() => handleConfirmClick()}
        onClickSecondary={handleClose}
        primaryButtonLabel={tShared('confirm')}
        secondaryButtonLabel={tShared('cancel')}
        title={t('modalTitle')}
      >
        {t('modalDescriptionConfirm')}
      </DecisionModal>
    )
  }

  return (
    <CategoryDeleteModalItemsReassign
      id={id}
      isDeletingCategory={isDeletingCategory}
      items={data.getItemsWithOneCategory.items}
      name={name}
      onCancel={handleClose}
      onDelete={handleConfirmClick}
    />
  )
}
