import { ApolloCache } from '@apollo/client'
import { ItemCategoryStatus } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useSubscription } from 'hooks/useSubscription'

import { CacheLocalStateEntityScopedVariable } from '../../../../../graphql/cache.types'
import { GetItemManagementLocalState } from '../../../graphql/getItemManagementLocalState'
import { CacheLocalStateItemManagement } from '../../../ItemManagement.types'
import { DEFAULT_GET_LIMIT } from '../../../ItemManagement.utils'
import {
  GetCategoriesTableCoreFieldsDoc,
  GetCategoriesTableCoreFieldsFragment,
} from './graphql/getCategoriesTableCoreFields.generated'
import {
  GetCategoriesTable,
  GetCategoriesTableQueryResponse,
} from './graphql/getCategoriesTableQuery.generated'
import {
  GetCategoriesTableSubscription,
  GetCategoriesTableSubscriptionResponse,
  GetCategoriesTableSubscriptionVariables,
} from './graphql/getCategoriesTableSubscription.generated'

export const updateCategoriesCache = ({
  cache,
  updateData,
  entityUuid,
}: {
  updateData: GetCategoriesTableQueryResponse['getItemCategories']['categories']
  cache: ApolloCache<any>
  entityUuid: string
}) => {
  if (!updateData?.length) {
    return
  }

  const localCache = cache.readQuery<
    CacheLocalStateItemManagement,
    CacheLocalStateEntityScopedVariable
  >({
    query: GetItemManagementLocalState,
    variables: { entityUuid },
  })

  const filter = localCache?.local.itemManagement.categories.filterInput ?? null

  const cachedCategoriesResponse =
    cache.readQuery<GetCategoriesTableQueryResponse>({
      query: GetCategoriesTable,
      variables: {
        filter,
        limit: DEFAULT_GET_LIMIT,
      },
    })

  const cachedCategories =
    cachedCategoriesResponse?.getItemCategories?.categories ?? []
  const nextToken =
    cachedCategoriesResponse?.getItemCategories?.nextToken ?? null

  const categoriesToAddInCache: GetCategoriesTableCoreFieldsFragment[] = []

  updateData
    // Do not include deleted categories events
    .filter((category) => category.status !== ItemCategoryStatus.DELETED)
    .forEach((categoryUpdate) => {
      const cachedCategory =
        cache.readFragment<GetCategoriesTableCoreFieldsFragment>({
          id: `ItemCategory:${categoryUpdate.id}`,
          fragment: GetCategoriesTableCoreFieldsDoc,
          fragmentName: 'GetCategoriesTableCoreFields',
        })

      // NOTE: a newly created or updated category will appear in the cache
      // immediately as response is stored before on subscription data
      if (cachedCategory) {
        const categoryExistsInQuery = cachedCategories.some(
          (category) => category?.id === cachedCategory?.id
        )

        // NOTE: only write to the list query if the category doesn't exist
        if (!categoryExistsInQuery) {
          categoriesToAddInCache.push(cachedCategory)
        }
      }
    })

  if (categoriesToAddInCache.length) {
    cache.writeQuery<GetCategoriesTableQueryResponse>({
      query: GetCategoriesTable,
      variables: {
        filter,
        limit: DEFAULT_GET_LIMIT,
      },
      data: {
        getItemCategories: {
          // NOTE: Always add at the top of the table for now
          // https://npco-dev.atlassian.net/browse/ZD-15369 task to
          // implement filtering and sorting on categories update
          categories: [...categoriesToAddInCache, ...cachedCategories],
          nextToken,
        },
      },
    })
  }
}

export const useGetCategoriesTableSubscription = () => {
  const entityUuid = useSelectedEntityUuid()

  return useSubscription<
    GetCategoriesTableSubscriptionResponse,
    GetCategoriesTableSubscriptionVariables
  >(GetCategoriesTableSubscription, {
    onData: ({ client, data }) => {
      const updatedCategories = data?.data?.onItemCategoryUpdate?.categories

      if (!updatedCategories || !entityUuid) {
        return
      }
      updateCategoriesCache({
        cache: client.cache,
        updateData: updatedCategories,
        entityUuid,
      })
    },
    variables: { entityUuid },
    skip: !entityUuid,
  })
}
