import { ApolloError, gql } from '@apollo/client'

import { BusinessesListCustomerEntityMappingFragment } from './BusinessesList.generated'
import { BusinessRow } from './BusinessRow/BusinessRow'
import { ErrorState } from './ErrorState/ErrorState'
import { LoadingState } from './LoadingState/LoadingState'
import { NoBusinessResult } from './NoBusinessResult/NoBusinessResult'

interface BusinessesListProps {
  entityRelations:
    | BusinessesListCustomerEntityMappingFragment['entityRelations']
    | undefined
  isLoading: boolean
  hasError?: ApolloError
  reload: () => void
  isSingleVisibleTab: boolean
}

export const BusinessesList = ({
  entityRelations,
  isLoading,
  hasError,
  reload,
  isSingleVisibleTab,
}: BusinessesListProps) => {
  if (isLoading) {
    return <LoadingState />
  }

  if (!entityRelations || hasError) {
    return <ErrorState reload={reload} />
  }

  if (!entityRelations.length) {
    return <NoBusinessResult />
  }

  return (
    <>
      {entityRelations.map((entityRelation) => (
        <BusinessRow
          key={entityRelation.entity.id}
          entityRelation={entityRelation}
          isDisabled={isSingleVisibleTab && entityRelation.isVisible}
        />
      ))}
    </>
  )
}

BusinessesList.fragments = {
  CustomerEntityMapping: gql`
    fragment BusinessesListCustomerEntityMappingFragment on CustomerEntityMapping {
      entityRelations {
        ...BusinessRowCustomerEntityRelationFragment
      }
    }

    ${BusinessRow.fragments.CustomerEntityRelation}
  `,
}
