import { lazy } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom-v5-compat'
import { CustomerRole } from '@npco/mp-gql-types'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { TransactionsRoutes } from 'features/Transactions/routes/TransactionsRoutes'

import { ROOT } from 'const/routes'
import { lazyWithPrefetch } from 'utils/prefetchBundle'
import { Devices } from 'pages/Devices/Devices'
import { NotFound } from 'pages/NotFound'
import { Reports } from 'pages/Reports/Reports'
import { ReportsNew } from 'pages/Reports/ReportsNew/ReportsNew'
import { DashboardLayoutContent } from 'layouts/DashboardLayout'
import { LazyLoadComponent } from 'components/LazyLoadComponent/LazyLoadComponent'
import { RoleGuardedRoutes } from 'components/RoleGuardedRoutes'

import { DepositsRoutes } from './DepositsRoutes/DepositsRoutes'
import { SimRoutes } from './SimRoutes/SimRoutes'
import { SitesRoutes } from './SitesRoutes/SitesRoutes'

const ItemManagementRoutes = lazy(
  () =>
    import(
      'features/ItemManagement/routes/ItemManagementRoutes/ItemManagementRoutes'
    )
)

const VirtualTerminalRoutes = lazyWithPrefetch(
  () => import('features/VirtualTerminal/VirtualTerminalRoutes')
)

export const PaymentsRoutes = () => {
  const flags = useFeatureFlags()
  const shortEntityId = useSelectedShortEntityUuid()

  const reportsElement = flags.ReportsFilterUplift ? (
    <ReportsNew />
  ) : (
    <Reports />
  )

  return (
    <Routes>
      <Route
        element={
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        }
      >
        <Route
          index
          element={
            <Navigate
              to={ROOT.ORGS.ORG(shortEntityId).TRANSACTIONS.path}
              replace
            />
          }
        />
        <Route
          path={`${ROOT.ORGS.ORG().TRANSACTIONS.relative}/*`}
          element={
            <LazyLoadComponent>
              <TransactionsRoutes />
            </LazyLoadComponent>
          }
        />
        <Route
          path={`${ROOT.ORGS.ORG().VIRTUAL_TERMINAL.relative}/*`}
          element={
            <LazyLoadComponent>
              <VirtualTerminalRoutes />
            </LazyLoadComponent>
          }
        />
        <Route
          path={`${ROOT.ORGS.ORG().PAYMENTS.SIM.relative}/*`}
          element={<SimRoutes />}
        />
        <Route
          path={ROOT.ORGS.ORG().PAYMENTS.DEVICES.relative}
          element={<Devices />}
        />
        <Route
          element={<RoleGuardedRoutes allowedRoles={[CustomerRole.ADMIN]} />}
        >
          <Route
            path={`${ROOT.ORGS.ORG().PAYMENTS.SETTLEMENTS.relative}/*`}
            element={<DepositsRoutes />}
          />
        </Route>
        <Route
          path={ROOT.ORGS.ORG().PAYMENTS.REPORTS.relative}
          element={
            flags.CashFlowReporting ? (
              <Navigate
                to={ROOT.ORGS.ORG(shortEntityId).REPORTS.SALES.path}
                replace
              />
            ) : (
              reportsElement
            )
          }
        />
        <Route
          path={`${ROOT.ORGS.ORG().SITES.relative}/*`}
          element={<SitesRoutes />}
        />
        <Route
          path={`${ROOT.ORGS.ORG().ITEM_MANAGEMENT.relative}/*`}
          element={
            <LazyLoadComponent>
              <ItemManagementRoutes />
            </LazyLoadComponent>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}
