import * as UseSelectedEntityUuidHook from '../hooks/useSelectedEntityUuid/useSelectedEntityUuid'

export const mockedEntityUuid = '1b1404d7-5c2b-4a14-bf9e-8bdc494e7234'

export const mockUseSelectedEntityUuid = (
  entityUuid: string = mockedEntityUuid
) => {
  jest
    .spyOn(UseSelectedEntityUuidHook, 'useSelectedEntityUuid')
    .mockReturnValue(entityUuid)
}
