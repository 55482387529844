import { ONBOARDING_SHOP, PORTAL_SHOP } from '@npco/mp-feature-onboarding-shop'
import { ACCOUNT } from '@npco/mp-utils-accounts-routing'

export enum ROUTE_NAME {
  PORTAL_ACCOUNTS_TRANSFER_CREATE = 'PORTAL_ACCOUNTS_TRANSFER_CREATE',
  JOIN_WAIT_LIST = 'JOIN_WAIT_LIST',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SIGNUP = 'SIGNUP',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  REGISTER_PHONE = 'REGISTER_PHONE',
  VALIDATE_CODE = 'VALIDATE_CODE',
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  CUSTOMER_PREFERENCES = 'CUSTOMER_PREFERENCES',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  LOGIN_ERROR_ONBOARDING_STATUS = 'LOGIN_ERROR_ONBOARDING_STATUS',
  LOGIN_ERROR_ENTITY_DISABLED = 'LOGIN_ERROR_ENTITY_DISABLED',
  LOGIN_ERROR_ENTITY_ABANDONED = 'LOGIN_ERROR_ENTITY_ABANDONED',
  PIN_SUCCESS = 'PIN_SUCCESS',

  PORTAL_KYC_EXISTING_USER = 'PORTAL_KYC_EXISTING_USER',
  PORTAL_KYC_NEW_USER = 'PORTAL_KYC_NEW_USER',

  PORTAL = 'PORTAL',
  PORTAL_DEMOS = 'PORTAL_DEMOS',
  PORTAL_OVERVIEW = 'PORTAL_OVERVIEW',
  PORTAL_HELP = 'PORTAL_HELP',
  PORTAL_REFERRAL = 'PORTAL_REFERRAL',
  SHOP = 'SHOP',
  PORTAL_NOTIFICATIONS = 'PORTAL_NOTIFICATIONS',

  PORTAL_KYC = 'PORTAL_KYC',
  PORTAL_KYC_MEDICARE = 'PORTAL_KYC_MEDICARE',
  PORTAL_KYC_PASSPORT = 'PORTAL_KYC_PASSPORT',
  PORTAL_KYC_DRIVING_LICENCE = 'PORTAL_KYC_DRIVING_LICENCE',
  PORTAL_KYC_NO_IDENTIFICATION = 'PORTAL_KYC_NO_IDENTIFICATION',
  PORTAL_KYC_FINALISE = 'PORTAL_KYC_FINALISE',
  PORTAL_KYC_YOUR_INFORMATION = 'PORTAL_KYC_YOUR_INFORMATION',
  PORTAL_KYC_YOUR_IDENTITY = 'PORTAL_KYC_YOUR_IDENTITY',
  PORTAL_KYC_TIME_FOR_A_SELFIE = 'PORTAL_KYC_TIME_FOR_A_SELFIE',
  PORTAL_KYC_SELFIE_VERIFICATION = 'PORTAL_KYC_SELFIE_VERIFICATION',
  PORTAL_KYC_VERIFICATION_FAILED = 'PORTAL_KYC_VERIFICATION_FAILED',
  PORTAL_KYC_RESIDENTAL_ADDRESS = 'PORTAL_KYC_RESIDENTAL_ADDRESS',
  PORTAL_KYC_REJECTED = 'PORTAL_KYC_REJECTED',
  PORTAL_KYC_SUCCESS = 'PORTAL_KYC_SUCCESS',
  PORTAL_KYC_IN_REVIEW = 'PORTAL_KYC_IN_REVIEW',
  PORTAL_KYC_UPLOAD_SUCCESS = 'PORTAL_KYC_UPLOAD_SUCCESS',
  PORTAL_KYC_REVIEW_DOCUMENT_UPLOAD = 'PORTAL_KYC_REVIEW_DOCUMENT_UPLOAD',
  PORTAL_KYC_DOCUMENT_UPLOAD = 'PORTAL_KYC_DOCUMENT_UPLOAD',

  PORTAL_PAYMENTS = 'PORTAL_PAYMENTS',
  PORTAL_PAYMENTS_REPORTS = 'PORTAL_PAYMENTS_REPORTS',
  PORTAL_PAYMENTS_TRANSACTIONS = 'PORTAL_PAYMENTS_TRANSACTIONS',
  PORTAL_PAYMENTS_DEPOSITS = 'PORTAL_PAYMENTS_DEPOSITS',
  PORTAL_PAYMENTS_DEVICES = 'PORTAL_PAYMENTS_DEVICES',

  PORTAL_PAYMENTS_DEPOSITS_STATEMENTS = 'PORTAL_PAYMENTS_DEPOSITS_STATEMENTS',
  PORTAL_PAYMENTS_DEPOSITS_ACCOUNT = 'PORTAL_PAYMENTS_DEPOSITS_SETTINGS',
  PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_DETAILS = 'PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_DETAILS',
  PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT = 'PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT',
  PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT_SUCCESS = 'PORTAL_PAYMENTS_DEPOSITS_SETTINGS_ADD_ACCOUNT_SUCCESS',
  PORTAL_PAYMENTS_DEPOSITS_SETTINGS_EDIT_ACCOUNT = 'PORTAL_PAYMENTS_DEPOSITS_SETTINGS_EDIT_ACCOUNT',
  PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_EDIT_ACCOUNT_DETAIL = 'PORTAL_PAYMENTS_DEPOSITS_ACCOUNT_EDIT_ACCOUNT_DETAIL',

  PORTAL_PAYMENTS_SITES = 'PORTAL_PAYMENTS_SITES',
  PORTAL_PAYMENTS_SITES_CREATE_SITE = 'PORTAL_PAYMENTS_SITES_CREATE_SITE',
  PORTAL_PAYMENTS_SITES_SITE = 'PORTAL_PAYMENTS_SITES_SITE',
  PORTAL_PAYMENTS_SITES_SITE_USERS = 'PORTAL_PAYMENTS_SITES_SITE_USERS',
  PORTAL_PAYMENTS_SITES_SITE_USERS_EDIT = 'PORTAL_PAYMENTS_SITES_SITE_USERS_EDIT',
  PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_TYPE = 'PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_TYPE',
  PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_DETAILS = 'PORTAL_PAYMENTS_SITES_SITE_USERS_CREATE_DETAILS',
  PORTAL_PAYMENTS_SITES_SITE_GENERAL = 'PORTAL_PAYMENTS_SITES_SITE_GENERAL',
  PORTAL_PAYMENTS_SITES_SITE_GENERAL_EDIT = 'PORTAL_PAYMENTS_SITES_SITE_GENERAL_EDIT',
  PORTAL_PAYMENTS_SITES_SITE_DEVICES = 'PORTAL_PAYMENTS_SITES_SITE_DEVICES',
  PORTAL_PAYMENTS_SITES_SITE_PAYMENTS = 'PORTAL_PAYMENTS_SITES_SITE_PAYMENTS',
  PORTAL_PAYMENTS_SITES_SITE_RECEIPT = 'PORTAL_PAYMENTS_SITES_SITE_RECEIPT',
  PORTAL_PAYMENTS_SITES_SITE_APPEARANCE = 'PORTAL_PAYMENTS_SITES_SITE_APPEARANCE',

  PORTAL_SETTINGS = 'PORTAL_SETTINGS',
  PORTAL_SETTINGS_PROFILE = 'PORTAL_SETTINGS_PROFILE',
  PORTAL_SETTINGS_PROFILE_PERSONAL = 'PORTAL_SETTINGS_PROFILE_PERSONAL',
  PORTAL_SETTINGS_PROFILE_PERSONAL_EDIT = 'PORTAL_SETTINGS_PROFILE_PERSONAL_EDIT',
  PORTAL_SETTINGS_PROFILE_SECURITY = 'PORTAL_SETTINGS_PROFILE_SECURITY',
  PORTAL_SETTINGS_PROFILE_SECURITY_CHANGE_NUMBER = 'PORTAL_SETTINGS_PROFILE_SECURITY_CHANGE_NUMBER',
  PORTAL_SETTINGS_PROFILE_SECURITY_VALIDATE_CODE = 'PORTAL_SETTINGS_PROFILE_SECURITY_VALIDATE_CODE',
  PORTAL_SETTINGS_PROFILE_DOCUMENTS = 'PORTAL_SETTINGS_PROFILE_DOCUMENTS',

  PORTAL_SETTINGS_FEES_AND_PRICING = 'PORTAL_SETTINGS_FEES_AND_PRICING',

  PORTAL_SETTINGS_USERS = 'PORTAL_SETTINGS_USERS',
  PORTAL_SETTINGS_USERS_USER_EDIT = 'PORTAL_SETTINGS_USERS_USER_EDIT',
  PORTAL_SETTINGS_USERS_USER_CREATE_TYPE = 'PORTAL_SETTINGS_USERS_USER_CREATE_TYPE',
  PORTAL_SETTINGS_USERS_USER_CREATE_DETAILS = 'PORTAL_SETTINGS_USERS_USER_CREATE_DETAILS',

  PORTAL_ACCOUNTS = 'PORTAL_ACCOUNTS',
  PORTAL_ACCOUNTS_TRANSACTIONS = 'PORTAL_ACCOUNTS_TRANSACTIONS',
  PORTAL_ACCOUNTS_ACCOUNT = 'PORTAL_ACCOUNTS_ACCOUNT',
  PORTAL_ACCOUNTS_ACCOUNT_ID = 'PORTAL_ACCOUNTS_ACCOUNT_ID',
  PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS = 'PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS',
  PORTAL_ACCOUNTS_ACCOUNT_CARDS = 'PORTAL_ACCOUNTS_ACCOUNT_CARDS',
  PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS_TRANSACTION = 'PORTAL_ACCOUNTS_ACCOUNT_TRANSACTIONS_TRANSACTION',
  PORTAL_ACCOUNTS_ACCOUNT_ID_TRANSACTIONS = 'PORTAL_ACCOUNTS_ACCOUNT_ID_TRANSACTIONS',
  PORTAL_ACCOUNTS_ACCOUNT_ID_DETAILS = 'PORTAL_ACCOUNTS_ACCOUNT_ID_DETAILS',
  PORTAL_ACCOUNTS_ACCOUNT_ID_EDIT = 'PORTAL_ACCOUNTS_ACCOUNT_ID_EDIT',
  PORTAL_ACCOUNTS_ACCOUNT_ID_CLOSE = 'PORTAL_ACCOUNTS_ACCOUNT_ID_CLOSE',

  PORTAL_CARDS = 'PORTAL_CARDS',
  PORTAL_CARDS_DEBIT = 'PORTAL_CARDS_DEBIT',
  PORTAL_CARDS_CORPORATE = 'PORTAL_CARDS_CORPORATE',
  PORTAL_CARDS_ACTIVATE = 'PORTAL_CARDS_ACTIVATE',
  PORTAL_CARDS_ID = 'PORTAL_CARDS_ID',
  PORTAL_INVOICES = 'PORTAL_INVOICES',
  PORTAL_INVOICES_OVERVIEW = 'PORTAL_INVOICES_OVERVIEW',
  PORTAL_INVOICES_INVOICE_LIST = 'PORTAL_INVOICES_INVOICE_LIST',
  PORTAL_INVOICES_INVOICE_DETAILS = 'PORTAL_INVOICES_INVOICE_DETAILS',
  PORTAL_INVOICES_INVOICE_CREATE = 'PORTAL_INVOICES_INVOICE_CREATE',
  PORTAL_INVOICES_INVOICE_EDIT = 'PORTAL_INVOICES_INVOICE_EDIT',
  PORTAL_INVOICES_QUOTES = 'PORTAL_INVOICES_QUOTES',
  PORTAL_INVOICES_RECURRING = 'PORTAL_INVOICES_RECURRING',
  PORTAL_INVOICES_SETTINGS = 'PORTAL_INVOICES_SETTINGS',
  PORTAL_INVOICES_SETTINGS_GENERAL = 'PORTAL_INVOICES_SETTINGS_GENERAL',
  PORTAL_INVOICES_SETTINGS_RECEIPTS = 'PORTAL_INVOICES_SETTINGS_RECEIPTS',
  PORTAL_INVOICES_SETTINGS_REMINDERS = 'PORTAL_INVOICES_SETTINGS_REMINDERS',
  PORTAL_INVOICES_SETTINGS_USERS = 'PORTAL_INVOICES_SETTINGS_USERS',
  PORTAL_INVOICES_SETTINGS_USERS_DETAILS = 'PORTAL_INVOICES_SETTINGS_USERS_DETAILS',
  PORTAL_INVOICES_SETTINGS_CUSTOMISATION = 'PORTAL_INVOICES_SETTINGS_CUSTOMISATION',
  PORTAL_INVOICES_QUOTE_CREATE = 'PORTAL_INVOICES_QUOTE_CREATE',

  PORTAL_CONTACTS = 'PORTAL_CONTACTS',
  PORTAL_CONTACTS_BUSINESSES = 'PORTAL_CONTACTS_BUSINESSES',
  PORTAL_CONTACTS_PEOPLE = 'PORTAL_CONTACTS_PEOPLE',
  PORTAL_CONTACTS_PEOPLE_GENERAL = 'PORTAL_CONTACTS_PEOPLE_GENERAL',
  PORTAL_CONTACTS_PEOPLE_PAYMENTS = 'PORTAL_CONTACTS_PEOPLE_PAYMENTS',
  PORTAL_CONTACTS_PEOPLE_ACCOUNTS = 'PORTAL_CONTACTS_PEOPLE_ACCOUNTS',
  PORTAL_CONTACTS_PEOPLE_INVOICES = 'PORTAL_CONTACTS_PEOPLE_INVOICES',
  PORTAL_CONTACTS_PEOPLE_INVOICES_DETAILS = 'PORTAL_CONTACTS_PEOPLE_INVOICES_DETAILS',
  PORTAL_CONTACTS_BUSINESSES_GENERAL = 'PORTAL_CONTACTS_BUSINESSES_GENERAL',
  PORTAL_CONTACTS_BUSINESSES_PAYMENTS = 'PORTAL_CONTACTS_BUSINESSES_PAYMENTS',
  PORTAL_CONTACTS_BUSINESSES_ACCOUNTS = 'PORTAL_CONTACTS_BUSINESSES_ACCOUNTS',
  PORTAL_CONTACTS_BUSINESSES_INVOICES = 'PORTAL_CONTACTS_BUSINESSES_INVOICES',
  PORTAL_CONTACTS_BUSINESSES_INVOICES_DETAILS = 'PORTAL_CONTACTS_BUSINESSES_INVOICES_DETAILS',

  ONBOARDING = 'ONBOARDING',
  ONBOARDING_WELCOME_BACK = 'ONBOARDING_WELCOME_BACK',
  ONBOARDING_YOUR_BUSINESS_INFORMATION = 'ONBOARDING_YOUR_BUSINESS_INFORMATION',
  ONBOARDING_TRY_AGAIN = 'ONBOARDING_TRY_AGAIN',
  ONBOARDING_GOVERNMENT = 'ONBOARDING_GOVERNMENT',
  ONBOARDING_GOVERNMENT_PRINCIPAL_PLACE_OF_BUSINESS = 'ONBOARDING_GOVERNMENT_PRINCIPAL_PLACE_OF_BUSINESS',
  ONBOARDING_GOVERNMENT_REVENUE = 'ONBOARDING_GOVERNMENT_REVENUE',
  ONBOARDING_GOVERNMENT_BUSINESS_TYPE = 'ONBOARDING_GOVERNMENT_BUSINESS_TYPE',
  ONBOARDING_GOVERNMENT_YOUR_ROLE = 'ONBOARDING_GOVERNMENT_YOUR_ROLE',
  ONBOARDING_GOVERNMENT_ABOUT_BUSINESS = 'ONBOARDING_GOVERNMENT_ABOUT_BUSINESS',
  ONBOARDING_GOVERNMENT_BUSINESS_REGULATIONS = 'ONBOARDING_GOVERNMENT_BUSINESS_REGULATIONS',
  ONBOARDING_INDIVIDUAL_NO_ABN = 'ONBOARDING_INDIVIDUAL_NO_ABN',
  ONBOARDING_INDIVIDUAL_NO_ABN_YOUR_BUSINESS_INFORMATION = 'ONBOARDING_INDIVIDUAL_NO_ABN_YOUR_BUSINESS_INFORMATION',
  ONBOARDING_INDIVIDUAL_NO_ABN_PRINCIPAL_PLACE_OF_BUSINESS = 'ONBOARDING_INDIVIDUAL_NO_ABN_PRINCIPAL_PLACE_OF_BUSINESS',
  ONBOARDING_INDIVIDUAL_NO_ABN_REVENUE = 'ONBOARDING_INDIVIDUAL_NO_ABN_REVENUE',
  ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_TYPE = 'ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_TYPE',
  ONBOARDING_INDIVIDUAL_NO_ABN_ABOUT_BUSINESS = 'ONBOARDING_INDIVIDUAL_NO_ABN_ABOUT_BUSINESS',
  ONBOARDING_INDIVIDUAL_NO_ABN_TRADING_NAME = 'ONBOARDING_INDIVIDUAL_NO_ABN_TRADING_NAME',
  ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_REGULATIONS = 'ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_REGULATIONS',
  ONBOARDING_INDIVIDUAL_NO_ABN_UPLOAD_BANK_STATEMENTS = 'ONBOARDING_INDIVIDUAL_NO_ABN_UPLOAD_BANK_STATEMENTS',
  ONBOARDING_INDIVIDUAL = 'ONBOARDING_INDIVIDUAL',
  ONBOARDING_INDIVIDUAL_REVENUE = 'ONBOARDING_INDIVIDUAL_REVENUE',
  ONBOARDING_INDIVIDUAL_TRADING_NAME = 'ONBOARDING_INDIVIDUAL_TRADING_NAME',
  ONBOARDING_INDIVIDUAL_REGISTERED_OFFICE_ADDRESS = 'ONBOARDING_INDIVIDUAL_REGISTERED_OFFICE_ADDRESS',
  ONBOARDING_INDIVIDUAL_BUSINESS_TYPE = 'ONBOARDING_INDIVIDUAL_BUSINESS_TYPE',
  ONBOARDING_INDIVIDUAL_ABOUT_BUSINESS = 'ONBOARDING_INDIVIDUAL_ABOUT_BUSINESS',
  ONBOARDING_INDIVIDUAL_BUSINESS_REGULATIONS = 'ONBOARDING_INDIVIDUAL_BUSINESS_REGULATIONS',
  ONBOARDING_INDIVIDUAL_UPLOAD_BANK_STATEMENTS = 'ONBOARDING_INDIVIDUAL_UPLOAD_BANK_STATEMENTS',
  ONBOARDING_COMPANY = 'ONBOARDING_COMPANY',
  ONBOARDING_COMPANY_SEARCH = 'ONBOARDING_COMPANY_SEARCH',
  ONBOARDING_COMPANY_INFORMATION = 'ONBOARDING_COMPANY_INFORMATION',
  ONBOARDING_BUSINESS_INFORMATION = 'ONBOARDING_BUSINESS_INFORMATION',
  ONBOARDING_COMPANY_TRADING_NAME = 'ONBOARDING_COMPANY_TRADING_NAME',
  ONBOARDING_COMPANY_REGISTERED_OFFICE_ADDRESS = 'ONBOARDING_COMPANY_REGISTERED_OFFICE_ADDRESS',
  ONBOARDING_COMPANY_PLACE_OF_BUSINESS = 'ONBOARDING_COMPANY_PLACE_OF_BUSINESS',
  ONBOARDING_COMPANY_REVENUE = 'ONBOARDING_COMPANY_REVENUE',
  ONBOARDING_COMPANY_BUSINESS_TYPE = 'ONBOARDING_COMPANY_BUSINESS_TYPE',
  ONBOARDING_COMPANY_POSITION = 'ONBOARDING_COMPANY_POSITION',
  ONBOARDING_COMPANY_NAME = 'ONBOARDING_COMPANY_NAME',
  ONBOARDING_COMPANY_CUSTOMERS = 'ONBOARDING_COMPANY_CUSTOMERS',
  ONBOARDING_COMPANY_CUSTOMERS_DIRECTORS = 'ONBOARDING_COMPANY_CUSTOMERS_DIRECTORS',
  ONBOARDING_COMPANY_CUSTOMERS_ADD_DIRECTOR = 'ONBOARDING_COMPANY_CUSTOMERS_ADD_DIRECTOR',
  ONBOARDING_COMPANY_CUSTOMERS_EDIT_DIRECTOR = 'ONBOARDING_COMPANY_CUSTOMERS_EDIT_DIRECTOR',
  ONBOARDING_COMPANY_CUSTOMERS_BENEFICIAL_OWNERS = 'ONBOARDING_COMPANY_CUSTOMERS_BENEFICIAL_OWNERS',
  ONBOARDING_COMPANY_CUSTOMERS_ADD_BENEFICIAL_OWNER = 'ONBOARDING_COMPANY_CUSTOMERS_ADD_BENEFICIAL_OWNER',
  ONBOARDING_COMPANY_CUSTOMERS_EDIT_BENEFICIAL_OWNER = 'ONBOARDING_COMPANY_CUSTOMERS_EDIT_BENEFICIAL_OWNER',
  ONBOARDING_COMPANY_CUSTOMERS_ALTERNATE_BENEFICIAL_OWNERS = 'ONBOARDING_COMPANY_CUSTOMERS_ALTERNATE_BENEFICIAL_OWNERS',
  ONBOARDING_COMPANY_CUSTOMERS_ADD_ALTERNATE_BENEFICIAL_OWNER = 'ONBOARDING_COMPANY_CUSTOMERS_ADD_ALTERNATE_BENEFICIAL_OWNER',
  ONBOARDING_COMPANY_CUSTOMERS_EDIT_ALTERNATE_BENEFICIAL_OWNER = 'ONBOARDING_COMPANY_CUSTOMERS_EDIT_ALTERNATE_BENEFICIAL_OWNER',
  ONBOARDING_COMPANY_ABOUT_BUSINESS = 'ONBOARDING_COMPANY_ABOUT_BUSINESS',
  ONBOARDING_COMPANY_BUSINESS_REGULATIONS = 'ONBOARDING_COMPANY_BUSINESS_REGULATIONS',
  ONBOARDING_COMPANY_UPLOAD_BANK_STATEMENTS = 'ONBOARDING_COMPANY_UPLOAD_BANK_STATEMENTS',
  ONBOARDING_TRUST = 'ONBOARDING_TRUST',
  ONBOARDING_TRUST_TRADING_NAME = 'ONBOARDING_TRUST_TRADING_NAME',
  ONBOARDING_TRUST_REGISTERED_OFFICE_ADDRESS = 'ONBOARDING_TRUST_REGISTERED_OFFICE_ADDRESS',
  ONBOARDING_TRUST_REVENUE = 'ONBOARDING_TRUST_REVENUE',
  ONBOARDING_TRUST_BUSINESS_TYPE = 'ONBOARDING_TRUST_BUSINESS_TYPE',
  ONBOARDING_TRUST_POSITION = 'ONBOARDING_TRUST_POSITION',
  ONBOARDING_TRUST_CUSTOMERS = 'ONBOARDING_TRUST_CUSTOMERS',
  ONBOARDING_TRUST_CUSTOMERS_SETTLED_SUM = 'ONBOARDING_TRUST_CUSTOMERS_SETTLED_SUM',
  ONBOARDING_TRUST_CUSTOMERS_SETTLOR_INFORMATION = 'ONBOARDING_TRUST_CUSTOMERS_SETTLOR_INFORMATION',
  ONBOARDING_TRUST_CUSTOMERS_BENEFICIARIES = 'ONBOARDING_TRUST_CUSTOMERS_BENEFICIARIES',
  ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIARIES = 'ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIARIES',
  ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIARIES = 'ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIARIES',
  ONBOARDING_TRUST_CUSTOMERS_TRUSTEES = 'ONBOARDING_TRUST_CUSTOMERS_TRUSTEES',
  ONBOARDING_TRUST_CUSTOMERS_ADD_TRUSTEES = 'ONBOARDING_TRUST_CUSTOMERS_ADD_TRUSTEES',
  ONBOARDING_TRUST_CUSTOMERS_EDIT_TRUSTEES = 'ONBOARDING_TRUST_CUSTOMERS_EDIT_TRUSTEES',
  ONBOARDING_TRUST_CUSTOMERS_BENEFICIAL_OWNERS = 'ONBOARDING_TRUST_CUSTOMERS_BENEFICIAL_OWNERS',
  ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIAL_OWNERS = 'ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIAL_OWNERS',
  ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIAL_OWNERS = 'ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIAL_OWNERS',
  ONBOARDING_TRUST_UPLOAD_DOCUMENTS = 'ONBOARDING_TRUST_UPLOAD_DOCUMENTS',
  ONBOARDING_TRUST_ABOUT_BUSINESS = 'ONBOARDING_TRUST_ABOUT_BUSINESS',
  ONBOARDING_TRUST_BUSINESS_REGULATIONS = 'ONBOARDING_TRUST_BUSINESS_REGULATIONS',
  ONBOARDING_TRUST_UPLOAD_BANK_STATEMENTS = 'ONBOARDING_TRUST_UPLOAD_BANK_STATEMENTS',
  ONBOARDING_PARTNERSHIP = 'ONBOARDING_PARTNERSHIP',
  ONBOARDING_PARTNERSHIP_TRADING_NAME = 'ONBOARDING_PARTNERSHIP_TRADING_NAME',
  ONBOARDING_PARTNERSHIP_REGISTERED_OFFICE_ADDRESS = 'ONBOARDING_PARTNERSHIP_REGISTERED_OFFICE_ADDRESS',
  ONBOARDING_PARTNERSHIP_REVENUE = 'ONBOARDING_PARTNERSHIP_REVENUE',
  ONBOARDING_PARTNERSHIP_BUSINESS_TYPE = 'ONBOARDING_PARTNERSHIP_BUSINESS_TYPE',
  ONBOARDING_PARTNERSHIP_POSITION = 'ONBOARDING_PARTNERSHIP_POSITION',
  ONBOARDING_PARTNERSHIP_CUSTOMERS = 'ONBOARDING_PARTNERSHIP_CUSTOMERS',
  ONBOARDING_PARTNERSHIP_CUSTOMERS_PARTNERS = 'ONBOARDING_PARTNERSHIP_CUSTOMERS_PARTNERS',
  ONBOARDING_PARTNERSHIP_CUSTOMERS_ADD_PARTNER = 'ONBOARDING_PARTNERSHIP_CUSTOMERS_ADD_PARTNER',
  ONBOARDING_PARTNERSHIP_CUSTOMERS_EDIT_PARTNER = 'ONBOARDING_PARTNERSHIP_CUSTOMERS_EDIT_PARTNER',
  ONBOARDING_PARTNERSHIP_ABOUT_BUSINESS = 'ONBOARDING_PARTNERSHIP_ABOUT_BUSINESS',
  ONBOARDING_PARTNERSHIP_BUSINESS_REGULATIONS = 'ONBOARDING_PARTNERSHIP_BUSINESS_REGULATIONS',
  ONBOARDING_PARTNERSHIP_UPLOAD_DOCUMENTS = 'ONBOARDING_PARTNERSHIP_UPLOAD_DOCUMENTS',
  ONBOARDING_PARTNERSHIP_UPLOAD_BANK_STATEMENTS = 'ONBOARDING_PARTNERSHIP_UPLOAD_BANK_STATEMENTS',
  ONBOARDING_ASSOCIATION = 'ONBOARDING_ASSOCIATION',
  ONBOARDING_ASSOCIATION_TRADING_NAME = 'ONBOARDING_ASSOCIATION_TRADING_NAME',
  ONBOARDING_ASSOCIATION_REGISTERED_OFFICE_ADDRESS = 'ONBOARDING_ASSOCIATION_REGISTERED_OFFICE_ADDRESS',
  ONBOARDING_ASSOCIATION_PLACE_OF_BUSINESS = 'ONBOARDING_ASSOCIATION_PLACE_OF_BUSINESS',
  ONBOARDING_ASSOCIATION_REVENUE = 'ONBOARDING_ASSOCIATION_REVENUE',
  ONBOARDING_ASSOCIATION_BUSINESS_TYPE = 'ONBOARDING_ASSOCIATION_BUSINESS_TYPE',
  ONBOARDING_ASSOCIATION_POSITION = 'ONBOARDING_ASSOCIATION_POSITION',
  ONBOARDING_ASSOCIATION_CUSTOMERS = 'ONBOARDING_ASSOCIATION_CUSTOMERS',
  ONBOARDING_ASSOCIATION_CUSTOMERS_CHAIR = 'ONBOARDING_ASSOCIATION_CUSTOMERS_CHAIR',
  ONBOARDING_ASSOCIATION_CUSTOMERS_SECRETARY = 'ONBOARDING_ASSOCIATION_CUSTOMERS_SECRETARY',
  ONBOARDING_ASSOCIATION_CUSTOMERS_TREASURER = 'ONBOARDING_ASSOCIATION_CUSTOMERS_TREASURER',
  ONBOARDING_ASSOCIATION_ABOUT_BUSINESS = 'ONBOARDING_ASSOCIATION_ABOUT_BUSINESS',
  ONBOARDING_ASSOCIATION_BUSINESS_REGULATIONS = 'ONBOARDING_ASSOCIATION_BUSINESS_REGULATIONS',
  ONBOARDING_ASSOCIATION_UPLOAD_DOCUMENTS = 'ONBOARDING_ASSOCIATION_UPLOAD_DOCUMENTS',
  ONBOARDING_ASSOCIATION_UPLOAD_BANK_STATEMENTS = 'ONBOARDING_ASSOCIATION_UPLOAD_BANK_STATEMENTS',
  ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE',
  ONBOARDING_KYC = 'ONBOARDING_KYC',
  ONBOARDING_KYC_MEDICARE = 'ONBOARDING_KYC_MEDICARE',
  ONBOARDING_KYC_PASSPORT = 'ONBOARDING_KYC_PASSPORT',
  ONBOARDING_KYC_DRIVING_LICENCE = 'ONBOARDING_KYC_DRIVING_LICENCE',
  ONBOARDING_KYC_YOUR_INFORMATION = 'ONBOARDING_KYC_YOUR_INFORMATION',
  ONBOARDING_KYC_VERIFICATION_FAILED = 'ONBOARDING_KYC_VERIFICATION_FAILED',
  ONBOARDING_KYC_RESIDENTAL_ADDRESS = 'ONBOARDING_KYC_RESIDENTAL_ADDRESS',
  ONBOARDING_KYC_NO_IDENTIFICATION = 'ONBOARDING_KYC_NO_IDENTIFICATION',
  ONBOARDING_KYC_TIME_FOR_A_SELFIE = 'ONBOARDING_KYC_TIME_FOR_A_SELFIE',
  ONBOARDING_KYC_SELFIE_VERIFICATION = 'ONBOARDING_KYC_SELFIE_VERIFICATION',
  ONBOARDING_BUSINESS_REVIEW = 'ONBOARDING_BUSINESS_REVIEW',
  ONBOARDING_BUSINESS_REVIEW_UPLOAD_DOCUMENTS = 'ONBOARDING_BUSINESS_REVIEW_UPLOAD_DOCUMENTS',
  ONBOARDING_BUSINESS_REVIEW_BIOMETRICS = 'ONBOARDING_BUSINESS_REVIEW_BIOMETRICS',
  ONBOARDING_BUSINESS_REVIEW_BIOMETRICS_ONFIDO = 'ONBOARDING_BUSINESS_REVIEW_BIOMETRICS_ONFIDO',
  ONBOARDING_UPLOAD_DOCUMENTS_LATER = 'ONBOARDING_UPLOAD_DOCUMENTS_LATER',
  ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE = 'ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE',
  ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE_UPLOAD_LATER = 'ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE_UPLOAD_LATER',

  MY_ZELLER_COM = 'MY_ZELLER_COM',
}

export enum ROUTE_PARAM_NAME {
  PORTAL_ACCOUNT_ID = 'accountId',
  PORTAL_ATTRIBUTE_SET_ID = 'attributeSetId',
  PORTAL_CARDS_ID = 'cardId',
  PORTAL_CONTACT_ID = 'contactId',
  PORTAL_DISCOUNT_REF_NUMBER = 'discountRefNumber',
  PORTAL_ENTITY_ID = 'entityId',
  PORTAL_INVOICE_REF_NUMBER = 'invoiceRefNumber',
  PORTAL_INVOICE_SETTINGS_USER_ID = 'invoiceSettingsUserId',
  PORTAL_ITEM_CATEGORY_ID = 'categoryId',
  PORTAL_ITEM_ID = 'itemRefNumber',
  PORTAL_MODIFIER_SET_ID = 'modifierSetId',
  PORTAL_SETTINGS_XERO_USER_ID = 'settingsXeroUserId',
  PORTAL_SPLIT_PAYMENT_ID = 'splitPaymentId',
}

export const ONBOARDING_ROUTES = {
  [ROUTE_NAME.ONBOARDING]: '/onboarding',
  [ROUTE_NAME.ONBOARDING_WELCOME_BACK]: '/onboarding/welcome-back',
  [ROUTE_NAME.ONBOARDING_TRY_AGAIN]: `/onboarding/try-again`,
  [ROUTE_NAME.ONBOARDING_GOVERNMENT]: '/onboarding/government',
  [ROUTE_NAME.ONBOARDING_YOUR_BUSINESS_INFORMATION]:
    '/onboarding/your-business-information',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_PRINCIPAL_PLACE_OF_BUSINESS]:
    '/onboarding/government/place-of-business',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_REVENUE]: '/onboarding/government/revenue',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_BUSINESS_TYPE]:
    '/onboarding/government/business-type',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_YOUR_ROLE]:
    '/onboarding/government/your-role',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_ABOUT_BUSINESS]:
    '/onboarding/government/about-business',
  [ROUTE_NAME.ONBOARDING_GOVERNMENT_BUSINESS_REGULATIONS]:
    '/onboarding/government/business-regulations',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN]: '/onboarding/individual-no-abn',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_YOUR_BUSINESS_INFORMATION]:
    '/onboarding/individual-no-abn/your-business-information',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_PRINCIPAL_PLACE_OF_BUSINESS]:
    '/onboarding/individual-no-abn/principal-place',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_REVENUE]:
    '/onboarding/individual-no-abn/revenue',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_TYPE]:
    '/onboarding/individual-no-abn/business-type',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_ABOUT_BUSINESS]:
    '/onboarding/individual-no-abn/about-business',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_TRADING_NAME]:
    '/onboarding/individual-no-abn/trading-name',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_BUSINESS_REGULATIONS]:
    '/onboarding/individual-no-abn/business-regulations',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_NO_ABN_UPLOAD_BANK_STATEMENTS]:
    '/onboarding/individual-no-abn/upload-bank-statements',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL]: '/onboarding/individual',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_TRADING_NAME]:
    '/onboarding/individual/trading-name',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_REGISTERED_OFFICE_ADDRESS]:
    '/onboarding/individual/registered-office-address',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_REVENUE]: '/onboarding/individual/revenue',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_BUSINESS_TYPE]:
    '/onboarding/individual/business-type',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_ABOUT_BUSINESS]:
    '/onboarding/individual/about-business',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_BUSINESS_REGULATIONS]:
    '/onboarding/individual/business-regulations',
  [ROUTE_NAME.ONBOARDING_INDIVIDUAL_UPLOAD_BANK_STATEMENTS]:
    '/onboarding/individual/upload-bank-statements',
  [ROUTE_NAME.ONBOARDING_COMPANY]: '/onboarding/company',
  [ROUTE_NAME.ONBOARDING_COMPANY_SEARCH]: '/onboarding/company-search',
  [ROUTE_NAME.ONBOARDING_COMPANY_INFORMATION]:
    '/onboarding/company-information',
  [ROUTE_NAME.ONBOARDING_BUSINESS_INFORMATION]:
    '/onboarding/business-information',
  [ROUTE_NAME.ONBOARDING_COMPANY_TRADING_NAME]:
    '/onboarding/company/trading-name',
  [ROUTE_NAME.ONBOARDING_COMPANY_REGISTERED_OFFICE_ADDRESS]:
    '/onboarding/company/registered-office-address',
  [ROUTE_NAME.ONBOARDING_COMPANY_PLACE_OF_BUSINESS]:
    '/onboarding/company/place-of-business',
  [ROUTE_NAME.ONBOARDING_COMPANY_REVENUE]: '/onboarding/company/revenue',
  [ROUTE_NAME.ONBOARDING_COMPANY_BUSINESS_TYPE]:
    '/onboarding/company/business-type',
  [ROUTE_NAME.ONBOARDING_COMPANY_POSITION]: '/onboarding/company/position',
  [ROUTE_NAME.ONBOARDING_COMPANY_NAME]: '/onboarding/company/name',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS]: '/onboarding/company/customers',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_DIRECTORS]:
    '/onboarding/company/customers/directors',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_ADD_DIRECTOR]:
    '/onboarding/company/customers/add-director',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_EDIT_DIRECTOR]:
    '/onboarding/company/customers/edit-director',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_BENEFICIAL_OWNERS]:
    '/onboarding/company/customers/beneficial-owners',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_ADD_BENEFICIAL_OWNER]:
    '/onboarding/company/customers/add-beneficial-owner',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_EDIT_BENEFICIAL_OWNER]:
    '/onboarding/company/customers/edit-beneficial-owner',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_ALTERNATE_BENEFICIAL_OWNERS]:
    '/onboarding/company/customers/alternate-beneficial-owners',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_ADD_ALTERNATE_BENEFICIAL_OWNER]:
    '/onboarding/company/customers/add-alternate-beneficial-owner',
  [ROUTE_NAME.ONBOARDING_COMPANY_CUSTOMERS_EDIT_ALTERNATE_BENEFICIAL_OWNER]:
    '/onboarding/company/customers/edit-alternate-beneficial-owner',
  [ROUTE_NAME.ONBOARDING_COMPANY_ABOUT_BUSINESS]:
    '/onboarding/company/about-business',
  [ROUTE_NAME.ONBOARDING_COMPANY_BUSINESS_REGULATIONS]:
    '/onboarding/company/business-regulations',
  [ROUTE_NAME.ONBOARDING_COMPANY_UPLOAD_BANK_STATEMENTS]:
    '/onboarding/company/upload-bank-statements',
  [ROUTE_NAME.ONBOARDING_TRUST]: '/onboarding/trust',
  [ROUTE_NAME.ONBOARDING_TRUST_TRADING_NAME]: '/onboarding/trust/trading-name',
  [ROUTE_NAME.ONBOARDING_TRUST_REGISTERED_OFFICE_ADDRESS]:
    '/onboarding/trust/registered-office-address',
  [ROUTE_NAME.ONBOARDING_TRUST_REVENUE]: '/onboarding/trust/revenue',
  [ROUTE_NAME.ONBOARDING_TRUST_BUSINESS_TYPE]:
    '/onboarding/trust/business-type',
  [ROUTE_NAME.ONBOARDING_TRUST_POSITION]: '/onboarding/trust/position',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS]: '/onboarding/trust/customers',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_SETTLED_SUM]:
    '/onboarding/trust/customers/settled-sum',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_SETTLOR_INFORMATION]:
    '/onboarding/trust/customers/settlor-information',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_BENEFICIARIES]:
    '/onboarding/trust/customers/beneficiaries',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIARIES]:
    '/onboarding/trust/customers/add-beneficiaries',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIARIES]:
    '/onboarding/trust/customers/edit-beneficiaries',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_TRUSTEES]:
    '/onboarding/trust/customers/trustees',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_ADD_TRUSTEES]:
    '/onboarding/trust/customers/add-trustees',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_EDIT_TRUSTEES]:
    '/onboarding/trust/customers/edit-trustees',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_BENEFICIAL_OWNERS]:
    '/onboarding/trust/customers/beneficial-owners',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_ADD_BENEFICIAL_OWNERS]:
    '/onboarding/trust/customers/add-beneficial-owners',
  [ROUTE_NAME.ONBOARDING_TRUST_CUSTOMERS_EDIT_BENEFICIAL_OWNERS]:
    '/onboarding/trust/customers/edit-beneficial-owners',
  [ROUTE_NAME.ONBOARDING_TRUST_UPLOAD_DOCUMENTS]:
    '/onboarding/trust/upload-documents',
  [ROUTE_NAME.ONBOARDING_TRUST_ABOUT_BUSINESS]:
    '/onboarding/trust/about-business',
  [ROUTE_NAME.ONBOARDING_TRUST_BUSINESS_REGULATIONS]:
    '/onboarding/trust/business-regulations',
  [ROUTE_NAME.ONBOARDING_TRUST_UPLOAD_BANK_STATEMENTS]:
    '/onboarding/trust/upload-bank-statements',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP]: '/onboarding/partnership',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_TRADING_NAME]:
    '/onboarding/partnership/trading-name',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_REGISTERED_OFFICE_ADDRESS]:
    '/onboarding/partnership/registered-office-address',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_REVENUE]:
    '/onboarding/partnership/revenue',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_BUSINESS_TYPE]:
    '/onboarding/partnership/business-type',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_POSITION]:
    '/onboarding/partnership/position',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_CUSTOMERS]:
    '/onboarding/partnership/customers',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_CUSTOMERS_PARTNERS]:
    '/onboarding/partnership/customers/partners',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_CUSTOMERS_ADD_PARTNER]:
    '/onboarding/partnership/customers/add-partner',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_CUSTOMERS_EDIT_PARTNER]:
    '/onboarding/partnership/customers/edit-partner',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_ABOUT_BUSINESS]:
    '/onboarding/partnership/about-business',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_BUSINESS_REGULATIONS]:
    '/onboarding/partnership/business-regulations',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_UPLOAD_DOCUMENTS]:
    '/onboarding/partnership/upload-documents',
  [ROUTE_NAME.ONBOARDING_PARTNERSHIP_UPLOAD_BANK_STATEMENTS]:
    '/onboarding/partnership/upload-bank-statements',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION]: '/onboarding/association',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_TRADING_NAME]:
    '/onboarding/association/trading-name',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_REGISTERED_OFFICE_ADDRESS]:
    '/onboarding/association/registered-office-address',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_PLACE_OF_BUSINESS]:
    '/onboarding/association/place-of-business',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_REVENUE]:
    '/onboarding/association/revenue',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_BUSINESS_TYPE]:
    '/onboarding/association/business-type',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_POSITION]:
    '/onboarding/association/position',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_CUSTOMERS]:
    '/onboarding/association/customers',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_CUSTOMERS_CHAIR]:
    '/onboarding/association/customers/chair',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_CUSTOMERS_SECRETARY]:
    '/onboarding/association/customers/secretary',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_CUSTOMERS_TREASURER]:
    '/onboarding/association/customers/treasurer',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_ABOUT_BUSINESS]:
    '/onboarding/association/about-business',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_BUSINESS_REGULATIONS]:
    '/onboarding/association/business-regulations',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_UPLOAD_DOCUMENTS]:
    '/onboarding/association/upload-documents',
  [ROUTE_NAME.ONBOARDING_ASSOCIATION_UPLOAD_BANK_STATEMENTS]:
    '/onboarding/association/upload-bank-statements',
  [ROUTE_NAME.ONBOARDING_COMPLETE]: '/onboarding/complete',
  [ROUTE_NAME.ONBOARDING_KYC]: '/onboarding/kyc',
  [ROUTE_NAME.ONBOARDING_KYC_MEDICARE]: '/onboarding/kyc/medicare',
  [ROUTE_NAME.ONBOARDING_KYC_PASSPORT]: '/onboarding/kyc/passport',
  [ROUTE_NAME.ONBOARDING_KYC_TIME_FOR_A_SELFIE]:
    '/onboarding/kyc/time-for-a-selfie',
  [ROUTE_NAME.ONBOARDING_KYC_SELFIE_VERIFICATION]:
    '/onboarding/kyc/selfie-verification',
  [ROUTE_NAME.ONBOARDING_KYC_DRIVING_LICENCE]:
    '/onboarding/kyc/driving-licence',
  [ROUTE_NAME.ONBOARDING_KYC_RESIDENTAL_ADDRESS]:
    '/onboarding/kyc/residental-address',
  [ROUTE_NAME.ONBOARDING_KYC_YOUR_INFORMATION]:
    '/onboarding/kyc/your-information',
  [ROUTE_NAME.ONBOARDING_KYC_VERIFICATION_FAILED]:
    '/onboarding/kyc/verification-failed',
  [ROUTE_NAME.ONBOARDING_KYC_NO_IDENTIFICATION]:
    '/onboarding/kyc/no-identification',
  [ROUTE_NAME.ONBOARDING_UPLOAD_DOCUMENTS_LATER]:
    '/onboarding/upload-documents-later',
  [ROUTE_NAME.ONBOARDING_BUSINESS_REVIEW]: '/onboarding/business-review',
  [ROUTE_NAME.ONBOARDING_BUSINESS_REVIEW_UPLOAD_DOCUMENTS]:
    '/onboarding/business-review-upload-documents',
  [ROUTE_NAME.ONBOARDING_BUSINESS_REVIEW_BIOMETRICS]:
    '/onboarding/business-review/biometrics',
  [ROUTE_NAME.ONBOARDING_BUSINESS_REVIEW_BIOMETRICS_ONFIDO]:
    '/onboarding/business-review/biometrics/onfido',
  [ROUTE_NAME.ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE]:
    '/onboarding/pre-onboarding-questionnaire',
  [ROUTE_NAME.ONBOARDING_PRE_ONBOARDED_QUESTIONNAIRE_UPLOAD_LATER]:
    '/onboarding/upload-later/pre-onboarding-questionnaire',
}

export const sitesSubpath = '/sites'
export const siteSubpath = '/site'
export const settingsSubpath = '/settings'

export const getContactPaths = (
  entityId: string,
  contactId: string,
  path: string
) => ({
  path: `/orgs/${entityId}/contacts/${path}/${contactId}`,
  relative: contactId,
  GENERAL: {
    path: `/orgs/${entityId}/contacts/${path}/${contactId}/general`,
    relative: 'general',
    customPageName: 'Contact',
  },
  ACCOUNTS: {
    path: `/orgs/${entityId}/contacts/${path}/${contactId}/accounts`,
    relative: 'accounts',
    customPageName: 'ContactAccounts',
  },
  PAYMENTS: {
    path: `/orgs/${entityId}/contacts/${path}/${contactId}/payments`,
    relative: 'payments',
    customPageName: 'ContactPayments',
  },
  INVOICES: {
    path: `/orgs/${entityId}/contacts/${path}/${contactId}/invoices`,
    relative: 'invoices',
    customPageName: 'ContactInvoices',
    INVOICE: (
      referenceNumber = `:${ROUTE_PARAM_NAME.PORTAL_INVOICE_REF_NUMBER}`
    ) => ({
      path: `/orgs/${entityId}/contacts/${path}/${contactId}/invoices/${referenceNumber}`,
      relative: referenceNumber,
      customPageName: 'ContactInvoiceDetails',
    }),
  },
})

const SHARED_ONBOARDING_ROUTES = (
  basePath: string,
  customPagePrefix: string
) => ({
  TRADING_NAME: {
    path: `${basePath}/trading-name`,
    relative: 'trading-name',
    customPageName: `${customPagePrefix}TradingName`,
  },
  REGISTERED_OFFICE_ADDRESS: {
    path: `${basePath}/registered-office-address`,
    relative: 'registered-office-address',
    customPageName: `${customPagePrefix}RegisteredOfficeAddress`,
  },
  REVENUE: {
    path: `${basePath}/revenue`,
    relative: 'revenue',
    customPageName: `${customPagePrefix}Revenue`,
  },
  BUSINESS_TYPE: {
    path: `${basePath}/business-type`,
    relative: 'business-type',
    customPageName: `${customPagePrefix}BusinessType`,
  },
  POSITION: {
    path: `${basePath}/position`,
    relative: 'position',
    customPageName: `${customPagePrefix}Position`,
  },
  ABOUT_BUSINESS: {
    path: `${basePath}/about-business`,
    relative: 'about-business',
    customPageName: `${customPagePrefix}AboutBusiness`,
  },
  BUSINESS_REGULATIONS: {
    path: `${basePath}/business-regulations`,
    relative: 'business-regulations',
    customPageName: `${customPagePrefix}BusinessRegulations`,
  },
  UPLOAD_BANK_STATEMENTS: {
    path: `${basePath}/upload-bank-statements`,
    relative: 'upload-bank-statements',
    customPageName: `${customPagePrefix}UploadBankStatements`,
  },
})

// TICKET: https://npco-dev.atlassian.net/browse/BANK-561
// Need to find a better, extensible, non redundant structure for routes.
export const ROOT = {
  path: '/',
  relative: '',
  customPageName: 'Login',
  JOIN_WAITLIST: {
    path: '/join-waitlist',
    relative: 'join-waitlist',
    customPageName: 'RegionWaitlist',
  },
  CUSTOMER_PREFERENCES: {
    path: `/customer-preferences`,
    relative: 'customer-preferences',
    customPageName: 'CustomerPreferences',
  },
  EMAIL_VERIFIED: {
    path: '/email-verified',
    relative: 'email-verified',
    customPageName: 'EmailVerified',
  },
  CHANGE_EMAIL: {
    path: '/change-email',
    relative: 'change-email',
    customPageName: 'ChangeEmail',
  },
  UNEXPECTED_ERROR: {
    path: '/unexpected-error',
    relative: 'unexpected-error',
    customPageName: 'UnexpectedError',
  },
  ACCOUNT_CREATED: {
    path: '/account-created',
    relative: 'account-created',
    customPageName: 'AccountCreated',
  },
  FORGOT_PASSWORD: {
    path: '/forgot-password',
    relative: 'forgot-password',
    customPageName: 'ForgotPassword',
  },
  PIN_SUCCESS: {
    path: '/pin-success',
    relative: 'pin-success',
    customPageName: 'PinSuccess',
  },
  REGISTER_PHONE: {
    path: '/register-phone',
    relative: 'register-phone',
    customPageName: 'RegisterPhone',
  },
  VALIDATE_CODE: { path: '/validate-code', customPageName: 'ValidateCode' },
  ONBOARDING: {
    path: '/onboarding',
    relative: 'onboarding',
    customPageName: 'Onboarding_',
    ...ONBOARDING_SHOP,

    WELCOME_BACK: {
      path: '/onboarding/welcome-back',
      relative: 'welcome-back',
      customPageName: 'Onboarding_WelcomeBack',
    },
    TRY_AGAIN: {
      path: '/onboarding/try-again',
      relative: 'try-again',
      customPageName: 'Onboarding_TryAgain',
    },
    YOUR_BUSINESS_INFORMATION: {
      path: '/onboarding/your-business-information',
      relative: 'your-business-information',
      customPageName: 'Onboarding_YourBusinessInformation',
    },
    COMPANY_SEARCH: {
      path: '/onboarding/company-search',
      relative: 'company-search',
      customPageName: 'Onboarding_CompanySearch',
    },
    COMPANY_INFORMATION: {
      path: '/onboarding/company-information',
      relative: 'company-information',
      customPageName: 'Onboarding_CompanyInformation',
    },
    BUSINESS_INFORMATION: {
      path: '/onboarding/business-information',
      relative: 'business-information',
      customPageName: 'Onboarding_BusinessInformation',
    },
    UPLOAD_DOCUMENTS_LATER: {
      path: '/onboarding/upload-documents-later',
      relative: 'upload-documents-later',
      customPageName: 'Onboarding_UploadDocumentsLater',
    },
    BUSINESS_REVIEW: {
      path: '/onboarding/business-review',
      relative: 'business-review',
      customPageName: 'Onboarding_BusinessReview',
      BIOMETRICS: {
        path: '/onboarding/business-review/biometrics',
        relative: 'biometrics',
        customPageName: 'Onboarding_BusinessReviewBiometrics',
        ONFIDO: {
          path: '/onboarding/business-review/biometrics/onfido',
          relative: 'onfido',
          customPageName: 'Onboarding_BusinessReviewBiometricsOnfido',
        },
      },
    },
    BUSINESS_REVIEW_UPLOAD_DOCUMENTS: {
      path: '/onboarding/business-review-upload-documents',
      relative: 'business-review-upload-documents',
      customPageName: 'Onboarding_BusinessReviewUploadDocuments',
    },
    PRE_ONBOARDING_QUESTIONNAIRE: {
      path: '/onboarding/pre-onboarding-questionnaire',
      relative: 'pre-onboarding-questionnaire',
      customPageName: 'Onboarding_PreOnboardingQuestionnaire',
    },
    UPLOAD_LATER: {
      path: '/onboarding/upload-later',
      relative: 'upload-later',
      PRE_ONBOARDING_QUESTIONNAIRE: {
        path: '/onboarding/upload-later/pre-onboarding-questionnaire',
        relative: 'pre-onboarding-questionnaire',
        customPageName:
          'Onboarding_UploadDocumentsLater_PreOnboardingQuestionnaire',
      },
    },
    ASSOCIATION: {
      path: '/onboarding/association',
      relative: 'association',
      customPageName: 'Onboarding_Association',
      ...SHARED_ONBOARDING_ROUTES(
        '/onboarding/association',
        'Onboarding_Association'
      ),
      PLACE_OF_BUSINESS: {
        path: '/onboarding/association/place-of-business',
        relative: 'place-of-business',
        customPageName: 'Onboarding_AssociationPlaceOfBusiness',
      },
      CUSTOMERS: {
        path: '/onboarding/association/customers',
        relative: 'customers',
        customPageName: 'Onboarding_AssociationCustomers',
        CHAIR: {
          path: '/onboarding/association/customers/chair',
          relative: 'chair',
          customPageName: 'Onboarding_AssociationCustomersChair',
        },
        SECRETARY: {
          path: '/onboarding/association/customers/secretary',
          relative: 'secretary',
          customPageName: 'Onboarding_AssociationCustomersSecretary',
        },
        TREASURER: {
          path: '/onboarding/association/customers/treasurer',
          relative: 'treasurer',
          customPageName: 'Onboarding_AssociationCustomersTreasurer',
        },
      },
      UPLOAD_DOCUMENTS: {
        path: '/onboarding/association/upload-documents',
        relative: 'upload-documents',
        customPageName: 'Onboarding_AssociationUploadDocuments',
      },
    },
    COMPANY: {
      path: '/onboarding/company',
      relative: 'company',
      customPageName: 'Onboarding_Company',
      ...SHARED_ONBOARDING_ROUTES('/onboarding/company', 'Onboarding_Company'),
      CUSTOMERS: {
        path: '/onboarding/company/customers',
        relative: 'customers',
        customPageName: 'Onboarding_CompanyCustomers',
        DIRECTORS: {
          path: '/onboarding/company/customers/directors',
          relative: 'directors',
          customPageName: 'Onboarding_CompanyCustomersDirectors',
        },
        ADD_DIRECTOR: {
          path: '/onboarding/company/customers/add-director',
          relative: 'add-director',
          customPageName: 'Onboarding_CompanyCustomersAddDirector',
        },
        EDIT_DIRECTOR: {
          path: '/onboarding/company/customers/edit-director',
          relative: 'edit-director',
          customPageName: 'Onboarding_CompanyCustomersEditDirector',
        },
        BENEFICIAL_OWNERS: {
          path: '/onboarding/company/customers/beneficial-owners',
          relative: 'beneficial-owners',
          customPageName: 'Onboarding_CompanyCustomersBeneficialOwners',
        },
        ADD_BENEFICIAL_OWNER: {
          path: '/onboarding/company/customers/add-beneficial-owner',
          relative: 'add-beneficial-owner',
          customPageName: 'Onboarding_CompanyCustomersAddBeneficialOwner',
        },
        EDIT_BENEFICIAL_OWNER: {
          path: '/onboarding/company/customers/edit-beneficial-owner',
          relative: 'edit-beneficial-owner',
          customPageName: 'Onboarding_CompanyCustomersEditBeneficialOwner',
        },
        ALTERNATE_BENEFICIAL_OWNERS: {
          path: '/onboarding/company/customers/alternate-beneficial-owners',
          relative: 'alternate-beneficial-owners',
          customPageName:
            'Onboarding_CompanyCustomersAlternateBeneficialOwners',
        },
        ADD_ALTERNATE_BENEFICIAL_OWNER: {
          path: '/onboarding/company/customers/add-alternate-beneficial-owner',
          relative: 'add-alternate-beneficial-owner',
          customPageName:
            'Onboarding_CompanyCustomersAddAlternateBeneficialOwner',
        },
        EDIT_ALTERNATE_BENEFICIAL_OWNER: {
          path: '/onboarding/company/customers/edit-alternate-beneficial-owner',
          relative: 'edit-alternate-beneficial-owner',
          customPageName:
            'Onboarding_CompanyCustomersEditAlternateBeneficialOwner',
        },
      },
      NAME: {
        path: '/onboarding/company/name',
        relative: 'name',
        customPageName: 'Onboarding_CompanyName',
      },
      PLACE_OF_BUSINESS: {
        path: '/onboarding/company/place-of-business',
        relative: 'place-of-business',
        customPageName: 'Onboarding_CompanyPlaceOfBusiness',
      },
    },
    INDIVIDUAL: {
      path: '/onboarding/individual',
      relative: 'individual',
      customPageName: 'Onboarding_Individual',
      TRADING_NAME: {
        path: '/onboarding/individual/trading-name',
        relative: 'trading-name',
        customPageName: 'Onboarding_IndividualTradingName',
      },
      REVENUE: {
        path: '/onboarding/individual/revenue',
        relative: 'revenue',
        customPageName: 'Onboarding_IndividualRevenue',
      },
      BUSINESS_TYPE: {
        path: '/onboarding/individual/business-type',
        relative: 'business-type',
        customPageName: 'Onboarding_IndividualBusinessType',
      },
      ABOUT_BUSINESS: {
        path: '/onboarding/individual/about-business',
        relative: 'about-business',
        customPageName: 'Onboarding_IndividualAboutBusiness',
      },
      BUSINESS_REGULATIONS: {
        path: '/onboarding/individual/business-regulations',
        relative: 'business-regulations',
        customPageName: 'Onboarding_IndividualBusinessRegulations',
      },
      UPLOAD_BANK_STATEMENTS: {
        path: '/onboarding/individual/upload-bank-statements',
        relative: 'upload-bank-statements',
        customPageName: 'Onboarding_IndividualUploadBankStatements',
      },
      REGISTERED_OFFICE_ADDRESS: {
        path: '/onboarding/individual/registered-office-address',
        relative: 'registered-office-address',
        customPageName: 'Onboarding_IndividualRegisteredOfficeAddress',
      },
    },
    INDIVIDUAL_NO_ABN: {
      path: '/onboarding/individual-no-abn',
      relative: 'individual-no-abn',
      customPageName: 'Onboarding_IndividualNoAbn',
      PRINCIPAL_PLACE_OF_BUSINESS: {
        path: '/onboarding/individual-no-abn/principal-place',
        relative: 'principal-place',
        customPageName: 'Onboarding_IndividualNoAbnPrincipalPlaceOfBusiness',
      },
      YOUR_BUSINESS_INFORMATION: {
        path: '/onboarding/individual-no-abn/your-business-information',
        relative: 'your-business-information',
        customPageName: 'Onboarding_IndividualNoAbnYourBusinessInformation',
      },
      REVENUE: {
        path: '/onboarding/individual-no-abn/revenue',
        relative: 'revenue',
        customPageName: 'Onboarding_IndividualNoAbnRevenue',
      },
      BUSINESS_TYPE: {
        path: '/onboarding/individual-no-abn/business-type',
        relative: 'business-type',
        customPageName: 'Onboarding_IndividualNoAbnBusinessType',
      },
      ABOUT_BUSINESS: {
        path: '/onboarding/individual-no-abn/about-business',
        relative: 'about-business',
        customPageName: 'Onboarding_IndividualNoAbnAboutBusiness',
      },
      TRADING_NAME: {
        path: '/onboarding/individual-no-abn/trading-name',
        relative: 'trading-name',
        customPageName: 'Onboarding_IndividualNoAbnTradingName',
      },
      BUSINESS_REGULATIONS: {
        path: '/onboarding/individual-no-abn/business-regulations',
        relative: 'business-regulations',
        customPageName: 'Onboarding_IndividualNoAbnBusinessRegulations',
      },
      UPLOAD_BANK_STATEMENTS: {
        path: '/onboarding/individual-no-abn/upload-bank-statements',
        relative: 'upload-bank-statements',
        customPageName: 'Onboarding_IndividualNoAbnUploadBankStatements',
      },
    },
    GOVERNMENT: {
      path: '/onboarding/government',
      relative: 'government',
      customPageName: 'Onboarding_Government',
      PLACE_OF_BUSINESS: {
        path: '/onboarding/government/place-of-business',
        relative: 'place-of-business',
        customPageName: 'Onboarding_GovernmentPrincipalPlaceOfBusiness',
      },
      REVENUE: {
        path: '/onboarding/government/revenue',
        relative: 'revenue',
        customPageName: 'Onboarding_GovernmentRevenue',
      },
      BUSINESS_TYPE: {
        path: '/onboarding/government/business-type',
        relative: 'business-type',
        customPageName: 'Onboarding_GovernmentBusinessType',
      },
      YOUR_ROLE: {
        path: '/onboarding/government/your-role',
        relative: 'your-role',
        customPageName: 'Onboarding_GovernmentYourRole',
      },
      ABOUT_BUSINESS: {
        path: '/onboarding/government/about-business',
        relative: 'about-business',
        customPageName: 'Onboarding_GovernmentAboutBusiness',
      },
      BUSINESS_REGULATIONS: {
        path: '/onboarding/government/business-regulations',
        relative: 'business-regulations',
        customPageName: 'Onboarding_GovernmentBusinessRegulations',
      },
    },
    KYC: {
      path: '/onboarding/kyc',
      relative: 'kyc',
      customPageName: 'Onboarding_Kyc',
      YOUR_INFORMATION: {
        path: '/onboarding/kyc/your-information',
        relative: 'your-information',
        customPageName: 'Onboarding_KycYourInformation',
      },
      MEDICARE: {
        path: '/onboarding/kyc/medicare',
        relative: 'medicare',
        customPageName: 'Onboarding_KycMedicare',
      },
      PASSPORT: {
        path: '/onboarding/kyc/passport',
        relative: 'passport',
        customPageName: 'Onboarding_KycPassport',
      },
      RESIDENTIAL_ADDRESS: {
        // Residential is misspelled in the route name. Will fix it later.
        path: '/onboarding/kyc/residental-address',
        customPageName: 'Onboarding_KycResidentialAddress',
      },
      DRIVING_LICENCE: {
        path: '/onboarding/kyc/driving-licence',
        relative: 'driving-licence',
        customPageName: 'Onboarding_KycDrivingLicence',
      },
      NO_IDENTIFICATION: {
        path: '/onboarding/kyc/no-identification',
        relative: 'no-identification',
        customPageName: 'Onboarding_KycNoIdentification',
      },
      VERIFICATION_FAILED: {
        path: '/onboarding/kyc/verification-failed',
        relative: 'verification-failed',
        customPageName: 'Onboarding_KycVerificationFailed',
      },
      TIME_FOR_A_SELFIE: {
        path: '/onboarding/kyc/time-for-a-selfie',
        relative: 'time-for-a-selfie',
        customPageName: 'Onboarding_TimeForASelfie',
      },
      SELFIE_VERIFICATION: {
        path: '/onboarding/kyc/selfie-verification',
        relative: 'selfie-verification',
        customPageName: 'Onboarding_SelfieVerification',
      },
    },
    PARTNERSHIP: {
      path: '/onboarding/partnership',
      relative: 'partnership',
      customPageName: 'Onboarding_Partnership',
      ...SHARED_ONBOARDING_ROUTES(
        '/onboarding/partnership',
        'Onboarding_Partnership'
      ),
      CUSTOMERS: {
        path: '/onboarding/partnership/customers',
        relative: 'customers',
        customPageName: 'Onboarding_PartnershipCustomers',
        PARTNERS: {
          path: '/onboarding/partnership/customers/partners',
          relative: 'partners',
          customPageName: 'Onboarding_PartnershipCustomersPartners',
        },
        ADD_PARTNER: {
          path: '/onboarding/partnership/customers/add-partner',
          relative: 'add-partner',
          customPageName: 'Onboarding_PartnershipCustomersAddPartner',
        },
        EDIT_PARTNER: {
          path: '/onboarding/partnership/customers/edit-partner',
          relative: 'edit-partner',
          customPageName: 'Onboarding_PartnershipCustomersEditPartner',
        },
      },
      UPLOAD_DOCUMENTS: {
        path: '/onboarding/partnership/upload-documents',
        relative: 'upload-documents',
        customPageName: 'Onboarding_PartnershipUploadDocuments',
      },
    },
    TRUST: {
      path: '/onboarding/trust',
      relative: 'trust',
      customPageName: 'Onboarding_Trust',
      ...SHARED_ONBOARDING_ROUTES('/onboarding/trust', 'Onboarding_Trust'),
      CUSTOMERS: {
        path: '/onboarding/trust/customers',
        relative: 'customers',
        customPageName: 'Onboarding_TrustCustomers',
        SETTLED_SUM: {
          path: '/onboarding/trust/customers/settled-sum',
          relative: 'settled-sum',
          customPageName: 'Onboarding_TrustCustomersSettledSum',
        },
        SETTLOR_INFORMATION: {
          path: '/onboarding/trust/customers/settlor-information',
          relative: 'settlor-information',
          customPageName: 'Onboarding_TrustCustomersSettlorInformation',
        },
        BENEFICIAL_OWNERS: {
          path: '/onboarding/trust/customers/beneficial-owners',
          relative: 'beneficial-owners',
          customPageName: 'Onboarding_TrustCustomersBeneficialOwners',
        },
        ADD_BENEFICIAL_OWNERS: {
          path: '/onboarding/trust/customers/add-beneficial-owners',
          relative: 'add-beneficial-owners',
          customPageName: 'Onboarding_TrustCustomersAddBeneficialOwners',
        },
        EDIT_BENEFICIAL_OWNERS: {
          path: '/onboarding/trust/customers/edit-beneficial-owners',
          relative: 'edit-beneficial-owners',
          customPageName: 'Onboarding_TrustCustomersEditBeneficialOwners',
        },
        BENEFICIARIES: {
          path: '/onboarding/trust/customers/beneficiaries',
          relative: 'beneficiaries',
          customPageName: 'Onboarding_TrustCustomersBeneficiaries',
        },
        ADD_BENEFICIARIES: {
          path: '/onboarding/trust/customers/add-beneficiaries',
          relative: 'add-beneficiaries',
          customPageName: 'Onboarding_TrustCustomersAddBeneficiaries',
        },
        EDIT_BENEFICIARIES: {
          path: '/onboarding/trust/customers/edit-beneficiaries',
          relative: 'edit-beneficiaries',
          customPageName: 'Onboarding_TrustCustomersEditBeneficiaries',
        },
        TRUSTEES: {
          path: '/onboarding/trust/customers/trustees',
          relative: 'trustees',
          customPageName: 'Onboarding_TrustCustomersTrustees',
        },
        ADD_TRUSTEES: {
          path: '/onboarding/trust/customers/add-trustees',
          relative: 'add-trustees',
          customPageName: 'Onboarding_TrustCustomersAddTrustees',
        },
        EDIT_TRUSTEES: {
          path: '/onboarding/trust/customers/edit-trustees',
          relative: 'edit-trustees',
          customPageName: 'Onboarding_TrustCustomersEditTrustees',
        },
      },
      UPLOAD_DOCUMENTS: {
        path: '/onboarding/trust/upload-documents',
        relative: 'upload-documents',
        customPageName: 'Onboarding_TrustUploadDocuments',
      },
    },
    PRE_ONBOARDED_QUESTIONNAIRE: {
      path: '/onboarding/pre-onboarding-questionnaire',
      relative: 'pre-onboarding-questionnaire',
    },
    COMPLETE: {
      path: '/onboarding/complete',
      relative: 'complete',
      customPageName: 'Onboarding_Complete',
    },
  },
  ORGS: {
    path: '/orgs',
    relative: 'orgs',
    MANAGE_BUSINESSES: {
      path: '/orgs/manage-businesses',
      relative: 'manage-businesses',
      customPageName: 'ManageBusinesses',
    },
    ORG: (entityId = `:${ROUTE_PARAM_NAME.PORTAL_ENTITY_ID}`) => ({
      path: `/orgs/${entityId}`,
      relative: entityId,
      customPageName: '',
      ...PORTAL_SHOP(entityId),
      PAYMENTS: {
        path: `/orgs/${entityId}/payments`,
        relative: 'payments',
        customPageName: 'Payments',
        SIM: {
          customPageName: 'Sim',
          path: `/orgs/${entityId}/payments/sim`,
          relative: 'sim',
          BILLING_HISTORY: {
            customPageName: 'SimBillingHistory',
            path: `/orgs/${entityId}/payments/sim/billing-history`,
            relative: 'billing-history',
            STATEMENTS: {
              customPageName: 'SimBillingHistoryStatements',
              path: `/orgs/${entityId}/payments/sim/billing-history/statements`,
              relative: 'statements',
            },
          },
          BILLING_ACCOUNT: {
            customPageName: 'SimBillingAccount',
            path: `/orgs/${entityId}/payments/sim/billing-account`,
            relative: 'billing-account',
          },
        },
        REPORTS: {
          customPageName: 'PaymentsReports',
          path: `/orgs/${entityId}/payments/reports`,
          relative: 'reports',
        },
        DEVICES: {
          customPageName: 'Devices',
          path: `/orgs/${entityId}/payments/devices`,
          relative: 'devices',
        },
        SETTLEMENTS: {
          customPageName: 'Settlements',
          path: `/orgs/${entityId}/payments/settlements`,
          relative: 'settlements',
        },
        DEPOSITS: {
          path: `/orgs/${entityId}/payments/settlements`,
          relative: 'settlements',
          STATEMENTS: {
            path: `/orgs/${entityId}/payments/settlements/statements`,
            relative: 'statements',
            customPageName: 'DepositsStatements',
          },
          SETTINGS: {
            path: `/orgs/${entityId}/payments/settlements/settlement-account`,
            relative: 'settlement-account',
            customPageName: 'DepositsSettings',
          },
        },
        SITES: {
          path: `/orgs/${entityId}/payments/sites`,
          relative: 'sites',
          customPageName: 'Sites',
          CREATE_SITE: {
            path: `/orgs/${entityId}/payments/sites/create-site`,
            relative: 'create-site',
            customPageName: 'SitesCreateSite',
          },
          SITE: {
            customPageName: 'Site',
            path: `/orgs/${entityId}/payments/sites/site`,
            relative: 'site',
            GENERAL: {
              customPageName: 'SiteGeneral',
              path: `/orgs/${entityId}/payments/sites/site/general`,
              relative: 'general',
              EDIT: {
                path: `/orgs/${entityId}/payments/sites/site/general/edit`,
                relative: 'edit',
                customPageName: 'SiteGeneralEdit',
              },
            },
            USERS: {
              customPageName: 'SiteUsers',
              path: `/orgs/${entityId}/payments/sites/site/users`,
              relative: 'users',
              EDIT: {
                customPageName: 'SiteUsersEdit',
                path: `/orgs/${entityId}/payments/sites/site/users/edit`,
                relative: 'edit',
              },
              CREATE_TYPE: {
                customPageName: 'SiteUsersCreateType',
                path: `/orgs/${entityId}/payments/sites/site/users/create-type`,
                relative: 'create-type',
              },
              CREATE_DETAILS: {
                customPageName: 'SiteUsersCreateDetails',
                path: `/orgs/${entityId}/payments/sites/site/users/create-details`,
                relative: 'create-details',
              },
            },
            APPEARANCE: {
              customPageName: 'SiteAppearance',
              path: `/orgs/${entityId}/payments/sites/site/appearance`,
              relative: 'appearance',
            },
            DEVICES: {
              path: `/orgs/${entityId}/payments/sites/site/devices`,
              relative: 'devices',
              customPageName: 'SiteDevices',
            },
            PAYMENTS: {
              path: `/orgs/${entityId}/payments/sites/site/payments`,
              relative: 'payments',
              customPageName: 'SitePayments',
            },
            RECEIPT: {
              path: `/orgs/${entityId}/payments/sites/site/receipt`,
              relative: 'receipt',
              customPageName: 'SiteReceipt',
            },
          },
        },
        TRANSACTIONS: {
          path: `/orgs/${entityId}/payments/transactions`,
          relative: 'transactions',
          customPageName: 'Transactions',
        },
      },
      ONBOARDING: {
        customPageName: 'Onboarding',
        path: '/onboarding',
        relative: 'onboarding',
        INDIVIDUAL_NO_ABN: {
          path: '/onboarding/individual-no-abn',
          relative: 'individual-no-abn',
          customPageName: 'Onboarding_IndividualNoAbn',
        },
      },
      OVERVIEW: {
        path: `/orgs/${entityId}/overview`,
        relative: 'overview',
        customPageName: 'Overview',
        PAYMENTS: {
          path: `/orgs/${entityId}/overview/payments`,
          relative: 'payments',
        },
        FINANCE: {
          path: `/orgs/${entityId}/overview/finance`,
          relative: 'finance',
        },
      },
      NOTIFICATIONS: {
        customPageName: 'Notifications',
        path: `/orgs/${entityId}/notifications`,
        relative: 'notifications',
        PREFERENCES: {
          path: `/orgs/${entityId}/notifications/preferences`,
          relative: 'preferences',
          PAYMENTS: {
            path: `/orgs/${entityId}/notifications/preferences/payments`,
            relative: 'payments',
          },
          ACCOUNTS: {
            path: `/orgs/${entityId}/notifications/preferences/accounts`,
            relative: 'accounts',
          },
          CARDS: {
            path: `/orgs/${entityId}/notifications/preferences/cards`,
            relative: 'cards',
          },
          USERS: {
            path: `/orgs/${entityId}/notifications/preferences/users`,
            relative: 'users',
          },
        },
      },
      TRANSACTIONS: {
        path: `/orgs/${entityId}/payments/transactions`,
        relative: 'transactions',
        customPageName: 'Transactions',
        SPLITPAYMENT: {
          path: `/orgs/${entityId}/payments/transactions/splitpayment`,
          relative: 'splitpayment',
          SPLIT_PAYMENT_ID: (
            splitPaymentId = `:${ROUTE_PARAM_NAME.PORTAL_SPLIT_PAYMENT_ID}`
          ) => ({
            path: `/orgs/${entityId}/payments/transactions/splitpayment/${splitPaymentId}`,
            relative: splitPaymentId,
          }),
        },
      },
      SITES: {
        path: `/orgs/${entityId}/payments/sites`,
        relative: 'sites',
        customPageName: 'Sites',
        SITE: {
          path: `/orgs/${entityId}/payments/sites/site`,
          relative: 'site',
          customPageName: 'Site',
          GENERAL: {
            path: `/orgs/${entityId}/payments/sites/site/general`,
            relative: 'general',
            customPageName: 'SiteGeneral',
            EDIT: {
              path: `/orgs/${entityId}/payments/sites/site/general/edit`,
              relative: 'edit',
              customPageName: 'SiteGeneralEdit',
            },
          },
          DEVICES: {
            path: `/orgs/${entityId}/payments/sites/site/devices`,
            relative: 'devices',
            customPageName: 'SiteDevices',
          },
          PAYMENTS: {
            path: `/orgs/${entityId}/payments/sites/site/payments`,
            relative: 'payments',
            customPageName: 'SitePayments',
          },
          RECEIPT: {
            path: `/orgs/${entityId}/payments/sites/site/receipt`,
            relative: 'receipt',
            customPageName: 'SiteReceipt',
          },
          USERS: {
            path: `/orgs/${entityId}/payments/sites/site/users`,
            relative: 'users',
            customPageName: 'SiteUsers',
            EDIT: {
              path: `/orgs/${entityId}/payments/sites/site/users/edit`,
              relative: 'edit',
              customPageName: 'SiteUsersEdit',
            },
          },
          APPEARANCE: {
            path: `/orgs/${entityId}/payments/sites/site/appearance`,
            relative: 'appearance',
            customPageName: 'SiteAppearance',
          },
        },
      },
      ACCOUNTS: {
        path: `/orgs/${entityId}/accounts`,
        relative: 'accounts',
        customPageName: 'Accounts',
        ACTIVATE_SAVINGS: {
          path: `/orgs/${entityId}/accounts/activate-savings`,
          relative: 'activate-savings',
        },
        TRANSACTIONS: {
          path: `/orgs/${entityId}/accounts/transactions`,
          relative: 'transactions',
          customPageName: 'AccountsTransactions',
        },
        TRANSFER: {
          path: `/orgs/${entityId}/accounts/transfer`,
          relative: 'transfer',
          customPageName: 'Transfer',
          SAVE: {
            path: `/orgs/${entityId}/accounts/transfer/save`,
            relative: 'save',
          },
        },
        ACCOUNT: (accountId = `:${ROUTE_PARAM_NAME.PORTAL_ACCOUNT_ID}`) =>
          ACCOUNT(entityId, accountId),
      },
      CARDS: {
        path: `/orgs/${entityId}/cards`,
        relative: 'cards',
        customPageName: 'Cards',
        DEBIT: {
          path: `/orgs/${entityId}/cards/debit`,
          relative: 'debit',
          customPageName: 'CardsDebit',
        },
        CORPORATE: {
          path: `/orgs/${entityId}/cards/corporate`,
          relative: 'corporate',
          customPageName: 'CardsCorporate',
          NEW: {
            path: `/orgs/${entityId}/cards/corporate/new`,
            relative: 'new',
          },
        },
        ACTIVATE: {
          path: `/orgs/${entityId}/cards/activate`,
          relative: 'activate',
          customPageName: 'CardsActivate',
        },
        SETTINGS: {
          path: `/orgs/${entityId}/cards/settings`,
          relative: 'settings',
          OUTSTANDING_EXPENSES: {
            path: `/orgs/${entityId}/cards/settings/outstanding-expenses`,
            relative: 'outstanding-expenses',
          },
        },
        CARD: (cardId = `:${ROUTE_PARAM_NAME.PORTAL_CARDS_ID}`) => ({
          path: `/orgs/${entityId}/cards/${cardId}`,
          relative: cardId,
          customPageName: 'CardsId',
        }),
      },
      INVOICING: {
        path: `/orgs/${entityId}/invoicing`,
        relative: 'invoicing',
        customPageName: 'Invoicing',
        INVOICES: {
          path: `/orgs/${entityId}/invoicing/invoices`,
          relative: 'invoices',
          customPageName: 'InvoicingInvoices',
          INVOICE: (
            referenceNumber = `:${ROUTE_PARAM_NAME.PORTAL_INVOICE_REF_NUMBER}`
          ) => ({
            path: `/orgs/${entityId}/invoicing/invoices/${referenceNumber}`,
            relative: referenceNumber,
            customPageName: 'InvoicingInvoicesDetails',
            EDIT: {
              path: `/orgs/${entityId}/invoicing/invoices/${referenceNumber}/edit`,
              relative: `${referenceNumber}/edit`,
              customPageName: 'InvoicingInvoicesEdit',
            },
          }),
          NEW: {
            path: `/orgs/${entityId}/invoicing/invoices/new`,
            relative: 'new',
            customPageName: 'InvoicingInvoiceCreate',
          },
          SETTINGS: {
            path: `/orgs/${entityId}/invoicing/settings`,
            relative: 'settings',
            customPageName: 'InvoicingSettings',
            GENERAL: {
              path: `/orgs/${entityId}/invoicing/settings/general`,
              relative: 'general',
              customPageName: 'InvoicingSettingsGeneral',
            },
            REMINDERS: {
              path: `/orgs/${entityId}/invoicing/settings/reminders`,
              relative: 'reminders',
              customPageName: 'InvoicingSettingsReminders',
            },
            RECEIPTS: {
              path: `/orgs/${entityId}/invoicing/settings/receipts`,
              relative: 'receipts',
              customPageName: 'InvoicingSettingsReceipts',
            },
            USERS: {
              path: `/orgs/${entityId}/invoicing/settings/users`,
              relative: 'users',
              customPageName: 'InvoicingSettingsUsers',
              USER: (
                userId = `:${ROUTE_PARAM_NAME.PORTAL_INVOICE_SETTINGS_USER_ID}`
              ) => ({
                path: `/orgs/${entityId}/invoicing/settings/users/${userId}`,
                relative: userId,
                customPageName: 'InvoicingSettingsUsersDetails',
              }),
            },
            CUSTOMISATION: {
              customPageName: 'InvoicingSettingsCustomisation',
              path: `/orgs/${entityId}/invoicing/settings/customisation`,
              relative: 'customisation',
            },
          },
        },
      },
      ITEM_MANAGEMENT: {
        path: `/orgs/${entityId}/payments/item-management`,
        relative: 'item-management',
        ITEMS: {
          customPageName: 'ItemManagementItems',
          path: `/orgs/${entityId}/payments/item-management/items`,
          relative: 'items',
          NEW: {
            path: `/orgs/${entityId}/payments/item-management/items/new`,
            relative: 'new',
          },
          ITEM: (itemId = `:${ROUTE_PARAM_NAME.PORTAL_ITEM_ID}`) => ({
            customPageName: 'ItemManagementItemsDetails',
            path: `/orgs/${entityId}/payments/item-management/items/${itemId}`,
            relative: itemId,
            EDIT_NOTES: {
              path: `/orgs/${entityId}/payments/item-management/items/${itemId}/notes`,
              relative: `notes`,
            },
            EDIT_DETAILS: {
              path: `/orgs/${entityId}/payments/item-management/items/${itemId}/edit`,
              relative: `edit`,
            },
          }),
          IMPORT: {
            path: `/orgs/${entityId}/payments/item-management/items/import`,
            relative: 'import',
          },
          DELETE_ALL: {
            path: `/orgs/${entityId}/payments/item-management/items/delete-all`,
            relative: 'delete-all',
          },
        },
        CATEGORIES: {
          customPageName: 'ItemManagementCategories',
          path: `/orgs/${entityId}/payments/item-management/categories`,
          relative: 'categories',
          CATEGORY: (
            categoryId = `:${ROUTE_PARAM_NAME.PORTAL_ITEM_CATEGORY_ID}`
          ) => ({
            customPageName: 'ItemManagementCategoriesDetails',
            path: `/orgs/${entityId}/payments/item-management/categories/${categoryId}`,
            relative: categoryId,
            EDIT: {
              path: `/orgs/${entityId}/payments/item-management/categories/${categoryId}/edit`,
              relative: 'edit',
            },
            ITEMS: {
              path: `/orgs/${entityId}/payments/item-management/categories/${categoryId}/items`,
              relative: 'items',
            },
          }),
          NEW: {
            path: `/orgs/${entityId}/payments/item-management/categories/new`,
            relative: 'new',
          },
        },

        SETTINGS: {
          customPageName: 'ItemManagementSettings',
          path: `/orgs/${entityId}/payments/item-management/settings`,
          relative: 'settings',
        },
      },
      ITEMS: {
        path: `/orgs/${entityId}/items`,
        relative: 'items',
        customPageName: 'All Items',
        CREATE: {
          path: `/orgs/${entityId}/items/create`,
          relative: 'create',
          customPageName: 'ItemsCreate',
        },
        IMPORT: {
          path: `/orgs/${entityId}/items/import`,
          relative: 'import',
          customPageName: 'Import Items',
        },
        EDIT: (itemId = `:${ROUTE_PARAM_NAME.PORTAL_ITEM_ID}`) => ({
          customPageName: 'ItemsEdit',
          path: `/orgs/${entityId}/items/${itemId}`,
          relative: itemId,
        }),
        CATEGORIES: {
          path: `/orgs/${entityId}/items/categories`,
          relative: 'categories',
          customPageName: 'ItemsCategories',
          CREATE: {
            path: `/orgs/${entityId}/items/categories/create`,
            relative: 'create',
            customPageName: 'ItemsCategoryCreate',
          },
          EDIT: (
            categoryId = `:${ROUTE_PARAM_NAME.PORTAL_ITEM_CATEGORY_ID}`
          ) => ({
            customPageName: 'ItemsCategoryEdit',
            path: `/orgs/${entityId}/items/categories/${categoryId}`,
            relative: categoryId,
          }),
        },
        ATTRIBUTE_SETS: {
          path: `/orgs/${entityId}/items/attribute-sets`,
          relative: 'attribute-sets',
          customPageName: 'ItemsAttributeSets',
          CREATE: {
            path: `/orgs/${entityId}/items/attribute-sets/create`,
            relative: 'create',
            customPageName: 'ItemsAttributeSetCreate',
          },
          EDIT: (
            attributeSetId = `:${ROUTE_PARAM_NAME.PORTAL_ATTRIBUTE_SET_ID}`
          ) => ({
            customPageName: 'ItemsAttributeSetEdit',
            path: `/orgs/${entityId}/items/attribute-sets/${attributeSetId}`,
            relative: attributeSetId,
          }),
        },
        DISCOUNTS: {
          path: `/orgs/${entityId}/items/discounts`,
          relative: 'discounts',
          customPageName: 'ItemsDiscounts',
          CREATE: {
            path: `/orgs/${entityId}/items/discounts/create`,
            relative: 'create',
            customPageName: 'ItemsDiscountsCreate',
          },
          EDIT: (
            discountRefNumber = `:${ROUTE_PARAM_NAME.PORTAL_DISCOUNT_REF_NUMBER}`
          ) => ({
            customPageName: 'ItemsDiscountsEdit',
            path: `/orgs/${entityId}/items/discounts/${discountRefNumber}`,
            relative: discountRefNumber,
          }),
        },
        MODIFIER_SETS: {
          path: `/orgs/${entityId}/items/modifier-sets`,
          relative: 'modifier-sets',
          customPageName: 'ItemsModifierSets',
          CREATE: {
            path: `/orgs/${entityId}/items/modifier-sets/create`,
            relative: 'create',
            customPageName: 'ItemsModifierSetCreate',
          },
          EDIT: (
            modifierSetId = `:${ROUTE_PARAM_NAME.PORTAL_MODIFIER_SET_ID}`
          ) => ({
            path: `/orgs/${entityId}/items/modifier-sets/${modifierSetId}`,
            relative: modifierSetId,
            customPageName: 'ItemsModifierSetEdit',
          }),
        },
        REPORTS: {
          path: `/orgs/${entityId}/items/reports`,
          relative: 'reports',
          customPageName: 'ItemsReports',
          ITEMS: {
            path: `/orgs/${entityId}/items/reports/items`,
            relative: 'items',
            customPageName: 'ItemsReportsItems',
          },
          CATEGORIES: {
            path: `/orgs/${entityId}/items/reports/categories`,
            relative: 'categories',
            customPageName: 'ItemsReportsCategories',
          },
          MODIFIERS: {
            path: `/orgs/${entityId}/items/reports/modifiers`,
            relative: 'modifiers',
            customPageName: 'ItemsReportsModifiers',
          },
          DISCOUNTS: {
            path: `/orgs/${entityId}/items/reports/discounts`,
            relative: 'discounts',
            customPageName: 'ItemsReportsDiscounts',
          },
        },
        SETTINGS: {
          path: `/orgs/${entityId}/items/settings`,
          relative: 'settings',
          customPageName: 'ItemsSettings',
        },
      },
      CONTACTS: {
        path: `/orgs/${entityId}/contacts`,
        relative: 'contacts',
        customPageName: 'Contacts',
        BUSINESSES: {
          path: `/orgs/${entityId}/contacts/businesses`,
          relative: 'businesses',
          customPageName: 'ContactsBusinesses',
          CONTACT: (contactId = `:${ROUTE_PARAM_NAME.PORTAL_CONTACT_ID}`) =>
            getContactPaths(entityId, contactId, 'businesses'),
        },
        PEOPLE: {
          path: `/orgs/${entityId}/contacts/people`,
          relative: 'people',
          customPageName: 'ContactsPeople',
          CONTACT: (contactId = `:${ROUTE_PARAM_NAME.PORTAL_CONTACT_ID}`) =>
            getContactPaths(entityId, contactId, 'people'),
        },
      },
      KYC: {
        path: `/orgs/${entityId}/kyc`,
        relative: 'kyc',
        customPageName: 'Kyc',
        EXISTING_USER: {
          path: `/orgs/${entityId}/kyc/existing-user`,
          relative: 'existing-user',
          customPageName: 'KycExistingUser',
        },
        NEW_USER: {
          path: `/orgs/${entityId}/kyc/new-user`,
          relative: 'new-user',
          customPageName: 'KycNewUser',
        },
        YOUR_INFORMATION: {
          path: `/orgs/${entityId}/kyc/your-information`,
          relative: 'your-information',
          customPageName: 'KycYourInformation',
        },
        YOUR_IDENTITY: {
          path: `/orgs/${entityId}/kyc/your-identity`,
          relative: 'your-identity',
          customPageName: 'KycYourIdentity',
        },
        DRIVING_LICENCE: {
          path: `/orgs/${entityId}/kyc/driving-licence`,
          relative: 'driving-licence',
          customPageName: 'KycDrivingLicence',
        },
        PASSPORT: {
          path: `/orgs/${entityId}/kyc/passport`,
          relative: 'passport',
          customPageName: 'KycPassport',
        },
        MEDICARE: {
          path: `/orgs/${entityId}/kyc/medicare`,
          relative: 'medicare',
          customPageName: 'KycMedicare',
        },
        NO_IDENTIFICATION: {
          path: `/orgs/${entityId}/kyc/no-identification`,
          relative: 'no-identification',
          customPageName: 'KycNoIdentification',
        },
        TIME_FOR_A_SELFIE: {
          path: `/orgs/${entityId}/kyc/time-for-a-selfie`,
          relative: 'time-for-a-selfie',
          customPageName: 'KycTimeForASelfie',
        },
        SELFIE_VERIFICATION: {
          path: `/orgs/${entityId}/kyc/selfie-verification`,
          relative: 'selfie-verification',
          customPageName: 'KycSelfieVerification',
        },
        VERIFICATION_FAILED: {
          path: `/orgs/${entityId}/kyc/verification-failed`,
          relative: 'verification-failed',
          customPageName: 'KycVerificationFailed',
        },
        FINALISE: {
          path: `/orgs/${entityId}/kyc/finalise`,
          relative: 'finalise',
          customPageName: 'KycFinalise',
        },
        DOCUMENT_UPLOAD: {
          path: `/orgs/${entityId}/kyc/document-upload`,
          relative: 'document-upload',
          customPageName: 'KycUploadDocuments',
        },
        IN_REVIEW: {
          path: `/orgs/${entityId}/kyc/in-review`,
          relative: 'in-review',
          customPageName: 'KycInReview',
        },
        SUCCESS: {
          path: `/orgs/${entityId}/kyc/success`,
          relative: 'success',
          customPageName: 'KycSuccess',
        },
        REJECTED: {
          path: `/orgs/${entityId}/kyc/rejected`,
          relative: 'rejected',
          customPageName: 'KycRejected',
        },
        UPLOAD_SUCCESS: {
          path: `/orgs/${entityId}/kyc/upload-success`,
          relative: 'upload-success',
          customPageName: 'KycUploadSuccess',
        },
        REVIEW_DOCUMENT_UPLOAD: {
          path: `/orgs/${entityId}/kyc/review-document-upload`,
          relative: 'review-document-upload',
          customPageName: 'KycReviewDocumentUpload',
        },
      },
      SETTINGS: {
        path: `/orgs/${entityId}/settings`,
        relative: 'settings',
        customPageName: 'Settings',
        FEES_AND_PRICING: {
          path: `/orgs/${entityId}/settings/fees-and-pricing`,
          relative: 'fees-and-pricing',
          customPageName: 'SettingsFeesAndPricing',
        },
        CONNECTIONS: {
          path: `/orgs/${entityId}/settings/connections`,
          relative: 'connections',
          customPageName: 'SettingsConnections',
          ORACLE_POS: {
            path: `/orgs/${entityId}/settings/connections/oracle-pos`,
            relative: 'oracle-pos',
            customPageName: 'OraclePos',
            MANAGE: {
              path: `/orgs/${entityId}/settings/connections/oracle-pos/manage`,
              relative: 'manage',
              customPageName: 'OraclePosManage',
              GENERAL: {
                path: `/orgs/${entityId}/settings/connections/oracle-pos/manage/general`,
                customPageName: 'OraclePosManageGeneral',
                relative: 'general',
              },
              PAIRING: {
                path: `/orgs/${entityId}/settings/connections/oracle-pos/manage/pairing`,
                customPageName: 'OraclePosManagePairing',
                relative: 'pairing',
              },
            },
          },
          XERO_PAYMENT_SERVICES: {
            path: `/orgs/${entityId}/settings/connections/xero-payment-services`,
            relative: 'xero-payment-services',
            customPageName: 'SettingsConnectionsXeroPaymentServices',
          },
          XERO_PAYMENT_SERVICES_MANAGE: {
            path: `/orgs/${entityId}/settings/connections/xero-payment-services-manage`,
            relative: 'xero-payment-services-manage',
            customPageName: 'SettingsConnectionsXeroPaymentServicesManage',
            GENERAL: {
              path: `/orgs/${entityId}/settings/connections/xero-payment-services-manage/general`,
              customPageName:
                'SettingsConnectionsXeroPaymentServicesManageGeneral',
              relative: 'general',
            },
            RECEIPT: {
              path: `/orgs/${entityId}/settings/connections/xero-payment-services-manage/receipt`,
              customPageName:
                'SettingsConnectionsXeroPaymentServicesManageReceipt',
              relative: 'receipt',
            },
            USERS: {
              path: `/orgs/${entityId}/settings/connections/xero-payment-services-manage/users`,
              customPageName:
                'SettingsConnectionsXeroPaymentServicesManageUsers',
              relative: 'users',
              USER: (
                userId = `:${ROUTE_PARAM_NAME.PORTAL_SETTINGS_XERO_USER_ID}`
              ) => ({
                path: `/orgs/${entityId}/settings/connections/xero-payment-services-manage/users/${userId}`,
                customPageName:
                  'SettingsConnectionsXeroPaymentServicesManageUsersDetails',
                relative: userId,
              }),
            },
          },
          XERO_PAYMENT_SERVICES_SETUP: {
            path: `/orgs/${entityId}/settings/connections/xero-payment-services-setup`,
            relative: 'xero-payment-services-setup',
            customPageName: 'SettingsConnectionsXeroPaymentServicesSetup',
          },
          XERO_BANK_FEEDS: {
            path: `/orgs/${entityId}/settings/connections/xero-bank-feeds`,
            relative: 'xero-bank-feeds',
            customPageName: 'SettingsConnectionsXeroBankFeeds',
          },
          XERO_BANK_FEEDS_SETUP: {
            path: `/orgs/${entityId}/settings/connections/xero-bank-feeds-setup`,
            relative: 'xero-bank-feeds-setup',
            customPageName: 'SettingsConnectionsXeroBankFeedsSetup',
          },
          XERO_BANK_FEEDS_MANAGE: {
            path: `/orgs/${entityId}/settings/connections/xero-bank-feeds-manage`,
            relative: 'xero-bank-feeds-manage',
            customPageName: 'SettingsConnectionsXeroBankFeedsManage',
          },
          HL_POS: {
            path: `/orgs/${entityId}/settings/connections/hl-pos`,
            relative: 'hl-pos',
            MANAGE: {
              path: `/orgs/${entityId}/settings/connections/hl-pos/manage`,
              relative: 'manage',
              customPageName: 'SettingsConnectionsHlPosManage',
            },
          },
        },
        PROFILE: {
          path: `/orgs/${entityId}/settings/profile`,
          relative: 'profile',
          customPageName: 'SettingsProfile',
          PERSONAL: {
            path: `/orgs/${entityId}/settings/profile/personal`,
            relative: 'personal',
            customPageName: 'SettingsProfilePersonal',
            EDIT: {
              path: `/orgs/${entityId}/settings/profile/personal/edit`,
              relative: 'edit',
              customPageName: 'SettingsProfilePersonalEdit',
            },
          },
          SECURITY: {
            path: `/orgs/${entityId}/settings/profile/security`,
            relative: 'security',
            customPageName: 'SettingsProfileSecurity',
            CHANGE_NUMBER: {
              path: `/orgs/${entityId}/settings/profile/security/change-number`,
              relative: 'change-number',
              customPageName: 'SettingsProfileSecurityChangeNumber',
            },
            VALIDATE_CODE: {
              path: `/orgs/${entityId}/settings/profile/security/validate-code`,
              relative: 'validate-code',
              customPageName: 'SettingsProfileSecurityValidateCode',
            },
          },
          DOCUMENTS: {
            path: `/orgs/${entityId}/settings/profile/documents`,
            relative: 'documents',
            customPageName: 'SettingsProfileDocuments',
          },
        },
        USERS: {
          path: `/orgs/${entityId}/settings/users`,
          relative: 'users',
          customPageName: 'SettingsUsers',
          USER_EDIT: {
            path: `/orgs/${entityId}/settings/users/user-edit`,
            relative: 'user-edit',
            customPageName: 'SettingsUsersUserEdit',
          },
          USER_CREATE_TYPE: {
            path: `/orgs/${entityId}/settings/users/user-create-type`,
            relative: 'user-create-type',
            customPageName: 'SettingsUsersUserCreateType',
          },
          USER_CREATE_DETAILS: {
            path: `/orgs/${entityId}/settings/users/user-create-details`,
            relative: 'user-create-details',
            customPageName: 'SettingsUsersUserCreateDetails',
          },
        },
      },
      BIOMETRICS: {
        path: `/orgs/${entityId}/biometrics`,
        relative: 'biometrics',
        ONFIDO: {
          path: `/orgs/${entityId}/biometrics/onfido`,
          relative: 'onfido',
        },
        PUBLIC_ONFIDO: {
          path: '/biometrics',
          relative: 'biometrics',
        },
      },
      VIRTUAL_TERMINAL: {
        customPageName: 'VirtualTerminal',
        path: `/orgs/${entityId}/payments/virtual-terminal`,
        relative: 'virtual-terminal',
        HISTORY: {
          customPageName: 'VirtualTerminalHistory',
          path: `/orgs/${entityId}/payments/virtual-terminal/history`,
          relative: 'history',
        },
      },
      REPORTS: {
        customPageName: 'Reports',
        path: `/orgs/${entityId}/reports`,
        relative: 'reports',
        CASHFLOW: {
          path: `/orgs/${entityId}/reports/cashflow`,
          relative: 'cashflow',
        },
        SALES: {
          path: `/orgs/${entityId}/reports/sales`,
          relative: 'sales',
        },
      },
      REFERRAL: {
        customPageName: 'Referral',
        path: `/orgs/${entityId}/referral`,
        relative: 'referral',
      },
      HELP: {
        customPageName: 'Help',
        path: `/orgs/${entityId}/help`,
        relative: 'help',
      },
      DEMOS: {
        path: `/orgs/${entityId}/demos`,
        relative: 'demos',
        customPageName: 'Demos',
      },
      POS_LITE: {
        path: `/orgs/${entityId}/pos-lite`,
        relative: 'pos-lite',
        customPageName: 'PosLite',
      },
    }),
  },
  SIGNUP: {
    path: '/signup',
    relative: 'signup',
    customPageName: 'Signup',
  },
  LOGOUT: {
    path: '/logout',
    relative: 'logout',
    customPageName: 'Logout',
    INACTIVITY_TIMEOUT: {
      path: '/logout/inactivity-timeout',
      relative: 'inactivity-timeout',
    },
  },
  LOGIN_ERROR_ONBOARDING_STATUS: {
    path: '/login-error-onboarding-status',
    relative: 'login-error-onboarding-status',
    customPageName: 'LoginErrorOnboardingStatus',
  },
  LOGIN_ERROR_ENTITY_DISABLED: {
    path: '/login-error-entity-disabled',
    relative: 'login-error-entity-disabled',
    customPageName: 'LoginErrorEntityDisabled',
  },
  LOGIN_ERROR_ENTITY_ABANDONED: {
    path: '/login-error-entity-abandoned',
    relative: 'login-error-entity-abandoned',
  },
} satisfies RootRoute

export type RootRoute = {
  /**
   * Full URL path
   */
  path: string
  /**
   * Relative path is the new convention when defining paths
   * in router v6
   */
  relative?: string
  /**
   * Define customPageName if you want this path to be
   * tracked in segment analytics
   */
  customPageName?: string
  [key: string]: RootRoute | string | undefined | (() => RootRoute)
}

export const ADMIN_KYC_ROUTES = (entityId: string) => {
  return {
    [ROUTE_NAME.PORTAL_KYC]: ROOT.ORGS.ORG(entityId).KYC.path,

    [ROUTE_NAME.PORTAL_KYC_EXISTING_USER]:
      ROOT.ORGS.ORG(entityId).KYC.EXISTING_USER.path,
    [ROUTE_NAME.PORTAL_KYC_NEW_USER]: ROOT.ORGS.ORG(entityId).KYC.NEW_USER.path,

    [ROUTE_NAME.PORTAL_KYC_MEDICARE]: ROOT.ORGS.ORG(entityId).KYC.MEDICARE.path,
    [ROUTE_NAME.PORTAL_KYC_PASSPORT]: ROOT.ORGS.ORG(entityId).KYC.PASSPORT.path,
    [ROUTE_NAME.PORTAL_KYC_DRIVING_LICENCE]:
      ROOT.ORGS.ORG(entityId).KYC.DRIVING_LICENCE.path,
    [ROUTE_NAME.PORTAL_KYC_NO_IDENTIFICATION]:
      ROOT.ORGS.ORG(entityId).KYC.NO_IDENTIFICATION.path,
    [ROUTE_NAME.PORTAL_KYC_FINALISE]: ROOT.ORGS.ORG(entityId).KYC.FINALISE.path,
    [ROUTE_NAME.PORTAL_KYC_REJECTED]: ROOT.ORGS.ORG(entityId).KYC.REJECTED.path,
    [ROUTE_NAME.PORTAL_KYC_YOUR_INFORMATION]:
      ROOT.ORGS.ORG(entityId).KYC.YOUR_INFORMATION.path,
    [ROUTE_NAME.PORTAL_KYC_YOUR_IDENTITY]:
      ROOT.ORGS.ORG(entityId).KYC.YOUR_IDENTITY.path,
    [ROUTE_NAME.PORTAL_KYC_TIME_FOR_A_SELFIE]:
      ROOT.ORGS.ORG(entityId).KYC.TIME_FOR_A_SELFIE.path,
    [ROUTE_NAME.PORTAL_KYC_SELFIE_VERIFICATION]:
      ROOT.ORGS.ORG(entityId).KYC.SELFIE_VERIFICATION.path,
    [ROUTE_NAME.PORTAL_KYC_VERIFICATION_FAILED]:
      ROOT.ORGS.ORG(entityId).KYC.VERIFICATION_FAILED.path,
    [ROUTE_NAME.PORTAL_KYC_SUCCESS]: ROOT.ORGS.ORG(entityId).KYC.SUCCESS.path,
    [ROUTE_NAME.PORTAL_KYC_IN_REVIEW]:
      ROOT.ORGS.ORG(entityId).KYC.IN_REVIEW.path,
    [ROUTE_NAME.PORTAL_KYC_UPLOAD_SUCCESS]:
      ROOT.ORGS.ORG(entityId).KYC.UPLOAD_SUCCESS.path,
    [ROUTE_NAME.PORTAL_KYC_REVIEW_DOCUMENT_UPLOAD]:
      ROOT.ORGS.ORG(entityId).KYC.REVIEW_DOCUMENT_UPLOAD.path,
    [ROUTE_NAME.PORTAL_KYC_DOCUMENT_UPLOAD]:
      ROOT.ORGS.ORG(entityId).KYC.DOCUMENT_UPLOAD.path,
  }
}

export const DEFAULT_ENTITY = 'default'
export const PORTAL_ROUTE = '/portal'

export const DEFAULT_AFTER_LOGIN_ROUTE = (entityId: string) =>
  ROOT.ORGS.ORG(entityId || DEFAULT_ENTITY).OVERVIEW.PAYMENTS.path
