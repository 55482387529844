import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { ApolloError } from '@apollo/client'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { showErrorToast, showSuccessToast } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { EditItemFormFields } from 'components/ItemBaseModalForm'
import {
  convertItemFormFieldsToUpdateItemInput,
  initialItemDrawerEditFieldValues,
} from 'components/ItemBaseModalForm/ItemBaseModalForm.utils'
import { ItemEditModalForm } from 'components/ItemBaseModalForm/ItemEditModalForm/ItemEditModalForm'

import { useItemsAnalytics } from '../../../../../hooks/useItemsAnalytics'
import { getDuplicateSkuErrorItemName } from '../../../../../ItemManagement.utils'
import { useUpdateItemMutation } from '../../graphql/updateItem.generated'
import { translations } from './ItemEditModal.i18n'

interface ItemEditModalProps {
  itemId: string
  initialValues: EditItemFormFields
  itemReferenceNumber: string | null
}

export const ItemEditModal = ({
  itemId,
  initialValues = initialItemDrawerEditFieldValues,
  itemReferenceNumber,
}: ItemEditModalProps) => {
  const { trackEditItem } = useItemsAnalytics()
  const t = useTranslations(translations)
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()
  const [skuDuplicateError, setSkuDuplicateError] = useState('')

  const [updateItem, { loading: isUpdatingItem }] = useUpdateItemMutation()

  const handleClose = useCallback(() => {
    if (itemReferenceNumber)
      navigate(
        ROOT.ORGS.ORG(shortEntityId).ITEM_MANAGEMENT.ITEMS.ITEM(
          itemReferenceNumber
        ).path
      )
  }, [itemReferenceNumber, navigate, shortEntityId])

  const handleOnSubmit = useCallback(
    (formValues: EditItemFormFields) => {
      setSkuDuplicateError('')
      async function sendRequest() {
        const convertedFormValues =
          convertItemFormFieldsToUpdateItemInput(formValues)
        const updatedItem = { id: itemId, ...convertedFormValues }
        const result = await updateItem({ variables: { item: updatedItem } })

        if (result.data?.updateItem) {
          trackEditItem(initialValues, formValues)
          showSuccessToast(t('successNotification'))
          handleClose()
        } else {
          showErrorToast(t('errorNotification'))
        }
      }

      sendRequest().catch((error: ApolloError) => {
        const dupeSkuItemName = getDuplicateSkuErrorItemName(error)

        if (dupeSkuItemName) {
          setSkuDuplicateError(
            t('skuDuplicateError', {
              itemName: dupeSkuItemName,
            })
          )
        }

        showErrorToast(t('errorNotification'))
      })
    },
    [itemId, updateItem, trackEditItem, initialValues, t, handleClose]
  )

  return (
    <ItemEditModalForm
      initialValues={initialValues}
      title={t('heading')}
      isUpdatingItem={isUpdatingItem}
      onClose={handleClose}
      onSubmit={handleOnSubmit}
      skuDuplicateError={skuDuplicateError}
    />
  )
}
