import { useCallback } from 'react'
import { CatalogDiscountConfig } from '@npco/mp-gql-types'

import {
  Action,
  ImportFileType,
} from 'services/Analytics/events/catalogueItems'
import { useAnalyticsLogger } from 'services/Analytics/useAnalyticsLogger'

import { ItemsSettingsFormFields } from '../../../components/ItemsSettingsForm/ItemsSettingsForm.types'
import { AttributeSetFormFields } from '../components/AttributeSets/CreateAttributeSet/CreateAttributeSet.types'
import {
  CatalogueItemFormFields,
  ITEM_ATTRIBUTE_SETS_FIELD,
  ITEM_CATEGORIES_FIELD,
  ITEM_DISPLAY_LABEL_FIELD,
  ITEM_IMAGES_FIELD,
  ITEM_INVOICES_ENABLED_FIELD,
  ITEM_MODIFIER_SETS_FIELD,
  ITEM_REPORTING_CATEGORY_FIELD,
  ITEM_SITES_FIELD,
} from '../components/Catalogue/CatalogueTable/CatalogueItem/CatalogueItem.types'
import { CategoryFormFields } from '../components/Category/CategoryForm/CategoryForm.types'
import { DiscountFormFields } from '../components/Discounts/Discounts.types'
import { ModifierSetFormFields } from '../components/ModifierSet/ModifierSetForm/ModifierSetForm.types'

const getFileFormat = (fileName: string): ImportFileType => {
  if (fileName.includes('.csv')) {
    return '.csv'
  }

  if (fileName.includes('.xlsx')) {
    return '.xlsx'
  }

  return '.xls'
}

export const useItemsAnalytics = () => {
  const { trackAnalyticsEvent } = useAnalyticsLogger()

  const trackItemCreationStarted = useCallback(() => {
    trackAnalyticsEvent('item_creation_started')
  }, [trackAnalyticsEvent])

  const trackItemSaved = useCallback(
    ({
      fields,
      itemUuid,
      action,
    }: {
      fields: CatalogueItemFormFields
      itemUuid: string
      action: Action
    }) => {
      trackAnalyticsEvent('item_saved', {
        action,
        item_uuid: itemUuid,
        attribute_set_count: fields[ITEM_ATTRIBUTE_SETS_FIELD]?.length,
        attribute_count: fields[ITEM_ATTRIBUTE_SETS_FIELD]?.reduce(
          (acc, attributeSet) => acc + (attributeSet.attributes?.length ?? 0),
          0
        ),
        category_count: fields.categories?.length,
        enable_for_invoices: fields[ITEM_INVOICES_ENABLED_FIELD],
        image_uploaded: Boolean(fields[ITEM_IMAGES_FIELD].length),
        modifier_set_count: fields[ITEM_MODIFIER_SETS_FIELD]?.length,
        modifier_count: fields[ITEM_MODIFIER_SETS_FIELD]?.reduce(
          (acc, modifierSet) => acc + (modifierSet.modifiers?.length ?? 0),
          0
        ),
        pos_tile_label: fields[ITEM_DISPLAY_LABEL_FIELD]
          ? 'display_label'
          : 'display_image',
        reporting_category_matches: fields[ITEM_CATEGORIES_FIELD].includes(
          fields[ITEM_REPORTING_CATEGORY_FIELD][0]
        ),
        site_count:
          fields[ITEM_SITES_FIELD] === '__all__'
            ? 'all'
            : fields[ITEM_SITES_FIELD]?.length ?? 0,
        variant_count: fields.variants?.length,
      })
    },
    [trackAnalyticsEvent]
  )

  const trackItemDiscarded = useCallback(
    ({ itemUuid }: { itemUuid: string | null }) => {
      if (!itemUuid) {
        trackAnalyticsEvent('item_discarded', {
          item_uuid: null,
          action: 'create',
        })
        return
      }
      trackAnalyticsEvent('item_discarded', {
        item_uuid: itemUuid,
        action: 'edit',
      })
    },
    [trackAnalyticsEvent]
  )

  const trackItemDeleted = useCallback(
    ({ itemUuid }: { itemUuid: string }) => {
      trackAnalyticsEvent('item_deleted', {
        item_uuid: itemUuid,
      })
    },
    [trackAnalyticsEvent]
  )

  const trackItemImportStarted = useCallback(() => {
    trackAnalyticsEvent('bulk_item_import_initiated')
  }, [trackAnalyticsEvent])

  const trackItemImportCompleted = useCallback(
    ({
      fileName,
      rowCount,
      sitesCount,
    }: {
      fileName: string
      rowCount: number
      sitesCount: number
    }) => {
      trackAnalyticsEvent('bulk_item_import_completed', {
        file_type: getFileFormat(fileName),
        row_count: rowCount,
        mapped_sites: sitesCount,
      })
    },
    [trackAnalyticsEvent]
  )

  const trackExportStarted = useCallback(() => {
    trackAnalyticsEvent('export_started')
  }, [trackAnalyticsEvent])

  const trackDeleteAllStarted = useCallback(() => {
    trackAnalyticsEvent('delete_all_started')
  }, [trackAnalyticsEvent])

  const trackDeleteAllConfirmed = useCallback(
    ({ deleteCategories }: { deleteCategories: boolean }) => {
      trackAnalyticsEvent('delete_all_confirmed', {
        delete_categories: deleteCategories,
      })
    },
    [trackAnalyticsEvent]
  )

  const trackCategoryCreationStarted = useCallback(() => {
    trackAnalyticsEvent('category_creation_started')
  }, [trackAnalyticsEvent])

  const trackCategoryDiscarded = useCallback(
    ({ categoryUuid }: { categoryUuid: string | null }) => {
      if (!categoryUuid) {
        trackAnalyticsEvent('category_discarded', {
          category_uuid: null,
          action: 'create',
        })
        return
      }
      trackAnalyticsEvent('category_discarded', {
        category_uuid: categoryUuid,
        action: 'edit',
      })
    },
    [trackAnalyticsEvent]
  )

  const trackCategorySaved = useCallback(
    ({
      categoryUuid,
      fields,
      action,
    }: {
      categoryUuid: string
      fields: CategoryFormFields
      action: Action
    }) => {
      trackAnalyticsEvent('category_saved', {
        action,
        category_uuid: categoryUuid,
        category_colour: fields.color,
        has_description: Boolean(fields.description),
        image_uploaded: Boolean(fields.images.length),
        pos_tile_label: fields.displayLabel ? 'display_label' : 'display_image',
      })
    },
    [trackAnalyticsEvent]
  )

  const trackCategoryDeleted = useCallback(
    ({ categoryUuid }: { categoryUuid: string }) => {
      trackAnalyticsEvent('category_deleted', {
        category_uuid: categoryUuid,
      })
    },
    [trackAnalyticsEvent]
  )

  const trackAttributeSetCreationStarted = useCallback(() => {
    trackAnalyticsEvent('attribute_set_creation_started')
  }, [trackAnalyticsEvent])

  const trackAttributeSetDiscarded = useCallback(
    ({ attributeSetUuid }: { attributeSetUuid: string | null }) => {
      if (!attributeSetUuid) {
        trackAnalyticsEvent('attribute_set_discarded', {
          attribute_set_uuid: null,
          action: 'create',
        })
        return
      }
      trackAnalyticsEvent('attribute_set_discarded', {
        attribute_set_uuid: attributeSetUuid,
        action: 'edit',
      })
    },
    [trackAnalyticsEvent]
  )

  const trackAttributeSetSaved = useCallback(
    ({
      attributeSetUuid,
      initialFields,
      currentFields,
      action,
      itemCreationFlow,
    }: {
      attributeSetUuid: string
      initialFields: Partial<AttributeSetFormFields>
      currentFields: AttributeSetFormFields
      action: Action
      itemCreationFlow: boolean
    }) => {
      trackAnalyticsEvent('attribute_set_saved', {
        action,
        attribute_set_uuid: attributeSetUuid,
        attribute_count: currentFields.attributes.length,
        attributes_added: currentFields.attributes.some(
          (attribute) =>
            !initialFields.attributes?.some(
              (initialAttribute) => initialAttribute.id === attribute.id
            )
        ),
        attributes_deleted: !!initialFields.attributes?.some(
          (initialAttribute) =>
            !currentFields.attributes.some(
              (attribute) => attribute.id === initialAttribute.id
            )
        ),
        attributes_reordered: currentFields.attributes.some(
          (attribute, index) => {
            const foundIndex = initialFields.attributes?.findIndex(
              (initialAttribute) => initialAttribute.id === attribute.id
            )
            return (
              foundIndex !== undefined &&
              foundIndex !== -1 &&
              foundIndex !== index
            )
          }
        ),
        attributes_renamed: currentFields.attributes.some((attribute) =>
          initialFields.attributes?.find(
            (initialAttribute) =>
              initialAttribute.id === attribute.id &&
              initialAttribute.value !== attribute.value
          )
        ),
        initial_attribute_count: initialFields.attributes?.length ?? 0,
        final_attribute_count: currentFields.attributes.length,
        item_creation_flow: itemCreationFlow,
      })
    },
    [trackAnalyticsEvent]
  )

  const trackAttributeSetDeleted = useCallback(
    ({ attributeSetUuid }: { attributeSetUuid: string }) => {
      trackAnalyticsEvent('attribute_set_deleted', {
        attribute_set_uuid: attributeSetUuid,
      })
    },
    [trackAnalyticsEvent]
  )

  const trackModifierSetCreationStarted = useCallback(() => {
    trackAnalyticsEvent('modifier_set_creation_started')
  }, [trackAnalyticsEvent])

  const trackModifierSetDiscarded = useCallback(
    ({ modifierSetUuid }: { modifierSetUuid: string | null }) => {
      if (!modifierSetUuid) {
        trackAnalyticsEvent('modifier_set_discarded', {
          modifier_set_uuid: null,
          action: 'create',
        })
        return
      }
      trackAnalyticsEvent('modifier_set_discarded', {
        modifier_set_uuid: modifierSetUuid,
        action: 'edit',
      })
    },
    [trackAnalyticsEvent]
  )

  const trackModifierSetSaved = useCallback(
    ({
      modifierSetUuid,
      initialFields,
      currentFields,
      action,
    }: {
      modifierSetUuid: string
      initialFields?: Partial<ModifierSetFormFields>
      currentFields: ModifierSetFormFields
      action: Action
    }) => {
      trackAnalyticsEvent('modifier_set_saved', {
        action,
        modifier_set_uuid: modifierSetUuid,
        modifier_count: currentFields.modifiers.length,
        modifiers_with_price: currentFields.modifiers.filter(
          (modifier) => modifier.price && modifier.price !== '0.00'
        ).length,
        site_count: currentFields.sites?.length ?? 0,
        selection_required: currentFields.selectionRequired,
        modifiers_added: currentFields.modifiers.some(
          (modifier) =>
            !initialFields?.modifiers?.some(
              (initialModifier) => initialModifier.id === modifier.id
            )
        ),
        modifiers_deleted: !!initialFields?.modifiers?.some(
          (initialModifier) =>
            !currentFields.modifiers.some(
              (modifier) => modifier.id === initialModifier.id
            )
        ),
        modifiers_reordered: currentFields.modifiers.some((modifier, index) => {
          const foundIndex = initialFields?.modifiers?.findIndex(
            (initialModifier) => initialModifier.id === modifier.id
          )
          return (
            foundIndex !== undefined &&
            foundIndex !== -1 &&
            foundIndex !== index
          )
        }),
        modifiers_renamed: currentFields.modifiers.some((modifier) =>
          initialFields?.modifiers?.find(
            (initialModifier) =>
              initialModifier.id === modifier.id &&
              initialModifier.name !== modifier.name
          )
        ),
        initial_modifier_count: initialFields?.modifiers?.length ?? 0,
        final_modifier_count: currentFields.modifiers.length,
      })
    },
    [trackAnalyticsEvent]
  )

  const trackModifierSetDeleted = useCallback(
    ({ modifierSetUuid }: { modifierSetUuid: string }) => {
      trackAnalyticsEvent('modifier_set_deleted', {
        modifier_set_uuid: modifierSetUuid,
      })
    },
    [trackAnalyticsEvent]
  )

  const trackDiscountCreationStarted = useCallback(() => {
    trackAnalyticsEvent('discount_creation_started')
  }, [trackAnalyticsEvent])

  const trackDiscountDiscarded = useCallback(
    ({ discountUuid }: { discountUuid: string | null }) => {
      if (!discountUuid) {
        trackAnalyticsEvent('discount_discarded', {
          discount_uuid: null,
          action: 'create',
        })
        return
      }
      trackAnalyticsEvent('discount_discarded', {
        discount_uuid: discountUuid,
        action: 'edit',
      })
    },
    [trackAnalyticsEvent]
  )

  const trackDiscountSaved = useCallback(
    ({
      discountUuid,
      action,
      fields,
    }: {
      discountUuid: string
      action: Action
      fields: DiscountFormFields
    }) => {
      trackAnalyticsEvent('discount_saved', {
        action,
        discount_uuid: discountUuid,
        type:
          fields.config === CatalogDiscountConfig.PERCENTAGE
            ? 'percentage'
            : 'value',
        amount: Number(fields.value),
        site_count: fields.sites?.length ?? 0,
      })
    },
    [trackAnalyticsEvent]
  )

  const trackDiscountDeleted = useCallback(
    ({ discountUuid }: { discountUuid: string }) => {
      trackAnalyticsEvent('discount_deleted', {
        discount_uuid: discountUuid,
      })
    },
    [trackAnalyticsEvent]
  )

  const trackItemSettingsUpdated = useCallback(
    ({ fields }: { fields: ItemsSettingsFormFields }) => {
      trackAnalyticsEvent('item_settings_updated', {
        gst_price_display: fields.isTaxInclusive,
        new_items_gst: fields.isTaxApplicable,
        sku_generation: fields.shouldAutoGenerateSku ?? false,
      })
    },
    [trackAnalyticsEvent]
  )

  return {
    trackItemDiscarded,
    trackItemCreationStarted,
    trackItemDeleted,
    trackItemSaved,
    trackItemImportStarted,
    trackItemImportCompleted,
    trackExportStarted,
    trackDeleteAllStarted,
    trackDeleteAllConfirmed,
    trackCategoryCreationStarted,
    trackCategoryDiscarded,
    trackCategorySaved,
    trackCategoryDeleted,
    trackAttributeSetCreationStarted,
    trackAttributeSetDiscarded,
    trackAttributeSetSaved,
    trackAttributeSetDeleted,
    trackModifierSetCreationStarted,
    trackModifierSetDiscarded,
    trackModifierSetSaved,
    trackModifierSetDeleted,
    trackDiscountCreationStarted,
    trackDiscountDiscarded,
    trackDiscountSaved,
    trackDiscountDeleted,
    trackItemSettingsUpdated,
  }
}
