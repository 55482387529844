import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  COLOR,
  CrossmarkRoundEdgeIcon,
  MenubarsIcon,
  SvgIcon,
} from '@npco/zeller-design-system'

import { useGetUnreadNotificationCountQuery } from 'hooks/useGetUnreadNotificationCount/graphql/getUnreadNotificationCount.generated'
import { LogoIconLink } from 'components/LogoIconLink'
import { translationsShared } from 'translations'

import * as notificationStyled from '../../Elements/NotificationsItem.styled'
import { useSidebarLocalCache } from '../../hooks/useSidebarLocalCache'
import * as styled from './MobileNavHeader.styled'

export const MobileNavHeaderDeps = {
  useGetUnreadNotificationCountQuery,
}

export const MobileNavHeader = () => {
  const { useGetUnreadNotificationCountQuery } = MobileNavHeaderDeps

  const entityUuid = useSelectedEntityUuid()
  const { toggleFold, isFolded } = useSidebarLocalCache()
  const t = useTranslations(translationsShared)

  const { data } = useGetUnreadNotificationCountQuery({
    variables: { entityUuid },
  })
  const isDotVisible = Number(data?.getUnreadNotificationCount) > 0

  return (
    <styled.PageTemplateWrapper data-testid="mobile-nav-header">
      <styled.MenuIconWrapper
        aria-controls="menu-sidebar"
        aria-label={t('toggleMenu')}
        aria-expanded={!isFolded}
        data-testid="menu-icon"
        onClick={toggleFold}
      >
        <SvgIcon width="20" height="20" color={COLOR.BLUE_1000}>
          {isFolded ? (
            <>
              <MenubarsIcon />
              <notificationStyled.DotWrapper>
                <notificationStyled.NotificationsDot
                  $isMobile
                  $isFolded={isFolded}
                  $newDot={!isDotVisible}
                  data-testid="notifications-dot"
                />
              </notificationStyled.DotWrapper>
            </>
          ) : (
            <CrossmarkRoundEdgeIcon />
          )}
        </SvgIcon>
      </styled.MenuIconWrapper>
      <LogoIconLink
        width="69"
        height="24"
        ariaLabel="Zeller - back to overview"
      />
    </styled.PageTemplateWrapper>
  )
}
