import { useLazyQuery } from '@apollo/client'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { GetInvoiceItemSettings as GetInvoiceItemSettingsResponse } from 'types/gql-types/GetInvoiceItemSettings'

import { GetInvoiceItemSettings } from './graphql/getInvoiceItemSettings'

export const useGetInvoiceItemSettings = () => {
  const entityUuid = useSelectedEntityUuid()
  const flags = useFeatureFlags()

  const [getInvoiceItemSettings, { data, loading: isLoading }] =
    useLazyQuery<GetInvoiceItemSettingsResponse>(GetInvoiceItemSettings, {
      fetchPolicy: 'network-only',
      variables: {
        entityUuid,
      },
    })

  const settings = flags.POSItems
    ? data?.getCatalogSettings
    : data?.getItemSettings

  return {
    getInvoiceItemSettings,
    isLoading,
    settings,
  }
}
