import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { BusinessesListCustomerEntityMappingFragmentDoc } from '@npco/mp-feature-manage-businesses'
import * as Types from '@npco/mp-gql-types'

import { TopBarNavCustomerEntityRelationFragmentDoc } from '../../../../../components/TopBarNav/TopBarNav.generated'

const defaultOptions = {} as const
export type CustomerEntityMappingQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type CustomerEntityMappingQueryResponse = {
  __typename?: 'Query'
  getCustomerEntityMapping: {
    __typename?: 'CustomerEntityMapping'
    defaultEntityUuid: string
    entityRelations: Array<{
      __typename?: 'CustomerEntityRelation'
      entityUuid: string
      isVisible: boolean
      order: number | null
      role: Types.CustomerRole
      entity: { __typename?: 'Entity'; id: string; name: string | null }
    }> | null
  }
}

export const CustomerEntityMapping = gql`
  query CustomerEntityMapping {
    getCustomerEntityMapping {
      defaultEntityUuid
      ...BusinessesListCustomerEntityMappingFragment
      entityRelations {
        entityUuid
        isVisible
        order
        ...TopBarNavCustomerEntityRelationFragment
      }
    }
  }
  ${BusinessesListCustomerEntityMappingFragmentDoc}
  ${TopBarNavCustomerEntityRelationFragmentDoc}
` as unknown as TypedDocumentNode<
  CustomerEntityMappingQueryResponse,
  CustomerEntityMappingQueryVariables
>

/**
 * __useCustomerEntityMappingQuery__
 *
 * To run a query within a React component, call `useCustomerEntityMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerEntityMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerEntityMappingQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerEntityMappingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomerEntityMappingQueryResponse,
    CustomerEntityMappingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CustomerEntityMappingQueryResponse,
    CustomerEntityMappingQueryVariables
  >(CustomerEntityMapping, options)
}
export function useCustomerEntityMappingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomerEntityMappingQueryResponse,
    CustomerEntityMappingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CustomerEntityMappingQueryResponse,
    CustomerEntityMappingQueryVariables
  >(CustomerEntityMapping, options)
}
export type CustomerEntityMappingQueryHookResult = ReturnType<
  typeof useCustomerEntityMappingQuery
>
export type CustomerEntityMappingLazyQueryHookResult = ReturnType<
  typeof useCustomerEntityMappingLazyQuery
>
export type CustomerEntityMappingQueryResult = Apollo.QueryResult<
  CustomerEntityMappingQueryResponse,
  CustomerEntityMappingQueryVariables
>
