import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  AVATAR_BADGE_SIZE,
  AvatarBasic,
  BodyXSmall,
  Box,
  COLOR,
  Divider,
  Ellipsize,
  Flex,
  Heading,
  Message,
} from '@npco/zeller-design-system'

import { ReactComponent as SomethingWentWrongImage } from 'assets/svg/Something went wrong - dominoes.svg'
import { ROOT } from 'const/routes'
import { ListLoader } from 'components/Lists'

import { translations } from './CategoryDrawerItems.i18n'
import * as styled from './CategoryDrawerItems.styled'
import { useGetDrawerItemsInfiniteLoader } from './hooks/useGetDrawerItemsInfiniteLoader'
import { LoaderList } from './LoaderList'

interface CategoryDrawerItemsProps {
  categoryId: string
}

export const CategoryDrawerItems = ({
  categoryId,
}: CategoryDrawerItemsProps) => {
  const t = useTranslations(translations)
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const { isLoading, isLoadingMore, items, observerContainer } =
    useGetDrawerItemsInfiniteLoader(categoryId)

  if (isLoading) {
    return <LoaderList />
  }

  if (!items?.length) {
    return (
      <Message
        margin="76px 0 0 0"
        description={t('noDataDescription')}
        image={<SomethingWentWrongImage />}
        primaryButton={{
          label: t('primaryButtonLabel'),
          onClick: () =>
            navigate(ROOT.ORGS.ORG(shortEntityId).ITEM_MANAGEMENT.ITEMS.path),
        }}
        title={t('noDataTitle')}
      />
    )
  }

  return (
    <styled.Wrapper>
      <Heading.H3>{t('heading')}</Heading.H3>
      <Box as="ul" m={0} p={0} mt="12px">
        {items.map(({ id, name, sku }) => {
          return (
            <Fragment key={id}>
              <Flex
                as="li"
                alignItems="center"
                gridGap="12px"
                px="6px"
                py="12px"
              >
                <AvatarBasic badgeSize={AVATAR_BADGE_SIZE.SMALL} text={name} />
                <Flex flexDirection="column" overflow="hidden">
                  <Ellipsize as="span">{name}</Ellipsize>
                  {sku && (
                    <BodyXSmall as="span" color={COLOR.GREY_550}>
                      {t('sku', { sku })}
                    </BodyXSmall>
                  )}
                </Flex>
              </Flex>
              <Divider margin={0} />
            </Fragment>
          )
        })}
        <div ref={observerContainer} />
        {isLoadingMore && <ListLoader />}
      </Box>
    </styled.Wrapper>
  )
}
