import { type NavigateFunction } from 'react-router-dom-v5-compat'
import { ApolloClient, ReactiveVar } from '@apollo/client'
import { isEmpty, sortBy } from 'lodash-es'

import {
  EntitySession,
  EntityStore,
  GetEntityLocalStorageDataType,
  GetEntityReactiveVariableDataType,
  GetEntitySessionDataType,
  SavedEntitySession,
} from './MultiEntityStore.types'
import { setSessionStorageItem } from './MultiEntityStore.utils'

const DefaultPath = '/'

export type MultiEntityStoreConstructorProps = {
  navigate: NavigateFunction
  getEntityReactiveVariableData: GetEntityReactiveVariableDataType
  getEntityLocalStorageData: GetEntityLocalStorageDataType
  getEntitySessionData: GetEntitySessionDataType
  resetEntityReactiveVariables: () => void
  resetEntitySessionData: () => void
  keyToReactiveVarMap: {
    [key: string]: ReactiveVar<any>
  }
  initialEntitySessions: SavedEntitySession[]
  client: ApolloClient<unknown>
  convertToShortUuid: (entityUuid: string) => string
}
export class MultiEntityStore {
  private readonly entityStore: EntityStore = {}

  private readonly client: ApolloClient<unknown>

  private currentEntityUuid: string | null = null

  private listeners: Array<() => void> = [] // Listeners to trigger React updates

  private readonly keyToReactiveVarMap: {
    [key: string]: ReactiveVar<any>
  } = {}

  private readonly getEntityReactiveVariableData: GetEntityReactiveVariableDataType

  private readonly getEntitySessionData: GetEntityLocalStorageDataType

  private readonly getEntityLocalStorageData: GetEntitySessionDataType

  private readonly resetEntityReactiveVariables: () => void

  private readonly resetEntitySessionData: () => void

  private navigate: NavigateFunction

  private readonly convertToShortUuid: (entityUuid: string) => string

  constructor({
    navigate,
    keyToReactiveVarMap,
    getEntityReactiveVariableData,
    getEntitySessionData,
    getEntityLocalStorageData,
    resetEntityReactiveVariables,
    resetEntitySessionData,
    initialEntitySessions,
    client,
    convertToShortUuid,
  }: MultiEntityStoreConstructorProps) {
    this.navigate = navigate
    this.client = client
    this.keyToReactiveVarMap = keyToReactiveVarMap
    this.getEntityReactiveVariableData = getEntityReactiveVariableData
    this.resetEntityReactiveVariables = resetEntityReactiveVariables
    this.getEntitySessionData = getEntitySessionData
    this.resetEntitySessionData = resetEntitySessionData
    this.getEntityLocalStorageData = getEntityLocalStorageData
    this.convertToShortUuid = convertToShortUuid

    const sortedInitialEntitySessions = sortBy(initialEntitySessions, 'order')

    sortedInitialEntitySessions.forEach((savedSession) => {
      if (!this.entityStore[savedSession.entityUuid]) {
        this.addEntitySession(savedSession.entityUuid)
      }
    })

    const initialEntityUuid =
      sortedInitialEntitySessions.find((savedSession) => savedSession.isActive)
        ?.entityUuid ??
      sortedInitialEntitySessions[0]?.entityUuid ??
      null

    if (!this.entityStore[initialEntityUuid]) {
      this.addEntitySession(initialEntityUuid)
    }

    this.restoreEntityReactiveVariables(initialEntityUuid)
    this.restoreEntitySessionData(initialEntityUuid)
    this.restoreEntityLocalStorage(initialEntityUuid)

    this.currentEntityUuid = initialEntityUuid
  }

  public subscribe = (listener: () => void) => {
    this.listeners.push(listener)
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener)
    }
  }

  public addEntitySession = (entityId: string) => {
    if (this.entityStore[entityId]) {
      return
    }

    this.saveEntityData({
      entityId,
      reactiveVariableData: {},
      sessionData: {},
      localStorageData: {},
      path: `/orgs/${this.convertToShortUuid(entityId)}/overview/payments`,
      routeState: {},
    })

    this.client.cache.modify({
      id: this.client.cache.identify({
        __typename: 'CustomerEntityRelation',
        entityUuid: entityId,
      }),
      fields: {
        isVisible: () => true,
        order: () => Object.keys(this.entityStore).length,
      },
    })
    this.syncWithStorage()
  }

  public removeEntitySession = (entityId: string) => {
    delete this.entityStore[entityId]
    this.syncWithStorage()

    if (this.currentEntityUuid === entityId) {
      this.currentEntityUuid = null
      const newEntityUuid =
        Object.keys(this.entityStore).find((key) => !!key) ?? null

      if (newEntityUuid) {
        this.changeCurrentEntitySession(newEntityUuid)
      }
    }

    const prevOrderRef: { prevOrder: number | null } = { prevOrder: null }

    this.client.cache.modify({
      id: this.client.cache.identify({
        __typename: 'CustomerEntityRelation',
        entityUuid: entityId,
      }),
      fields: {
        isVisible: () => false,
        order: (prevOrder: number | null) => {
          prevOrderRef.prevOrder = prevOrder

          return null
        },
      },
    })

    Object.keys(this.entityStore).forEach((entityUuid) => {
      this.client.cache.modify({
        id: this.client.cache.identify({
          __typename: 'CustomerEntityRelation',
          entityUuid,
        }),
        fields: {
          order: (prevOrder) => {
            if (
              prevOrder &&
              prevOrderRef.prevOrder !== null &&
              prevOrder > prevOrderRef.prevOrder
            ) {
              return prevOrder - 1
            }

            return prevOrder
          },
        },
      })
    })
  }

  public changeCurrentEntitySession = (entityUuid: string | null) => {
    if (this.currentEntityUuid === entityUuid) {
      return
    }

    if (this.currentEntityUuid) {
      this.saveEntityData({
        entityId: this.currentEntityUuid,
        reactiveVariableData: this.getEntityReactiveVariableData?.() ?? {},
        sessionData: this.getEntitySessionData?.() ?? {},
        localStorageData: this.getEntityLocalStorageData?.() ?? {},
        path: window.location.pathname,
        routeState: window.history.state?.state ?? {},
      })
    }

    // Create a new session if it doesn't exist
    if (entityUuid && !this.entityStore[entityUuid]) {
      this.addEntitySession(entityUuid)
    }

    if (entityUuid) {
      this.restoreEntityReactiveVariables(entityUuid)
      this.restoreEntitySessionData(entityUuid)
      this.restoreEntityLocalStorage(entityUuid)
    }

    this.currentEntityUuid = entityUuid

    if (entityUuid) {
      this.navigate(this.entityStore[entityUuid].path ?? DefaultPath, {
        state: this.entityStore[entityUuid].routeState,
      })
    }

    this.notifyListeners() // Notify listeners when entityStore changes
  }

  public getCurrentEntityId = () => {
    return this.currentEntityUuid
  }

  public getEntityStore = () => {
    return this.entityStore
  }

  public setCurrentEntityId = (entityUuid: string) => {
    this.currentEntityUuid = entityUuid
    if (!this.entityStore[entityUuid]) {
      this.addEntitySession(entityUuid)
    }
  }

  public setNavigate = (navigate: NavigateFunction) => {
    this.navigate = navigate
  }

  private notifyListeners = () => {
    this.listeners?.forEach((listener) => listener())
  }

  private saveEntityData = ({
    entityId,
    reactiveVariableData,
    sessionData,
    localStorageData,
    path,
    routeState,
  }: EntitySession & { entityId: string }) => {
    this.entityStore[entityId] = {
      ...this.entityStore[entityId],
      reactiveVariableData,
      sessionData,
      localStorageData,
      path,
      routeState,
    }
    this.syncWithStorage()
  }

  private restoreEntityReactiveVariables = (entityId: string) => {
    const session = this.entityStore[entityId]
    // Reset all reactive variables to default values first
    this.resetEntityReactiveVariables()
    // Set reactive variables to the values stored in the session
    if (
      session?.reactiveVariableData &&
      !isEmpty(session.reactiveVariableData)
    ) {
      Object.keys(session.reactiveVariableData).forEach((key) => {
        if (this.keyToReactiveVarMap[key]) {
          this.keyToReactiveVarMap[key](session.reactiveVariableData[key])
        }
      })
    }
  }

  private restoreEntitySessionData = (entityId: string) => {
    const session = this.entityStore[entityId]
    // Rest all session data to default values
    this.resetEntitySessionData()
    // Restore session data from the session
    if (session?.sessionData && !isEmpty(session.sessionData)) {
      Object.keys(session.sessionData).forEach((key) => {
        setSessionStorageItem(key, session.sessionData[key])
      })
    }
  }

  private restoreEntityLocalStorage = (entityId: string) => {
    const session = this.entityStore[entityId]
    if (session?.localStorageData && !isEmpty(session.localStorageData)) {
      Object.keys(session.localStorageData).forEach((key) => {
        window.localStorage.setItem(
          key,
          JSON.stringify(session.localStorageData[key])
        )
      })
    }
  }

  private syncWithStorage = () => {
    // Save entity store state to session storage
    setSessionStorageItem('entityStore', this.entityStore)
  }
}
