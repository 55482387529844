import { createTranslations } from '@npco/utils-translations'

export const translations = createTranslations({
  breadcrumb: 'Transfer',
  recentTransactionsLinkMobile: 'Repeat past transaction',
})

export const contactTransferTranslations = createTranslations({
  accountNameLabel: 'Account Name',
  accountNumberLabel: 'Account Number',
  accountBsbLabel: 'BSB',
})

export const externalTransferTranslations = createTranslations({
  accountNameLabel: 'Account Name',
  nicknameLabel: 'Nickname (Optional)',
  accountBsbLabel: 'BSB',
  accountNumberLabel: 'Account Number',
  recipientDescriptionPlaceholder: 'Up to 18 characters',
  abusiveDescriptionError:
    'This transfer has been flagged for possibly containing abusive or negative content. Please update the content before sending. If you believe this is a mistake, please contact Zeller support.',
})

export const zellerTransferTranslations = createTranslations({
  amountLabel: 'Amount',
  referencePlaceholder: 'Up to {maxLength} characters',
})

export const bPayTransferTranslations = createTranslations({
  transferFailed: 'Your transfer was unsuccessful. Please try again.',
})
