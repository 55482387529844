import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { ConnectionType } from '@npco/mp-gql-types'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { Avatar } from '@npco/ui-avatar'
import { Box, DrawerWithIcon, Flex } from '@npco/zeller-design-system'

import { ReactComponent as XeroLogo } from 'assets/svg/logos/xero.svg'
import { ROOT } from 'const/routes'
import { translate } from 'utils/translations'
import { AVATAR_BADGE_SIZE } from 'types/common'

import { XeroConnectionAgreement } from '../components/XeroConnectionAgreement'
import { generateXeroOauthScopeAuthorizeURI } from '../Connections.utils'
import { AccountSkeleton } from './components/AccountSkeleton'
import { RetryModal } from './components/RetryModal'
import { useGetSettlementAccountInfo } from './hooks/useGetSettlementAccountInfo'
import { Description } from './XeroPaymentServicesConnection.styled'

export const XeroPaymentServicesConnection = () => {
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()

  const { accountName, accountColor, accountIcon, isLoading } =
    useGetSettlementAccountInfo()

  const handleXeroRedirection = useCallback(() => {
    window.location.replace(
      generateXeroOauthScopeAuthorizeURI(
        ConnectionType.XERO_PAYMENT_SERVICES,
        shortEntityId
      )
    )
  }, [shortEntityId])

  const handleOnClose = useCallback(
    () => navigate(ROOT.ORGS.ORG(shortEntityId).SETTINGS.CONNECTIONS.path),
    [navigate, shortEntityId]
  )

  return (
    <DrawerWithIcon
      isOpen
      icon={
        <Flex justifyContent="left">
          <XeroLogo width={48} height={48} />
        </Flex>
      }
      drawerTitle={translate(
        'page.settings.connections.xeroPaymentServices.title'
      )}
      onClose={handleOnClose}
      overlayClassName="animated-drawer-overlay"
    >
      <Flex flexDirection="column" height="100%">
        <Box mb="36px">
          <Description>
            {translate(
              'page.settings.connections.xeroPaymentServices.description'
            )}
          </Description>
        </Box>
        {isLoading ? (
          <AccountSkeleton />
        ) : (
          <Flex alignItems="center">
            <Box mr="16px">
              <Avatar
                text={accountName}
                color={accountColor}
                badgeSize={AVATAR_BADGE_SIZE.SMALL}
                userDefinedContent={accountIcon}
              />
            </Box>
            <Description>{accountName}</Description>
          </Flex>
        )}
        <Box height="100%" mt="36px">
          <XeroConnectionAgreement onSubmit={handleXeroRedirection} />
        </Box>
        <RetryModal />
      </Flex>
    </DrawerWithIcon>
  )
}
