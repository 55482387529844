import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { EntityCategories } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { DeleteSubcategory } from 'apps/component-merchant-portal/src/graphql/merchant-portal/mutations/subcategories'
import { GetSubcategories } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/subcategories'

import {
  DeleteSubcategory as DeleteSubcategoryResponse,
  DeleteSubcategoryVariables,
} from 'types/gql-types/DeleteSubcategory'
import {
  GetSubcategories as GetSubcategoriesResponse,
  GetSubcategoriesVariables,
} from 'types/gql-types/GetSubcategories'

interface Props {
  category: EntityCategories | undefined
  subcategoryId: string | null | undefined
  onSuccess?: () => void
  onFailure?: () => void
}

export const useDeleteSubcategory = ({
  category,
  subcategoryId,
  onSuccess,
  onFailure,
}: Props) => {
  const entityUuid = useSelectedEntityUuid()
  const [deleteSubcategoryMutation, { loading: isLoading, error }] =
    useMutation<DeleteSubcategoryResponse, DeleteSubcategoryVariables>(
      DeleteSubcategory,
      {
        onCompleted: (response) => {
          if (response.removeEntitySubcategory) {
            onSuccess?.()
          } else {
            onFailure?.()
          }
        },
        onError: onFailure,
      }
    )

  const deleteSubcategory = useCallback(() => {
    if (category && subcategoryId) {
      deleteSubcategoryMutation({
        variables: {
          subcategoryUuid: subcategoryId,
        },
        update: (cache, result) => {
          if (result.data?.removeEntitySubcategory) {
            const data = cache.readQuery<
              GetSubcategoriesResponse,
              GetSubcategoriesVariables
            >({ query: GetSubcategories, variables: { category, entityUuid } })
            cache.writeQuery<
              GetSubcategoriesResponse,
              GetSubcategoriesVariables
            >({
              query: GetSubcategories,
              variables: { category, entityUuid },
              data: {
                getEntitySubcategories:
                  data?.getEntitySubcategories?.filter(
                    (cachedSubcategory) =>
                      cachedSubcategory?.id !== subcategoryId
                  ) || [],
              },
            })
          }
        },
      })
    }
  }, [category, subcategoryId, deleteSubcategoryMutation, entityUuid])

  return { deleteSubcategory, isLoading, error }
}
