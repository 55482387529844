import { sortAscendingByProperty } from '@npco/component-mp-common'
import {
  ContactEmailInput,
  ContactInput,
  ContactPhoneInput,
  ContactType,
  ContactUpdateInput,
  LabelType,
} from '@npco/mp-gql-types'
import { ContactCoreFieldsFragment } from 'features/Contacts/graphql/ContactCoreFields.generated'
import { FormikHelpers } from 'formik'
import { type CountryCode, parsePhoneNumber } from 'libphonenumber-js/max'

import { AUSTRALIA_COUNTRY_CODE } from 'const/common'
import countryCodes from 'const/country-codes.json'
import { buildStringWithSeparator } from 'utils/common'
import { toAlpha2CodeCountry } from 'utils/countries'
import {
  getIsPhoneNumberValid,
  makeOptional,
  validateEmailForContact,
} from 'utils/formValidation'
import {
  getLocationDataFromGoogle,
  locationTypeInMetersMap,
} from 'utils/google'
import { translate } from 'utils/translations'
import { CustomValidator } from 'types/common'
import { GoogleParsedAddressData } from 'types/google'
import { CreateContact_createContact as CreateContact } from 'types/gql-types/CreateContact'
import {
  GetLabel,
  rvBusinessLabels,
  rvBusinessLabelsDeleted,
  rvPersonLabels,
  rvPersonLabelsDeleted,
} from 'components/LabelControl'
import { errorMessages } from 'translations'

import {
  ContactBasicType,
  ContactBusinessFormData,
  ContactFormData,
  ContactPersonFormData,
  InputSelectComboBoxItem,
} from './Contacts.types'

type GetContact = ContactCoreFieldsFragment
type GetSubContact = NonNullable<GetContact['contacts']>[0]
type GetContactEmail = GetContact['email']
type GetContactAdditionalEmail = NonNullable<GetContact['additionalEmails']>[0]
type GetContactAdditionalPhone = NonNullable<GetContact['additionalPhones']>[0]

export const initialValues: ContactFormData = {
  business: {
    abn: '',
    acn: '',
    additionalEmails: [],
    additionalPhones: [],
    country: '',
    email: {
      email: '',
      label: null,
    },
    businessName: '',
    phone: '',
    phoneV2: {
      phone: '',
      label: null,
    },
    postcode: '',
    state: '',
    street: '',
    suburb: '',
    website: '',
    isSelf: null,
  },
  linkedContactUuid: '',
  person: {
    additionalEmails: [],
    additionalPhones: [],
    country: '',
    email: {
      email: '',
      label: null,
    },
    firstName: '',
    jobTitle: '',
    lastName: '',
    phone: '',
    phoneV2: {
      phone: '',
      label: null,
    },
    postcode: '',
    state: '',
    street: '',
    suburb: '',
    isSelf: null,
  },
}

export const getAdditionalEmailsPayload = (
  additionalEmails: GetContactAdditionalEmail[]
): ContactEmailInput[] =>
  additionalEmails
    .filter((additionalEmail) => Boolean(additionalEmail?.email))
    .map((additionalEmail) => ({
      email: additionalEmail.email.trim(),
      labelUuid: additionalEmail?.label?.id ?? null,
    }))

export const getAdditionalPhoneNumbersPayload = (
  additionalPhoneNumbers: GetContactAdditionalPhone[]
): ContactPhoneInput[] => {
  return additionalPhoneNumbers
    .filter((phone) => Boolean(phone?.phone))
    .map((additionalPhone) => ({
      phone: additionalPhone.phone.trim(),
      labelUuid: additionalPhone?.label?.id ?? null,
    }))
}

export const getContactName = (contact: ContactBasicType | null) => {
  if (!contact) {
    return null
  }

  const { contactType, businessName, firstName, lastName } = contact

  return contactType === ContactType.BUSINESS
    ? businessName
    : `${firstName} ${lastName}`
}

export const getCountryNameByAlpha3Code = (alpha3Code: string) => {
  const result = countryCodes.find(
    (element) => element.alpha3Code === alpha3Code
  )
  return result?.name || ''
}

export const getAddress = (contact: GetContact) => {
  if (
    !contact.address ||
    Object.values(contact?.address).every((value) => !value)
  ) {
    return '-'
  }

  const { street, suburb, state, postcode, country } = contact.address

  return [street, suburb, `${state ?? ''} ${postcode ?? ''}`.trim(), country]
    .filter(Boolean)
    .join(', ')
}

const getAddressFromContact = (contact: GetContact) => {
  return {
    country: contact.address?.country || '',
    postcode: contact.address?.postcode || '',
    state: contact.address?.state || '',
    street: contact.address?.street || '',
    suburb: contact.address?.suburb || '',
  }
}

const getAdditionalEmailsFromContact = (contact: GetContact) => {
  return (contact.additionalEmails || []).filter((additionalEmail) =>
    Boolean(additionalEmail?.email)
  )
}

const getAdditionalPhoneNumbersFromContact = (
  contact: GetContact
): GetContactAdditionalPhone[] => {
  return (contact.additionalPhones || []).filter((additionalPhone) =>
    Boolean(additionalPhone?.phone)
  )
}

export const getAddressString = (
  data: ContactBusinessFormData | ContactPersonFormData
) => {
  const addressList = [
    data.street || '',
    data.suburb || '',
    data.state || '',
    data.postcode || '',
    getCountryNameByAlpha3Code(data.country) || '',
  ]

  return addressList.filter((element) => Boolean(element)).join(',')
}

const getContactFormBusinessInitialValues = (contact: GetContact) => ({
  ...initialValues,
  business: {
    abn: contact.abn || '',
    acn: contact.acn || '',
    additionalEmails: getAdditionalEmailsFromContact(contact),
    additionalPhones: getAdditionalPhoneNumbersFromContact(contact),
    email: {
      email: contact.email?.email || '',
      label: contact.email?.label || null,
    },
    businessName: contact.businessName || '',
    phone: contact.phone || '',
    phoneV2: {
      phone: contact.phoneV2?.phone.trim() || '',
      label: contact.phoneV2?.label || null,
    },
    website: contact.website || '',
    isSelf: contact.isSelf,
    ...getAddressFromContact(contact),
  },
})

const getBusinessPayload = async (data: ContactFormData) => {
  const address = getAddressString(data.business)
  const locationData = address
    ? await getLocationDataFromGoogle({
        address,
      })
    : null

  return {
    abn: data.business.abn,
    acn: data.business.acn,
    additionalEmails: getAdditionalEmailsPayload(
      data.business.additionalEmails
    ),
    additionalPhones: getAdditionalPhoneNumbersPayload(
      data.business.additionalPhones
    ),
    address: {
      street: data.business.street,
      suburb: data.business.suburb,
      state: data.business.state,
      postcode: data.business.postcode,
      country: data.business.country,
    },
    businessName: data.business.businessName?.trim(),
    email: {
      email: data.business.email?.email?.trim() ?? '',
      labelUuid: data.business.email?.label?.id ?? null,
    },
    location: locationData?.locationString,
    locationAccuracy: locationData?.locationTypeInMeters,
    phone: data.business.phone?.trim(),
    phoneV2: {
      phone: data.business.phoneV2?.phone.trim() || '',
      labelUuid: data.business.phoneV2?.label?.id ?? null,
    },
    website: data.business.website,
    isSelf: data.business.isSelf,
  }
}

const getPersonPayload = async (data: ContactFormData) => {
  const address = getAddressString(data.person)
  const locationData = address
    ? await getLocationDataFromGoogle({
        address,
      })
    : null

  return {
    additionalEmails: getAdditionalEmailsPayload(data.person.additionalEmails),
    additionalPhones: getAdditionalPhoneNumbersPayload(
      data.person.additionalPhones
    ),
    address: {
      country: data.person.country,
      postcode: data.person.postcode,
      state: data.person.state,
      street: data.person.street,
      suburb: data.person.suburb,
    },
    email: {
      email: data.person.email?.email?.trim() ?? '',
      labelUuid: data.person.email?.label?.id ?? null,
    },
    firstName: data.person.firstName?.trim(),
    jobTitle: data.person.jobTitle,
    lastName: data.person.lastName?.trim(),
    location: locationData?.locationString,
    locationAccuracy: locationData
      ? locationTypeInMetersMap[locationData.locationType]
      : undefined,
    phone: data.person.phone?.trim(),
    phoneV2: {
      phone: data.person.phoneV2?.phone?.trim() || '',
      labelUuid: data.person.phoneV2?.label?.id ?? null,
    },
    isSelf: data.person.isSelf,
  }
}

export const getBusinessCreateContactPayload = async (
  data: ContactFormData
): Promise<ContactInput> => {
  const payload = await getBusinessPayload(data)

  return {
    ...payload,
    contactType: ContactType.BUSINESS,
  }
}

export const getBusinessUpdateContactPayload = async (
  data: ContactFormData
): Promise<ContactUpdateInput> => getBusinessPayload(data)

export const getBusinessContactData = (
  contact: GetContact,
  data: ContactFormData,
  location: string | null,
  locationAccuracy: number | null
): GetContact => ({
  ...contact,
  abn: data.business.abn,
  acn: data.business.acn,
  additionalEmails: data.business.additionalEmails,
  additionalPhones: data.business.additionalPhones,
  address: {
    street: data.business.street,
    suburb: data.business.suburb,
    state: data.business.state,
    postcode: data.business.postcode,
    country: data.business.country,
  },
  businessName: data.business.businessName?.trim(),
  contactType: ContactType.BUSINESS,
  email: {
    email: data.business.email?.email?.trim() ?? '',
    label: data.business.email?.label ?? null,
  },
  firstName: null,
  lastName: null,
  location,
  locationAccuracy,
  phone: data.business.phone?.trim() || '',
  phoneV2: {
    phone: data.business.phoneV2?.phone.trim() || '',
    label: data.business.phoneV2?.label || null,
  },
  website: data.business.website,
  isSelf: data.business.isSelf,
})

const getContactFormPersonInitialValues = (contact: GetContact) => ({
  ...initialValues,
  person: {
    additionalEmails: getAdditionalEmailsFromContact(contact),
    additionalPhones: getAdditionalPhoneNumbersFromContact(contact),
    email: {
      email: contact.email?.email || '',
      label: contact.email?.label || null,
    },
    firstName: contact.firstName || '',
    jobTitle: contact.jobTitle || '',
    lastName: contact.lastName || '',
    phone: contact.phone || '',
    phoneV2: {
      phone: contact.phoneV2?.phone.trim() || '',
      label: contact.phoneV2?.label || null,
    },
    isSelf: contact.isSelf,
    ...getAddressFromContact(contact),
  },
})

export const getContactFormInitialValues = (
  contact: GetContact | null
): ContactFormData => {
  if (!contact) {
    return initialValues
  }

  if (contact.contactType === ContactType.BUSINESS) {
    return getContactFormBusinessInitialValues(contact)
  }

  return getContactFormPersonInitialValues(contact)
}

export const getPersonCreateContactPayload = async (
  data: ContactFormData
): Promise<ContactInput> => {
  const payload = await getPersonPayload(data)

  return {
    ...payload,
    contactType: ContactType.PERSON,
  }
}

export const getPersonUpdateContactPayload = async (
  data: ContactFormData
): Promise<ContactUpdateInput> => getPersonPayload(data)

export const getPersonContactData = (
  contact: GetContact,
  data: ContactFormData,
  location: string | null,
  locationAccuracy: number | null
) => ({
  ...contact,
  additionalEmails: data.person.additionalEmails,
  additionalPhones: data.person.additionalPhones,
  address: {
    country: data.person.country,
    postcode: data.person.postcode,
    state: data.person.state,
    street: data.person.street,
    suburb: data.person.suburb,
  },
  contactType: ContactType.PERSON,
  email: {
    email: data.person.email?.email?.trim() ?? '',
    label: data.person.email?.label ?? null,
  },
  firstName: data.person.firstName?.trim(),
  jobTitle: data.person.jobTitle,
  lastName: data.person.lastName?.trim(),
  location,
  locationAccuracy,
  phone: data.person.phone?.trim(),
  phoneV2: {
    phone: data.person.phoneV2?.phone.trim() || '',
    label: data.person.phoneV2?.label || null,
  },
  isSelf: data.person.isSelf,
})

export const getSortedSubContacts = (contact: GetContact): GetSubContact[] => {
  const contacts = contact?.contacts || []
  const type = contact.contactType

  return contacts
    .filter((subContact): subContact is GetSubContact => Boolean(subContact))
    .sort((a, b) => {
      const sortProperty =
        type === ContactType.BUSINESS ? 'firstName' : 'businessName'

      const prev = a?.[sortProperty] ?? null
      const next = b?.[sortProperty] ?? null

      if (prev === null) {
        return 1
      }
      if (next === null) {
        return -1
      }
      if (prev < next) {
        return -1
      }
      if (prev > next) {
        return 1
      }

      return 0
    })
}

export const isCountryNilOrAustralia = (country?: string | null) =>
  !country || country === AUSTRALIA_COUNTRY_CODE

export const setParsedGoogleAddressToFormValues =
  (
    contactType: ContactType,
    setValues: FormikHelpers<ContactFormData>['setValues']
  ) =>
  (parsedGoogleAddress: GoogleParsedAddressData) => {
    const property =
      contactType === ContactType.BUSINESS ? 'business' : 'person'

    setValues((prevValues) => ({
      ...prevValues,
      [property]: {
        ...prevValues[property],
        street: buildStringWithSeparator(
          ' ',
          parsedGoogleAddress.streetNumber,
          parsedGoogleAddress.street
        ),
        postcode: parsedGoogleAddress.postcode,
        suburb: parsedGoogleAddress.suburb,
        state: parsedGoogleAddress.state,
        country: parsedGoogleAddress.country,
      },
    }))
  }

export const getContactFormValues = (
  contactType: ContactType,
  values: ContactFormData
) => {
  const isBusinessContactType = contactType === ContactType.BUSINESS

  return isBusinessContactType ? values.business : values.person
}

export const getRvLabelTextByLabel = (label?: GetLabel | null) => {
  // NOTE: an email label is not required so return early if no data
  if (!label) {
    return null
  }

  const isBusinessLabelType = label.type === LabelType.BUSINESS

  const rvLabelsDeleted = isBusinessLabelType
    ? rvBusinessLabelsDeleted
    : rvPersonLabelsDeleted

  // NOTE: if label exists in rv labels deleted this means we've deleted
  // optimistically so should return null to keep this change consistent
  const isDeletedLabel = rvLabelsDeleted().includes(label.id)

  if (isDeletedLabel) {
    return null
  }

  const rvLabels = isBusinessLabelType ? rvBusinessLabels : rvPersonLabels

  // NOTE: if we have no rvLabel this means we have no optimistic updates for
  // this label as of yet so return existing label text
  const rvLabel = rvLabels().find((item: GetLabel) => item.id === label.id)

  if (!rvLabel) {
    return label.labelText
  }

  return rvLabel.labelText
}

export const getContactIconLetter = ({
  businessName,
  contactType,
  firstName,
}: Pick<GetContact, 'contactType' | 'businessName' | 'firstName'>) =>
  contactType === ContactType.BUSINESS
    ? String(businessName).charAt(0)
    : String(firstName).charAt(0)

export const transformCreateContactToGetContact = (
  createContact: CreateContact
): GetContact => {
  const { locationAccuracy } = createContact
  return {
    ...createContact,
    __typename: 'Contact',
    category: null,
    contacts: null,
    icon: {
      colour: null,
      images: null,
      letter: getContactIconLetter(createContact),
    },
    locationAccuracy,
    notes: null,
    paymentInstruments: [],
    subcategory: null,
    subcategoryUuid: null,
    tags: null,
  }
}

export const validateEmailDuplication: CustomValidator<
  string,
  GetContactAdditionalEmail[]
> = (value, existingEmails) => {
  if (!value) {
    return undefined
  }

  const hasDuplicateEmail = existingEmails?.some(
    (item) => item?.email?.toUpperCase() === value?.toUpperCase()
  )

  return hasDuplicateEmail
    ? translate('errorMessages.duplicateEmail')
    : undefined
}

export const validateContactEmail = (
  value: string,
  additionalEmails: GetContactAdditionalEmail[],
  isFieldOptional = true,
  errorMessageForIsRequiredValidation = errorMessages.emailRequired
) => {
  const isRequiredError = makeOptional(
    validateEmailForContact(errorMessageForIsRequiredValidation),
    value,
    isFieldOptional
  )

  return isRequiredError || validateEmailDuplication(value, additionalEmails)
}

export const validatePhoneNumberDuplication =
  (country?: string): CustomValidator<string, GetContactAdditionalPhone[]> =>
  (phone, existingPhones) => {
    // Return undefined if phone is not provided or any phone in existingPhones is invalid
    if (
      !phone ||
      existingPhones?.some(
        (item) => !getIsPhoneNumberValid(item?.phone?.toString().trim())
      )
    ) {
      return undefined
    }

    const twoLetterCountryCode = (
      toAlpha2CodeCountry(country) === '' ? 'AU' : toAlpha2CodeCountry(country)
    ) as CountryCode

    const hasDuplicatePhoneNumber = existingPhones?.some(
      (item) =>
        parsePhoneNumber(
          String(item?.phone),
          twoLetterCountryCode
        ).formatInternational() ===
        parsePhoneNumber(
          String(phone),
          twoLetterCountryCode
        ).formatInternational()
    )
    return hasDuplicatePhoneNumber
      ? translate('errorMessages.duplicatePhoneNumber')
      : undefined
  }

export const getUpdatedContactFromFormValues = (
  isBusinessType: boolean,
  contact: GetContact,
  formValues: ContactFormData,
  payload: ContactInput | ContactUpdateInput
) => {
  // NOTE: location and locationAccuracy are not part of the form values and
  // are calculated when creating the payload so pluck from here to update selected
  // contact
  const { location = null, locationAccuracy = null } = payload

  return isBusinessType
    ? getBusinessContactData(contact, formValues, location, locationAccuracy)
    : getPersonContactData(contact, formValues, location, locationAccuracy)
}

export const getContactInputComboboxItem = (contact: ContactBasicType) => {
  const label = getContactName(contact) || ''

  return {
    id: contact.id,
    label,
    subLabel:
      contact?.contactType === ContactType.BUSINESS
        ? translate('page.contacts.form.business')
        : '',
    value: label,
  }
}

export const getContactsInputComboboxItems = (
  contacts: (ContactBasicType | null)[]
): InputSelectComboBoxItem[] => {
  return contacts
    .filter(
      (contact): contact is GetContact =>
        Boolean(contact) && Boolean(contact?.id)
    )
    .map(getContactInputComboboxItem)
}

export const getAllContactEmails = (
  contact: Pick<GetContact, 'email' | 'additionalEmails'> | null
) => {
  if (!contact) {
    return []
  }

  return sortAscendingByProperty<{ email: string; label: string }>('email')(
    [contact.email]
      .concat(contact.additionalEmails)
      .filter((email): email is GetContactEmail => Boolean(email?.email))
      .map((email) => ({
        email: email?.email ?? '',
        label: email?.label?.labelText || '',
      }))
  )
}

export const getDeletedContactInitialValues = (): GetContact => {
  return {
    __typename: 'Contact',
    email: { email: '', label: null },
    firstName: null,
    lastName: null,
    businessName: null,
    contactType: ContactType.PERSON,
    abn: null,
    acn: null,
    additionalEmails: null,
    additionalPhones: null,
    address: null,
    contacts: null,
    icon: null,
    id: '',
    jobTitle: null,
    notes: null,
    paymentInstruments: null,
    phone: null,
    phoneV2: null,
    tags: null,
    website: null,
    location: null,
    locationAccuracy: null,
    category: null,
    subcategory: null,
    subcategoryUuid: null,
    isSelf: null,
  }
}
