import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { cacheNewDebitCard } from 'utils/banking/cacheNewDebitCard/cacheNewDebitCard'
import { UNEXPECTED_ERROR } from 'types/errors'

import {
  ActivateDebitCard,
  ActivateDebitCardMutationResponse,
  ActivateDebitCardMutationVariables,
} from './graphql/ActivateDebitCard.generated'
import {
  getIsApiError,
  getIsDebitCardV2,
  SUPPORTED_ERRORS,
} from './useActivateCard.utils'

interface ActivateDebitCardArguments {
  debitCardAccountUuid?: string
  reference: string
}

export const useActivateCard = () => {
  const entityUuid = useSelectedEntityUuid()
  const [activateCard, { loading }] = useMutation<
    ActivateDebitCardMutationResponse,
    ActivateDebitCardMutationVariables
  >(ActivateDebitCard, {
    update: (cache, { data }) => {
      if (
        data?.activateDebitCard &&
        getIsDebitCardV2(data?.activateDebitCard)
      ) {
        const newDebitCard = data?.activateDebitCard
        cacheNewDebitCard({
          cache,
          newCard: newDebitCard,
          entityUuid,
        })
      }
    },
  })

  const activateDebitCard = useCallback(
    async ({ debitCardAccountUuid, reference }: ActivateDebitCardArguments) => {
      try {
        const activatedCard = await activateCard({
          variables: { input: { debitCardAccountUuid, reference } },
        })

        if (
          !activatedCard.data?.activateDebitCard ||
          (!getIsDebitCardV2(activatedCard.data?.activateDebitCard) &&
            !getIsApiError(activatedCard.data.activateDebitCard))
        ) {
          return UNEXPECTED_ERROR
        }

        if (getIsApiError(activatedCard.data.activateDebitCard)) {
          // Guard against new errors added to BE schema being treated as success
          // If schema is updated without FE update, new errors would not be caught
          // by typescript, and be treated as a success response, leading to an uncaught
          // error on FE. Instead, we map new errors to UNEXPECTED_ERROR
          if (
            activatedCard.data.activateDebitCard.error &&
            !SUPPORTED_ERRORS.includes(
              activatedCard.data.activateDebitCard.error
            )
          ) {
            return UNEXPECTED_ERROR
          }

          return activatedCard.data.activateDebitCard.error || UNEXPECTED_ERROR
        }

        return activatedCard.data.activateDebitCard
      } catch (err) {
        return UNEXPECTED_ERROR
      }
    },
    [activateCard]
  )

  return { activateDebitCard, isActivatingCard: loading }
}
