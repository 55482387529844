import { Navigate, Route, Routes } from 'react-router-dom-v5-compat'

import { ROOT } from 'const/routes'
import { NotFound } from 'pages/NotFound'

import { CategoriesTable } from '../../components/CategoriesTable/CategoriesTable'
import { CreateModalsLauncher } from '../../components/CreateModalsLauncher/CreateModalsLauncher'
import { ItemDeleteAllModal } from '../../components/ItemDeleteAllModal/ItemDeleteAllModal'
import { ItemManagementProvider } from '../../components/ItemManagementContext/ItemManagementContext'
import { ItemManagementLayout } from '../../components/ItemManagementLayout/ItemManagementLayout'
import { ItemsImportModal } from '../../components/ItemsImportModal/ItemsImportModal'
import { ItemsSettings } from '../../components/ItemsSettings/ItemsSettings'
import { ItemsSettingsLayout } from '../../components/ItemsSettings/ItemsSettingsLayout'
import { ItemsTable } from '../../components/ItemsTable/ItemsTable'
import { PrivateItemManagementRoute } from '../PrivateItemManagementRoute/PrivateItemManagementRoute'

const {
  ITEMS: {
    relative: itemsTablePath,
    ITEM,
    NEW: { relative: newItemPath },
    IMPORT: { relative: importItemsPath },
    DELETE_ALL: { relative: deleteAllItemsPath },
  },
  CATEGORIES: {
    relative: categoriesTablePath,
    CATEGORY,
    NEW: { relative: newCategoryPath },
  },
  SETTINGS: { relative: settingsPath },
} = ROOT.ORGS.ORG().ITEM_MANAGEMENT

const { relative: selectedItemPath } = ITEM()
const { relative: selectedCategoryPath } = CATEGORY()

export const ItemManagementRoutes = () => (
  <Routes>
    <Route element={<PrivateItemManagementRoute />}>
      <Route
        element={
          <ItemManagementProvider>
            <ItemManagementLayout />
          </ItemManagementProvider>
        }
      >
        <Route index element={<Navigate to={itemsTablePath} replace />} />
        <Route path={`${itemsTablePath}/*`} element={<ItemsTable />}>
          <Route path={newItemPath} element={<CreateModalsLauncher />} />
          <Route path={importItemsPath} element={<ItemsImportModal />} />
          <Route path={deleteAllItemsPath} element={<ItemDeleteAllModal />} />
          {/*
              ItemDrawer is rendered inside ItemsTable
              This 👇 registers the item route so useParams can
              recognise the /items/:itemId path which triggers
              the ItemDrawer to open/close with animation
          */}
          <Route path={`${selectedItemPath}/*`} element={null} />
        </Route>
        <Route element={<CategoriesTable />} path={categoriesTablePath}>
          {/*
              CategoryDrawer is rendered inside CategoriesTable
              This 👇 registers the category route so useParams can
              recognise the /categories/:categoryId path which triggers
              the CategoryDrawer to open/close with animation
          */}
          <Route path={`${selectedCategoryPath}/*`} element={null} />
          <Route path={newCategoryPath} element={<CreateModalsLauncher />} />
        </Route>
      </Route>
      <Route
        path={settingsPath}
        element={
          <ItemsSettingsLayout>
            <ItemsSettings />
          </ItemsSettingsLayout>
        }
      />
    </Route>

    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default ItemManagementRoutes
