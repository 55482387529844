import { forwardRef, useCallback, useEffect, useState } from 'react'
import {
  ButtonClear,
  INPUT_SIZE,
  InputWithoutLabel,
  SearchIcon,
} from '@npco/zeller-design-system'
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs'

interface SearchInputProps {
  ariaLabel?: string
  autoFocus?: boolean
  dataTestId?: string
  debounceDuration?: number
  defaultValue: string
  name?: string
  onChange: (text: string) => void
  onClear?: () => void
  placeholder?: string
  size?: INPUT_SIZE
  hideClearButton?: boolean
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      ariaLabel = 'search input',
      autoFocus = true,
      dataTestId = 'search-input',
      debounceDuration = 500,
      defaultValue,
      name = 'search-input',
      onChange: handleChange,
      onClear,
      placeholder,
      size = INPUT_SIZE.MEDIUM,
      hideClearButton = false,
    },
    ref
  ) => {
    const [search$] = useState(new Subject<string>())

    useEffect(() => {
      const subscription = new Subscription()

      subscription.add(
        search$
          .pipe(debounceTime(debounceDuration), distinctUntilChanged())
          .subscribe((nextSearch) => handleChange(nextSearch.trim()))
      )

      return () => subscription.unsubscribe()
    }, [debounceDuration, handleChange, search$])

    const handleInputChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) =>
        search$.next(event.target.value),
      [search$]
    )

    return (
      <InputWithoutLabel
        aria-label={ariaLabel}
        autoFocus={autoFocus}
        data-testid={dataTestId}
        defaultValue={defaultValue}
        icon={<SearchIcon />}
        renderRightControls={({ resetInput }) => {
          return (
            !hideClearButton && (
              <ButtonClear
                onClick={() => {
                  onClear?.()
                  resetInput?.()
                }}
              />
            )
          )
        }}
        size={size}
        name={name}
        onChange={handleInputChange}
        placeholder={placeholder}
        ref={ref}
      />
    )
  }
)
