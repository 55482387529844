import { gql } from '@apollo/client'

export const GetItemManagementLocalState = gql`
  query GetItemManagementLocalState($entityUuid: ID!) {
    local(entityUuid: $entityUuid) @client {
      itemManagement {
        categories {
          filterInput
          sortInput
        }
        items {
          filterInput
          sortInput
        }
        hasSeenItemOnboarding
      }
    }
  }
`
