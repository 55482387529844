import { useMemo } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { TabItemProps } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { useItemManagementContext } from '../../ItemManagementContext/ItemManagementContext'
import { translations } from './useTabs.i18n'

const TabValues = {
  ITEMS: ROOT.ORGS.ORG().ITEM_MANAGEMENT.ITEMS,
  CATEGORIES: ROOT.ORGS.ORG().ITEM_MANAGEMENT.CATEGORIES,
}

export const useTabs = () => {
  const navigate = useNavigate()
  const t = useTranslations(translations)
  const { pathname } = useLocation()

  const {
    hasNoItemsAndCategories,
    items: { isDefaultFilters: isDefaultItemFilters },
    categories: { isDefaultFilters: isDefaultCategoryFilters },
  } = useItemManagementContext()

  const tabs: TabItemProps[] = useMemo(
    () =>
      hasNoItemsAndCategories &&
      isDefaultItemFilters &&
      isDefaultCategoryFilters
        ? []
        : [
            {
              id: 'tab-items',
              children: t('items'),
              value: TabValues.ITEMS.path,
              onClick: () => navigate(TabValues.ITEMS.relative),
            },
            {
              id: 'tab-categories',
              children: t('categories'),
              value: TabValues.CATEGORIES.path,
              onClick: () => navigate(TabValues.CATEGORIES.relative),
            },
          ],
    [
      hasNoItemsAndCategories,
      isDefaultCategoryFilters,
      isDefaultItemFilters,
      navigate,
      t,
    ]
  )

  return {
    tabs,
    currentTab: (value: string) => Boolean(matchPath(`${value}/*`, pathname)),
  }
}
