import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { ComingSoonBadge, SvgIcon } from '@npco/zeller-design-system'

import { ReactComponent as ItemsIcon } from 'assets/svg/items.svg'
import { useCustomerItemPermissions } from 'hooks/useGetCustomerItemPermissions/useCustomerItemPermissions'
import { ROOT } from 'const/routes'
import { translationsShared } from 'translations/index'

import { ItemToggle } from '../components/ItemToggle/ItemToggle'
import { SubItem } from '../Sidebar.types'
import { translations } from './ItemsItem.i18n'

export const ItemsItem = () => {
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const flags = useFeatureFlags()
  const shortEntityId = useSelectedShortEntityUuid()

  const { allowDiscountManagement, allowItemManagement } =
    useCustomerItemPermissions()

  if (!flags.POSItems) {
    return null
  }

  const subItems: SubItem[] = [
    {
      subitemTitle: t('allItems'),
      subitemPath: ROOT.ORGS.ORG(shortEntityId).ITEMS.path,
      isVisible: allowItemManagement,
      excludeSubPaths: [
        ROOT.ORGS.ORG(shortEntityId).ITEMS.CATEGORIES.path,
        ROOT.ORGS.ORG(shortEntityId).ITEMS.ATTRIBUTE_SETS.path,
        ROOT.ORGS.ORG(shortEntityId).ITEMS.MODIFIER_SETS.path,
        ROOT.ORGS.ORG(shortEntityId).ITEMS.DISCOUNTS.path,
        ROOT.ORGS.ORG(shortEntityId).ITEMS.REPORTS.path,
        ROOT.ORGS.ORG(shortEntityId).ITEMS.SETTINGS.path,
      ],
    },
    {
      subitemTitle: t('categories'),
      subitemPath: ROOT.ORGS.ORG(shortEntityId).ITEMS.CATEGORIES.path,
      isVisible: allowItemManagement,
    },
    {
      subitemTitle: t('attributeSets'),
      subitemPath: ROOT.ORGS.ORG(shortEntityId).ITEMS.ATTRIBUTE_SETS.path,
      isVisible: allowItemManagement,
    },
    {
      subitemTitle: t('modifierSets'),
      subitemPath: ROOT.ORGS.ORG(shortEntityId).ITEMS.MODIFIER_SETS.path,
      isVisible: allowItemManagement,
    },
    {
      subitemTitle: t('discounts'),
      subitemPath: ROOT.ORGS.ORG(shortEntityId).ITEMS.DISCOUNTS.path,
      isVisible: allowDiscountManagement,
    },
    {
      subitemTitle: t('reports'),
      subitemPath: ROOT.ORGS.ORG(shortEntityId).ITEMS.REPORTS.path,
      isVisible: allowItemManagement && flags.POSItemReports,
    },
    {
      subitemTitle: t('settings'),
      subitemPath: ROOT.ORGS.ORG(shortEntityId).ITEMS.SETTINGS.path,
      isVisible: allowItemManagement,
    },
  ]

  return (
    <ItemToggle
      badge={<ComingSoonBadge size="Small" text={tShared('updated')} />}
      icon={
        <SvgIcon>
          <ItemsIcon />
        </SvgIcon>
      }
      subItems={subItems}
      name="Items"
      className="nav-item-items"
    >
      {t('items')}
    </ItemToggle>
  )
}
