import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useTranslations } from '@npco/utils-translations'
import { TabItemProps } from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'

import { translations } from '../CategoryDrawer.i18n'

interface UseCategoryDrawerTabs {
  categoryId: string
  isLoading: boolean
  itemCount: number
}

const TabValues = {
  CATEGORY: 'category',
  ITEM: 'items',
}

export const useCategoryDrawerTabs = ({
  categoryId,
  isLoading,
  itemCount,
}: UseCategoryDrawerTabs) => {
  const t = useTranslations(translations)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const categoryRoute =
    ROOT.ORGS.ORG().ITEM_MANAGEMENT.CATEGORIES.CATEGORY(categoryId).relative

  const tabs: TabItemProps[] = useMemo(
    () => [
      {
        id: 'category-drawer-tabs-details',
        children: t('categoryTab'),
        value: TabValues.CATEGORY,
        onClick: () => navigate(categoryRoute),
      },
      {
        id: 'category-drawer-tabs-activity',
        children: isLoading
          ? t('itemsLoading')
          : t('itemsTab', { itemCount: `${itemCount}` }),
        value: TabValues.ITEM,
        onClick: () => navigate(`${categoryRoute}/items`),
      },
    ],
    [categoryRoute, isLoading, itemCount, navigate, t]
  )

  return {
    tabs,
    currentTab: (value: string) =>
      value ===
      (pathname.includes('items') ? TabValues.ITEM : TabValues.CATEGORY),
  }
}
