import { gql, type TypedDocumentNode } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '@npco/mp-gql-types'

const defaultOptions = {} as const
export type GetDebitCardAccountsForTransferQueryVariables = Types.Exact<{
  entityUuid: Types.Scalars['ID']['input']
}>

export type GetDebitCardAccountsForTransferQueryResponse = {
  __typename?: 'Query'
  getDebitCardAccountsV2: {
    __typename?: 'DebitCardAccountConnectionV2'
    accounts: Array<{
      __typename: 'DebitCardAccountV2'
      name: string
      id: string
      status: Types.DebitCardAccountStatus
      type: Types.DebitCardAccountType
      icon: {
        __typename?: 'Icon'
        colour: string | null
        image: string | null
        letter: string | null
        animation: string | null
        images: Array<{
          __typename?: 'Image'
          size: Types.ImageSize
          url: string
        }> | null
      } | null
      balance: { __typename?: 'Money'; currency: string; value: string } | null
      accountDetails: {
        __typename?: 'BankAccountDetails'
        account: string | null
      } | null
    } | null>
  }
  getSavingsAccounts: Array<{
    __typename: 'DebitCardAccountV2'
    name: string
    id: string
    status: Types.DebitCardAccountStatus
    type: Types.DebitCardAccountType
    icon: {
      __typename?: 'Icon'
      colour: string | null
      image: string | null
      letter: string | null
      animation: string | null
      images: Array<{
        __typename?: 'Image'
        size: Types.ImageSize
        url: string
      }> | null
    } | null
    balance: { __typename?: 'Money'; currency: string; value: string } | null
    accountDetails: {
      __typename?: 'BankAccountDetails'
      account: string | null
    } | null
  } | null>
  getEntity: {
    __typename?: 'Entity'
    id: string
    canTransferOut: boolean | null
  }
}

export const GetDebitCardAccountsForTransfer = gql`
  query GetDebitCardAccountsForTransfer($entityUuid: ID!) {
    getDebitCardAccountsV2(limit: 50, entityUuid: $entityUuid) {
      accounts {
        __typename
        name
        id
        icon {
          colour
          image
          images {
            size
            url
          }
          letter
          animation
        }
        balance {
          currency
          value
        }
        accountDetails {
          account
        }
        status
        type
      }
    }
    getSavingsAccounts {
      __typename
      name
      id
      icon {
        colour
        image
        images {
          size
          url
        }
        letter
        animation
      }
      balance {
        currency
        value
      }
      accountDetails {
        account
      }
      status
      type
    }
    getEntity {
      id
      canTransferOut
    }
  }
` as unknown as TypedDocumentNode<
  GetDebitCardAccountsForTransferQueryResponse,
  GetDebitCardAccountsForTransferQueryVariables
>

/**
 * __useGetDebitCardAccountsForTransferQuery__
 *
 * To run a query within a React component, call `useGetDebitCardAccountsForTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDebitCardAccountsForTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDebitCardAccountsForTransferQuery({
 *   variables: {
 *      entityUuid: // value for 'entityUuid'
 *   },
 * });
 */
export function useGetDebitCardAccountsForTransferQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDebitCardAccountsForTransferQueryResponse,
    GetDebitCardAccountsForTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetDebitCardAccountsForTransferQueryResponse,
    GetDebitCardAccountsForTransferQueryVariables
  >(GetDebitCardAccountsForTransfer, options)
}
export function useGetDebitCardAccountsForTransferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDebitCardAccountsForTransferQueryResponse,
    GetDebitCardAccountsForTransferQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetDebitCardAccountsForTransferQueryResponse,
    GetDebitCardAccountsForTransferQueryVariables
  >(GetDebitCardAccountsForTransfer, options)
}
export type GetDebitCardAccountsForTransferQueryHookResult = ReturnType<
  typeof useGetDebitCardAccountsForTransferQuery
>
export type GetDebitCardAccountsForTransferLazyQueryHookResult = ReturnType<
  typeof useGetDebitCardAccountsForTransferLazyQuery
>
export type GetDebitCardAccountsForTransferQueryResult = Apollo.QueryResult<
  GetDebitCardAccountsForTransferQueryResponse,
  GetDebitCardAccountsForTransferQueryVariables
>
