import { ApolloCache } from '@apollo/client'
import { ItemStatus } from '@npco/mp-gql-types'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'

import { useSubscription } from 'hooks/useSubscription'

import { CacheLocalStateEntityScopedVariable } from '../../../../../graphql/cache.types'
import { GetItemManagementLocalState } from '../../../graphql/getItemManagementLocalState'
import { CacheLocalStateItemManagement } from '../../../ItemManagement.types'
import { DEFAULT_GET_LIMIT } from '../../../ItemManagement.utils'
import {
  GetItemsTableCoreFieldsDoc,
  GetItemsTableCoreFieldsFragment,
} from './graphql/getItemsTableCoreFields.generated'
import {
  GetItemsTable,
  GetItemsTableQueryResponse,
} from './graphql/getItemsTableQuery.generated'
import {
  GetItemsTableSubscription,
  GetItemsTableSubscriptionResponse,
  GetItemsTableSubscriptionVariables,
} from './graphql/getItemsTableSubscription.generated'

export const updateItemsCache = ({
  cache,
  updateData,
  entityUuid,
}: {
  updateData: GetItemsTableQueryResponse['getItems']['items']
  cache: ApolloCache<any>
  entityUuid: string
}) => {
  if (!updateData?.length) {
    return
  }

  const localCache = cache.readQuery<
    CacheLocalStateItemManagement,
    CacheLocalStateEntityScopedVariable
  >({
    query: GetItemManagementLocalState,
    variables: { entityUuid },
  })

  const filter = localCache?.local.itemManagement.items.filterInput ?? null

  const cachedItemsResponse = cache.readQuery<GetItemsTableQueryResponse>({
    query: GetItemsTable,
    variables: {
      filter,
      limit: DEFAULT_GET_LIMIT,
    },
  })

  const cachedItems = cachedItemsResponse?.getItems?.items ?? []
  const nextToken = cachedItemsResponse?.getItems?.nextToken ?? null

  const itemsToAddInCache: GetItemsTableCoreFieldsFragment[] = []

  updateData
    // Do not include deleted item events
    .filter((item) => item.status !== ItemStatus.DELETED)
    .forEach((itemUpdate) => {
      const cachedItem = cache.readFragment<GetItemsTableCoreFieldsFragment>({
        id: `Item:${itemUpdate.id}`,
        fragment: GetItemsTableCoreFieldsDoc,
        fragmentName: 'GetItemsTableCoreFields',
      })

      // NOTE: a newly created or updated item will appear in the cache
      // immediately as response is stored before on subscription data
      if (cachedItem) {
        const itemExistsInQuery = cachedItems.some(
          (item) => item?.id === cachedItem?.id
        )

        // NOTE: only write to the list query if the item doesn't exist
        if (!itemExistsInQuery) {
          itemsToAddInCache.push(cachedItem)
        }
      }
    })

  if (itemsToAddInCache.length) {
    cache.writeQuery<GetItemsTableQueryResponse>({
      query: GetItemsTable,
      variables: {
        filter,
        limit: DEFAULT_GET_LIMIT,
      },
      data: {
        getItems: {
          // NOTE: Always add at the top of the table for now
          // https://npco-dev.atlassian.net/browse/ZD-15369 task to
          // implement filtering and sorting on items update
          items: [...itemsToAddInCache, ...cachedItems],
          nextToken,
        },
      },
    })
  }
}

export const useGetItemsTableSubscription = () => {
  const entityUuid = useSelectedEntityUuid()

  return useSubscription<
    GetItemsTableSubscriptionResponse,
    GetItemsTableSubscriptionVariables
  >(GetItemsTableSubscription, {
    onData: ({ client, data }) => {
      const updatedItems = data?.data?.onItemUpdate?.items

      if (!updatedItems || !entityUuid) {
        return
      }

      updateItemsCache({
        cache: client.cache,
        updateData: updatedItems,
        entityUuid,
      })
    },
    variables: { entityUuid },
    skip: !entityUuid,
  })
}
