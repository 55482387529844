import { useEffect, useMemo } from 'react'
import { OnDataOptions, useApolloClient } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { isNil } from 'lodash-es'
import { buffer, debounceTime, filter, Subject, Subscription } from 'rxjs'

import {
  GetUnreadNotificationCount,
  GetUnreadNotificationCountQueryResponse,
  useGetUnreadNotificationCountQuery,
} from './graphql/getUnreadNotificationCount.generated'
import {
  OnUnreadNotificationCountUpdateSubscriptionResponse,
  useOnUnreadNotificationCountUpdateSubscription,
} from './graphql/onUnreadNotificationCountUpdate.generated'

export type UseGetUnreadNotificationsCountProps = {
  customerUuid: string
  skip?: boolean
  subscriptionDebounceTime?: number
}

/**
 * A hook that returns the number of unread notifications for a given customer.
 * @param {Object} props - The props object.
 * @param {string} props.customerUuid - The UUID of the customer.
 * @param {boolean} [props.skip=false] - Whether to skip the query.
 * @param {number} [props.subscriptionDebounceTime=10000] - The duration to debounce the subscription updates.
 * @returns {Object} An object containing the count of unread notifications.
 */
export const useGetUnreadNotificationCount = ({
  customerUuid,
  skip = false,
  subscriptionDebounceTime = 10000,
}: UseGetUnreadNotificationsCountProps) => {
  const client = useApolloClient()
  const entityUuid = useSelectedEntityUuid()
  const subscriptionSubject$ = useMemo(() => new Subject<number>(), [])

  const { data } = useGetUnreadNotificationCountQuery({
    skip,
    variables: { entityUuid },
  })

  useEffect(() => {
    const subscription = new Subscription()

    // NOTE: to prevent multiple subscription events causing the notification
    // count to flicker, we buffer updating the query by the duration so it
    // only updates on the last known event. Furthermore, to see the latest
    // count ensure that you are optimistically updating the cache, this is
    // outside the remit of this hook.
    subscription.add(
      subscriptionSubject$
        .pipe(
          // NOTE: buffer subscriptions over debounce time and only continue if
          // we have values during this window
          buffer(
            subscriptionSubject$.pipe(debounceTime(subscriptionDebounceTime))
          ),
          filter((events) => events.length > 0)
        )
        .subscribe((counts: number[]) => {
          client.cache.updateQuery(
            { query: GetUnreadNotificationCount, variables: { entityUuid } },
            (response: GetUnreadNotificationCountQueryResponse | null) => ({
              ...response,
              getUnreadNotificationCount: counts[counts.length - 1],
            })
          )
        })
    )

    return () => subscription.unsubscribe()
  }, [client, subscriptionSubject$, subscriptionDebounceTime, entityUuid])

  useOnUnreadNotificationCountUpdateSubscription({
    // NOTE: prevent subscription events from automagically updating cache, we
    // want to buffer these updates through an rxjs subscription
    fetchPolicy: 'no-cache',
    variables: { customerUuid },
    skip,
    onData: ({
      data: response,
    }: OnDataOptions<OnUnreadNotificationCountUpdateSubscriptionResponse>) => {
      const count = response.data?.onUnreadNotificationCountUpdate?.count

      // NOTE: specifically test for null or undefined as we can recieve '0'
      // which is falsey
      if (isNil(count)) {
        return
      }

      subscriptionSubject$.next(count)
    },
  })

  return { count: data?.getUnreadNotificationCount ?? 0 }
}
