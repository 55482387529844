import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  BUTTON_SIZE,
  ButtonFill,
  ButtonGhost,
  COLOR,
  Flex,
  showErrorToast,
  showSuccessToast,
  Typography,
} from '@npco/zeller-design-system'
import { Form, Formik } from 'formik'

import { GetCatalogSettingsUpdateQueryResponse } from 'hooks/useGetCatalogSettings/graphql/getCatalogSettings.generated'
import { GetItemSettingsQueryResponse } from 'hooks/useGetItemSettings/graphql/getItemSettings.generated'
import { SettingsLeavingRoutePromptGuard } from 'components/RouteLeavingPromptGuard'
import { SpinnerWrapped } from 'components/Spinner'
import { ToggleFormField } from 'components/ToggleFormField'
import { translationsShared } from 'translations'

import { useItemsAnalytics } from '../../features/Items/hooks/useItemsAnalytics'
import { GstDisplayInfobox } from './components/GstDisplayInfobox'
import {
  ITEM_AUTO_GENERATE_SKU_FIELD,
  ITEM_TAX_APPLICABLE_FIELD,
  ITEM_TAX_INCLUSIVE_FIELD,
} from './ItemsSettingsForm.constants'
import { translations } from './ItemsSettingsForm.i18n'
import { ItemsSettingsFormFields } from './ItemsSettingsForm.types'

type ItemsSettingsFormProps = {
  settings?:
    | GetItemSettingsQueryResponse['getItemSettings']
    | GetCatalogSettingsUpdateQueryResponse['getCatalogSettings']
  updateSettings: (values: ItemsSettingsFormFields) => Promise<void>
  isUpdatingSettings: boolean
}

export const ItemsSettingsForm = ({
  settings,
  updateSettings,
  isUpdatingSettings,
}: ItemsSettingsFormProps) => {
  const { trackItemSettingsUpdated } = useItemsAnalytics()
  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)

  const flags = useFeatureFlags()

  const handleFormSubmit = async (values: ItemsSettingsFormFields) => {
    try {
      await updateSettings(values)
      trackItemSettingsUpdated({ fields: values })

      showSuccessToast(t('successNotification'))
    } catch (e) {
      showErrorToast(t('errorNotification'))
    }
  }

  const initialValues: ItemsSettingsFormFields = {
    isTaxApplicable: Boolean(settings?.itemsApplyTax),
    isTaxInclusive: Boolean(settings?.itemsTaxInclusive),
    shouldAutoGenerateSku:
      settings?.__typename === 'CatalogSettings'
        ? Boolean(settings?.autoSkuEnabled ?? true)
        : undefined,
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
      {({ handleSubmit, resetForm }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <SettingsLeavingRoutePromptGuard />

            {isUpdatingSettings ? (
              <SpinnerWrapped variant="top" />
            ) : (
              <Flex gap="40px" flexDirection="column">
                <Box>
                  <Typography component="h3" variant="heading-xl">
                    {t('heading')}
                  </Typography>
                </Box>
                <Box mt="-24px">
                  <Typography
                    component="p"
                    variant="body-base"
                    color={COLOR.GREY_550}
                  >
                    {t('description')}
                  </Typography>
                </Box>
                <Box>
                  <ToggleFormField
                    label={t('taxApplicableLabel')}
                    marginBottom="16px"
                    name={ITEM_TAX_APPLICABLE_FIELD}
                    value={false}
                  />
                </Box>
                <Box>
                  <ToggleFormField
                    label={t('taxPriceLabel')}
                    marginBottom="16px"
                    name={ITEM_TAX_INCLUSIVE_FIELD}
                    value={false}
                  />
                  <Box mt="24px">
                    <GstDisplayInfobox />
                  </Box>
                </Box>

                {flags.POSItems && (
                  <>
                    <Typography component="h3" variant="heading-xl">
                      {t('itemManagement')}
                    </Typography>
                    <Box>
                      <ToggleFormField
                        label={t('autoGenerateSkuLabel')}
                        marginBottom="16px"
                        name={ITEM_AUTO_GENERATE_SKU_FIELD}
                        value={false}
                      />
                      <Typography
                        component="p"
                        variant="body-base"
                        color={COLOR.GREY_550}
                      >
                        {t('autoGenerateSkuDescription')}
                      </Typography>
                    </Box>
                  </>
                )}

                <Flex
                  gap="24px"
                  flexDirection={{ _: 'column-reverse', SM: 'row' }}
                >
                  <Box width={{ _: '100%', SM: '156px' }}>
                    <ButtonFill
                      size={BUTTON_SIZE.MEDIUM}
                      type="submit"
                      fullWidth
                    >
                      {tShared('save')}
                    </ButtonFill>
                  </Box>
                  <Box width={{ _: '100%', SM: '110px' }}>
                    <ButtonGhost
                      size={BUTTON_SIZE.MEDIUM}
                      onClick={() => resetForm()}
                      fullWidth
                    >
                      {tShared('cancel')}
                    </ButtonGhost>
                  </Box>
                </Flex>
              </Flex>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}
