import { useCallback, useEffect } from 'react'
import {
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import { DrawerBasic, showErrorToast } from '@npco/zeller-design-system'

import { useGetItemSettings } from 'hooks/useGetItemSettings/useGetItemSettings'
import { ROOT, ROUTE_PARAM_NAME } from 'const/routes'
import { getInitialItemEditFormValues } from 'components/ItemBaseModalForm/ItemBaseModalForm.utils'
import { SpinnerWrapped } from 'components/Spinner'

import { useGetItemDrawerQuery } from './graphql/getItemDrawer.generated'
import { translations } from './ItemDrawer.i18n'
import { ItemDrawerDetails } from './ItemDrawerDetails/ItemDrawerDetails'
import { ItemEditModal } from './ItemDrawerDetails/ItemEditModal/ItemEditModal'

const {
  relative: itemPath,
  EDIT_DETAILS: { relative: itemEditDetailsPath },
} = ROOT.ORGS.ORG().ITEM_MANAGEMENT.ITEMS.ITEM()

export const ItemDrawer = () => {
  const t = useTranslations(translations)
  const navigate = useNavigate()
  const { itemRefNumber = '' } = useParams<ROUTE_PARAM_NAME>()
  const shortEntityId = useSelectedShortEntityUuid()

  const {
    data: itemData,
    loading: itemIsLoading,
    error,
  } = useGetItemDrawerQuery({
    variables: { referenceNumber: itemRefNumber },
    skip: !itemRefNumber,
  })

  const { isLoading: isItemSettingsLoading, settings } = useGetItemSettings()
  const isTaxInclusive = Boolean(settings?.itemsTaxInclusive)

  const handleOnClose = useCallback(() => {
    navigate(ROOT.ORGS.ORG(shortEntityId).ITEM_MANAGEMENT.ITEMS.path)
  }, [navigate, shortEntityId])

  useEffect(() => {
    if (error) {
      handleOnClose()
      showErrorToast(t('itemError', { itemRefNumber }))
    }
  }, [error, handleOnClose, itemRefNumber, t])

  const isOpen = Boolean(itemRefNumber || error)
  const item = itemData?.getItemV2

  const initialFormValues = getInitialItemEditFormValues(item, isTaxInclusive)

  return (
    <DrawerBasic
      isOpen={isOpen}
      title={item?.name || ' '}
      onClose={handleOnClose}
      overlayClassName="animated-drawer-overlay"
    >
      {(itemIsLoading || isItemSettingsLoading) && (
        <SpinnerWrapped variant="top" />
      )}
      {!itemIsLoading && !isItemSettingsLoading && item && (
        <Routes>
          <Route
            path={itemPath}
            element={
              <ItemDrawerDetails
                details={item}
                isTaxInclusive={isTaxInclusive}
              />
            }
          >
            <Route
              path={itemEditDetailsPath}
              element={
                <ItemEditModal
                  itemId={item.id}
                  itemReferenceNumber={item.referenceNumber}
                  initialValues={initialFormValues}
                />
              }
            />
          </Route>
        </Routes>
      )}
    </DrawerBasic>
  )
}
