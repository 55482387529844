import { gql } from '@apollo/client'

export const CreateContact = gql`
  mutation CreateContact(
    $contact: ContactInput!
    $linkedContactUuid: ID
    $entityUuid: ID!
  ) {
    createContact(
      contact: $contact
      linkedContactUuid: $linkedContactUuid
      entityUuid: $entityUuid
    ) {
      abn
      acn
      additionalEmails {
        email
        label {
          id
          isEditable
          labelText
          type
        }
      }
      additionalPhones {
        phone
        label {
          id
          isEditable
          labelText
          type
        }
      }
      address {
        country
        postcode
        state
        street
        suburb
      }
      businessName
      category
      contactType
      email {
        email
        label {
          id
          isEditable
          labelText
          type
        }
      }
      firstName
      id
      jobTitle
      lastName
      phone
      phoneV2 {
        phone
        label {
          id
          isEditable
          labelText
          type
        }
      }
      website
      location
      locationAccuracy
      subcategory
      subcategoryUuid
      isSelf
    }
  }
`

export const CreateContacts = gql`
  mutation CreateContacts($contact1: ContactInput!, $contact2: ContactInput!) {
    createContacts(contact1: $contact1, contact2: $contact2) {
      abn
      acn
      additionalEmails {
        email
        label {
          id
          isEditable
          labelText
          type
        }
      }
      additionalPhones {
        phone
        label {
          id
          isEditable
          labelText
          type
        }
      }
      address {
        country
        postcode
        state
        street
        suburb
      }
      businessName
      category
      contactType
      email {
        email
        label {
          id
          isEditable
          labelText
          type
        }
      }
      firstName
      id
      jobTitle
      lastName
      phone
      phoneV2 {
        phone
        label {
          id
          isEditable
          labelText
          type
        }
      }
      website
      location
      locationAccuracy
      subcategory
      subcategoryUuid
      isSelf
    }
  }
`

export const LinkContacts = gql`
  mutation LinkContacts($contact1Uuid: ID!, $contact2Uuid: ID!) {
    linkContacts(contact1Uuid: $contact1Uuid, contact2Uuid: $contact2Uuid)
  }
`

export const UnlinkContacts = gql`
  mutation UnlinkContacts($contact1Uuid: ID!, $contact2Uuid: ID!) {
    unlinkContacts(contact1Uuid: $contact1Uuid, contact2Uuid: $contact2Uuid)
  }
`

export const UpdateContact = gql`
  mutation UpdateContact($contact: ContactUpdateInput!, $id: ID!) {
    updateContact(contact: $contact, id: $id)
  }
`

export const DeleteContact = gql`
  mutation DeleteContact($id: ID!) {
    deleteContact(id: $id)
  }
`

export const RemoveContactImage = gql`
  mutation RemoveContactImage($contactUuid: ID!) {
    removeContactImage(contactUuid: $contactUuid)
  }
`

export const TagContact = gql`
  mutation TagContact($contactUuid: ID!, $tagNames: [String!]!) {
    tagContact(contactUuid: $contactUuid, tagNames: $tagNames)
  }
`

export const LinkSubcategoryToContact = gql`
  mutation LinkSubcategoryToContact(
    $contactUuid: ID!
    $subcategoryUuid: ID!
    $category: EntityCategories!
  ) {
    linkContactWithSubcategory(
      contactUuid: $contactUuid
      subcategoryUuid: $subcategoryUuid
      category: $category
    )
  }
`

export const UnlinkSubcategoryToContact = gql`
  mutation UnlinkSubcategoryToContact(
    $contactUuid: ID!
    $subcategoryUuid: ID!
  ) {
    unlinkContactWithSubcategory(
      contactUuid: $contactUuid
      subcategoryUuid: $subcategoryUuid
    )
  }
`
