import { useMemo } from 'react'
import { Navigate } from 'react-router-dom-v5-compat'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { MultiEntityStoreProvider } from '@npco/mp-utils-multi-entity-store'
import {
  useConvertUuidToShortUuid,
  useEntityIdParam,
} from '@npco/mp-utils-selected-entity'

import { DEFAULT_AFTER_LOGIN_ROUTE } from 'const/routes'
import {
  getEntityLocalStorageData,
  getEntityReactiveVariableData,
  getEntitySessionData,
  keyToReactiveVarMap,
  resetEntityReactiveVariables,
  resetEntitySessionData,
} from 'utils/multiEntityStore/multiEntityStore'
import { SpinnerWrapped } from 'components/Spinner'

import {
  MultiEntityContext,
  MultiEntityContextType,
} from './context/MultiEntityContext'
import { useCustomerEntityMappingData } from './hooks/useCustomerEntityMappingQuery/useCustomerEntityMappingData'

type MultiEntityProviderProps = { children: React.ReactNode }

const getIsDefaultEntityUuidDefined = (value: {
  defaultEntityUuid: string | undefined
}): value is MultiEntityContextType => {
  return !!value.defaultEntityUuid
}

export const MultiEntityProvider = ({ children }: MultiEntityProviderProps) => {
  const flags = useFeatureFlags()

  const { defaultEntityUuid, entityRelations, isLoading } =
    useCustomerEntityMappingData({
      skip: !flags.MultiEntity,
    })

  const translator = useConvertUuidToShortUuid()

  const { type, entityId } = useEntityIdParam()

  const isEntityNotAssociatedToUser =
    type === 'uuid' &&
    entityId !== defaultEntityUuid &&
    !entityRelations.some(
      (entityRelation) => entityRelation.entityUuid === entityId
    )

  const shouldFallbackToDefaultEntity =
    type === 'invalid uuid' || isEntityNotAssociatedToUser

  const multiEntityContextValue = useMemo(
    () => ({ defaultEntityUuid }),
    [defaultEntityUuid]
  )

  const initialEntitySessions = useMemo(() => {
    return entityRelations
      .filter(
        (entityRelation) =>
          entityRelation.isVisible || entityRelation.entityUuid === entityId
      )
      .map((entityRelation) => ({
        entityUuid: entityRelation.entityUuid,
        isActive: shouldFallbackToDefaultEntity
          ? entityRelation.entityUuid === defaultEntityUuid
          : entityRelation.entityUuid === entityId,
        order: entityRelation.order,
      }))
  }, [
    entityRelations,
    defaultEntityUuid,
    entityId,
    shouldFallbackToDefaultEntity,
  ])

  if (!flags.MultiEntity) {
    return <>{children}</>
  }

  if (isLoading) {
    return <SpinnerWrapped variant="centre" />
  }

  if (!getIsDefaultEntityUuidDefined(multiEntityContextValue)) {
    // No data is available, cannot provide default entity id
    return (
      <MultiEntityStoreProvider
        initialEntitySessions={[]}
        keyToReactiveVarMap={keyToReactiveVarMap}
        getEntityLocalStorageData={getEntityLocalStorageData}
        getEntityReactiveVariableData={getEntityReactiveVariableData}
        getEntitySessionData={getEntitySessionData}
        resetEntityReactiveVariables={resetEntityReactiveVariables}
        resetEntitySessionData={resetEntitySessionData}
        convertToShortUuid={translator}
      >
        {children}
      </MultiEntityStoreProvider>
    )
  }

  if (shouldFallbackToDefaultEntity) {
    return (
      <Navigate
        to={DEFAULT_AFTER_LOGIN_ROUTE(
          translator(multiEntityContextValue.defaultEntityUuid)
        )}
        replace
      />
    )
  }

  return (
    <MultiEntityContext.Provider value={multiEntityContextValue}>
      <MultiEntityStoreProvider
        initialEntitySessions={
          initialEntitySessions.length === 0
            ? [
                {
                  entityUuid: multiEntityContextValue.defaultEntityUuid,
                  isActive: true,
                  order: 0,
                },
              ]
            : initialEntitySessions
        }
        keyToReactiveVarMap={keyToReactiveVarMap}
        getEntityLocalStorageData={getEntityLocalStorageData}
        getEntityReactiveVariableData={getEntityReactiveVariableData}
        getEntitySessionData={getEntitySessionData}
        resetEntityReactiveVariables={resetEntityReactiveVariables}
        resetEntitySessionData={resetEntitySessionData}
        convertToShortUuid={translator}
      >
        {children}
      </MultiEntityStoreProvider>
    </MultiEntityContext.Provider>
  )
}
