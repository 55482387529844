import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { gql, useApolloClient } from '@apollo/client'
import { useMultiEntityStore } from '@npco/mp-utils-multi-entity-store'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import { TabItemType } from '@npco/zeller-design-system'

import { UseMultiEntityTabsCustomerEntityRelationFragment } from './useMultiEntityTabs.generated'

export const MANAGE_BUSINESS_PATH = '/orgs/manage-businesses'

type UseMultiEntityTabsProps = {
  customerEntityRelations: UseMultiEntityTabsCustomerEntityRelationFragment[]
}

export const useMultiEntityTabs = ({
  customerEntityRelations,
}: UseMultiEntityTabsProps) => {
  const { changeCurrentEntitySession, removeEntitySession } =
    useMultiEntityStore()
  const currentEntityUuid = useSelectedEntityUuid()
  const { cache } = useApolloClient()

  const entities = useMemo(
    () =>
      customerEntityRelations.map((entity) => ({
        id: entity.entity.id,
        title: entity.entity?.name ?? '',
      })),
    [customerEntityRelations]
  )

  const navigate = useNavigate()

  const setSelectedEntityId = (entityId: string | number) => {
    if (typeof entityId === 'string') {
      changeCurrentEntitySession(entityId)
    }
  }

  const reorderEntities = (updatedEntities: TabItemType[]) => {
    updatedEntities.forEach((entity, index) => {
      cache.modify({
        id: cache.identify({
          __typename: 'CustomerEntityRelation',
          entityUuid: entity.id,
        }),
        fields: {
          order: () => index,
        },
      })
    })
  }

  const closeEntitySession = (entityId: string | number) => {
    if (typeof entityId === 'string') {
      removeEntitySession(entityId)
    }
  }

  const goToManageBusinessesPage = useCallback(() => {
    changeCurrentEntitySession(null)
    navigate(MANAGE_BUSINESS_PATH)
  }, [navigate, changeCurrentEntitySession])

  return {
    entities,
    activeEntityId: currentEntityUuid,
    setSelectedEntityId,
    reorderEntities,
    closeEntitySession,
    goToManageBusinessesPage,
  }
}

useMultiEntityTabs.fragments = {
  CustomerEntityRelation: gql`
    fragment UseMultiEntityTabsCustomerEntityRelationFragment on CustomerEntityRelation {
      entityUuid
      isVisible
      order
      entity {
        id
        name
      }
    }
  `,
}
