import { gql } from '@apollo/client'

export const AccountLayoutQuery = gql`
  query AccountLayoutQuery($accountId: ID!, $entityUuid: ID!) {
    getDebitCardAccountV2(
      debitCardAccountUuid: $accountId
      entityUuid: $entityUuid
    ) {
      id
      balance {
        value
      }
      name
      status
      type
    }
    getEntity {
      __typename
      id
      canTransferOut
    }
  }
`
