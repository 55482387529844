import { useLocation } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'

import { ROOT } from 'const/routes'

import { translations } from './useItemsBreadcrumbs.i18n'

export const useItemsBreadcrumbs = () => {
  const location = useLocation()
  const shortEntityId = useSelectedShortEntityUuid()
  const t = useTranslations(translations)

  const itemManagementListPart = {
    name: t('items'),
    path: ROOT.ORGS.ORG(shortEntityId).ITEM_MANAGEMENT.path,
  }

  if (
    location.pathname.includes(
      ROOT.ORGS.ORG().ITEM_MANAGEMENT.SETTINGS.relative
    )
  ) {
    return [
      itemManagementListPart,
      {
        name: t('settings'),
        path: ROOT.ORGS.ORG(shortEntityId).ITEM_MANAGEMENT.SETTINGS.path,
      },
    ]
  }

  return [itemManagementListPart]
}
