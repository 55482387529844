import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import {
  LogoutOptions,
  RedirectLoginOptions,
  useAuth0,
} from '@auth0/auth0-react'
import { showApiErrorToast } from '@npco/zeller-design-system'
import { v4 as randomUUID } from 'uuid'

import {
  ANALYTICS_SEGMENT_ACCESS_KEY,
  ZELLER_SESSION_CLIENT_ID,
} from 'const/envs'
import { ROOT } from 'const/routes'
import { SESSION_STORAGE_KEYS } from 'services/sessionStorage/keys'
import {
  clearSessionStorage,
  setSessionStorageItem,
} from 'services/sessionStorage/utils'

import { useAuthContext } from './AuthContext'
import { ZellerAuthenticationContext } from './ZellerAuthenticationContext'

interface ZellerAuthenticationProviderProps {
  children: ReactNode
}

export const ZellerAuthenticationProvider = ({
  children,
}: ZellerAuthenticationProviderProps) => {
  const [isClientSetup, setIsClientSetup] = useState(false)
  const [isSubscriptionsSetup, setIsSubscriptionsSetup] = useState(false)

  const {
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0()

  const {
    idTokenClaims,
    redirectTo,
    scope,
    shouldRedirectAfterLogin,
    setShouldRedirectAfterLogin,
    token,
    updateIdTokenClaims,
    updateScope,
    updateToken,
  } = useAuthContext()

  const customLoginWithRedirect = useCallback(
    (options: RedirectLoginOptions) => {
      setSessionStorageItem('redirect', true)

      const { appState } = options
      const redirect = window.location.pathname + window.location.search
      const isPrivateRoute = redirect.startsWith(ROOT.ORGS.path)

      return loginWithRedirect({
        ...options,
        ...{ segmentWriteKey: ANALYTICS_SEGMENT_ACCESS_KEY },
        appState: {
          ...appState,
          ...(isPrivateRoute ? { returnTo: redirect } : {}),
        },
      })
    },
    [loginWithRedirect]
  )

  const setZellerSessionId = () => {
    const zellerSessionId = randomUUID()
    const zellerSessionClientId = ZELLER_SESSION_CLIENT_ID
    const hasZellerSessionClientId = Boolean(zellerSessionClientId)
    if (hasZellerSessionClientId) {
      setSessionStorageItem(
        SESSION_STORAGE_KEYS.ZELLER_SESSION_ID,
        zellerSessionId
      )
    }
    return {
      zellerSessionId,
      zellerSessionClientId,
      hasZellerSessionClientId,
    }
  }

  const customLogout = useCallback(
    (
      options: LogoutOptions = {
        returnTo: window.location.origin,
      }
    ) => {
      clearSessionStorage()
      logout(options)
    },
    [logout]
  )

  const getNewToken = useCallback(
    async (ignoreCache = false) => {
      try {
        const newToken = await getAccessTokenSilently({ scope, ignoreCache })
        updateToken(newToken)
      } catch (e) {
        const {
          hasZellerSessionClientId,
          zellerSessionId,
          zellerSessionClientId,
        } = setZellerSessionId()
        customLoginWithRedirect({
          scope,
          ...(hasZellerSessionClientId && {
            zellerSessionId,
            zellerSessionClientId,
          }),
        }).catch(() => {
          showApiErrorToast()
          customLogout()
        })
      }
    },
    [
      getAccessTokenSilently,
      updateToken,
      customLoginWithRedirect,
      customLogout,
      scope,
    ]
  )

  const stepUpAuth = useCallback(
    (appState: any = {}) => {
      setIsSubscriptionsSetup(false)

      customLoginWithRedirect({
        scope: 'access:sensitive offline_access',
        appState: {
          ...appState,
          scope: 'access:sensitive offline_access',
        },
      })
    },
    [customLoginWithRedirect, setIsSubscriptionsSetup]
  )

  const loginWithInactiveTimeout = useCallback(
    async (redirect: string) => {
      const {
        hasZellerSessionClientId,
        zellerSessionId,
        zellerSessionClientId,
      } = setZellerSessionId()

      loginWithRedirect({
        scope,
        loginWithInactiveTimeout: true,
        ...{ segmentWriteKey: ANALYTICS_SEGMENT_ACCESS_KEY },
        ...(hasZellerSessionClientId && {
          zellerSessionId,
          zellerSessionClientId,
        }),
        appState: redirect ? { returnTo: `${redirect}` } : {},
      }).catch(() => {
        showApiErrorToast()
        customLogout()
      })
    },
    [loginWithRedirect, scope, customLogout]
  )

  const fetchTokenClaims = useCallback(async () => {
    return getIdTokenClaims()
      .then(updateIdTokenClaims)
      .catch(() => undefined)
  }, [getIdTokenClaims, updateIdTokenClaims])

  useEffect(() => {
    if (!idTokenClaims) {
      fetchTokenClaims().catch(() => undefined)
    }
  }, [token, isAuthenticated, idTokenClaims, fetchTokenClaims])

  const value = useMemo(
    () => ({
      fetchTokenClaims,
      getNewToken,
      loginWithInactiveTimeout,
      idTokenClaims,
      isAuthenticated,
      isClientSetup,
      isSubscriptionsSetup,
      logout: customLogout,
      redirectTo,
      scope,
      setIsClientSetup,
      setIsSubscriptionsSetup,
      setShouldRedirectAfterLogin,
      shouldRedirectAfterLogin,
      stepUpAuth,
      token,
      updateScope,
      updateToken,
    }),
    [
      customLogout,
      fetchTokenClaims,
      getNewToken,
      loginWithInactiveTimeout,
      idTokenClaims,
      isAuthenticated,
      isClientSetup,
      isSubscriptionsSetup,
      redirectTo,
      scope,
      setIsClientSetup,
      setIsSubscriptionsSetup,
      setShouldRedirectAfterLogin,
      shouldRedirectAfterLogin,
      stepUpAuth,
      token,
      updateScope,
      updateToken,
    ]
  )
  return (
    <ZellerAuthenticationContext.Provider value={value}>
      {children}
    </ZellerAuthenticationContext.Provider>
  )
}
