import { useMemo } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'
import { gql } from '@apollo/client'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  COLOR,
  DragAndDropTabs,
  Flex,
  MenubarsIcon,
  PlusIcon,
  SvgIcon,
  TopBar,
} from '@npco/zeller-design-system'

import { ReactComponent as LogoIcon } from 'assets/svg/zeller-logo-icon.svg'
import { ROOT } from 'const/routes'

import { TopBarUserMenu } from './components/TopBarUserMenu/TopBarUserMenu'
import { useMultiEntityTabs } from './hooks/useMultiEntityTabs/useMultiEntityTabs'
import { useTopBarNavData } from './hooks/useTopBarNavData/useTopBarNavData'
import { topBarNavTranslations } from './TopBarNav.i18n'

export const TopBarNav = ({
  hasLeftSection = false,
}: {
  hasLeftSection?: boolean
}) => {
  const { entityRelations } = useTopBarNavData()

  const {
    entities,
    activeEntityId,
    setSelectedEntityId,
    reorderEntities,
    closeEntitySession,
    goToManageBusinessesPage,
  } = useMultiEntityTabs({
    customerEntityRelations: entityRelations,
  })

  const t = useTranslations(topBarNavTranslations)

  const { pathname } = useLocation()
  const isManageBusinessesPage = useMemo(() => {
    return pathname === ROOT.ORGS.MANAGE_BUSINESSES.path
  }, [pathname])

  return (
    <TopBar>
      {hasLeftSection && (
        <TopBar.LeftSection isFolded={false} hasBorderRight={false}>
          <Flex width="264px" height="40px" px="16px" alignItems="center">
            <Box p="8px" width="36px" height="36px">
              <MenubarsIcon color={COLOR.GREY_250} height="20" width="20" />
            </Box>
            <Flex flex="1" justifyContent="center">
              <SvgIcon width="57" height="20" ariaLabel={t('zellerLogo')}>
                <LogoIcon data-testid="logo" />
              </SvgIcon>
            </Flex>
          </Flex>
        </TopBar.LeftSection>
      )}
      <TopBar.CentreSection>
        <DragAndDropTabs
          tabs={entities}
          activeTabId={activeEntityId ?? ''}
          onTabsChange={reorderEntities}
          handleSelectTab={setSelectedEntityId}
          handleTabClose={closeEntitySession}
        />
      </TopBar.CentreSection>
      <TopBar.RightSection>
        <TopBar.TopBarButton
          component="button"
          activeClassName={TopBar.TopBarButton.activeClassName}
          onClick={goToManageBusinessesPage}
          aria-label={t('goToManageBusinessesPage')}
          isActive={isManageBusinessesPage}
        >
          <PlusIcon height="12" width="12" color={COLOR.BLACK_900} />
        </TopBar.TopBarButton>
        <TopBarUserMenu />
      </TopBar.RightSection>
    </TopBar>
  )
}

TopBarNav.fragments = {
  CustomerEntityRelation: gql`
    fragment TopBarNavCustomerEntityRelationFragment on CustomerEntityRelation {
      ...UseMultiEntityTabsCustomerEntityRelationFragment
    }

    ${useMultiEntityTabs.fragments.CustomerEntityRelation}
  `,
}
