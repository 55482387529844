import { useMemo, useState } from 'react'
import { NetworkStatus } from '@apollo/client'
import { useFeatureFlags } from '@npco/mp-utils-mp-feature-flags'
import { useTranslations } from '@npco/utils-translations'
import {
  Box,
  ButtonClear,
  ButtonLink,
  Flex,
  INPUT_SIZE,
  InputWithoutLabel,
  SearchIcon,
  Typography,
} from '@npco/zeller-design-system'

import { BusinessesList } from './BusinessesList/BusinessesList'
import { useManageBusinessesQuery } from './hooks/graphql/getCustomerEntityMapping.generated'
import { manageBusinessesTranslations } from './ManageBusinesses.i18n'

export const ManageBusinesses = () => {
  const t = useTranslations(manageBusinessesTranslations)

  const [searchValue, setSearchValue] = useState('')

  const { data, loading, error, refetch, networkStatus } =
    useManageBusinessesQuery({
      fetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    })

  const isLastVisibleEntity = useMemo(() => {
    if (loading || networkStatus === NetworkStatus.refetch || !data) {
      return false
    }

    const visibleEntitiesCount =
      data.getCustomerEntityMapping.entityRelations?.filter(
        (entity) => entity.isVisible
      ).length ?? 0

    return visibleEntitiesCount === 1
  }, [data, loading, networkStatus])

  const onChange = (event: React.BaseSyntheticEvent) => {
    setSearchValue(event.target.value)
  }
  const onClear = () => {
    setSearchValue('')
  }

  const searchedBusinesses =
    data?.getCustomerEntityMapping.entityRelations?.filter(
      (EntityMappingMock) => {
        return EntityMappingMock.entity.name
          ?.toLowerCase()
          .includes(searchValue.toLowerCase())
      }
    )

  const featureFlags = useFeatureFlags()

  return (
    <Flex width="100%" p="56px 0">
      <Box width="548px" mx="auto">
        <Flex width="512px" justifyContent="space-between">
          <Typography variant="heading-xl">{t('header')}</Typography>
          {(featureFlags.MultiEntityLinkExisting ||
            featureFlags.MultiEntityOnboarding) && (
            <ButtonLink>{t('addBusiness')}</ButtonLink>
          )}
        </Flex>
        <Box mt="24px" width="512px">
          <InputWithoutLabel
            aria-label={t('searchInput')}
            name={t('searchInput')}
            value={searchValue}
            onChange={onChange}
            icon={<SearchIcon />}
            size={INPUT_SIZE.MEDIUM}
            placeholder={t('placeholder')}
            renderRightControls={() =>
              searchValue && <ButtonClear onClick={onClear} />
            }
          />
        </Box>
        <Box mt="24px" width="548px">
          <BusinessesList
            isLoading={loading || networkStatus === NetworkStatus.refetch}
            entityRelations={searchedBusinesses}
            hasError={error}
            reload={refetch}
            isSingleVisibleTab={isLastVisibleEntity}
          />
        </Box>
      </Box>
    </Flex>
  )
}
