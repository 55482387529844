export type Maybe<T> = T | null
export type InputMaybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  AWSDate: { input: any; output: any }
  AWSDateTime: { input: any; output: any }
  AWSEmail: { input: any; output: any }
  AWSPhone: { input: any; output: any }
  AWSURL: { input: any; output: any }
}

export enum AccountConnectionStatus {
  LINKED = 'LINKED',
  UNLINKED = 'UNLINKED',
}

export type AccountDetails = {
  __typename?: 'AccountDetails'
  account?: Maybe<Scalars['String']['output']>
  bsb?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type AccountNotificationRules = {
  __typename?: 'AccountNotificationRules'
  accountName?: Maybe<Scalars['String']['output']>
  accountUuid?: Maybe<Scalars['String']['output']>
  balanceBelow?: Maybe<Scalars['Int']['output']>
  transactionInAbove?: Maybe<Scalars['Int']['output']>
  transactionOutAbove?: Maybe<Scalars['Int']['output']>
}

export type AccountNotificationRulesInput = {
  accountName?: InputMaybe<Scalars['String']['input']>
  accountUuid?: InputMaybe<Scalars['String']['input']>
  balanceBelow?: InputMaybe<Scalars['Int']['input']>
  transactionInAbove?: InputMaybe<Scalars['Int']['input']>
  transactionOutAbove?: InputMaybe<Scalars['Int']['input']>
}

export enum AccountStatementType {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
}

export type AccountsNotificationSettings = {
  __typename?: 'AccountsNotificationSettings'
  accountRules?: Maybe<Array<Maybe<AccountNotificationRules>>>
  firstTransferToNewAccount?: Maybe<NotificationSettings>
  largeAmountTransferredIn?: Maybe<NotificationSettings>
  largeAmountTransferredOut?: Maybe<NotificationSettings>
  lowAccountBalance?: Maybe<NotificationSettings>
}

export type AccountsNotificationSettingsInput = {
  accountRules?: InputMaybe<Array<InputMaybe<AccountNotificationRulesInput>>>
  firstTransferToNewAccount?: InputMaybe<NotificationSettingsInput>
  largeAmountTransferredIn?: InputMaybe<NotificationSettingsInput>
  largeAmountTransferredOut?: InputMaybe<NotificationSettingsInput>
  lowAccountBalance?: InputMaybe<NotificationSettingsInput>
}

export type ActivateDebitCardError = {
  __typename?: 'ActivateDebitCardError'
  error?: Maybe<ActivateDebitCardErrorEnum>
}

export enum ActivateDebitCardErrorEnum {
  ACCOUNT_REQUIRED = 'ACCOUNT_REQUIRED',
  ALREADY_ACTIVATED = 'ALREADY_ACTIVATED',
  DOES_NOT_OWN_CARD = 'DOES_NOT_OWN_CARD',
  USER_IS_NOT_VERIFIED = 'USER_IS_NOT_VERIFIED',
}

export type ActivateDebitCardInput = {
  debitCardAccountUuid?: InputMaybe<Scalars['ID']['input']>
  reference: Scalars['String']['input']
}

export type ActivateDebitCardResult =
  | ActivateDebitCardError
  | DebitCard
  | DebitCardV2

export enum ActiveConnection {
  CELLULAR = 'CELLULAR',
  ETHERNET = 'ETHERNET',
  WIFI = 'WIFI',
}

export type Address = {
  country?: InputMaybe<Scalars['String']['input']>
  postcode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  suburb?: InputMaybe<Scalars['String']['input']>
}

export type AffectedCatalogItemVariantsCount = {
  __typename?: 'AffectedCatalogItemVariantsCount'
  variantsToDeleteCount: Scalars['Int']['output']
  variantsToUpdateCount: Scalars['Int']['output']
}

export type AndroidCardProvisioningRequestResult = {
  __typename?: 'AndroidCardProvisioningRequestResult'
  OPC: Scalars['String']['output']
  TSP: Scalars['String']['output']
  additionalRequestData: Scalars['String']['output']
}

export type AppSyncDomain = {
  __typename?: 'AppSyncDomain'
  apiUrl?: Maybe<Scalars['String']['output']>
  region?: Maybe<Scalars['String']['output']>
  wssUrl?: Maybe<Scalars['String']['output']>
}

export type AttestationDetailsInput = {
  deviceId: Scalars['String']['input']
  instanceId: Scalars['String']['input']
  signature: Scalars['String']['input']
  token: Scalars['String']['input']
}

export enum AttestationErrorCategory {
  ACCOUNT_BLOCKED = 'ACCOUNT_BLOCKED',
  ACCOUNT_DEACTIVATED = 'ACCOUNT_DEACTIVATED',
  ACCOUNT_LINKING = 'ACCOUNT_LINKING',
  ACCOUNT_LINKING_REQUIRES_ICLOUD_SIGN_IN = 'ACCOUNT_LINKING_REQUIRES_ICLOUD_SIGN_IN',
  DEVELOPER_MODE = 'DEVELOPER_MODE',
  DEVICE_INCOMPATIBLE = 'DEVICE_INCOMPATIBLE',
  INTEGRITY_COMPROMISED = 'INTEGRITY_COMPROMISED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INTERNAL_ERROR_WITH_RETRY = 'INTERNAL_ERROR_WITH_RETRY',
  LOCATION_OUTSIDE_SERVICE_AREA = 'LOCATION_OUTSIDE_SERVICE_AREA',
  LOCATION_PERMISSIONS = 'LOCATION_PERMISSIONS',
  LOCATION_SERVICES = 'LOCATION_SERVICES',
  LOCATION_UNAVAILABLE = 'LOCATION_UNAVAILABLE',
  NETWORK_ERROR = 'NETWORK_ERROR',
  NFC_DISABLED = 'NFC_DISABLED',
  OS_VERSION_NOT_SUPPORTED = 'OS_VERSION_NOT_SUPPORTED',
  PASSCODE_DISABLED = 'PASSCODE_DISABLED',
  TIME_OUT_OF_SYNC = 'TIME_OUT_OF_SYNC',
}

export enum AttestationPlatform {
  MOBILE_ANDROID = 'MOBILE_ANDROID',
  MOBILE_IOS = 'MOBILE_IOS',
}

/**   Bank Account Details */
export type BankAccountDetails = {
  __typename?: 'BankAccountDetails'
  account?: Maybe<Scalars['String']['output']>
  bsb?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

/**   Bank Account Details Event Input */
export type BankAccountDetailsEventInput = {
  account?: InputMaybe<Scalars['String']['input']>
  bsb?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

/**   Bank Account Details Input */
export type BankAccountDetailsInput = {
  account: Scalars['String']['input']
  bsb: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type BankfeedEnabledBankAccountInput = {
  debitCardAccountUuid?: InputMaybe<Scalars['String']['input']>
}

export type BankingAccountStatement = {
  __typename?: 'BankingAccountStatement'
  downloadLink: Scalars['String']['output']
  endDate: Scalars['AWSDateTime']['output']
  expires: Scalars['AWSDateTime']['output']
  startDate: Scalars['AWSDateTime']['output']
}

export type BillFilterInput = {
  amount?: InputMaybe<IntFilterInput>
  siteUuid?: InputMaybe<StringFilterInput>
  timestamp?: InputMaybe<StringFilterInput>
}

export enum BillStatus {
  CHARGED = 'CHARGED',
  CHARGE_FAILED = 'CHARGE_FAILED',
  REFUNDED = 'REFUNDED',
  REFUND_FAILED = 'REFUND_FAILED',
}

export type BillingAccount = {
  __typename?: 'BillingAccount'
  accountBsb?: Maybe<Scalars['String']['output']>
  accountName?: Maybe<Scalars['String']['output']>
  accountNumber?: Maybe<Scalars['String']['output']>
  accountType: BillingAccountType
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
}

export enum BillingAccountType {
  NOMINATED = 'NOMINATED',
  PREPAID = 'PREPAID',
}

export type BoolFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>
  ne?: InputMaybe<Scalars['Boolean']['input']>
}

export type BpayBiller = {
  __typename?: 'BpayBiller'
  billerCode: Scalars['String']['output']
  billerName: Scalars['String']['output']
}

export type BpayBillerDetail = {
  __typename?: 'BpayBillerDetail'
  billerCode: Scalars['String']['output']
  billerName: Scalars['String']['output']
  crnLengths: Array<Scalars['Int']['output']>
  icrnIndicator: Scalars['Boolean']['output']
}

export type BpayBillerSearchResponse = {
  __typename?: 'BpayBillerSearchResponse'
  bpayBillers: Array<BpayBiller>
  nextPageToken?: Maybe<Scalars['String']['output']>
}

/**   Bpay Details */
export type BpayDetails = {
  __typename?: 'BpayDetails'
  billerCode: Scalars['String']['output']
  billerName: Scalars['String']['output']
  crn: Scalars['String']['output']
  nickname: Scalars['String']['output']
}

/**   Bpay Details Input */
export type BpayDetailsInput = {
  billerCode: Scalars['String']['input']
  billerName: Scalars['String']['input']
  crn: Scalars['String']['input']
  nickname: Scalars['String']['input']
}

export enum CardMedia {
  CNP = 'CNP',
  ICC = 'ICC',
  MANUAL = 'MANUAL',
  MSR = 'MSR',
  NFC = 'NFC',
  PICC = 'PICC',
}

export enum CardProvisioningDeviceType {
  MOBILE_PHONE = 'MOBILE_PHONE',
  TABLET = 'TABLET',
  WATCH = 'WATCH',
}

export enum CardProvisioningWalletName {
  ANDROID_PAY = 'ANDROID_PAY',
  APPLE_PAY = 'APPLE_PAY',
}

export enum CardScheme {
  AMEX = 'AMEX',
  CUP = 'CUP',
  DINERS = 'DINERS',
  EFTPOS = 'EFTPOS',
  JCB = 'JCB',
  MC = 'MC',
  OTHER = 'OTHER',
  VISA = 'VISA',
}

export type CardsNotificationSettings = {
  __typename?: 'CardsNotificationSettings'
  debitCardTransaction?: Maybe<NotificationSettings>
  pinChanged?: Maybe<NotificationSettings>
  purchaseTransactions?: Maybe<NotificationSettings>
}

export type CardsNotificationSettingsInput = {
  debitCardTransaction?: InputMaybe<NotificationSettingsInput>
  purchaseTransactions?: InputMaybe<NotificationSettingsInput>
}

export type CartDetails = {
  __typename?: 'CartDetails'
  baseAmount: Scalars['Int']['output']
  id: Scalars['ID']['output']
  lineItems: Array<Maybe<CartItem>>
}

export type CartItem = {
  __typename?: 'CartItem'
  extendedListPrice?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  listPrice?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  productId: Scalars['ID']['output']
  quantity: Scalars['Int']['output']
  sku: Scalars['String']['output']
  variantId?: Maybe<Scalars['ID']['output']>
}

export type CashFlowAllCategoriesTotal = CashFlowSummaryTotal & {
  __typename?: 'CashFlowAllCategoriesTotal'
  accountUuid?: Maybe<Scalars['String']['output']>
  categoriesTotal?: Maybe<Array<CashFlowCategoryTotal>>
  change?: Maybe<Scalars['Float']['output']>
  previousTotalSum?: Maybe<Money>
  range: CashFlowReportRange
  reportType: CashFlowReportType
  timeZone: Scalars['String']['output']
  totalSum: Money
}

export type CashFlowCategoriesNetAmounts = CashFlowNetAmounts & {
  __typename?: 'CashFlowCategoriesNetAmounts'
  accountUuid?: Maybe<Scalars['String']['output']>
  categoryNetAmounts?: Maybe<Array<CashFlowCategoryNetAmount>>
  limit?: Maybe<Scalars['Int']['output']>
  range: CashFlowReportRange
  reportType: CashFlowReportType
  sortBy?: Maybe<CashFlowNetAmountSortBy>
  sortOrder?: Maybe<SortOrder>
  timeZone: Scalars['String']['output']
}

export type CashFlowCategoryNetAmount = CashFlowNetAmount & {
  __typename?: 'CashFlowCategoryNetAmount'
  average: Money
  category?: Maybe<EntityCategories>
  /**   In percentage */
  change?: Maybe<Scalars['Float']['output']>
  noOfTransaction: Scalars['Int']['output']
  subcategoryCount?: Maybe<Scalars['Int']['output']>
  total: Money
}

export type CashFlowCategoryTotal = {
  __typename?: 'CashFlowCategoryTotal'
  category?: Maybe<EntityCategories>
  /**   total of the top ten */
  total: Money
}

export type CashFlowContactNetAmount = CashFlowNetAmount & {
  __typename?: 'CashFlowContactNetAmount'
  average: Money
  /**   In percentage */
  change?: Maybe<Scalars['Float']['output']>
  contact?: Maybe<Contact>
  contactUuid?: Maybe<Scalars['ID']['output']>
  noOfTransaction: Scalars['Int']['output']
  total: Money
}

export type CashFlowContactTotal = {
  __typename?: 'CashFlowContactTotal'
  contact?: Maybe<Contact>
  contactUuid?: Maybe<Scalars['ID']['output']>
  /**   total of the top ten */
  total: Money
}

export type CashFlowContactsNetAmounts = CashFlowNetAmounts & {
  __typename?: 'CashFlowContactsNetAmounts'
  accountUuid?: Maybe<Scalars['String']['output']>
  contactNetAmounts?: Maybe<Array<CashFlowContactNetAmount>>
  limit?: Maybe<Scalars['Int']['output']>
  range: CashFlowReportRange
  reportType: CashFlowReportType
  sortBy?: Maybe<CashFlowNetAmountSortBy>
  sortOrder?: Maybe<SortOrder>
  timeZone: Scalars['String']['output']
}

export type CashFlowNetAmount = {
  average: Money
  /**   In percentage */
  change?: Maybe<Scalars['Float']['output']>
  noOfTransaction: Scalars['Int']['output']
  total: Money
}

export enum CashFlowNetAmountSortBy {
  AVERAGE = 'AVERAGE',
  AZ = 'AZ',
  NO_OF_TRANSACTION = 'NO_OF_TRANSACTION',
  TOTAL = 'TOTAL',
}

export type CashFlowNetAmounts = {
  accountUuid?: Maybe<Scalars['String']['output']>
  limit?: Maybe<Scalars['Int']['output']>
  range: CashFlowReportRange
  reportType: CashFlowReportType
  sortBy?: Maybe<CashFlowNetAmountSortBy>
  sortOrder?: Maybe<SortOrder>
  timeZone: Scalars['String']['output']
}

export type CashFlowReportRange = {
  __typename?: 'CashFlowReportRange'
  /**   Always the last day of the corresponding month */
  end: Scalars['AWSDate']['output']
  /**   Always the first day of the corresponding month */
  start: Scalars['AWSDate']['output']
}

export enum CashFlowReportType {
  MONTHLY = 'MONTHLY',
  TTM = 'TTM',
}

export type CashFlowSubcategoriesNetAmounts = CashFlowNetAmounts & {
  __typename?: 'CashFlowSubcategoriesNetAmounts'
  accountUuid?: Maybe<Scalars['String']['output']>
  limit?: Maybe<Scalars['Int']['output']>
  range: CashFlowReportRange
  reportType: CashFlowReportType
  sortBy?: Maybe<CashFlowNetAmountSortBy>
  sortOrder?: Maybe<SortOrder>
  subcategoryNetAmounts?: Maybe<Array<CashFlowSubcategoryNetAmount>>
  timeZone: Scalars['String']['output']
}

export type CashFlowSubcategoryNetAmount = CashFlowNetAmount & {
  __typename?: 'CashFlowSubcategoryNetAmount'
  average: Money
  category?: Maybe<EntityCategories>
  /**   In percentage */
  change?: Maybe<Scalars['Float']['output']>
  noOfTransaction: Scalars['Int']['output']
  subcategory?: Maybe<Scalars['String']['output']>
  subcategoryDetails?: Maybe<Subcategory>
  total: Money
}

export type CashFlowSummaryTotal = {
  accountUuid?: Maybe<Scalars['String']['output']>
  change?: Maybe<Scalars['Float']['output']>
  previousTotalSum?: Maybe<Money>
  range: CashFlowReportRange
  reportType: CashFlowReportType
  timeZone: Scalars['String']['output']
  totalSum: Money
}

export type CashFlowTopTenContactTotal = CashFlowSummaryTotal & {
  __typename?: 'CashFlowTopTenContactTotal'
  accountUuid?: Maybe<Scalars['String']['output']>
  change?: Maybe<Scalars['Float']['output']>
  contactTopTenTotals?: Maybe<Array<CashFlowContactTotal>>
  previousTotalSum?: Maybe<Money>
  range: CashFlowReportRange
  reportType: CashFlowReportType
  timeZone: Scalars['String']['output']
  totalSum: Money
}

export type CashFlowTotalAmountsAmounts = {
  __typename?: 'CashFlowTotalAmountsAmounts'
  accountUuid?: Maybe<Scalars['String']['output']>
  cashFlowPeriodicTotalAmounts?: Maybe<Array<cashFlowPeriodicTotalAmount>>
  range: CashFlowReportRange
  timeZone: Scalars['String']['output']
  transactionDirectionTotalAmounts: TransactionDirectionTotalAmounts
}

export type CatalogAttributeSet = {
  __typename?: 'CatalogAttributeSet'
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  status: CatalogItemStatus
  values?: Maybe<Array<Maybe<CatalogAttributeValue>>>
}

export type CatalogAttributeSetConnection = {
  __typename?: 'CatalogAttributeSetConnection'
  attributeSets?: Maybe<Array<CatalogAttributeSet>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type CatalogAttributeValue = {
  __typename?: 'CatalogAttributeValue'
  attributeSetUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  ordinal: Scalars['Int']['output']
  value: Scalars['String']['output']
}

export type CatalogAttributeValueInput = {
  attributeSetUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  ordinal: Scalars['Int']['input']
  value: Scalars['String']['input']
}

export type CatalogCategory = {
  __typename?: 'CatalogCategory'
  color: Scalars['String']['output']
  createdTime?: Maybe<Scalars['Int']['output']>
  description?: Maybe<Scalars['String']['output']>
  displayLabel?: Maybe<Scalars['Boolean']['output']>
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  images?: Maybe<Array<CatalogImage>>
  itemsNumber: Scalars['Int']['output']
  label?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  status: CatalogCategoryStatus
  updatedTime?: Maybe<Scalars['Int']['output']>
}

export type CatalogCategoryBatchUpdate = {
  __typename?: 'CatalogCategoryBatchUpdate'
  catalogCategories?: Maybe<Array<CatalogCategory>>
  entityUuid: Scalars['ID']['output']
}

export type CatalogCategoryBatchUpdateInput = {
  catalogCategories?: InputMaybe<Array<CatalogCategoryInput>>
  entityUuid: Scalars['ID']['input']
}

export type CatalogCategoryConnection = {
  __typename?: 'CatalogCategoryConnection'
  categories?: Maybe<Array<CatalogCategory>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type CatalogCategoryFilterInput = {
  idFilter?: InputMaybe<CatalogCategoryIdFilterInput>
  name?: InputMaybe<Scalars['String']['input']>
  textSearchFilter?: InputMaybe<Scalars['String']['input']>
}

export type CatalogCategoryIdFilterInput = {
  categoryUuids?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CatalogCategoryInput = {
  color: Scalars['String']['input']
  createdTime?: InputMaybe<Scalars['Int']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  displayLabel?: InputMaybe<Scalars['Boolean']['input']>
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  images?: InputMaybe<Array<CatalogImageInput>>
  itemsNumber?: InputMaybe<Scalars['Int']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  status: CatalogCategoryStatus
  updatedTime?: InputMaybe<Scalars['Int']['input']>
}

export enum CatalogCategorySortColumnName {
  ItemsNumber = 'ItemsNumber',
  Name = 'Name',
}

export type CatalogCategorySortInput = {
  ascending?: InputMaybe<Scalars['Boolean']['input']>
  columnName?: InputMaybe<CatalogCategorySortColumnName>
}

export enum CatalogCategoryStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type CatalogDiscount = {
  __typename?: 'CatalogDiscount'
  config: CatalogDiscountConfig
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  referenceNumber: Scalars['ID']['output']
  siteSettings?: Maybe<Array<CatalogDiscountSiteSettings>>
  siteUuidList?: Maybe<Array<Scalars['ID']['output']>>
  sitesNumber: Scalars['Int']['output']
  status: CatalogItemStatus
  type: CatalogDiscountType
  value: Scalars['String']['output']
}

export type CatalogDiscountsiteSettingsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  siteUuid?: InputMaybe<Scalars['ID']['input']>
}

export enum CatalogDiscountConfig {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export type CatalogDiscountConnection = {
  __typename?: 'CatalogDiscountConnection'
  discounts?: Maybe<Array<CatalogDiscount>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type CatalogDiscountFilterInput = {
  siteUuid?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum CatalogDiscountSiteAssignment {
  ALL_SITES = 'ALL_SITES',
  NO_SITES = 'NO_SITES',
  SELECTED_SITES = 'SELECTED_SITES',
}

export type CatalogDiscountSiteSettings = {
  __typename?: 'CatalogDiscountSiteSettings'
  siteName: Scalars['String']['output']
  siteType?: Maybe<SiteType>
  siteUuid: Scalars['ID']['output']
}

/**   Catalog Discounts */
export enum CatalogDiscountType {
  AUTOMATIC = 'AUTOMATIC',
  BASIC = 'BASIC',
}

export type CatalogFileUploadUrl = {
  __typename?: 'CatalogFileUploadUrl'
  expire: Scalars['AWSDateTime']['output']
  fileName: Scalars['String']['output']
  uploadUrl: Scalars['String']['output']
}

export type CatalogImage = {
  __typename?: 'CatalogImage'
  id: Scalars['ID']['output']
  sizes: Array<CatalogImageVariant>
}

export type CatalogImageInput = {
  id: Scalars['ID']['input']
  sizes: Array<CatalogImageVariantInput>
}

export type CatalogImagePresignedDetails = {
  __typename?: 'CatalogImagePresignedDetails'
  jsonFields: Scalars['String']['output']
  url: Scalars['String']['output']
}

export enum CatalogImageSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  NATIVE = 'NATIVE',
  SMALL = 'SMALL',
  THUMBNAIL = 'THUMBNAIL',
}

export enum CatalogImageType {
  CATEGORY = 'CATEGORY',
  DISCOUNT = 'DISCOUNT',
  ITEM = 'ITEM',
}

export type CatalogImageUploadDetails = {
  __typename?: 'CatalogImageUploadDetails'
  expireDate: Scalars['AWSDateTime']['output']
  imageUuid: Scalars['ID']['output']
  presignedDetails: CatalogImagePresignedDetails
}

export type CatalogImageVariant = {
  __typename?: 'CatalogImageVariant'
  size: CatalogImageSize
  url: Scalars['AWSURL']['output']
}

export type CatalogImageVariantInput = {
  size: CatalogImageSize
  url: Scalars['AWSURL']['input']
}

export type CatalogImportFileColumnMapping = {
  attribute1?: InputMaybe<Scalars['String']['input']>
  attribute2?: InputMaybe<Scalars['String']['input']>
  attribute3?: InputMaybe<Scalars['String']['input']>
  attributeSet1?: InputMaybe<Scalars['String']['input']>
  attributeSet2?: InputMaybe<Scalars['String']['input']>
  attributeSet3?: InputMaybe<Scalars['String']['input']>
  available?: InputMaybe<Scalars['String']['input']>
  category: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  enableForInvoice?: InputMaybe<Scalars['String']['input']>
  gst: Scalars['String']['input']
  gtin?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  price: Scalars['String']['input']
  referenceNumber?: InputMaybe<Scalars['String']['input']>
  sku?: InputMaybe<Scalars['String']['input']>
}

export type CatalogImportFileMetadata = {
  columnMapping?: InputMaybe<CatalogImportFileColumnMapping>
  siteColumnMapping?: InputMaybe<Array<InputMaybe<SiteColumnMapping>>>
}

export type CatalogItem = {
  __typename?: 'CatalogItem'
  attributeSets?: Maybe<Array<CatalogItemAttributeSet>>
  attributeSetsEnabled: Scalars['Boolean']['output']
  available: Scalars['Boolean']['output']
  categories: Array<CatalogCategory>
  createdTime?: Maybe<Scalars['Int']['output']>
  description?: Maybe<Scalars['String']['output']>
  displayLabel?: Maybe<Scalars['Boolean']['output']>
  entityUuid: Scalars['ID']['output']
  gtin?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  images?: Maybe<Array<CatalogImage>>
  invoicesEnabled: Scalars['Boolean']['output']
  label?: Maybe<Scalars['String']['output']>
  /**   additional batched resolver */
  modifierSets?: Maybe<Array<CatalogItemModifierSet>>
  modifiersEnabled: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  parentName?: Maybe<Scalars['String']['output']>
  price: CatalogMoney
  referenceNumber: Scalars['String']['output']
  reportingCategoryColor: Scalars['String']['output']
  reportingCategoryUuid: Scalars['ID']['output']
  siteAssignmentDetails?: Maybe<CatalogSiteAssignmentDetails>
  /**   additional batched resolver */
  siteSettings?: Maybe<Array<CatalogItemSiteSettings>>
  siteUuidList?: Maybe<Array<Scalars['ID']['output']>>
  /**   for backwards compatible, make it mandatory once bff-cims-api to prod */
  sitesNumber?: Maybe<Scalars['Int']['output']>
  sku?: Maybe<Scalars['String']['output']>
  status: CatalogItemStatus
  taxes?: Maybe<Array<CatalogTax>>
  type: CatalogItemType
  updatedTime?: Maybe<Scalars['Int']['output']>
  variants?: Maybe<Array<CatalogItemVariant>>
}

export type CatalogItemmodifierSetsArgs = {
  siteUuid?: InputMaybe<Scalars['ID']['input']>
}

export type CatalogItemsiteSettingsArgs = {
  siteUuid?: InputMaybe<Scalars['ID']['input']>
}

export type CatalogItemAttributeSet = {
  __typename?: 'CatalogItemAttributeSet'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  ordinal: Scalars['Int']['output']
  status: CatalogItemStatus
  values: Array<CatalogItemAttributeValue>
}

export type CatalogItemAttributeSetInput = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
  values: Array<CatalogItemAttributeValueInput>
}

export type CatalogItemAttributeValue = {
  __typename?: 'CatalogItemAttributeValue'
  id: Scalars['ID']['output']
  ordinal: Scalars['Int']['output']
  selected: Scalars['Boolean']['output']
  value: Scalars['String']['output']
}

export type CatalogItemAttributeValueInput = {
  id: Scalars['ID']['input']
  ordinal: Scalars['Int']['input']
  selected: Scalars['Boolean']['input']
  value: Scalars['String']['input']
}

export type CatalogItemBatchUpdate = {
  __typename?: 'CatalogItemBatchUpdate'
  catalogItems?: Maybe<Array<CatalogItem>>
  entityUuid: Scalars['ID']['output']
  siteUuidList?: Maybe<Array<Scalars['ID']['output']>>
}

export type CatalogItemBatchUpdateInput = {
  catalogItems?: InputMaybe<Array<CatalogItemInput>>
  entityUuid: Scalars['ID']['input']
  siteUuidList?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CatalogItemConnection = {
  __typename?: 'CatalogItemConnection'
  items?: Maybe<Array<CatalogItem>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export enum CatalogItemExportFormat {
  CSV = 'CSV',
  XLSX = 'XLSX',
}

export type CatalogItemFilterInput = {
  attributeValueUuidsFilter?: InputMaybe<Array<Scalars['ID']['input']>>
  categoryUuidFilter?: InputMaybe<CatalogCategoryIdFilterInput>
  filterAvailable?: InputMaybe<Scalars['Boolean']['input']>
  invoicesEnabled?: InputMaybe<Scalars['Boolean']['input']>
  itemTypesFilter?: InputMaybe<Array<CatalogItemType>>
  name?: InputMaybe<Scalars['String']['input']>
  priceFilter?: InputMaybe<CatalogPriceFilterInput>
  reportingCategoryUuidFilter?: InputMaybe<Scalars['ID']['input']>
  siteUuidFilter?: InputMaybe<Array<Scalars['ID']['input']>>
  textSearchFilter?: InputMaybe<Scalars['String']['input']>
}

export type CatalogItemInput = {
  attributeSets?: InputMaybe<Array<CatalogItemAttributeSetInput>>
  attributeSetsEnabled?: InputMaybe<Scalars['Boolean']['input']>
  available: Scalars['Boolean']['input']
  categories: Array<CatalogCategoryInput>
  createdTime?: InputMaybe<Scalars['Int']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  displayLabel?: InputMaybe<Scalars['Boolean']['input']>
  entityUuid: Scalars['ID']['input']
  gtin?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  images?: InputMaybe<Array<CatalogImageInput>>
  invoicesEnabled: Scalars['Boolean']['input']
  label?: InputMaybe<Scalars['String']['input']>
  modifiersEnabled?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  price: CatalogMoneyInput
  referenceNumber: Scalars['String']['input']
  reportingCategoryColor: Scalars['String']['input']
  reportingCategoryUuid: Scalars['ID']['input']
  siteUuidList?: InputMaybe<Array<Scalars['ID']['input']>>
  sitesNumber: Scalars['Int']['input']
  sku?: InputMaybe<Scalars['String']['input']>
  status: CatalogItemStatus
  taxes?: InputMaybe<Array<CatalogTaxInput>>
  type: CatalogItemType
  updatedTime?: InputMaybe<Scalars['Int']['input']>
  variants?: InputMaybe<Array<CatalogItemVariantInput>>
}

export type CatalogItemModifier = {
  __typename?: 'CatalogItemModifier'
  id: Scalars['ID']['output']
  images?: Maybe<Array<CatalogImage>>
  name: Scalars['String']['output']
  ordinal: Scalars['Int']['output']
  price: CatalogMoney
  selected: Scalars['Boolean']['output']
}

export type CatalogItemModifierSet = {
  __typename?: 'CatalogItemModifierSet'
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  invoicesEnabled: Scalars['Boolean']['output']
  modifiers: Array<CatalogItemModifier>
  name: Scalars['String']['output']
  ordinal: Scalars['Int']['output']
  selectionRequired: Scalars['Boolean']['output']
  siteUuidList?: Maybe<Array<Scalars['ID']['output']>>
}

export type CatalogItemSiteSettings = {
  __typename?: 'CatalogItemSiteSettings'
  hidden?: Maybe<Scalars['Boolean']['output']>
  inStock?: Maybe<Scalars['Boolean']['output']>
  price?: Maybe<CatalogMoney>
  siteName: Scalars['String']['output']
  siteType?: Maybe<SiteType>
  siteUuid: Scalars['ID']['output']
}

export enum CatalogItemSortColumnName {
  Name = 'Name',
  Price = 'Price',
}

export type CatalogItemSortInput = {
  ascending?: InputMaybe<Scalars['Boolean']['input']>
  columnName?: InputMaybe<CatalogItemSortColumnName>
}

export enum CatalogItemStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export enum CatalogItemType {
  MODIFIER = 'MODIFIER',
  PARENT = 'PARENT',
  SINGLE = 'SINGLE',
  VARIANT = 'VARIANT',
}

export type CatalogItemVariant = {
  __typename?: 'CatalogItemVariant'
  attributeCompositeUuid: Scalars['String']['output']
  attributeValueUuids: Array<Scalars['ID']['output']>
  available: Scalars['Boolean']['output']
  createdTime?: Maybe<Scalars['Int']['output']>
  description?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['ID']['output']
  gtin?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  images?: Maybe<Array<CatalogImage>>
  label?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  ordinal: Scalars['Int']['output']
  parentUuid: Scalars['ID']['output']
  price: CatalogMoney
  referenceNumber: Scalars['String']['output']
  reportingCategoryColor: Scalars['String']['output']
  sku?: Maybe<Scalars['String']['output']>
  type: CatalogItemType
  updatedTime?: Maybe<Scalars['Int']['output']>
}

export type CatalogItemVariantInput = {
  attributeCompositeUuid: Scalars['String']['input']
  attributeValueUuids: Array<Scalars['ID']['input']>
  available?: InputMaybe<Scalars['Boolean']['input']>
  createdTime?: InputMaybe<Scalars['Int']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  entityUuid: Scalars['ID']['input']
  gtin?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  images?: InputMaybe<Array<CatalogImageInput>>
  label?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
  parentUuid: Scalars['ID']['input']
  price: CatalogMoneyInput
  referenceNumber: Scalars['String']['input']
  sku?: InputMaybe<Scalars['String']['input']>
  type: CatalogItemType
  updatedTime?: InputMaybe<Scalars['Int']['input']>
}

export type CatalogItemsExportDownload = {
  __typename?: 'CatalogItemsExportDownload'
  downloadLink: Scalars['String']['output']
  expire: Scalars['AWSDateTime']['output']
}

export type CatalogModifier = {
  __typename?: 'CatalogModifier'
  id: Scalars['ID']['output']
  images?: Maybe<Array<CatalogImage>>
  name: Scalars['String']['output']
  ordinal: Scalars['Int']['output']
  price: CatalogMoney
  status?: Maybe<ItemStatus>
}

export type CatalogModifierInput = {
  id: Scalars['ID']['input']
  images?: InputMaybe<Array<CatalogImageInput>>
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
  price: CatalogMoneyInput
  status: CatalogItemStatus
}

export type CatalogModifierSet = {
  __typename?: 'CatalogModifierSet'
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  invoicesEnabled: Scalars['Boolean']['output']
  modifiers: Array<CatalogModifier>
  name: Scalars['String']['output']
  ordinal: Scalars['Int']['output']
  selectionRequired: Scalars['Boolean']['output']
  siteSettings?: Maybe<Array<CatalogModifierSiteSettings>>
  siteUuidList?: Maybe<Array<Scalars['ID']['output']>>
  sitesNumber: Scalars['Int']['output']
  status: CatalogItemStatus
}

export type CatalogModifierSetsiteSettingsArgs = {
  siteUuid?: InputMaybe<Scalars['ID']['input']>
}

export type CatalogModifierSetConnection = {
  __typename?: 'CatalogModifierSetConnection'
  modifierSets?: Maybe<Array<CatalogModifierSet>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type CatalogModifierSetFilterInput = {
  siteUuidFilter?: InputMaybe<Scalars['ID']['input']>
}

export type CatalogModifierSiteSettings = {
  __typename?: 'CatalogModifierSiteSettings'
  siteName: Scalars['String']['output']
  siteType?: Maybe<SiteType>
  siteUuid: Scalars['ID']['output']
}

export type CatalogMoney = {
  __typename?: 'CatalogMoney'
  currency: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type CatalogMoneyInput = {
  currency: Scalars['String']['input']
  value: Scalars['String']['input']
}

export enum CatalogPriceFilterColumnName {
  price = 'price',
}

export type CatalogPriceFilterInput = {
  columnName?: InputMaybe<CatalogPriceFilterColumnName>
  from?: InputMaybe<Scalars['Int']['input']>
  to?: InputMaybe<Scalars['Int']['input']>
}

export type CatalogSettings = {
  __typename?: 'CatalogSettings'
  autoSkuEnabled?: Maybe<Scalars['Boolean']['output']>
  entityUuid: Scalars['ID']['output']
  itemsApplyTax: Scalars['Boolean']['output']
  itemsTaxInclusive: Scalars['Boolean']['output']
}

export type CatalogSettingsUpdateInput = {
  autoSkuEnabled?: InputMaybe<Scalars['Boolean']['input']>
  itemsApplyTax?: InputMaybe<Scalars['Boolean']['input']>
  itemsTaxInclusive?: InputMaybe<Scalars['Boolean']['input']>
}

export enum CatalogSiteAssignment {
  ALL_SITES = 'ALL_SITES',
  INVOICE_ONLY = 'INVOICE_ONLY',
  NO_SITES = 'NO_SITES',
  SELECTED_SITES = 'SELECTED_SITES',
}

export type CatalogSiteAssignmentDetails = {
  __typename?: 'CatalogSiteAssignmentDetails'
  assignedTo: CatalogSiteAssignment
  siteCount: Scalars['Int']['output']
}

export type CatalogTax = {
  __typename?: 'CatalogTax'
  enabled: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  percent?: Maybe<Scalars['Int']['output']>
}

export type CatalogTaxInput = {
  enabled: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  percent?: InputMaybe<Scalars['Int']['input']>
}

export enum CatalogUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
  QUANTITY = 'QUANTITY',
}

export enum Category {
  ACCOUNTING = 'ACCOUNTING',
  ACUPUNCTURE = 'ACUPUNCTURE',
  ADULTSERVICES = 'ADULTSERVICES',
  AIRLINEANDAIRCARRIER = 'AIRLINEANDAIRCARRIER',
  ALCOHOLWHOLESALER = 'ALCOHOLWHOLESALER',
  ANESTHETIST = 'ANESTHETIST',
  ARCHITECTURE = 'ARCHITECTURE',
  ARTPHOTOFILM = 'ARTPHOTOFILM',
  AUTOMOTIVE = 'AUTOMOTIVE',
  BAKERY = 'BAKERY',
  BARBERSHOP = 'BARBERSHOP',
  BARCLUB = 'BARCLUB',
  BEAUTYSALON = 'BEAUTYSALON',
  BOATRENTALS = 'BOATRENTALS',
  BOOKSMUSICVIDEO = 'BOOKSMUSICVIDEO',
  BRICKLAYING = 'BRICKLAYING',
  BUS = 'BUS',
  BUSINESSSERVICES = 'BUSINESSSERVICES',
  CAREGIVER = 'CAREGIVER',
  CARPENTRY = 'CARPENTRY',
  CARPETCLEANING = 'CARPETCLEANING',
  CARRENTAL = 'CARRENTAL',
  CARTRUCKSERVICES = 'CARTRUCKSERVICES',
  CATERING = 'CATERING',
  CHARITY = 'CHARITY',
  CHILDCARE = 'CHILDCARE',
  CHIROPRACTOR = 'CHIROPRACTOR',
  CINEMA = 'CINEMA',
  CLEANING = 'CLEANING',
  CLOTHING = 'CLOTHING',
  CLOTHINGALTERATIONS = 'CLOTHINGALTERATIONS',
  COFFEE = 'COFFEE',
  COMPUTERAPPLICANCES = 'COMPUTERAPPLICANCES',
  CONCRETING = 'CONCRETING',
  CONSTRUCTION = 'CONSTRUCTION',
  CONSTRUCTIONMATERIALS = 'CONSTRUCTIONMATERIALS',
  CONSULTING = 'CONSULTING',
  COUNSELLOR = 'COUNSELLOR',
  COURIERSERVICES = 'COURIERSERVICES',
  CRYPTOCURRENCY = 'CRYPTOCURRENCY',
  DATINGSERVICES = 'DATINGSERVICES',
  DEALERS = 'DEALERS',
  DELIVERY = 'DELIVERY',
  DENTIST = 'DENTIST',
  DESIGN = 'DESIGN',
  DIETITIAN = 'DIETITIAN',
  DOCTOR = 'DOCTOR',
  DRYCLEANING = 'DRYCLEANING',
  ELECTRICAL = 'ELECTRICAL',
  ELECTRONICS = 'ELECTRONICS',
  EMPLOYMENTAGENCIES = 'EMPLOYMENTAGENCIES',
  EVENTS = 'EVENTS',
  EYEWEAR = 'EYEWEAR',
  FESTIVALS = 'FESTIVALS',
  FLOORING = 'FLOORING',
  FLOWERSGIFTS = 'FLOWERSGIFTS',
  FOODTRUCKCART = 'FOODTRUCKCART',
  FOREIGNEXCHANGESEVICES = 'FOREIGNEXCHANGESEVICES',
  FURNITURE = 'FURNITURE',
  GAMBLINGESTABLISHMENT = 'GAMBLINGESTABLISHMENT',
  GENERALCONTRACTING = 'GENERALCONTRACTING',
  GLAZING = 'GLAZING',
  GROCERY = 'GROCERY',
  GYM = 'GYM',
  HAIRSALON = 'HAIRSALON',
  HEALTHBEAUTYSPA = 'HEALTHBEAUTYSPA',
  HEATINGANDAC = 'HEATINGANDAC',
  HOBBYSHOP = 'HOBBYSHOP',
  HOMEGOODS = 'HOMEGOODS',
  HYPNOTHERAPIST = 'HYPNOTHERAPIST',
  INSTALLATIONSERVICES = 'INSTALLATIONSERVICES',
  INSURANCEPROVIDERS = 'INSURANCEPROVIDERS',
  INTERIORDESIGN = 'INTERIORDESIGN',
  JEWELLERYWATCHES = 'JEWELLERYWATCHES',
  LANDSCAPING = 'LANDSCAPING',
  LEGALSERVICES = 'LEGALSERVICES',
  LIBRARY = 'LIBRARY',
  LOCALCOUNCIL = 'LOCALCOUNCIL',
  LOCKSMITH = 'LOCKSMITH',
  LODGING = 'LODGING',
  MARKET = 'MARKET',
  MARKETING = 'MARKETING',
  MASSAGEPARLOUR = 'MASSAGEPARLOUR',
  MASSAGETHERAPIST = 'MASSAGETHERAPIST',
  MEDICALTPRACTITIONER = 'MEDICALTPRACTITIONER',
  MEMBERSHIPORG = 'MEMBERSHIPORG',
  MIDWIFE = 'MIDWIFE',
  MOTIVATIONALSERVICES = 'MOTIVATIONALSERVICES',
  MOVERS = 'MOVERS',
  MUSEUM = 'MUSEUM',
  MUSIC = 'MUSIC',
  NAILSALON = 'NAILSALON',
  NURSE = 'NURSE',
  OCCUPATIONALTHERAPIST = 'OCCUPATIONALTHERAPIST',
  OFFICESUPPLY = 'OFFICESUPPLY',
  ONLINETOBACCOVAPERETAILERS = 'ONLINETOBACCOVAPERETAILERS',
  OPTOMETRIST = 'OPTOMETRIST',
  OTHER = 'OTHER',
  PAINTING = 'PAINTING',
  PARKSRECREATION = 'PARKSRECREATION',
  PAYMENTPROCESSORS = 'PAYMENTPROCESSORS',
  PERFORMINGARTS = 'PERFORMINGARTS',
  PERSONALTRAINER = 'PERSONALTRAINER',
  PESTCONTROL = 'PESTCONTROL',
  PETSHOP = 'PETSHOP',
  PHARMACIST = 'PHARMACIST',
  PHOTOGRAPHY = 'PHOTOGRAPHY',
  PHYSICALTHERAPIST = 'PHYSICALTHERAPIST',
  PHYSIOTHERAPIST = 'PHYSIOTHERAPIST',
  PLASTERINGCEILING = 'PLASTERINGCEILING',
  PLUMBING = 'PLUMBING',
  PODIATRIST = 'PODIATRIST',
  POLITICALORG = 'POLITICALORG',
  PRIMARYSCHOOL = 'PRIMARYSCHOOL',
  PRINTINGSERVICES = 'PRINTINGSERVICES',
  PRIVATECARHIRE = 'PRIVATECARHIRE',
  PRIVATECHEF = 'PRIVATECHEF',
  PSYCHIATRIST = 'PSYCHIATRIST',
  REALESTATE = 'REALESTATE',
  RELIGIOUSORG = 'RELIGIOUSORG',
  REMOVALIST = 'REMOVALIST',
  RUBBISHREMOVAL = 'RUBBISHREMOVAL',
  SCHOOL = 'SCHOOL',
  SOFTWAREDEVELOPMENT = 'SOFTWAREDEVELOPMENT',
  SPECIALITYSHOP = 'SPECIALITYSHOP',
  SPORTINGEVENTS = 'SPORTINGEVENTS',
  SPORTINGGOODS = 'SPORTINGGOODS',
  SPORTSRECREATION = 'SPORTSRECREATION',
  TABLESERVICERESTAURANT = 'TABLESERVICERESTAURANT',
  TAKEAWAYRESTAURANT = 'TAKEAWAYRESTAURANT',
  TATTOOPIERCING = 'TATTOOPIERCING',
  TAXI = 'TAXI',
  TEACHER = 'TEACHER',
  TILINGCARPETING = 'TILINGCARPETING',
  TRAVELAGENCY = 'TRAVELAGENCY',
  TUTOR = 'TUTOR',
  UNIVERSITY = 'UNIVERSITY',
  VETERINARY = 'VETERINARY',
  WEAPONSAMMUNITIONS = 'WEAPONSAMMUNITIONS',
  WHOLESALEVENDOR = 'WHOLESALEVENDOR',
}

export enum CategoryGroup {
  BEAUTY = 'BEAUTY',
  CHARITIES = 'CHARITIES',
  EDUCATION = 'EDUCATION',
  FINANCIALSERVICES = 'FINANCIALSERVICES',
  FOODDRINK = 'FOODDRINK',
  GOVERNMENTSERVICES = 'GOVERNMENTSERVICES',
  HEALTHCAREFITNESS = 'HEALTHCAREFITNESS',
  HOMEMAINTENANCE = 'HOMEMAINTENANCE',
  LEISUREENTERTAINMENT = 'LEISUREENTERTAINMENT',
  PROFESSIONALSERVICES = 'PROFESSIONALSERVICES',
  RETAIL = 'RETAIL',
  TRANSPORTATION = 'TRANSPORTATION',
  TRAVEL = 'TRAVEL',
}

export enum Channel {
  DASHBOARD = 'DASHBOARD',
  MOBILE_ANDROID = 'MOBILE_ANDROID',
  MOBILE_IOS = 'MOBILE_IOS',
  PAY_MYZELLER = 'PAY_MYZELLER',
  TERMINAL = 'TERMINAL',
  ZELLER_ADMIN = 'ZELLER_ADMIN',
}

export type CheckForAbusiveDescriptionResult = {
  __typename?: 'CheckForAbusiveDescriptionResult'
  isAbusive: Scalars['Boolean']['output']
}

export type CommonError = {
  __typename?: 'CommonError'
  type?: Maybe<CommonErrorType>
}

export enum CommonErrorType {
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  CUSTOMER_EMAIL_ALREADY_EXISTS = 'CUSTOMER_EMAIL_ALREADY_EXISTS',
  CUSTOMER_IDENTITY_ALREADY_EXISTS = 'CUSTOMER_IDENTITY_ALREADY_EXISTS',
  CUSTOMER_IDENTITY_ERROR = 'CUSTOMER_IDENTITY_ERROR',
  FORBIDDEN = 'FORBIDDEN',
  INVALID_REQUEST = 'INVALID_REQUEST',
  MFA_REQUIRED = 'MFA_REQUIRED',
  MFA_SENSITIVE_ACCESS_EXPIRED = 'MFA_SENSITIVE_ACCESS_EXPIRED',
  NOT_FOUND = 'NOT_FOUND',
  RESOURCE_ALREADY_EXISTS = 'RESOURCE_ALREADY_EXISTS',
  SERVER_ERROR = 'SERVER_ERROR',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum CompanyProfileData {
  MANUALLY_CREATED = 'MANUALLY_CREATED',
  MODIFIED = 'MODIFIED',
  PRISTINE = 'PRISTINE',
}

export type CompleteEmailChangeInput = {
  code: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type CompleteEmailChangeResult = {
  __typename?: 'CompleteEmailChangeResult'
  message?: Maybe<Scalars['String']['output']>
  status: CompleteEmailChangeStatus
}

export enum CompleteEmailChangeStatus {
  CODE_EXPIRED_OR_INVALID = 'CODE_EXPIRED_OR_INVALID',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INVALID_EMAIL = 'INVALID_EMAIL',
}

export type CompleteOptOutReceiptsResult = {
  __typename?: 'CompleteOptOutReceiptsResult'
  message?: Maybe<Scalars['String']['output']>
  status: CompleteOptOutReceiptsStatus
}

export enum CompleteOptOutReceiptsStatus {
  CODE_EXPIRED_OR_INVALID = 'CODE_EXPIRED_OR_INVALID',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export type ConfirmEntityDetailsInitialInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  acn?: InputMaybe<Scalars['String']['input']>
  manualEntry?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  type: EntityType
}

export type Connection = {
  __typename?: 'Connection'
  connectionError?: Maybe<Scalars['String']['output']>
  errorType?: Maybe<ConnectionErrorType>
  id?: Maybe<Scalars['String']['output']>
  status?: Maybe<ConnectionStatus>
}

export enum ConnectionErrorType {
  DATA_SYNC_ERROR = 'DATA_SYNC_ERROR',
  NO_ERROR = 'NO_ERROR',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export enum ConnectionStatus {
  CONNECTED = 'CONNECTED',
  CONNECTED_WITH_ERROR = 'CONNECTED_WITH_ERROR',
  NOT_CONNECTED = 'NOT_CONNECTED',
  PARTIALLY_CONNECTED = 'PARTIALLY_CONNECTED',
}

export enum ConnectionType {
  HL_POS = 'HL_POS',
  ORACLE_POS = 'ORACLE_POS',
  XERO_BANKFEEDS = 'XERO_BANKFEEDS',
  XERO_PAYMENT_SERVICES = 'XERO_PAYMENT_SERVICES',
}

export type Contact = {
  __typename?: 'Contact'
  abn?: Maybe<Scalars['String']['output']>
  acn?: Maybe<Scalars['String']['output']>
  /**   getContactEmails resolver */
  additionalEmails?: Maybe<Array<ContactEmail>>
  /**   getContactPhones resolver */
  additionalPhones?: Maybe<Array<ContactPhone>>
  address?: Maybe<ContactAddress>
  businessName?: Maybe<Scalars['String']['output']>
  category?: Maybe<EntityCategories>
  contactType: ContactType
  /**   getLinkedContacts resolver */
  contacts?: Maybe<Array<Maybe<Contact>>>
  email?: Maybe<ContactEmail>
  entityUuid?: Maybe<Scalars['ID']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  hours?: Maybe<Scalars['String']['output']>
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  isSelf?: Maybe<Scalars['Boolean']['output']>
  jobTitle?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  locationAccuracy?: Maybe<Scalars['Int']['output']>
  notes?: Maybe<Scalars['String']['output']>
  /**   getPaymentInstruments resolver */
  paymentInstruments?: Maybe<Array<PaymentInstrument>>
  phone?: Maybe<Scalars['String']['output']>
  phoneV2?: Maybe<ContactPhone>
  subcategory?: Maybe<Scalars['String']['output']>
  subcategoryUuid?: Maybe<Scalars['String']['output']>
  /**   getContactTags resolver */
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  website?: Maybe<Scalars['String']['output']>
}

export type ContactAddress = {
  __typename?: 'ContactAddress'
  country?: Maybe<Scalars['String']['output']>
  postcode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  suburb?: Maybe<Scalars['String']['output']>
}

export type ContactAddressInput = {
  country?: InputMaybe<Scalars['String']['input']>
  postcode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  suburb?: InputMaybe<Scalars['String']['input']>
}

export type ContactBasicInfo = {
  __typename?: 'ContactBasicInfo'
  businessName?: Maybe<Scalars['String']['output']>
  contactType: ContactType
  firstName?: Maybe<Scalars['String']['output']>
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  isSelf?: Maybe<Scalars['Boolean']['output']>
  lastName?: Maybe<Scalars['String']['output']>
}

export type ContactBasicInfoInput = {
  businessName?: InputMaybe<Scalars['String']['input']>
  contactType: ContactType
  firstName?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<IconEventInput>
  id: Scalars['ID']['input']
  isSelf?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
}

export type ContactConnection = {
  __typename?: 'ContactConnection'
  contacts: Array<Maybe<Contact>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type ContactEmail = {
  __typename?: 'ContactEmail'
  email: Scalars['String']['output']
  label?: Maybe<Label>
}

export type ContactEmailInput = {
  email: Scalars['String']['input']
  labelUuid?: InputMaybe<Scalars['ID']['input']>
}

export type ContactFilterInput = {
  /**
   *   If the query is person, name matches firstName, lastName, phone, email,
   *  if the query is business, name matches businessName
   */
  name?: InputMaybe<StringFilterInput>
  /**   list of tags to filter by, results are grouped by tags */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ContactInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  acn?: InputMaybe<Scalars['String']['input']>
  /**   All contact emails must be unique */
  additionalEmails?: InputMaybe<Array<ContactEmailInput>>
  additionalPhones?: InputMaybe<Array<ContactPhoneInput>>
  address?: InputMaybe<ContactAddressInput>
  businessName?: InputMaybe<Scalars['String']['input']>
  contactType: ContactType
  /**   primary email and additionalEmails must be unique */
  email?: InputMaybe<ContactEmailInput>
  firstName?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<IconInput>
  isSelf?: InputMaybe<Scalars['Boolean']['input']>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  /**   Location is a string of format lat,long */
  location?: InputMaybe<Scalars['String']['input']>
  locationAccuracy?: InputMaybe<Scalars['Int']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  phoneV2?: InputMaybe<ContactPhoneInput>
  website?: InputMaybe<Scalars['String']['input']>
}

export enum ContactMethod {
  EMAIL = 'EMAIL',
  LINK = 'LINK',
  SMS = 'SMS',
}

export type ContactPhone = {
  __typename?: 'ContactPhone'
  label?: Maybe<Label>
  phone: Scalars['String']['output']
}

export type ContactPhoneInput = {
  labelUuid?: InputMaybe<Scalars['ID']['input']>
  phone: Scalars['String']['input']
}

/**   Contact Create Response to bypass Resolver connections */
export type ContactResponse = {
  __typename?: 'ContactResponse'
  abn?: Maybe<Scalars['String']['output']>
  acn?: Maybe<Scalars['String']['output']>
  additionalEmails?: Maybe<Array<ContactEmail>>
  additionalPhones?: Maybe<Array<ContactPhone>>
  address?: Maybe<ContactAddress>
  businessName?: Maybe<Scalars['String']['output']>
  category?: Maybe<EntityCategories>
  contactType: ContactType
  email?: Maybe<ContactEmail>
  firstName?: Maybe<Scalars['String']['output']>
  icon: Icon
  id: Scalars['ID']['output']
  isSelf?: Maybe<Scalars['Boolean']['output']>
  jobTitle?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  locationAccuracy?: Maybe<Scalars['Int']['output']>
  notes?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  phoneV2?: Maybe<ContactPhone>
  subcategory?: Maybe<Scalars['String']['output']>
  subcategoryUuid?: Maybe<Scalars['String']['output']>
  website?: Maybe<Scalars['String']['output']>
}

export type ContactTransactionTotals = {
  __typename?: 'ContactTransactionTotals'
  count: Scalars['Int']['output']
  latestTimestamp: Scalars['AWSDateTime']['output']
  totalAmount: Scalars['Int']['output']
}

export enum ContactType {
  BUSINESS = 'BUSINESS',
  PERSON = 'PERSON',
}

export type ContactUpdateInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  acn?: InputMaybe<Scalars['String']['input']>
  additionalEmails?: InputMaybe<Array<ContactEmailInput>>
  additionalPhones?: InputMaybe<Array<ContactPhoneInput>>
  address?: InputMaybe<ContactAddressInput>
  businessName?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<ContactEmailInput>
  firstName?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<IconInput>
  isSelf?: InputMaybe<Scalars['Boolean']['input']>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  /**   Location is a string of format lat,long */
  location?: InputMaybe<Scalars['String']['input']>
  locationAccuracy?: InputMaybe<Scalars['Int']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  phoneV2?: InputMaybe<ContactPhoneInput>
  website?: InputMaybe<Scalars['String']['input']>
}

export type CpocReverseTransactionInput = {
  altitude?: InputMaybe<Scalars['String']['input']>
  amount: Scalars['Int']['input']
  bearing?: InputMaybe<Scalars['String']['input']>
  caid: Scalars['String']['input']
  deviceUuid: Scalars['ID']['input']
  humidity?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  isRepeat?: InputMaybe<Scalars['Boolean']['input']>
  light?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  locationAccuracy?: InputMaybe<Scalars['Int']['input']>
  locationTimestamp?: InputMaybe<Scalars['AWSDateTime']['input']>
  originalTransactionUuid: Scalars['ID']['input']
  pressure?: InputMaybe<Scalars['String']['input']>
  reason: TransactionCancelReason
  siteUuid: Scalars['ID']['input']
  source: Source
  speed?: InputMaybe<Scalars['String']['input']>
  speedAccuracy?: InputMaybe<Scalars['String']['input']>
  stan?: InputMaybe<Scalars['String']['input']>
  temperatureAmbient?: InputMaybe<Scalars['String']['input']>
  temperatureDevice?: InputMaybe<Scalars['String']['input']>
  timestamp: Scalars['AWSDateTime']['input']
  timestampUtc: Scalars['AWSDateTime']['input']
  timezone?: InputMaybe<Scalars['String']['input']>
}

export type CpocReverseTransactionResponse = {
  __typename?: 'CpocReverseTransactionResponse'
  approvalCode?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  responseCode: Scalars['String']['output']
  rrn?: Maybe<Scalars['String']['output']>
  timestampUtc: Scalars['AWSDateTime']['output']
}

export type CpocReverseTransactionV2Input = {
  deviceUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  isRetry?: InputMaybe<Scalars['Boolean']['input']>
  reason: TransactionCancelReason
  siteUuid: Scalars['ID']['input']
  timestamp: Scalars['AWSDateTime']['input']
  timestampUtc: Scalars['AWSDateTime']['input']
  timezone?: InputMaybe<Scalars['String']['input']>
  transactionDetails: CpocTransactionInput
}

export type CpocTransactionInput = {
  adjustAmount?: InputMaybe<Scalars['Int']['input']>
  altitude?: InputMaybe<Scalars['String']['input']>
  amount: Scalars['Int']['input']
  attestationDetails?: InputMaybe<AttestationDetailsInput>
  bearing?: InputMaybe<Scalars['String']['input']>
  bin?: InputMaybe<Scalars['String']['input']>
  caid: Scalars['String']['input']
  cardMedia?: InputMaybe<CardMedia>
  cashAmount?: InputMaybe<Scalars['Int']['input']>
  commsFallback?: InputMaybe<Scalars['Boolean']['input']>
  deviceUuid: Scalars['ID']['input']
  externalReference?: InputMaybe<Scalars['String']['input']>
  humidity?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  isoMessageType?: InputMaybe<Scalars['String']['input']>
  isoPosConditionCode?: InputMaybe<Scalars['String']['input']>
  isoPosEntryMode: Scalars['String']['input']
  isoProcessingCode?: InputMaybe<Scalars['String']['input']>
  ksn: Scalars['String']['input']
  lcr?: InputMaybe<Scalars['Boolean']['input']>
  light?: InputMaybe<Scalars['String']['input']>
  lineItems?: InputMaybe<Array<Scalars['Int']['input']>>
  location?: InputMaybe<Scalars['String']['input']>
  locationAccuracy?: InputMaybe<Scalars['Int']['input']>
  locationTimestamp?: InputMaybe<Scalars['AWSDateTime']['input']>
  message: Scalars['String']['input']
  messageLength: Scalars['Int']['input']
  originalTransactionUuid?: InputMaybe<Scalars['ID']['input']>
  panMasked?: InputMaybe<Scalars['String']['input']>
  pinBypassed?: InputMaybe<Scalars['Boolean']['input']>
  posName?: InputMaybe<Scalars['String']['input']>
  pressure?: InputMaybe<Scalars['String']['input']>
  scheme?: InputMaybe<CardScheme>
  source: Source
  speed?: InputMaybe<Scalars['String']['input']>
  speedAccuracy?: InputMaybe<Scalars['String']['input']>
  stan?: InputMaybe<Scalars['String']['input']>
  surchargeAmount?: InputMaybe<Scalars['Int']['input']>
  taxAmounts?: InputMaybe<Array<TaxAmountInput>>
  temperatureAmbient?: InputMaybe<Scalars['String']['input']>
  temperatureDevice?: InputMaybe<Scalars['String']['input']>
  timestamp: Scalars['AWSDateTime']['input']
  timestampUtc: Scalars['AWSDateTime']['input']
  timezone?: InputMaybe<Scalars['String']['input']>
  tipAmount?: InputMaybe<Scalars['Int']['input']>
  type: TransactionType
}

export type CpocTransactionResponse = {
  __typename?: 'CpocTransactionResponse'
  approvalCode?: Maybe<Scalars['String']['output']>
  cardMedia?: Maybe<CardMedia>
  cardholderEmail?: Maybe<Scalars['String']['output']>
  cardholderMobile?: Maybe<Scalars['String']['output']>
  cardholderUuid?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  panMasked?: Maybe<Scalars['String']['output']>
  /**   Expected on Android but no Tweed */
  response?: Maybe<Scalars['String']['output']>
  responseCode: Scalars['String']['output']
  responseLength?: Maybe<Scalars['Int']['output']>
  rrn?: Maybe<Scalars['String']['output']>
  scheme?: Maybe<CardScheme>
  timestampUtc: Scalars['AWSDateTime']['output']
}

export type CreateAndSendPayByLinkInput = {
  amount: Scalars['Int']['input']
  contact?: InputMaybe<PayByContactInput>
  contactUuid: Scalars['ID']['input']
  siteUuid: Scalars['ID']['input']
  transactionNotes?: InputMaybe<Scalars['String']['input']>
}

export type CreateCatalogAttributeSetInput = {
  name: Scalars['String']['input']
  values?: InputMaybe<Array<InputMaybe<CreateCatalogAttributeValueInput>>>
}

export type CreateCatalogAttributeValueInput = {
  ordinal: Scalars['Int']['input']
  value: Scalars['String']['input']
}

export type CreateCatalogCategoryInput = {
  color: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  displayLabel?: InputMaybe<Scalars['Boolean']['input']>
  images?: InputMaybe<Array<Scalars['ID']['input']>>
  items?: InputMaybe<Array<Scalars['ID']['input']>>
  label?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type CreateCatalogDiscountInput = {
  config: CatalogDiscountConfig
  name: Scalars['String']['input']
  siteAssignment: CatalogDiscountSiteAssignment
  /**   required when siteAssignment = SELECTED_SITES */
  sites?: InputMaybe<Array<Scalars['ID']['input']>>
  type: CatalogDiscountType
  /**   supports up to 2 decimal places when config is 'PERCENTAGE' (e.g. '0.01', '100.00', '12.12', etc) and whole positive numbers (e.g 0, 874, etc) when config is 'AMOUNT' */
  value: Scalars['String']['input']
}

export type CreateCatalogItemAttributeSetsInput = {
  attributeSetUuid: Scalars['ID']['input']
  attributeValueUuids: Array<Scalars['ID']['input']>
  ordinal: Scalars['Int']['input']
}

export type CreateCatalogItemInput = {
  attributeSets?: InputMaybe<Array<CreateCatalogItemAttributeSetsInput>>
  attributeSetsEnabled: Scalars['Boolean']['input']
  available?: InputMaybe<Scalars['Boolean']['input']>
  categories?: InputMaybe<Array<Scalars['ID']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  displayLabel?: InputMaybe<Scalars['Boolean']['input']>
  gtin?: InputMaybe<Scalars['String']['input']>
  images?: InputMaybe<Array<Scalars['ID']['input']>>
  invoicesEnabled: Scalars['Boolean']['input']
  label?: InputMaybe<Scalars['String']['input']>
  modifierSets?: InputMaybe<Array<CreateCatalogItemModifiersInput>>
  modifiersEnabled: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  price: Scalars['String']['input']
  reportingCategoryUuid: Scalars['ID']['input']
  siteAssignment: CatalogSiteAssignment
  sites?: InputMaybe<Array<Scalars['ID']['input']>>
  sku?: InputMaybe<Scalars['String']['input']>
  taxes?: InputMaybe<Array<CatalogTaxInput>>
  variants?: InputMaybe<Array<CreateCatalogItemVariantInput>>
}

export type CreateCatalogItemModifiersInput = {
  modifierSetUuid: Scalars['ID']['input']
  modifierUuids: Array<Scalars['ID']['input']>
}

export type CreateCatalogItemVariantInput = {
  attributeValueUuids: Array<Scalars['ID']['input']>
  available?: InputMaybe<Scalars['Boolean']['input']>
  gtin?: InputMaybe<Scalars['String']['input']>
  images?: InputMaybe<Array<Scalars['ID']['input']>>
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
  price: Scalars['String']['input']
  sku?: InputMaybe<Scalars['String']['input']>
}

export type CreateCatalogModifierInput = {
  images?: InputMaybe<Array<Scalars['ID']['input']>>
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
  price?: InputMaybe<Scalars['String']['input']>
}

export type CreateCatalogModifierSetInput = {
  invoicesEnabled: Scalars['Boolean']['input']
  modifiers?: InputMaybe<Array<CreateCatalogModifierInput>>
  name: Scalars['String']['input']
  selectionRequired: Scalars['Boolean']['input']
  siteAssignment: CatalogSiteAssignment
  sites?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CreateCustomerInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  acn?: InputMaybe<Scalars['String']['input']>
  address?: InputMaybe<CustomerAddressInput>
  assignSites?: InputMaybe<Array<Scalars['String']['input']>>
  beneficialOwner?: InputMaybe<Scalars['Boolean']['input']>
  beneficialOwnerAlt?: InputMaybe<Scalars['Boolean']['input']>
  beneficiary?: InputMaybe<Scalars['Boolean']['input']>
  ceo?: InputMaybe<Scalars['Boolean']['input']>
  chair?: InputMaybe<Scalars['Boolean']['input']>
  companyProfileData?: InputMaybe<CompanyProfileData>
  companyTrustName?: InputMaybe<Scalars['String']['input']>
  createIdentity?: InputMaybe<Scalars['Boolean']['input']>
  director?: InputMaybe<Scalars['Boolean']['input']>
  dob?: InputMaybe<Scalars['AWSDate']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  financialContact?: InputMaybe<Scalars['Boolean']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  generalContact?: InputMaybe<Scalars['Boolean']['input']>
  governmentRole?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  middlename?: InputMaybe<Scalars['String']['input']>
  nickname?: InputMaybe<Scalars['String']['input']>
  partner?: InputMaybe<Scalars['Boolean']['input']>
  permissions?: InputMaybe<CustomerPermissionsInput>
  phone?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<CustomerRole>
  secretary?: InputMaybe<Scalars['Boolean']['input']>
  settlor?: InputMaybe<Scalars['Boolean']['input']>
  shareholder?: InputMaybe<Scalars['Boolean']['input']>
  treasurer?: InputMaybe<Scalars['Boolean']['input']>
  trustee?: InputMaybe<Scalars['Boolean']['input']>
  type?: InputMaybe<EntityType>
}

export type CreateDebitCardAccountInput = {
  icon?: InputMaybe<IconInput>
  name: Scalars['String']['input']
  /**   Deprecate */
  v2?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateInvoiceCustomerInput = {
  attentionContactUuid?: InputMaybe<Scalars['ID']['input']>
  payerContactUuid: Scalars['ID']['input']
  payerEmail: Scalars['String']['input']
}

export type CreateInvoiceInput = {
  customer?: InputMaybe<CreateInvoiceCustomerInput>
  discount?: InputMaybe<InvoiceDiscountInput>
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  email?: InputMaybe<InvoiceEmailInput>
  items?: InputMaybe<Array<CreateInvoiceItemInput>>
  itemsApplyTax: Scalars['Boolean']['input']
  itemsTaxInclusive: Scalars['Boolean']['input']
  message?: InputMaybe<Scalars['String']['input']>
  milestones?: InputMaybe<Array<InvoiceMilestoneInput>>
  notes?: InputMaybe<Scalars['String']['input']>
  sendSchedule?: InputMaybe<InvoiceSendScheduleInput>
  sms?: InputMaybe<InvoiceSMSInput>
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type CreateInvoiceItemInput = {
  catalogItemUuid?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  discount?: InputMaybe<InvoiceDiscountInput>
  includeCalculation?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  orderIndex?: InputMaybe<Scalars['Int']['input']>
  price: Scalars['String']['input']
  quantity: Scalars['Float']['input']
  taxes?: InputMaybe<Array<InvoiceTaxInput>>
  unit: InvoiceItemUnit
  updatedTime?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type CreateItemCategoryInput = {
  color: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  sites?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CreateItemInput = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  image?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  price: Scalars['Int']['input']
  productId: Scalars['String']['input']
  sites?: InputMaybe<Array<Scalars['ID']['input']>>
  sku?: InputMaybe<Scalars['String']['input']>
  taxes?: InputMaybe<Array<ItemTaxInput>>
}

export type CreateOrderDiscountInput = {
  catalogDiscountUuid?: InputMaybe<Scalars['ID']['input']>
  config: CatalogDiscountConfig
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  ordinal: Scalars['Int']['input']
  value: Scalars['String']['input']
}

export type CreateOrderInput = {
  createdFromDeviceUuid?: InputMaybe<Scalars['ID']['input']>
  discounts?: InputMaybe<Array<CreateOrderDiscountInput>>
  id: Scalars['ID']['input']
  items?: InputMaybe<Array<CreateOrderItemInput>>
  siteUuid: Scalars['ID']['input']
}

export type CreateOrderItemAttributeInput = {
  attributeSetName: Scalars['String']['input']
  attributeValue: Scalars['String']['input']
}

export type CreateOrderItemInput = {
  catalogItemUuid?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  discounts?: InputMaybe<Array<InputMaybe<CreateOrderDiscountInput>>>
  id?: InputMaybe<Scalars['ID']['input']>
  modifiers?: InputMaybe<Array<CreateOrderItemModifierInput>>
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
  price: Scalars['String']['input']
  quantity: Scalars['Float']['input']
  taxes?: InputMaybe<Array<CatalogTaxInput>>
  type: OrderItemType
  unit: CatalogUnit
  variantName?: InputMaybe<Scalars['String']['input']>
}

export type CreateOrderItemModifierInput = {
  catalogModifierSetUuid?: InputMaybe<Scalars['ID']['input']>
  catalogModifierUuid?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
  price: Scalars['String']['input']
  quantity: Scalars['Float']['input']
  unit: CatalogUnit
}

export type CreatePaymentInstrumentInput = {
  bankAccountDetails?: InputMaybe<BankAccountDetailsInput>
  bpayDynamicCrnDetails?: InputMaybe<PaymentInstrumentBpayDynamicCrnDetailsInput>
  bpayStaticCrnDetails?: InputMaybe<PaymentInstrumentBpayStaticCrnDetailsInput>
  contactUuid?: InputMaybe<Scalars['ID']['input']>
  nppDetails?: InputMaybe<NppDetailsInput>
  type: PaymentInstrumentType
}

export type CreateSavingsAccountInput = {
  displayName?: InputMaybe<Scalars['String']['input']>
  tfn: Scalars['String']['input']
}

export type CreateSavingsAccountV3Input = {
  ciphertextTfn: Scalars['String']['input']
  ciphertextTfnMac: Scalars['String']['input']
  displayName?: InputMaybe<Scalars['String']['input']>
  secureSessionId: Scalars['String']['input']
}

export type CreateSiteInput = {
  address?: InputMaybe<SiteAddressInput>
  businessName?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  pin: Scalars['String']['input']
  surchargesTaxes?: InputMaybe<SurchargesTaxesInput>
  type: SiteType
}

export type CreateThirdPartyBankAccountInput = {
  /**   The Deposit Account BSB */
  accountBsb: Scalars['String']['input']
  /**   Deposit Account Name */
  accountName: Scalars['String']['input']
  /**   Deposit Account Number - max length of 9 */
  accountNumber: Scalars['String']['input']
  /**   The display name given to this account */
  name: Scalars['String']['input']
}

export type CreateTransferFundsRecipientInput = {
  /**   Not yet in use, using BSB + AccountNumber + Account Name */
  accountDetails?: InputMaybe<BankAccountDetailsInput>
  /**
   *   Deprecate in v2
   *  Replaced by accountDetails in future
   */
  accountName?: InputMaybe<Scalars['String']['input']>
  /**
   *   Deprecate in v2
   *  Replaced by accountDetails in future
   */
  accountNumber?: InputMaybe<Scalars['String']['input']>
  /**   Not yet in use */
  bpayDetails?: InputMaybe<BpayDetailsInput>
  /**
   *   Deprecate in v2
   *  Replaced by accountDetails in future
   */
  bsb?: InputMaybe<Scalars['String']['input']>
  nickname?: InputMaybe<Scalars['String']['input']>
  /**   Not yet in use */
  nppDetails?: InputMaybe<NppDetailsInput>
}

export type CreateVirtualTerminalRecordInput = {
  amount: Scalars['Int']['input']
  contactUuid: Scalars['ID']['input']
  siteUuid: Scalars['ID']['input']
  transactionNotes?: InputMaybe<Scalars['String']['input']>
}

export type Customer = {
  __typename?: 'Customer'
  abn?: Maybe<Scalars['String']['output']>
  acn?: Maybe<Scalars['String']['output']>
  address?: Maybe<CustomerAddress>
  beneficialOwner?: Maybe<Scalars['Boolean']['output']>
  beneficialOwnerAlt?: Maybe<Scalars['Boolean']['output']>
  beneficiary?: Maybe<Scalars['Boolean']['output']>
  ceo?: Maybe<Scalars['Boolean']['output']>
  chair?: Maybe<Scalars['Boolean']['output']>
  companyProfileData?: Maybe<CompanyProfileData>
  companyTrustName?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>
  director?: Maybe<Scalars['Boolean']['output']>
  email?: Maybe<Scalars['String']['output']>
  emailVerified?: Maybe<Scalars['Boolean']['output']>
  entityUuid?: Maybe<Scalars['ID']['output']>
  financialContact?: Maybe<Scalars['Boolean']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  generalContact?: Maybe<Scalars['Boolean']['output']>
  governmentRole?: Maybe<Scalars['String']['output']>
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  idvAttempts?: Maybe<IdvAttempts>
  invitedBy?: Maybe<InvitedBy>
  isInvitationPending?: Maybe<Scalars['Boolean']['output']>
  kycCheckpoints?: Maybe<Array<Maybe<KycCheckpoint>>>
  kycStatus?: Maybe<KycStatus>
  lastname?: Maybe<Scalars['String']['output']>
  middlename?: Maybe<Scalars['String']['output']>
  nickname?: Maybe<Scalars['String']['output']>
  partner?: Maybe<Scalars['Boolean']['output']>
  permissions?: Maybe<CustomerPermissions>
  phone?: Maybe<Scalars['String']['output']>
  phoneVerified?: Maybe<Scalars['Boolean']['output']>
  productTourStatus?: Maybe<ProductTourStatus>
  /**   getReferralCode resolver */
  referralCode?: Maybe<Scalars['String']['output']>
  registeringIndividual?: Maybe<Scalars['Boolean']['output']>
  role?: Maybe<CustomerRole>
  secretary?: Maybe<Scalars['Boolean']['output']>
  settlor?: Maybe<Scalars['Boolean']['output']>
  shareholder?: Maybe<Scalars['Boolean']['output']>
  siteCount?: Maybe<Scalars['Int']['output']>
  /**   getCustomerSitesResolver */
  sites?: Maybe<Array<Maybe<CustomerSite>>>
  treasurer?: Maybe<Scalars['Boolean']['output']>
  trustee?: Maybe<Scalars['Boolean']['output']>
  type?: Maybe<EntityType>
}

export type CustomerAddress = {
  __typename?: 'CustomerAddress'
  country?: Maybe<Scalars['String']['output']>
  postcode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  suburb?: Maybe<Scalars['String']['output']>
}

export type CustomerAddressInput = {
  country?: InputMaybe<Scalars['String']['input']>
  postcode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  suburb?: InputMaybe<Scalars['String']['input']>
}

export type CustomerEntityMapping = {
  __typename?: 'CustomerEntityMapping'
  defaultEntityUuid: Scalars['String']['output']
  entityRelations?: Maybe<Array<CustomerEntityRelation>>
}

export type CustomerEntityMappingFilterInput = {
  isVisible?: InputMaybe<Scalars['Boolean']['input']>
}

export type CustomerEntityRelation = {
  __typename?: 'CustomerEntityRelation'
  /**   addional resolver getCustomerEntityRelation */
  entity: Entity
  entityUuid: Scalars['String']['output']
  isVisible: Scalars['Boolean']['output']
  order?: Maybe<Scalars['Int']['output']>
  role: CustomerRole
}

export enum CustomerKYCResult {
  IN_REVIEW = 'IN_REVIEW',
  VERIFIED = 'VERIFIED',
}

export type CustomerMarketing = {
  __typename?: 'CustomerMarketing'
  banner?: Maybe<Scalars['String']['output']>
  modal?: Maybe<Scalars['String']['output']>
}

export type CustomerNotificationSettings = {
  __typename?: 'CustomerNotificationSettings'
  accounts?: Maybe<AccountsNotificationSettings>
  cards?: Maybe<CardsNotificationSettings>
  customerNotificationSettingsType?: Maybe<NotificationSettingsType>
  customerUuid: Scalars['ID']['output']
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  payments?: Maybe<PaymentsNotificationSettings>
  security?: Maybe<SecurityNotificationSettings>
  users?: Maybe<UsersNotificationSettings>
}

export type CustomerPermissions = {
  __typename?: 'CustomerPermissions'
  allowDiscountManagement: Scalars['Boolean']['output']
  allowItemManagement: Scalars['Boolean']['output']
  allowXeroPaymentServices: Scalars['Boolean']['output']
  allowZellerInvoices: Scalars['Boolean']['output']
}

export type CustomerPermissionsInput = {
  allowDiscountManagement?: InputMaybe<Scalars['Boolean']['input']>
  allowItemManagement?: InputMaybe<Scalars['Boolean']['input']>
  allowXeroPaymentServices?: InputMaybe<Scalars['Boolean']['input']>
  allowZellerInvoices?: InputMaybe<Scalars['Boolean']['input']>
}

export enum CustomerRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
}

export type CustomerSettings = {
  __typename?: 'CustomerSettings'
  customerUuid?: Maybe<Scalars['ID']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  lastname?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  role?: Maybe<Scalars['String']['output']>
  sites?: Maybe<Array<Maybe<Site>>>
}

export type CustomerSettingsInput = {
  customerUuid?: InputMaybe<Scalars['ID']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Scalars['String']['input']>
  sites?: InputMaybe<Array<InputMaybe<SiteInput>>>
}

export type CustomerSite = {
  __typename?: 'CustomerSite'
  address?: Maybe<SiteAddress>
  customers?: Maybe<Array<Maybe<Customer>>>
  devices?: Maybe<Array<Maybe<Device>>>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  pin?: Maybe<Scalars['String']['output']>
  refundRequiresPin?: Maybe<Scalars['Boolean']['output']>
}

export type CustomisationPreviewInvoiceInput = {
  customer?: InputMaybe<InvoiceCustomerPreviewInput>
  discount?: InputMaybe<InvoiceDiscountInput>
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  email?: InputMaybe<InvoiceEmailInput>
  items?: InputMaybe<Array<PartialInvoiceItemInput>>
  itemsTaxInclusive?: InputMaybe<Scalars['Boolean']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  paidAmount?: InputMaybe<Scalars['String']['input']>
  payerContactName?: InputMaybe<Scalars['String']['input']>
  referenceNumber?: InputMaybe<Scalars['String']['input']>
  siteSettings?: InputMaybe<PreviewZellerSettingsInput>
  sms?: InputMaybe<InvoiceSMSInput>
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  subtotalAmount?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  totalAmount?: InputMaybe<Scalars['String']['input']>
  totalDiscount?: InputMaybe<Scalars['String']['input']>
  totalGst?: InputMaybe<Scalars['String']['input']>
  totalSurcharge?: InputMaybe<Scalars['String']['input']>
}

export type DailyTotal = {
  __typename?: 'DailyTotal'
  amount: Scalars['Int']['output']
  date: Scalars['AWSDateTime']['output']
}

export type DailyTotalInput = {
  amount: Scalars['Int']['input']
  date: Scalars['AWSDateTime']['input']
}

export type DateRange = {
  endDate: Scalars['AWSDate']['input']
  startDate: Scalars['AWSDate']['input']
}

/**   Deprecated */
export type DebitCard = {
  __typename?: 'DebitCard'
  accountBalance?: Maybe<Money>
  accountName?: Maybe<Scalars['String']['output']>
  colour: DebitCardColour
  entityUuid: Scalars['ID']['output']
  id: DebitCardId
  lastUsed?: Maybe<Scalars['AWSDateTime']['output']>
  maskedPan: Scalars['String']['output']
  name: Scalars['String']['output']
  owner?: Maybe<Scalars['String']['output']>
  status: DebitCardStatus
  type: DebitCardType
}

/**   Deprecated */
export type DebitCardAccount = {
  __typename?: 'DebitCardAccount'
  accountDetails?: Maybe<AccountDetails>
  balance?: Maybe<Money>
  cards?: Maybe<Array<DebitCard>>
  entityUuid: Scalars['ID']['output']
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  status: DebitCardAccountStatus
}

export type DebitCardAccountBalance = {
  __typename?: 'DebitCardAccountBalance'
  balance: Money
  date: Scalars['AWSDate']['output']
}

export type DebitCardAccountConnectionV2 = {
  __typename?: 'DebitCardAccountConnectionV2'
  accounts: Array<Maybe<DebitCardAccountV2>>
  nextToken?: Maybe<DebitCardTransactionV2NextToken>
}

export type DebitCardAccountConnectionV3 = {
  __typename?: 'DebitCardAccountConnectionV3'
  accounts: Array<Maybe<DebitCardAccountV3>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type DebitCardAccountResult = DebitCardAccount | DebitCardAccountV2

export enum DebitCardAccountStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  SUSPENDED = 'SUSPENDED',
}

export type DebitCardAccountTransaction = {
  __typename?: 'DebitCardAccountTransaction'
  amount: Money
  attachments?: Maybe<Array<Scalars['String']['output']>>
  cardAcceptorName?: Maybe<Scalars['String']['output']>
  category?: Maybe<EntityCategories>
  contact?: Maybe<Contact>
  contactUuid?: Maybe<Scalars['ID']['output']>
  debitCardAccountUuid: Scalars['ID']['output']
  debitCardId?: Maybe<Scalars['ID']['output']>
  debitCardMaskedPan?: Maybe<Scalars['String']['output']>
  debitCardName?: Maybe<Scalars['String']['output']>
  declineReason?: Maybe<DebitCardTransactionDeclinedReason>
  deposit?: Maybe<Deposit>
  depositId?: Maybe<Scalars['ID']['output']>
  description?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  merchant?: Maybe<MerchantDetails>
  merchantId?: Maybe<Scalars['ID']['output']>
  note?: Maybe<Scalars['String']['output']>
  originalTransactionDetails?: Maybe<DebitCardTransactionOriginalTransactionDetails>
  payeeDetails?: Maybe<DebitCardTransactionCounterparty>
  payerDetails?: Maybe<DebitCardTransactionCounterparty>
  reference?: Maybe<Scalars['String']['output']>
  referencePayee?: Maybe<Scalars['String']['output']>
  status: DebitCardAccountTransactionStatusEnum
  subcategory?: Maybe<Scalars['ID']['output']>
  subcategoryDetails?: Maybe<Subcategory>
  tags?: Maybe<Array<Scalars['String']['output']>>
  timestamp: Scalars['AWSDateTime']['output']
  type: DebitCardAccountTransactionTypeEnum
}

export enum DebitCardAccountTransactionStatusEnum {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
  PROCESSING = 'PROCESSING',
}

export enum DebitCardAccountTransactionTypeEnum {
  ACQUIRING_REFUND = 'ACQUIRING_REFUND',
  ACQUIRING_REFUND_RETURN = 'ACQUIRING_REFUND_RETURN',
  ADJUSTMENT_DEPOSIT = 'ADJUSTMENT_DEPOSIT',
  ADJUSTMENT_WITHDRAWAL = 'ADJUSTMENT_WITHDRAWAL',
  ATM_IN = 'ATM_IN',
  ATM_OUT = 'ATM_OUT',
  BPAY_IN = 'BPAY_IN',
  BPAY_OUT = 'BPAY_OUT',
  DDA_OUT = 'DDA_OUT',
  DEPOSIT = 'DEPOSIT',
  DE_IN = 'DE_IN',
  DE_OUT = 'DE_OUT',
  DE_OUT_RETURN = 'DE_OUT_RETURN',
  NPP_IN = 'NPP_IN',
  NPP_IN_RETURN = 'NPP_IN_RETURN',
  NPP_OUT = 'NPP_OUT',
  NPP_OUT_RETURN = 'NPP_OUT_RETURN',
  PURCHASE = 'PURCHASE',
  PURCHASE_CNP = 'PURCHASE_CNP',
  REFUND = 'REFUND',
  REFUND_CNP = 'REFUND_CNP',
  TRANSFER_IN = 'TRANSFER_IN',
  TRANSFER_OUT = 'TRANSFER_OUT',
  WITHDRAWAL = 'WITHDRAWAL',
}

export type DebitCardAccountTransactionsConnection = {
  __typename?: 'DebitCardAccountTransactionsConnection'
  nextToken?: Maybe<Scalars['String']['output']>
  transactions: Array<Maybe<DebitCardAccountTransaction>>
}

export enum DebitCardAccountType {
  EML_PREPAID = 'EML_PREPAID',
  SAVINGS = 'SAVINGS',
  ZLR_DEBIT = 'ZLR_DEBIT',
}

export type DebitCardAccountV2 = {
  __typename?: 'DebitCardAccountV2'
  accountClosureAt?: Maybe<Scalars['AWSDateTime']['output']>
  accountCreationAt?: Maybe<Scalars['AWSDateTime']['output']>
  accountDetails?: Maybe<BankAccountDetails>
  balance?: Maybe<Money>
  cards?: Maybe<Array<DebitCardV2>>
  cardsCount: DebitCardAccountV2CardsCount
  entityUuid: Scalars['ID']['output']
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  savingsAccountDetails?: Maybe<SavingsAccountDetails>
  /**   additional resolver */
  savingsAccountProduct?: Maybe<SavingsAccountProduct>
  status: DebitCardAccountStatus
  type: DebitCardAccountType
}

export type DebitCardAccountV2CardsCount = {
  __typename?: 'DebitCardAccountV2CardsCount'
  debit: Scalars['Int']['output']
  expense?: Maybe<Scalars['Int']['output']>
  total: Scalars['Int']['output']
}

export type DebitCardAccountV2NextToken = {
  __typename?: 'DebitCardAccountV2NextToken'
  entityUuid?: Maybe<Scalars['ID']['output']>
  id?: Maybe<Scalars['ID']['output']>
  type: Scalars['String']['output']
}

export type DebitCardAccountV2NextTokenInput = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  type: Scalars['String']['input']
}

export type DebitCardAccountV2Update = {
  __typename?: 'DebitCardAccountV2Update'
  accountClosureAt?: Maybe<Scalars['AWSDateTime']['output']>
  accountCreationAt?: Maybe<Scalars['AWSDateTime']['output']>
  accountDetails?: Maybe<BankAccountDetails>
  balance?: Maybe<Money>
  entityUuid: Scalars['ID']['output']
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  savingsAccountDetails?: Maybe<SavingsAccountDetails>
  status: DebitCardAccountStatus
  type: DebitCardAccountType
}

export type DebitCardAccountV2UpdateEventInput = {
  accountClosureAt?: InputMaybe<Scalars['AWSDateTime']['input']>
  accountCreationAt?: InputMaybe<Scalars['AWSDateTime']['input']>
  accountDetails?: InputMaybe<BankAccountDetailsEventInput>
  balance?: InputMaybe<MoneyInput>
  entityUuid: Scalars['ID']['input']
  icon?: InputMaybe<IconEventInput>
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  savingsAccountDetails?: InputMaybe<SavingsAccountDetailsEventInput>
  status: DebitCardAccountStatus
  type: DebitCardAccountType
}

export type DebitCardAccountV3 = {
  __typename?: 'DebitCardAccountV3'
  accountClosureAt?: Maybe<Scalars['AWSDateTime']['output']>
  accountCreationAt?: Maybe<Scalars['AWSDateTime']['output']>
  accountDetails?: Maybe<BankAccountDetails>
  balance?: Maybe<Money>
  /**   additional resolver */
  cards?: Maybe<Array<DebitCardV2>>
  /**   additional resolver */
  cardsCount: DebitCardAccountV2CardsCount
  entityUuid: Scalars['ID']['output']
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  status: DebitCardAccountStatus
  type: DebitCardAccountType
}

export type DebitCardAccountV3UpdateEventInput = {
  accountClosureAt?: InputMaybe<Scalars['AWSDateTime']['input']>
  accountCreationAt?: InputMaybe<Scalars['AWSDateTime']['input']>
  accountDetails?: InputMaybe<BankAccountDetailsEventInput>
  balance?: InputMaybe<MoneyInput>
  entityUuid: Scalars['ID']['input']
  icon?: InputMaybe<IconEventInput>
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  status: DebitCardAccountStatus
  type: DebitCardAccountType
}

export type DebitCardAddressInput = {
  country?: InputMaybe<Scalars['String']['input']>
  postcode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street1?: InputMaybe<Scalars['String']['input']>
  street2?: InputMaybe<Scalars['String']['input']>
  suburb?: InputMaybe<Scalars['String']['input']>
}

export enum DebitCardColour {
  BLACK = 'BLACK',
  DARKBLUE = 'DARKBLUE',
  GREEN = 'GREEN',
  LIGHTBLUE = 'LIGHTBLUE',
  WHITE = 'WHITE',
  YELLOW = 'YELLOW',
}

/**   Deprecated */
export type DebitCardConnection = {
  __typename?: 'DebitCardConnection'
  cards: Array<Maybe<DebitCard>>
  nextToken?: Maybe<DebitCardId>
}

export type DebitCardFilterInput = {
  or?: InputMaybe<Array<InputMaybe<DebitCardFilterInput>>>
  productType?: InputMaybe<StringFilterInput>
}

export enum DebitCardFormat {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',
}

/**   Deprecated in the EZTA */
export type DebitCardId = {
  __typename?: 'DebitCardId'
  cardSequenceNumber?: Maybe<Scalars['Int']['output']>
  debitCardAccountUuid?: Maybe<Scalars['ID']['output']>
}

export type DebitCardIdInput = {
  cardUuid?: InputMaybe<Scalars['ID']['input']>
}

export enum DebitCardProductType {
  DEBIT = 'DEBIT',
  EXPENSE = 'EXPENSE',
}

export enum DebitCardStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  EXPIRED = 'EXPIRED',
  INACTIVE = 'INACTIVE',
  LOCKED = 'LOCKED',
  LOST = 'LOST',
  SUSPENDED = 'SUSPENDED',
}

export type DebitCardTransactionAnnotations = {
  accountingCategory?: InputMaybe<Scalars['String']['input']>
  category?: InputMaybe<EntityCategories>
  debitCardTransactionUuid: Scalars['ID']['input']
  note?: InputMaybe<Scalars['String']['input']>
  subcategory?: InputMaybe<Scalars['ID']['input']>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
}

export type DebitCardTransactionConnectionV2 = {
  __typename?: 'DebitCardTransactionConnectionV2'
  nextToken?: Maybe<DebitCardTransactionV2NextToken>
  transactions: Array<Maybe<DebitCardTransactionV2>>
}

export type DebitCardTransactionCounterparty = {
  __typename?: 'DebitCardTransactionCounterparty'
  accountDetails?: Maybe<BankAccountDetails>
  bpayDetails?: Maybe<BpayDetails>
  debitCardAccount?: Maybe<DebitCardAccountV2>
  debitCardAccountUuid?: Maybe<Scalars['ID']['output']>
  nppDetails?: Maybe<NppDetails>
  paymentInstrumentUuid?: Maybe<Scalars['ID']['output']>
  savingsAccountUuid?: Maybe<Scalars['ID']['output']>
  senderUuid?: Maybe<Scalars['ID']['output']>
}

export type DebitCardTransactionCounterpartyEvent = {
  __typename?: 'DebitCardTransactionCounterpartyEvent'
  accountDetails?: Maybe<BankAccountDetails>
  bpayDetails?: Maybe<BpayDetails>
  debitCardAccount?: Maybe<DebitCardAccountV2>
  debitCardAccountUuid?: Maybe<Scalars['ID']['output']>
  nppDetails?: Maybe<NppDetails>
  paymentInstrumentUuid?: Maybe<Scalars['ID']['output']>
  savingsAccountUuid?: Maybe<Scalars['ID']['output']>
  senderUuid?: Maybe<Scalars['ID']['output']>
}

export type DebitCardTransactionCounterpartyEventInput = {
  accountDetails?: InputMaybe<BankAccountDetailsEventInput>
  bpayDetails?: InputMaybe<BpayDetailsInput>
  debitCardAccountUuid?: InputMaybe<Scalars['ID']['input']>
  nppDetails?: InputMaybe<NppDetailsInput>
  paymentInstrumentUuid?: InputMaybe<Scalars['ID']['input']>
  savingsAccountUuid?: InputMaybe<Scalars['ID']['input']>
  senderUuid?: InputMaybe<Scalars['ID']['input']>
}

export type DebitCardTransactionCounterpartyInput = {
  accountDetails?: InputMaybe<BankAccountDetailsInput>
  bpayDetails?: InputMaybe<BpayDetailsInput>
  debitCardAccountUuid?: InputMaybe<Scalars['ID']['input']>
  nppDetails?: InputMaybe<NppDetailsInput>
  paymentInstrumentUuid?: InputMaybe<Scalars['ID']['input']>
  savingsAccountUuid?: InputMaybe<Scalars['ID']['input']>
}

export enum DebitCardTransactionDeclinedReason {
  ACCOUNT_EXPIRED = 'ACCOUNT_EXPIRED',
  ACCOUNT_INACTIVE = 'ACCOUNT_INACTIVE',
  AMOUNT_LIMIT_EXCEEDED = 'AMOUNT_LIMIT_EXCEEDED',
  FRAUD_SCREENING_REJECTED = 'FRAUD_SCREENING_REJECTED',
  INCORRECT_PIN = 'INCORRECT_PIN',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  INVALID_ACCOUNT_STATE = 'INVALID_ACCOUNT_STATE',
  MERCHANT_DISALLOWED = 'MERCHANT_DISALLOWED',
  OPERATION_TIMEOUT = 'OPERATION_TIMEOUT',
  OTHER = 'OTHER',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  UNKNOWN = 'UNKNOWN',
  VELOCITY_EXCEEDED = 'VELOCITY_EXCEEDED',
}

export type DebitCardTransactionDownload = {
  __typename?: 'DebitCardTransactionDownload'
  downloadLink: Scalars['String']['output']
  expire: Scalars['AWSDateTime']['output']
}

/**   for 'merchantUuid', 'category' and 'subcategory' filter fields, the only supported operator is 'eq' */
export type DebitCardTransactionFilterInput = {
  amount?: InputMaybe<IntFilterInput>
  attachment?: InputMaybe<Scalars['Boolean']['input']>
  category?: InputMaybe<StringFilterInput>
  contactUuid?: InputMaybe<StringFilterInput>
  merchantUuid?: InputMaybe<StringFilterInput>
  or?: InputMaybe<Array<InputMaybe<DebitCardTransactionFilterInput>>>
  reference?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  subcategory?: InputMaybe<StringFilterInput>
  tags?: InputMaybe<StringFilterInput>
  timestamp?: InputMaybe<StringFilterInput>
  type?: InputMaybe<StringFilterInput>
}

export type DebitCardTransactionFilterInputV2 = {
  accountingCategory?: InputMaybe<StringFilterInput>
  amount?: InputMaybe<IntFilterInput>
  attachment?: InputMaybe<Scalars['Boolean']['input']>
  category?: InputMaybe<StringFilterInput>
  contactUuid?: InputMaybe<StringFilterInput>
  debitCardAccountUuid?: InputMaybe<StringFilterInput>
  debitCardId?: InputMaybe<StringFilterInput>
  merchantUuid?: InputMaybe<StringFilterInput>
  or?: InputMaybe<Array<InputMaybe<DebitCardTransactionFilterInputV2>>>
  reference?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  subcategory?: InputMaybe<StringFilterInput>
  tags?: InputMaybe<StringFilterInput>
  timestamp?: InputMaybe<StringFilterInput>
  type?: InputMaybe<StringFilterInput>
}

export type DebitCardTransactionOriginalTransactionDetails = {
  __typename?: 'DebitCardTransactionOriginalTransactionDetails'
  originalTransactionUuid?: Maybe<Scalars['ID']['output']>
}

export type DebitCardTransactionOriginalTransactionDetailsEvent = {
  __typename?: 'DebitCardTransactionOriginalTransactionDetailsEvent'
  originalTransactionUuid?: Maybe<Scalars['ID']['output']>
}

export type DebitCardTransactionOriginalTransactionDetailsEventInput = {
  originalTransactionUuid?: InputMaybe<Scalars['ID']['input']>
}

export enum DebitCardTransactionStatus {
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
  PROCESSING = 'PROCESSING',
  REVERSED = 'REVERSED',
  SUCCEEDED = 'SUCCEEDED',
}

/**   will be deprecated in the future */
export enum DebitCardTransactionStatusV2 {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  DECLINED = 'DECLINED',
  PROCESSING = 'PROCESSING',
}

export enum DebitCardTransactionType {
  ATM = 'ATM',
  CNP = 'CNP',
  DE_IN = 'DE_IN',
  DE_OUT = 'DE_OUT',
  MANUAL_TRANSFER = 'MANUAL_TRANSFER',
  POS = 'POS',
  REFUND = 'REFUND',
  SETTLEMENT = 'SETTLEMENT',
  TRANSFER = 'TRANSFER',
}

/**   will be deprecated in the future */
export enum DebitCardTransactionTypeV2 {
  ACQUIRING_REFUND = 'ACQUIRING_REFUND',
  ACQUIRING_REFUND_RETURN = 'ACQUIRING_REFUND_RETURN',
  ADJUSTMENT_DEPOSIT = 'ADJUSTMENT_DEPOSIT',
  ADJUSTMENT_WITHDRAWAL = 'ADJUSTMENT_WITHDRAWAL',
  ATM_IN = 'ATM_IN',
  ATM_OUT = 'ATM_OUT',
  BPAY_IN = 'BPAY_IN',
  BPAY_OUT = 'BPAY_OUT',
  DDA_OUT = 'DDA_OUT',
  DEPOSIT = 'DEPOSIT',
  DE_IN = 'DE_IN',
  DE_OUT = 'DE_OUT',
  DE_OUT_RETURN = 'DE_OUT_RETURN',
  INTEREST = 'INTEREST',
  NPP_IN = 'NPP_IN',
  NPP_IN_RETURN = 'NPP_IN_RETURN',
  NPP_OUT = 'NPP_OUT',
  NPP_OUT_RETURN = 'NPP_OUT_RETURN',
  PURCHASE = 'PURCHASE',
  PURCHASE_CNP = 'PURCHASE_CNP',
  REFUND = 'REFUND',
  REFUND_CNP = 'REFUND_CNP',
  TRANSFER_IN = 'TRANSFER_IN',
  TRANSFER_OUT = 'TRANSFER_OUT',
  WITHDRAWAL = 'WITHDRAWAL',
}

/**   will be deprecated in the future */
export type DebitCardTransactionV2 = {
  __typename?: 'DebitCardTransactionV2'
  accountingCategory?: Maybe<Scalars['String']['output']>
  amount: Money
  attachments?: Maybe<Array<Scalars['String']['output']>>
  cardAcceptorName?: Maybe<Scalars['String']['output']>
  category?: Maybe<EntityCategories>
  contact?: Maybe<Contact>
  contactUuid?: Maybe<Scalars['ID']['output']>
  debitCardAccountUuid: Scalars['ID']['output']
  debitCardId?: Maybe<Scalars['ID']['output']>
  debitCardMaskedPan?: Maybe<Scalars['String']['output']>
  debitCardName?: Maybe<Scalars['String']['output']>
  declineReason?: Maybe<DebitCardTransactionDeclinedReason>
  deposit?: Maybe<Deposit>
  description?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  merchant?: Maybe<MerchantDetails>
  merchantId?: Maybe<Scalars['ID']['output']>
  note?: Maybe<Scalars['String']['output']>
  originalTransactionDetails?: Maybe<DebitCardTransactionOriginalTransactionDetails>
  payeeDetails?: Maybe<DebitCardTransactionCounterparty>
  payeeDetailsUuid?: Maybe<Scalars['ID']['output']>
  payerDetails?: Maybe<DebitCardTransactionCounterparty>
  reference?: Maybe<Scalars['String']['output']>
  referencePayee?: Maybe<Scalars['String']['output']>
  status: DebitCardTransactionStatusV2
  subcategory?: Maybe<Scalars['ID']['output']>
  subcategoryDetails?: Maybe<Subcategory>
  tags?: Maybe<Array<Scalars['String']['output']>>
  taxRates?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['AWSDateTime']['output']
  type: DebitCardTransactionTypeV2
}

export type DebitCardTransactionV2EventInput = {
  accountingCategory?: InputMaybe<Scalars['String']['input']>
  amount: MoneyInput
  attachments?: InputMaybe<Array<Scalars['String']['input']>>
  cardAcceptorName?: InputMaybe<Scalars['String']['input']>
  category?: InputMaybe<EntityCategories>
  contact?: InputMaybe<ContactBasicInfoInput>
  debitCardAccountUuid: Scalars['ID']['input']
  debitCardId?: InputMaybe<Scalars['ID']['input']>
  debitCardMaskedPan?: InputMaybe<Scalars['String']['input']>
  debitCardName?: InputMaybe<Scalars['String']['input']>
  declineReason?: InputMaybe<DebitCardTransactionDeclinedReason>
  description?: InputMaybe<Scalars['String']['input']>
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  merchant?: InputMaybe<MerchantBasicInfoInput>
  merchantId?: InputMaybe<Scalars['ID']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  originalTransactionDetails?: InputMaybe<DebitCardTransactionOriginalTransactionDetailsEventInput>
  payeeDetails?: InputMaybe<DebitCardTransactionCounterpartyEventInput>
  payeeDetailsUuid?: InputMaybe<Scalars['ID']['input']>
  payerDetails?: InputMaybe<DebitCardTransactionCounterpartyEventInput>
  reference?: InputMaybe<Scalars['String']['input']>
  referencePayee?: InputMaybe<Scalars['String']['input']>
  status: DebitCardTransactionStatusV2
  subcategory?: InputMaybe<Scalars['ID']['input']>
  subcategoryDetails?: InputMaybe<SubcategoryInput>
  tags?: InputMaybe<Array<Scalars['String']['input']>>
  taxRates?: InputMaybe<Scalars['String']['input']>
  timestamp: Scalars['AWSDateTime']['input']
  type: DebitCardTransactionTypeV2
}

export type DebitCardTransactionV2NextToken = {
  __typename?: 'DebitCardTransactionV2NextToken'
  entityUuid?: Maybe<Scalars['ID']['output']>
  id?: Maybe<Scalars['ID']['output']>
  type: Scalars['String']['output']
}

export type DebitCardTransactionV2NextTokenInput = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  type: Scalars['String']['input']
}

export type DebitCardTransactionV2Update = {
  __typename?: 'DebitCardTransactionV2Update'
  accountingCategory?: Maybe<Scalars['String']['output']>
  amount: Money
  attachments?: Maybe<Array<Scalars['String']['output']>>
  cardAcceptorName?: Maybe<Scalars['String']['output']>
  category?: Maybe<EntityCategories>
  contact?: Maybe<ContactBasicInfo>
  contactUuid?: Maybe<Scalars['ID']['output']>
  debitCardAccountUuid: Scalars['ID']['output']
  debitCardId?: Maybe<Scalars['ID']['output']>
  debitCardMaskedPan?: Maybe<Scalars['String']['output']>
  debitCardName?: Maybe<Scalars['String']['output']>
  declineReason?: Maybe<DebitCardTransactionDeclinedReason>
  deposit?: Maybe<Deposit>
  depositId?: Maybe<Scalars['ID']['output']>
  description?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  merchant?: Maybe<MerchantBasicInfo>
  merchantId?: Maybe<Scalars['ID']['output']>
  note?: Maybe<Scalars['String']['output']>
  originalTransactionDetails?: Maybe<DebitCardTransactionOriginalTransactionDetailsEvent>
  payeeDetails?: Maybe<DebitCardTransactionCounterpartyEvent>
  payeeDetailsUuid?: Maybe<Scalars['ID']['output']>
  payerDetails?: Maybe<DebitCardTransactionCounterpartyEvent>
  reference?: Maybe<Scalars['String']['output']>
  referencePayee?: Maybe<Scalars['String']['output']>
  status: DebitCardTransactionStatusV2
  subcategory?: Maybe<Scalars['ID']['output']>
  subcategoryDetails?: Maybe<Subcategory>
  tags?: Maybe<Array<Scalars['String']['output']>>
  taxRates?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['AWSDateTime']['output']
  type: DebitCardTransactionTypeV2
}

/**
 *   The response parameter of exportDebitCardTransactions subscription
 *  downloadLink is a pre-signed url points to s3 bucket file
 *  expire is the expire time of the downloadLink, it lasts 1 hour
 */
export type DebitCardTransactionsDownload = {
  __typename?: 'DebitCardTransactionsDownload'
  /**   The requested customerUuid which is used by subscription. FE doesn't need to subscribe this value. */
  customerUuid: Scalars['ID']['output']
  /**   The download link is a presigned URL from s3 object. The file name is a uuid plus some random number, FE better to rename the file. */
  downloadLink?: Maybe<Scalars['String']['output']>
  /**   The requested entityUuid which is used by subscription. FE doesn't need to subscribe this value. */
  entityUuid: Scalars['ID']['output']
  /**   The error includes error message if something wrong happens. Unlike `Query`, subscription doesn't response the error in `errors` field of the payload. */
  error?: Maybe<Scalars['String']['output']>
  /**   The expire date time of the download link */
  expire?: Maybe<Scalars['AWSDateTime']['output']>
  /**   The requested filter which is used by subscription. FE doesn't need to subscribe this value. */
  filter: Scalars['String']['output']
  /**   # The requested format which is used by subscription. FE doesn't need to subscribe this value. */
  format: DebitCardTransactionsExportFormat
}

export type DebitCardTransactionsDownloadInput = {
  customerUuid: Scalars['ID']['input']
  downloadLink?: InputMaybe<Scalars['String']['input']>
  entityUuid: Scalars['ID']['input']
  error?: InputMaybe<Scalars['String']['input']>
  expire?: InputMaybe<Scalars['AWSDateTime']['input']>
  filter: Scalars['String']['input']
  format: DebitCardTransactionsExportFormat
}

export enum DebitCardTransactionsExportFormat {
  CSV = 'CSV',
  XLSX = 'XLSX',
}

export type DebitCardTxnAttachmentDownloadResult = {
  __typename?: 'DebitCardTxnAttachmentDownloadResult'
  contentType?: Maybe<Scalars['String']['output']>
  documentUuid: Scalars['String']['output']
  downloadUrl: Scalars['String']['output']
  fileName: Scalars['String']['output']
}

export type DebitCardTxnAttachmentUploadUrls = {
  __typename?: 'DebitCardTxnAttachmentUploadUrls'
  documentUuid: Scalars['String']['output']
  fileName: Scalars['String']['output']
  uploadUrl: Scalars['String']['output']
}

export enum DebitCardType {
  MASTERCARD_DEBIT = 'MASTERCARD_DEBIT',
}

export type DebitCardV2 = {
  __typename?: 'DebitCardV2'
  accessibleProfile?: Maybe<Scalars['Boolean']['output']>
  cardClosedAt?: Maybe<Scalars['AWSDateTime']['output']>
  cardSequenceNumber?: Maybe<Scalars['Int']['output']>
  closingCardUserUuid?: Maybe<Scalars['ID']['output']>
  colour: DebitCardColour
  createdTime?: Maybe<Scalars['AWSDateTime']['output']>
  customer?: Maybe<Customer>
  customerUuid?: Maybe<Scalars['String']['output']>
  debitCardAccount: DebitCardAccountV2
  debitCardAccountUuid: Scalars['ID']['output']
  /**   additional resolver */
  digitalWalletTokens: Array<DigitalWalletToken>
  entityUuid: Scalars['ID']['output']
  expiryMMYY?: Maybe<Scalars['String']['output']>
  firstActivatedAt?: Maybe<Scalars['AWSDateTime']['output']>
  format?: Maybe<DebitCardFormat>
  id: Scalars['ID']['output']
  lastUsed?: Maybe<Scalars['AWSDateTime']['output']>
  maskedPan: Scalars['String']['output']
  name: Scalars['String']['output']
  outstandingTransactions?: Maybe<Scalars['Int']['output']>
  owner?: Maybe<Scalars['String']['output']>
  productType?: Maybe<DebitCardProductType>
  status: DebitCardStatus
  type: DebitCardType
  velocityControl?: Maybe<VelocityControlType>
}

export type DebitCardV2Connection = {
  __typename?: 'DebitCardV2Connection'
  cards: Array<Maybe<DebitCardV2>>
  nextToken?: Maybe<DebitCardV2NextToken>
}

export type DebitCardV2EventInput = {
  cardClosedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
  cardSequenceNumber?: InputMaybe<Scalars['Int']['input']>
  closingCardUserUuid?: InputMaybe<Scalars['ID']['input']>
  colour: DebitCardColour
  createdTime?: InputMaybe<Scalars['AWSDateTime']['input']>
  customerUuid?: InputMaybe<Scalars['String']['input']>
  debitCardAccountUuid: Scalars['ID']['input']
  entityUuid: Scalars['ID']['input']
  expiryMMYY?: InputMaybe<Scalars['String']['input']>
  firstActivatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
  format?: InputMaybe<DebitCardFormat>
  id: Scalars['ID']['input']
  lastUsed?: InputMaybe<Scalars['AWSDateTime']['input']>
  maskedPan: Scalars['String']['input']
  name: Scalars['String']['input']
  owner?: InputMaybe<Scalars['String']['input']>
  productType?: InputMaybe<DebitCardProductType>
  status: DebitCardStatus
  type: DebitCardType
  velocityControl?: InputMaybe<VelocityControlEventInput>
}

export type DebitCardV2NextToken = {
  __typename?: 'DebitCardV2NextToken'
  entityUuid?: Maybe<Scalars['ID']['output']>
  id?: Maybe<Scalars['ID']['output']>
  type: Scalars['String']['output']
}

export type DebitCardV2NextTokenInput = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  type: Scalars['String']['input']
}

export type DebitCardV2Update = {
  __typename?: 'DebitCardV2Update'
  cardClosedAt?: Maybe<Scalars['AWSDateTime']['output']>
  cardSequenceNumber?: Maybe<Scalars['Int']['output']>
  closingCardUserUuid?: Maybe<Scalars['ID']['output']>
  colour: DebitCardColour
  createdTime?: Maybe<Scalars['AWSDateTime']['output']>
  customerUuid?: Maybe<Scalars['String']['output']>
  debitCardAccountUuid: Scalars['ID']['output']
  entityUuid: Scalars['ID']['output']
  expiryMMYY?: Maybe<Scalars['String']['output']>
  firstActivatedAt?: Maybe<Scalars['AWSDateTime']['output']>
  format?: Maybe<DebitCardFormat>
  id: Scalars['ID']['output']
  lastUsed?: Maybe<Scalars['AWSDateTime']['output']>
  maskedPan: Scalars['String']['output']
  name: Scalars['String']['output']
  outstandingTransactions?: Maybe<Scalars['Int']['output']>
  owner?: Maybe<Scalars['String']['output']>
  productType?: Maybe<DebitCardProductType>
  status: DebitCardStatus
  type: DebitCardType
  velocityControl?: Maybe<VelocityControlType>
}

export type DeleteDevicePushTokenInput = {
  deviceUuid: Scalars['ID']['input']
  pushToken: Scalars['String']['input']
}

export type Deposit = {
  __typename?: 'Deposit'
  accountLogo?: Maybe<Scalars['String']['output']>
  accountName?: Maybe<Scalars['String']['output']>
  accountNumber?: Maybe<Scalars['String']['output']>
  accountType?: Maybe<DepositAccountType>
  amountSettled: Scalars['Int']['output']
  chargebacksAmount: Scalars['Int']['output']
  /** @deprecated use processedAmount */
  collectedAmount: Scalars['Int']['output']
  /** @deprecated to be removed */
  dailyTotals?: Maybe<Array<DailyTotal>>
  /** @deprecated use settledAmount */
  depositAmount: Scalars['Int']['output']
  entityUuid: Scalars['ID']['output']
  /** @deprecated use processedFeeAmount */
  feeAmount: Scalars['Int']['output']
  id: Scalars['ID']['output']
  /** @deprecated use rolledOverProcessedAmount */
  owingAmount: Scalars['Int']['output']
  processedAmount: Scalars['Int']['output']
  processedFeeAmount: Scalars['Int']['output']
  processingDateTimeUtc?: Maybe<Scalars['AWSDateTime']['output']>
  reference?: Maybe<Scalars['String']['output']>
  refundsAmount: Scalars['Int']['output']
  rolledOverFeeAmount: Scalars['Int']['output']
  rolledOverProcessedAmount: Scalars['Int']['output']
  shortId?: Maybe<Scalars['String']['output']>
  siteName: Scalars['String']['output']
  siteUuid: Scalars['ID']['output']
  /**   Date held */
  skippedTimestamp?: Maybe<Scalars['AWSDateTime']['output']>
  status: DepositStatus
  submittedAt?: Maybe<Scalars['AWSDateTime']['output']>
  timestamp: Scalars['AWSDateTime']['output']
  /** @deprecated to be removed */
  totalAmount: Scalars['Int']['output']
  tradingSlot?: Maybe<Scalars['String']['output']>
  /**   Pending */
  withHeld: Scalars['Int']['output']
}

export enum DepositAccountType {
  NominatedBankAccount = 'NominatedBankAccount',
  PrepaidProgramme = 'PrepaidProgramme',
}

export type DepositConnection = {
  __typename?: 'DepositConnection'
  deposits: Array<Maybe<Deposit>>
  nextToken?: Maybe<DepositNextToken>
}

export type DepositDownload = {
  __typename?: 'DepositDownload'
  downloadLink: Scalars['String']['output']
  expire: Scalars['AWSDateTime']['output']
}

export enum DepositExportFormat {
  CSV = 'CSV',
  PDF = 'PDF',
  XLSX = 'XLSX',
}

export type DepositFilterInput = {
  chargebacksAmount?: InputMaybe<IntFilterInput>
  collectedAmount?: InputMaybe<IntFilterInput>
  depositAmount?: InputMaybe<IntFilterInput>
  feeAmount?: InputMaybe<IntFilterInput>
  owingAmount?: InputMaybe<IntFilterInput>
  refundsAmount?: InputMaybe<IntFilterInput>
  siteUuid?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  timestamp?: InputMaybe<StringFilterInput>
  totalAmount?: InputMaybe<IntFilterInput>
}

export type DepositInput = {
  accountLogo?: InputMaybe<Scalars['String']['input']>
  accountName?: InputMaybe<Scalars['String']['input']>
  accountNumber?: InputMaybe<Scalars['String']['input']>
  accountType?: InputMaybe<DepositAccountType>
  amountSettled: Scalars['Int']['input']
  chargebacksAmount: Scalars['Int']['input']
  collectedAmount: Scalars['Int']['input']
  dailyTotals?: InputMaybe<Array<DailyTotalInput>>
  depositAmount: Scalars['Int']['input']
  entityUuid: Scalars['ID']['input']
  feeAmount: Scalars['Int']['input']
  id: Scalars['ID']['input']
  owingAmount: Scalars['Int']['input']
  processedAmount: Scalars['Int']['input']
  processingDateTimeUtc?: InputMaybe<Scalars['AWSDateTime']['input']>
  reference?: InputMaybe<Scalars['String']['input']>
  refundsAmount: Scalars['Int']['input']
  rolledOverFeeAmount: Scalars['Int']['input']
  rolledOverProcessedAmount: Scalars['Int']['input']
  siteName?: InputMaybe<Scalars['String']['input']>
  siteUuid?: InputMaybe<Scalars['ID']['input']>
  skippedTimestamp?: InputMaybe<Scalars['AWSDateTime']['input']>
  status: DepositStatus
  submittedAt?: InputMaybe<Scalars['AWSDateTime']['input']>
  timestamp?: InputMaybe<Scalars['AWSDateTime']['input']>
  totalAmount: Scalars['Int']['input']
  tradingSlot?: InputMaybe<Scalars['String']['input']>
  /**   Pending */
  withHeld: Scalars['Int']['input']
}

export type DepositNextToken = {
  __typename?: 'DepositNextToken'
  entityUuid?: Maybe<Scalars['ID']['output']>
  id?: Maybe<Scalars['ID']['output']>
  type: Scalars['String']['output']
}

export type DepositNextTokenInput = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  type: Scalars['String']['input']
}

export enum DepositStatementExportFormat {
  PDF = 'PDF',
}

export enum DepositStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SKIPPED = 'SKIPPED',
  SUBMITTED = 'SUBMITTED',
}

export type DepositTotals = {
  __typename?: 'DepositTotals'
  chargebacksAmount: Scalars['String']['output']
  collectedAmount: Scalars['String']['output']
  countDeposits: Scalars['Int']['output']
  depositAmount: Scalars['String']['output']
  feeAmount: Scalars['String']['output']
  feeGstAmount: Scalars['String']['output']
  owingAmount: Scalars['String']['output']
  refundsAmount: Scalars['String']['output']
  totalAmount: Scalars['String']['output']
}

export type Device = {
  __typename?: 'Device'
  id: Scalars['ID']['output']
  mobileIdentifier?: Maybe<Scalars['String']['output']>
  mobileModel?: Maybe<Scalars['String']['output']>
  model: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
  posSettings?: Maybe<PosSettings>
  serial: Scalars['String']['output']
}

export type DeviceInformation = {
  __typename?: 'DeviceInformation'
  id: Scalars['ID']['output']
  network?: Maybe<DeviceNetworkInformation>
}

export type DeviceNameInput = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type DeviceNetworkCellularInformation = {
  __typename?: 'DeviceNetworkCellularInformation'
  ipAddress?: Maybe<Scalars['String']['output']>
  operatorName?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  strength?: Maybe<Scalars['String']['output']>
}

export type DeviceNetworkEthernetInformation = {
  __typename?: 'DeviceNetworkEthernetInformation'
  ipAddress?: Maybe<Scalars['String']['output']>
  macAddress?: Maybe<Scalars['String']['output']>
}

export type DeviceNetworkInformation = {
  __typename?: 'DeviceNetworkInformation'
  activeConnection?: Maybe<ActiveConnection>
  cellularInfo?: Maybe<DeviceNetworkCellularInformation>
  ethernetInfo?: Maybe<DeviceNetworkEthernetInformation>
  wifiInfo?: Maybe<DeviceNetworkWifiInformation>
}

export type DeviceNetworkWifiInformation = {
  __typename?: 'DeviceNetworkWifiInformation'
  ipAddress?: Maybe<Scalars['String']['output']>
  macAddress?: Maybe<Scalars['String']['output']>
  ssid?: Maybe<Scalars['String']['output']>
  strength?: Maybe<Scalars['String']['output']>
}

export type DeviceScreensaver = {
  __typename?: 'DeviceScreensaver'
  primaryColour: Scalars['String']['output']
  primaryLogoUrl: Scalars['String']['output']
}

export type DeviceScreensaverInput = {
  primaryColour: Scalars['String']['input']
  primaryLogoUrl: Scalars['String']['input']
}

export type DeviceSettings = {
  __typename?: 'DeviceSettings'
  appVersion?: Maybe<Scalars['String']['output']>
  /**   getCustomerSettingsResolver */
  customers?: Maybe<Array<Maybe<CustomerSettings>>>
  deviceUser?: Maybe<Scalars['String']['output']>
  emvCaKeys?: Maybe<Scalars['String']['output']>
  emvConfig?: Maybe<Scalars['String']['output']>
  emvTables?: Maybe<Scalars['String']['output']>
  entity?: Maybe<EntityDeviceSettings>
  entityUuid?: Maybe<Scalars['ID']['output']>
  features?: Maybe<SiteFeatures>
  firmwareVersion?: Maybe<Scalars['String']['output']>
  geofencing?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  model?: Maybe<Scalars['String']['output']>
  moto?: Maybe<MotoSettings>
  name?: Maybe<Scalars['String']['output']>
  network?: Maybe<NetworkDeviceSettings>
  posSettings?: Maybe<PosSettings>
  receipt?: Maybe<ReceiptSettings>
  schemes?: Maybe<Array<Maybe<SchemeSettings>>>
  schemesMoto?: Maybe<Array<Maybe<SchemeSettings>>>
  screen?: Maybe<ScreenDeviceSettings>
  serial?: Maybe<Scalars['String']['output']>
  site?: Maybe<SiteDeviceSettings>
  standInRules?: Maybe<Array<Maybe<StandInRule>>>
  surchargesTaxes?: Maybe<SurchargesTaxesSettings>
  terminalConfig?: Maybe<Scalars['String']['output']>
  tipping?: Maybe<TipSettings>
}

/**   Publisher Input */
export type DeviceSettingsInput = {
  customers?: InputMaybe<Array<InputMaybe<CustomerSettingsInput>>>
  emvCaKeys?: InputMaybe<Scalars['String']['input']>
  emvConfig?: InputMaybe<Scalars['String']['input']>
  emvTables?: InputMaybe<Scalars['String']['input']>
  entity?: InputMaybe<EntityDeviceSettingsInput>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  features?: InputMaybe<SiteFeaturesInput>
  geofencing?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  moto?: InputMaybe<MotoSettingsInput>
  name?: InputMaybe<Scalars['String']['input']>
  network?: InputMaybe<NetworkDeviceSettingsInput>
  posSettings?: InputMaybe<PosSettingsInput>
  receipt?: InputMaybe<ReceiptSettingsInput>
  schemes?: InputMaybe<Array<InputMaybe<SchemeSettingsInput>>>
  schemesMoto?: InputMaybe<Array<InputMaybe<SchemeSettingsInput>>>
  screen?: InputMaybe<ScreenDeviceSettingsInput>
  site?: InputMaybe<SiteDeviceSettingsInput>
  siteUuid?: InputMaybe<Scalars['ID']['input']>
  standInRules?: InputMaybe<Array<InputMaybe<StandInRuleInput>>>
  surchargesTaxes?: InputMaybe<SurchargesTaxesSettingsInput>
  terminalConfig?: InputMaybe<Scalars['String']['input']>
  tipping?: InputMaybe<TipSettingsInput>
}

export type DeviceStatusInput = {
  androidBuild?: InputMaybe<Scalars['String']['input']>
  androidDevice?: InputMaybe<Scalars['String']['input']>
  androidKernel?: InputMaybe<Scalars['String']['input']>
  androidModel?: InputMaybe<Scalars['String']['input']>
  androidOs?: InputMaybe<Scalars['String']['input']>
  connectionType?: InputMaybe<Scalars['Int']['input']>
  customerId?: InputMaybe<Scalars['String']['input']>
  customerName?: InputMaybe<Scalars['String']['input']>
  density?: InputMaybe<Scalars['String']['input']>
  densityDpi?: InputMaybe<Scalars['String']['input']>
  emvL1Version?: InputMaybe<Scalars['String']['input']>
  emvL2Version?: InputMaybe<Scalars['String']['input']>
  firmwareVersion?: InputMaybe<Scalars['String']['input']>
  flashAvail?: InputMaybe<Scalars['String']['input']>
  flashTotal?: InputMaybe<Scalars['String']['input']>
  hardwareVersion?: InputMaybe<Scalars['String']['input']>
  heightPixels?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  ksn?: InputMaybe<Scalars['String']['input']>
  model?: InputMaybe<Scalars['String']['input']>
  numAids?: InputMaybe<Scalars['Int']['input']>
  numCapk?: InputMaybe<Scalars['Int']['input']>
  numDukptKeys?: InputMaybe<Scalars['Int']['input']>
  numMasterKeys?: InputMaybe<Scalars['Int']['input']>
  pciFirmwareVersion?: InputMaybe<Scalars['String']['input']>
  pedCurrentTemp?: InputMaybe<Scalars['Int']['input']>
  pedModel?: InputMaybe<Scalars['String']['input']>
  pedStatus?: InputMaybe<Scalars['Int']['input']>
  pedVersion?: InputMaybe<Scalars['String']['input']>
  ramAvail?: InputMaybe<Scalars['String']['input']>
  ramTotal?: InputMaybe<Scalars['String']['input']>
  secureCpuId?: InputMaybe<Scalars['String']['input']>
  securityVersion?: InputMaybe<Scalars['String']['input']>
  serial?: InputMaybe<Scalars['String']['input']>
  simCellDetails?: InputMaybe<Scalars['String']['input']>
  simCountry?: InputMaybe<Scalars['String']['input']>
  simDns1Address?: InputMaybe<Scalars['String']['input']>
  simDns2Address?: InputMaybe<Scalars['String']['input']>
  simGatewayAddress?: InputMaybe<Scalars['String']['input']>
  simIpAddress?: InputMaybe<Scalars['String']['input']>
  simNetwork?: InputMaybe<Scalars['String']['input']>
  simNetworkName?: InputMaybe<Scalars['String']['input']>
  simNetworkType?: InputMaybe<Scalars['String']['input']>
  simOperator?: InputMaybe<Scalars['String']['input']>
  simOperatorName?: InputMaybe<Scalars['String']['input']>
  simRssi?: InputMaybe<Scalars['Int']['input']>
  simSerialNumber?: InputMaybe<Scalars['String']['input']>
  simState?: InputMaybe<Scalars['Int']['input']>
  simSubscriberId?: InputMaybe<Scalars['String']['input']>
  softwareVersion?: InputMaybe<Scalars['String']['input']>
  tcpDhcp?: InputMaybe<Scalars['Boolean']['input']>
  tcpDns1Address?: InputMaybe<Scalars['String']['input']>
  tcpDns2Address?: InputMaybe<Scalars['String']['input']>
  tcpGatewayAddress?: InputMaybe<Scalars['String']['input']>
  tcpIpAddress?: InputMaybe<Scalars['String']['input']>
  tcpMac?: InputMaybe<Scalars['String']['input']>
  tcpMaxTransferSpeeds?: InputMaybe<Scalars['String']['input']>
  tcpTransferSpeeds?: InputMaybe<Scalars['String']['input']>
  timestamp: Scalars['AWSDateTime']['input']
  timestampUtc: Scalars['AWSDateTime']['input']
  widthPixels?: InputMaybe<Scalars['String']['input']>
  wifiAvailableNetworks?: InputMaybe<Scalars['String']['input']>
  wifiBssid?: InputMaybe<Scalars['String']['input']>
  wifiChannel?: InputMaybe<Scalars['Int']['input']>
  wifiDns1Address?: InputMaybe<Scalars['String']['input']>
  wifiDns2Address?: InputMaybe<Scalars['String']['input']>
  wifiFrequency?: InputMaybe<Scalars['String']['input']>
  wifiGatewayAddress?: InputMaybe<Scalars['String']['input']>
  wifiIpAddress?: InputMaybe<Scalars['String']['input']>
  wifiMacAddress?: InputMaybe<Scalars['String']['input']>
  wifiMaxTransferSpeed?: InputMaybe<Scalars['Int']['input']>
  wifiRssi?: InputMaybe<Scalars['Int']['input']>
  wifiSecurityModel?: InputMaybe<Scalars['String']['input']>
  wifiSpeed?: InputMaybe<Scalars['Int']['input']>
  wifiSsid?: InputMaybe<Scalars['String']['input']>
  wifiStandard?: InputMaybe<Scalars['String']['input']>
  wifiState?: InputMaybe<Scalars['Int']['input']>
  wifiTransferSpeed?: InputMaybe<Scalars['Int']['input']>
  xdpi?: InputMaybe<Scalars['String']['input']>
  ydpi?: InputMaybe<Scalars['String']['input']>
}

export type DeviceUuidMobileInput = {
  model: MobileModel
  serial: Scalars['String']['input']
}

export type DeviceUuidMobileResponse = {
  __typename?: 'DeviceUuidMobileResponse'
  id: Scalars['ID']['output']
  model: MobileModel
  serial: Scalars['String']['output']
}

export type DigitalWalletToken = {
  __typename?: 'DigitalWalletToken'
  activatedAt?: Maybe<Scalars['AWSDateTime']['output']>
  cardDisplayName: Scalars['String']['output']
  cardHolderName: Scalars['String']['output']
  customerUuid: Scalars['String']['output']
  debitCard?: Maybe<DebitCardV2>
  debitCardAccountUuid: Scalars['String']['output']
  debitCardUuid: Scalars['String']['output']
  device: DigitalWalletTokenDevice
  deviceUuid?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['String']['output']
  id: Scalars['String']['output']
  maskedPan: Scalars['String']['output']
  requestedAt: Scalars['AWSDateTime']['output']
  status: Scalars['String']['output']
  suspended?: Maybe<DigitalWalletTokenSuspendedDetail>
  terminated?: Maybe<DigitalWalletTokenTerminatedDetail>
  tokenProvider: DigitalWalletTokenTokenProvider
  unsuspended?: Maybe<DigitalWalletTokenUnsuspendedDetail>
}

export type DigitalWalletTokenDevice = {
  __typename?: 'DigitalWalletTokenDevice'
  deviceId?: Maybe<Scalars['String']['output']>
  ipAddress?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
}

export type DigitalWalletTokenSuspendedDetail = {
  __typename?: 'DigitalWalletTokenSuspendedDetail'
  timestamp: Scalars['AWSDateTime']['output']
  zellerInternalUserIdentifier: Scalars['String']['output']
}

export type DigitalWalletTokenTerminatedDetail = {
  __typename?: 'DigitalWalletTokenTerminatedDetail'
  timestamp: Scalars['AWSDateTime']['output']
  userUuid?: Maybe<Scalars['String']['output']>
  zellerInternalUserIdentifier?: Maybe<Scalars['String']['output']>
}

export type DigitalWalletTokenTokenProvider = {
  __typename?: 'DigitalWalletTokenTokenProvider'
  requesterId?: Maybe<Scalars['String']['output']>
  requesterName?: Maybe<Scalars['String']['output']>
  tokenReferenceId?: Maybe<Scalars['String']['output']>
}

export type DigitalWalletTokenUnsuspendedDetail = {
  __typename?: 'DigitalWalletTokenUnsuspendedDetail'
  timestamp: Scalars['AWSDateTime']['output']
  zellerInternalUserIdentifier: Scalars['String']['output']
}

export enum DiscountPinType {
  DISCOUNT_PIN = 'DISCOUNT_PIN',
  SITE_PIN = 'SITE_PIN',
}

export enum DocumentType {
  DRIVING_LICENCE = 'DRIVING_LICENCE',
  MEDICARE_CARD = 'MEDICARE_CARD',
  NO_SECOND_ID = 'NO_SECOND_ID',
  PASSPORT = 'PASSPORT',
}

export type DocumentUploadFiles = {
  consentToViewDocument?: InputMaybe<Scalars['Boolean']['input']>
  documentType: DocumentUploadedType
  fileName: Scalars['String']['input']
}

export type DocumentUploadUrls = {
  __typename?: 'DocumentUploadUrls'
  fileName: Scalars['String']['output']
  uploadUrl: Scalars['String']['output']
}

export enum DocumentUploadedType {
  ASSOCIATION_DOCUMENT = 'ASSOCIATION_DOCUMENT',
  BANK_STATEMENT_DOCUMENT = 'BANK_STATEMENT_DOCUMENT',
  CUSTOMER_SUPPORT_DOCUMENT = 'CUSTOMER_SUPPORT_DOCUMENT',
  ID_DOCUMENT = 'ID_DOCUMENT',
  INVOICE_DOCUMENT = 'INVOICE_DOCUMENT',
  ITEM_IMPORT_DOCUMENT = 'ITEM_IMPORT_DOCUMENT',
  OTHER_DOCUMENT = 'OTHER_DOCUMENT',
  PARTNERSHIP_DOCUMENT = 'PARTNERSHIP_DOCUMENT',
  TRUST_DOCUMENT = 'TRUST_DOCUMENT',
}

export type DocumentVerificationClientPublicKeyInput = {
  clientPublicKey: Scalars['String']['input']
}

export type DocumentVerificationServerKey = {
  __typename?: 'DocumentVerificationServerKey'
  serverPublicKey: Scalars['String']['output']
}

export type Download = {
  __typename?: 'Download'
  downloadLink: Scalars['String']['output']
  expire: Scalars['AWSDateTime']['output']
}

export type DriversLicenceInput = {
  cardNumber: Scalars['String']['input']
  expiry?: InputMaybe<Scalars['AWSDate']['input']>
  number: Scalars['String']['input']
  state: VerifyState
}

export type Ecommerce = {
  __typename?: 'Ecommerce'
  amount: Scalars['Int']['output']
  amountDue: Scalars['Int']['output']
  contact?: Maybe<Contact>
  contactDetails?: Maybe<Scalars['String']['output']>
  contactError?: Maybe<Scalars['String']['output']>
  contactStatus?: Maybe<EcommerceContactStatus>
  contactType?: Maybe<ContactMethod>
  contactUuid: Scalars['String']['output']
  createdTimestamp?: Maybe<Scalars['AWSDateTime']['output']>
  currency: Scalars['String']['output']
  customer?: Maybe<Customer>
  customerUuid?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['String']['output']
  id: Scalars['ID']['output']
  originalContactName: Scalars['String']['output']
  paidAmount: Scalars['Int']['output']
  paymentLink?: Maybe<Scalars['String']['output']>
  reference: Scalars['String']['output']
  siteName: Scalars['String']['output']
  siteUuid: Scalars['ID']['output']
  status: EcommerceStatus
  surcharge?: Maybe<Scalars['Int']['output']>
  taxAmounts?: Maybe<Array<Maybe<TaxAmount>>>
  timestamp: Scalars['AWSDateTime']['output']
  timestampSent?: Maybe<Scalars['AWSDateTime']['output']>
  transaction?: Maybe<Transaction>
  transactionNotes?: Maybe<Scalars['String']['output']>
  transactionUuid?: Maybe<Scalars['ID']['output']>
  type: EcommerceType
}

export type EcommerceConnection = {
  __typename?: 'EcommerceConnection'
  ecommerces: Array<Maybe<Ecommerce>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export enum EcommerceContactStatus {
  FAILED = 'FAILED',
  SENT = 'SENT',
}

export enum EcommerceStatus {
  CANCELLED = 'CANCELLED',
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID',
}

export enum EcommerceType {
  PAY_BY_LINK = 'PAY_BY_LINK',
  VIRTUAL_TERMINAL = 'VIRTUAL_TERMINAL',
}

export type EnabledAccountDetails = {
  __typename?: 'EnabledAccountDetails'
  accountConnectionStatus: AccountConnectionStatus
  id: Scalars['String']['output']
}

export type EnabledTheme = {
  __typename?: 'EnabledTheme'
  xeroThemeId?: Maybe<Scalars['String']['output']>
}

export type EnabledThemeInput = {
  xeroThemeId?: InputMaybe<Scalars['String']['input']>
}

export type Entity = {
  __typename?: 'Entity'
  abn?: Maybe<Scalars['String']['output']>
  acn?: Maybe<Scalars['String']['output']>
  businessAddress?: Maybe<EntityAddress>
  canAcquire?: Maybe<Scalars['Boolean']['output']>
  canAcquireCnp?: Maybe<Scalars['Boolean']['output']>
  canAcquireMobile?: Maybe<Scalars['Boolean']['output']>
  canAcquireMoto?: Maybe<Scalars['Boolean']['output']>
  canAcquireVt?: Maybe<Scalars['Boolean']['output']>
  canCreateAccount?: Maybe<Scalars['Boolean']['output']>
  canCreateCard?: Maybe<Scalars['Boolean']['output']>
  canPayByCard?: Maybe<Scalars['Boolean']['output']>
  canRefund?: Maybe<Scalars['Boolean']['output']>
  canSettle?: Maybe<Scalars['Boolean']['output']>
  canStandIn?: Maybe<Scalars['Boolean']['output']>
  canTransferIn?: Maybe<Scalars['Boolean']['output']>
  canTransferOut?: Maybe<Scalars['Boolean']['output']>
  category?: Maybe<Category>
  categoryGroup?: Maybe<CategoryGroup>
  customerDiscovery?: Maybe<Scalars['String']['output']>
  debitCardAccountUuid?: Maybe<Scalars['ID']['output']>
  depositAccountUuid?: Maybe<Scalars['ID']['output']>
  establishingBusiness?: Maybe<Scalars['Boolean']['output']>
  estimatedAnnualRevenue?: Maybe<Scalars['Int']['output']>
  facebook?: Maybe<Scalars['String']['output']>
  feeRateSettings?: Maybe<FeeRateSettings>
  goodsServicesProvided?: Maybe<Scalars['String']['output']>
  hadDirectDebitFailure?: Maybe<Scalars['Boolean']['output']>
  hadForcedRefund?: Maybe<Scalars['Boolean']['output']>
  hasChargeback?: Maybe<Scalars['Boolean']['output']>
  hasDirectDebitRequest?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  instagram?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  onboardingStatus?: Maybe<OnboardingStatus>
  outstandingTransactionRequirementConfig?: Maybe<OutstandingTransactionRequirementConfig>
  paymentSettings: PaymentSettings
  promotionBalance?: Maybe<Scalars['Int']['output']>
  referralCode?: Maybe<Scalars['String']['output']>
  referralPromotion?: Maybe<EntityPromotion>
  referrals: Array<EntityReferral>
  registeredAddress?: Maybe<EntityAddress>
  regulatorBody?: Maybe<RegulatorBody>
  remitToCard?: Maybe<Scalars['Boolean']['output']>
  /**   additional resolver */
  savingsAccountProduct?: Maybe<EntitySavingsAccountProduct>
  shortId?: Maybe<Scalars['String']['output']>
  termsOfService?: Maybe<EntityTermsOfService>
  tradingName?: Maybe<Scalars['String']['output']>
  transactionMetaData?: Maybe<EntityTransactionMetaData>
  twitter?: Maybe<Scalars['String']['output']>
  type?: Maybe<EntityType>
  website?: Maybe<Scalars['String']['output']>
}

export type EntityAddress = {
  __typename?: 'EntityAddress'
  country?: Maybe<Scalars['String']['output']>
  postcode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street1?: Maybe<Scalars['String']['output']>
  street2?: Maybe<Scalars['String']['output']>
  suburb?: Maybe<Scalars['String']['output']>
}

export type EntityAddressInput = {
  country?: InputMaybe<Scalars['String']['input']>
  postcode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street1?: InputMaybe<Scalars['String']['input']>
  street2?: InputMaybe<Scalars['String']['input']>
  suburb?: InputMaybe<Scalars['String']['input']>
}

export enum EntityBankingMigrationState {
  COMPLETED = 'COMPLETED',
  COMPLETED_WITH_ERROR = 'COMPLETED_WITH_ERROR',
  ERROR = 'ERROR',
  NOT_REQUIRED = 'NOT_REQUIRED',
  REQUEST_STARTED = 'REQUEST_STARTED',
  REQUIRED = 'REQUIRED',
  STARTED = 'STARTED',
}

export enum EntityCategories {
  ADVERTISING = 'ADVERTISING',
  BANK_FEES = 'BANK_FEES',
  CLEANING = 'CLEANING',
  COMMISSION = 'COMMISSION',
  COMPUTER_EQUIPMENT = 'COMPUTER_EQUIPMENT',
  CONSULTING_ACCOUNTING = 'CONSULTING_ACCOUNTING',
  COST_OF_GOODS_SOLD = 'COST_OF_GOODS_SOLD',
  ENTERTAINMENT = 'ENTERTAINMENT',
  FREIGHT_COURIER = 'FREIGHT_COURIER',
  GENERAL_EXPENSES = 'GENERAL_EXPENSES',
  INCOME_TAX_EXPENSE = 'INCOME_TAX_EXPENSE',
  INSURANCE = 'INSURANCE',
  INTEREST_EXPENSE = 'INTEREST_EXPENSE',
  LEGAL_EXPENSES = 'LEGAL_EXPENSES',
  LIGHT_POWER_HEATING = 'LIGHT_POWER_HEATING',
  MOTOR_VEHICLE_EXPENSES = 'MOTOR_VEHICLE_EXPENSES',
  OFFICE_EQUIPMENT = 'OFFICE_EQUIPMENT',
  OFFICE_EXPENSES = 'OFFICE_EXPENSES',
  PRINTING_STATIONERY = 'PRINTING_STATIONERY',
  PURCHASES = 'PURCHASES',
  RENT = 'RENT',
  SUBSCRIPTIONS = 'SUBSCRIPTIONS',
  SUPERANNUATION = 'SUPERANNUATION',
  TELEPHONE_INTERNET = 'TELEPHONE_INTERNET',
  TRAVEL_INTERNATIONAL = 'TRAVEL_INTERNATIONAL',
  TRAVEL_NATIONAL = 'TRAVEL_NATIONAL',
  WAGES_SALARIES = 'WAGES_SALARIES',
}

export type EntityDailyLimit = {
  __typename?: 'EntityDailyLimit'
  id: Scalars['String']['output']
  maximumLimit: Money
}

export type EntityDeviceSettings = {
  __typename?: 'EntityDeviceSettings'
  canAcquire?: Maybe<Scalars['Boolean']['output']>
  canAcquireMobile?: Maybe<Scalars['Boolean']['output']>
  canAcquireMoto?: Maybe<Scalars['Boolean']['output']>
  canRefund?: Maybe<Scalars['Boolean']['output']>
  canStandIn?: Maybe<Scalars['Boolean']['output']>
  cpocPaymentLimits?: Maybe<PaymentLimits>
  motoPaymentLimits?: Maybe<PaymentLimits>
  paymentLimits?: Maybe<PaymentLimits>
}

export type EntityDeviceSettingsInput = {
  canAcquire?: InputMaybe<Scalars['Boolean']['input']>
  canAcquireMobile?: InputMaybe<Scalars['Boolean']['input']>
  canAcquireMoto?: InputMaybe<Scalars['Boolean']['input']>
  canRefund?: InputMaybe<Scalars['Boolean']['input']>
  canStandIn?: InputMaybe<Scalars['Boolean']['input']>
  cpocPaymentLimits?: InputMaybe<PaymentLimitsInput>
  motoPaymentLimits?: InputMaybe<PaymentLimitsInput>
  paymentLimits?: InputMaybe<PaymentLimitsInput>
}

export type EntityDocumentUploadUrls = {
  __typename?: 'EntityDocumentUploadUrls'
  fileName: Scalars['String']['output']
  uploadUrl: Scalars['String']['output']
}

export type EntityInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  acn?: InputMaybe<Scalars['String']['input']>
  businessAddress?: InputMaybe<EntityAddressInput>
  category?: InputMaybe<Category>
  categoryGroup?: InputMaybe<CategoryGroup>
  customerDiscovery?: InputMaybe<Scalars['String']['input']>
  debitCardAccountUuid?: InputMaybe<Scalars['ID']['input']>
  establishingBusiness?: InputMaybe<Scalars['Boolean']['input']>
  estimatedAnnualRevenue?: InputMaybe<Scalars['Int']['input']>
  facebook?: InputMaybe<Scalars['String']['input']>
  goodsServicesProvided?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  instagram?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  onboardingStatus?: InputMaybe<OnboardingStatus>
  outstandingTransactionRequirementConfig?: InputMaybe<OutstandingTransactionRequirementConfigInput>
  registeredAddress?: InputMaybe<EntityAddressInput>
  regulatorBody?: InputMaybe<RegulatorBodyInput>
  remitToCard?: InputMaybe<Scalars['Boolean']['input']>
  tradingName?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<EntityType>
  website?: InputMaybe<Scalars['String']['input']>
}

export type EntityOnboardingDetails = {
  __typename?: 'EntityOnboardingDetails'
  abn?: Maybe<Scalars['String']['output']>
  acn?: Maybe<Scalars['String']['output']>
  businessAddress?: Maybe<EntityAddress>
  category?: Maybe<Category>
  categoryGroup?: Maybe<CategoryGroup>
  customerDiscovery?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['ID']['output']
  establishingBusiness?: Maybe<Scalars['Boolean']['output']>
  estimatedAnnualRevenue?: Maybe<Scalars['Int']['output']>
  facebook?: Maybe<Scalars['String']['output']>
  flowFlags?: Maybe<Scalars['String']['output']>
  goodsServicesProvided?: Maybe<Scalars['String']['output']>
  governmentRole?: Maybe<Scalars['String']['output']>
  hasNoTradingName?: Maybe<Scalars['Boolean']['output']>
  helperFields?: Maybe<HelperFields>
  initialCustomerData?: Maybe<InitialCustomerData>
  instagram?: Maybe<Scalars['String']['output']>
  isAfterFullSearch?: Maybe<Scalars['Boolean']['output']>
  kycInitialData?: Maybe<KYCInitialData>
  lastCheckPoint?: Maybe<Scalars['String']['output']>
  lastRoute?: Maybe<Scalars['String']['output']>
  members?: Maybe<Array<Maybe<EntitySearchMemberOnboarding>>>
  membersFilters?: Maybe<MembersFilters>
  name?: Maybe<Scalars['String']['output']>
  onboardingFlowType?: Maybe<OnboardingFlowType>
  registeredAddress?: Maybe<EntityAddress>
  regulatorBody?: Maybe<RegulatorBody>
  tradingName?: Maybe<Scalars['String']['output']>
  twitter?: Maybe<Scalars['String']['output']>
  type?: Maybe<EntityType>
  uploadedFileNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  website?: Maybe<Scalars['String']['output']>
}

export type EntityOnboardingDetailsInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  acn?: InputMaybe<Scalars['String']['input']>
  businessAddress?: InputMaybe<EntityAddressInput>
  category?: InputMaybe<Category>
  categoryGroup?: InputMaybe<CategoryGroup>
  customerDiscovery?: InputMaybe<Scalars['String']['input']>
  entityUuid: Scalars['ID']['input']
  establishingBusiness?: InputMaybe<Scalars['Boolean']['input']>
  estimatedAnnualRevenue?: InputMaybe<Scalars['Int']['input']>
  facebook?: InputMaybe<Scalars['String']['input']>
  flowFlags?: InputMaybe<Scalars['String']['input']>
  goodsServicesProvided?: InputMaybe<Scalars['String']['input']>
  governmentRole?: InputMaybe<Scalars['String']['input']>
  hasNoTradingName?: InputMaybe<Scalars['Boolean']['input']>
  helperFields?: InputMaybe<HelperFieldsInput>
  initialCustomerData?: InputMaybe<InitialCustomerDataInput>
  instagram?: InputMaybe<Scalars['String']['input']>
  isAfterFullSearch?: InputMaybe<Scalars['Boolean']['input']>
  kycInitialData?: InputMaybe<KYCInitialDataInput>
  lastCheckPoint?: InputMaybe<Scalars['String']['input']>
  lastRoute?: InputMaybe<Scalars['String']['input']>
  members?: InputMaybe<Array<InputMaybe<EntitySearchMemberOnboardingInput>>>
  membersFilters?: InputMaybe<MembersFiltersInput>
  name?: InputMaybe<Scalars['String']['input']>
  onboardingFlowType?: InputMaybe<OnboardingFlowType>
  registeredAddress?: InputMaybe<EntityAddressInput>
  regulatorBody?: InputMaybe<RegulatorBodyInput>
  tradingName?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<EntityType>
  uploadedFileNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  website?: InputMaybe<Scalars['String']['input']>
}

export type EntityOnboardingErrorsInput = {
  data: Scalars['String']['input']
  errorMessage: Scalars['String']['input']
  errorType: Scalars['String']['input']
  onboardingStatus: OnboardingStatus
  timestamp: Scalars['AWSDateTime']['input']
}

export enum EntityOnboardingResult {
  COMPLETED = 'COMPLETED',
  IN_REVIEW = 'IN_REVIEW',
  MANUAL_ACTIVATION = 'MANUAL_ACTIVATION',
  MORE_INFO_REQUIRED = 'MORE_INFO_REQUIRED',
}

export type EntityOnboardingResults = {
  __typename?: 'EntityOnboardingResults'
  account?: Maybe<Scalars['String']['output']>
  bsb?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['ID']['output']
  result?: Maybe<EntityOnboardingResult>
}

export type EntityPromotion = {
  __typename?: 'EntityPromotion'
  balance: Scalars['Int']['output']
  expiry?: Maybe<Scalars['AWSDateTime']['output']>
}

export type EntityReferral = {
  __typename?: 'EntityReferral'
  createdAt: Scalars['AWSDateTime']['output']
  credit: Scalars['Int']['output']
  creditAppliedAt?: Maybe<Scalars['AWSDateTime']['output']>
  referredUuid: Scalars['ID']['output']
}

export type EntitySavingsAccountProduct = {
  __typename?: 'EntitySavingsAccountProduct'
  baseInterestRate?: Maybe<Scalars['String']['output']>
  bonusInterestRate?: Maybe<Scalars['String']['output']>
  bonusLengthInDays?: Maybe<Scalars['String']['output']>
  effectiveInterestRate?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['ID']['output']>
  interestThreshold?: Maybe<Money>
  productType?: Maybe<SavingsAccountProductType>
}

export type EntitySearchMember = {
  __typename?: 'EntitySearchMember'
  abn?: Maybe<Scalars['String']['output']>
  address?: Maybe<EntitySearchMemberAddress>
  beneficialOwner?: Maybe<Scalars['Boolean']['output']>
  beneficialOwnerAlt?: Maybe<Scalars['Boolean']['output']>
  beneficiary?: Maybe<Scalars['Boolean']['output']>
  ceo?: Maybe<Scalars['Boolean']['output']>
  chair?: Maybe<Scalars['Boolean']['output']>
  companyTrustName?: Maybe<Scalars['String']['output']>
  director?: Maybe<Scalars['Boolean']['output']>
  dob?: Maybe<Scalars['AWSDate']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  lastname?: Maybe<Scalars['String']['output']>
  middlename?: Maybe<Scalars['String']['output']>
  partner?: Maybe<Scalars['Boolean']['output']>
  secretary?: Maybe<Scalars['Boolean']['output']>
  settlor?: Maybe<Scalars['Boolean']['output']>
  shareholder?: Maybe<Scalars['Boolean']['output']>
  treasurer?: Maybe<Scalars['Boolean']['output']>
  trustee?: Maybe<Scalars['Boolean']['output']>
  type?: Maybe<EntityType>
}

export type EntitySearchMemberAddress = {
  __typename?: 'EntitySearchMemberAddress'
  country?: Maybe<Scalars['String']['output']>
  postcode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  suburb?: Maybe<Scalars['String']['output']>
}

export type EntitySearchMemberAddressInput = {
  country?: InputMaybe<Scalars['String']['input']>
  postcode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  suburb?: InputMaybe<Scalars['String']['input']>
}

export type EntitySearchMemberInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  address?: InputMaybe<EntitySearchMemberAddressInput>
  beneficialOwner?: InputMaybe<Scalars['Boolean']['input']>
  beneficialOwnerAlt?: InputMaybe<Scalars['Boolean']['input']>
  beneficiary?: InputMaybe<Scalars['Boolean']['input']>
  ceo?: InputMaybe<Scalars['Boolean']['input']>
  chair?: InputMaybe<Scalars['Boolean']['input']>
  companyTrustName?: InputMaybe<Scalars['String']['input']>
  director?: InputMaybe<Scalars['Boolean']['input']>
  dob?: InputMaybe<Scalars['AWSDate']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  middlename?: InputMaybe<Scalars['String']['input']>
  partner?: InputMaybe<Scalars['Boolean']['input']>
  secretary?: InputMaybe<Scalars['Boolean']['input']>
  settlor?: InputMaybe<Scalars['Boolean']['input']>
  shareholder?: InputMaybe<Scalars['Boolean']['input']>
  treasurer?: InputMaybe<Scalars['Boolean']['input']>
  trustee?: InputMaybe<Scalars['Boolean']['input']>
  type?: InputMaybe<EntityType>
}

export type EntitySearchMemberOnboarding = {
  __typename?: 'EntitySearchMemberOnboarding'
  abn?: Maybe<Scalars['String']['output']>
  acn?: Maybe<Scalars['String']['output']>
  address?: Maybe<EntitySearchMemberAddress>
  beneficialOwner?: Maybe<Scalars['Boolean']['output']>
  beneficialOwnerAlt?: Maybe<Scalars['Boolean']['output']>
  beneficiary?: Maybe<Scalars['Boolean']['output']>
  ceo?: Maybe<Scalars['Boolean']['output']>
  chair?: Maybe<Scalars['Boolean']['output']>
  companyProfileData?: Maybe<CompanyProfileData>
  companyTrustName?: Maybe<Scalars['String']['output']>
  director?: Maybe<Scalars['Boolean']['output']>
  dob?: Maybe<Scalars['AWSDate']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  isCurrentUser?: Maybe<Scalars['Boolean']['output']>
  lastname?: Maybe<Scalars['String']['output']>
  middlename?: Maybe<Scalars['String']['output']>
  partner?: Maybe<Scalars['Boolean']['output']>
  secretary?: Maybe<Scalars['Boolean']['output']>
  settlor?: Maybe<Scalars['Boolean']['output']>
  shareholder?: Maybe<Scalars['Boolean']['output']>
  temporaryId?: Maybe<Scalars['String']['output']>
  treasurer?: Maybe<Scalars['Boolean']['output']>
  trustee?: Maybe<Scalars['Boolean']['output']>
  type?: Maybe<EntityType>
}

export type EntitySearchMemberOnboardingInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  acn?: InputMaybe<Scalars['String']['input']>
  address?: InputMaybe<EntitySearchMemberAddressInput>
  beneficialOwner?: InputMaybe<Scalars['Boolean']['input']>
  beneficialOwnerAlt?: InputMaybe<Scalars['Boolean']['input']>
  beneficiary?: InputMaybe<Scalars['Boolean']['input']>
  ceo?: InputMaybe<Scalars['Boolean']['input']>
  chair?: InputMaybe<Scalars['Boolean']['input']>
  companyProfileData?: InputMaybe<CompanyProfileData>
  companyTrustName?: InputMaybe<Scalars['String']['input']>
  director?: InputMaybe<Scalars['Boolean']['input']>
  dob?: InputMaybe<Scalars['AWSDate']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  isCurrentUser?: InputMaybe<Scalars['Boolean']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  middlename?: InputMaybe<Scalars['String']['input']>
  partner?: InputMaybe<Scalars['Boolean']['input']>
  secretary?: InputMaybe<Scalars['Boolean']['input']>
  settlor?: InputMaybe<Scalars['Boolean']['input']>
  shareholder?: InputMaybe<Scalars['Boolean']['input']>
  temporaryId?: InputMaybe<Scalars['String']['input']>
  treasurer?: InputMaybe<Scalars['Boolean']['input']>
  trustee?: InputMaybe<Scalars['Boolean']['input']>
  type?: InputMaybe<EntityType>
}

export type EntitySearchResultsFull = {
  __typename?: 'EntitySearchResultsFull'
  acn?: Maybe<Scalars['String']['output']>
  businessAddress?: Maybe<EntityAddress>
  entityUuid: Scalars['ID']['output']
  error?: Maybe<Scalars['String']['output']>
  found?: Maybe<Scalars['Boolean']['output']>
  members?: Maybe<Array<EntitySearchMember>>
  registeredAddress?: Maybe<EntityAddress>
}

export type EntitySearchResultsFullInput = {
  acn?: InputMaybe<Scalars['String']['input']>
  businessAddress?: InputMaybe<EntityAddressInput>
  entityUuid: Scalars['ID']['input']
  error?: InputMaybe<Scalars['String']['input']>
  found?: InputMaybe<Scalars['Boolean']['input']>
  members?: InputMaybe<Array<EntitySearchMemberInput>>
  registeredAddress?: InputMaybe<EntityAddressInput>
}

export type EntitySearchResultsInitial = {
  __typename?: 'EntitySearchResultsInitial'
  abn?: Maybe<Scalars['String']['output']>
  acn?: Maybe<Scalars['String']['output']>
  businessIdentifier: Scalars['String']['output']
  error?: Maybe<Scalars['String']['output']>
  found: Scalars['Boolean']['output']
  name?: Maybe<Scalars['String']['output']>
  type?: Maybe<EntityType>
}

export type EntitySearchResultsInitialInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  acn?: InputMaybe<Scalars['String']['input']>
  businessIdentifier: Scalars['String']['input']
  error?: InputMaybe<Scalars['String']['input']>
  found: Scalars['Boolean']['input']
  name?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<EntityType>
}

export type EntitySubcategoryInput = {
  category: EntityCategories
  subcategory: Scalars['String']['input']
}

export type EntityTermsOfService = {
  __typename?: 'EntityTermsOfService'
  ezta?: Maybe<EntityTermsOfServiceAcceptance>
}

export type EntityTermsOfServiceAcceptance = {
  __typename?: 'EntityTermsOfServiceAcceptance'
  accepted: Scalars['Boolean']['output']
}

export type EntityTransactionMetaData = {
  __typename?: 'EntityTransactionMetaData'
  firstTransactionTimestamp?: Maybe<Scalars['AWSDateTime']['output']>
  firstTransactionUuid?: Maybe<Scalars['String']['output']>
  yetToMakeTransaction: Scalars['Boolean']['output']
}

export enum EntityType {
  ASSOCIATION = 'ASSOCIATION',
  ASSOCIATION_UNINCORPORATED = 'ASSOCIATION_UNINCORPORATED',
  BENEFICIARY_CLASS = 'BENEFICIARY_CLASS',
  COMPANY = 'COMPANY',
  GOVERNMENT = 'GOVERNMENT',
  INDIVIDUAL = 'INDIVIDUAL',
  OTHER = 'OTHER',
  PARTNERSHIP = 'PARTNERSHIP',
  TRUST = 'TRUST',
}

export enum EztaTermsOfServiceError {
  DEPOSIT_ACCOUNT_REQUIRED = 'DEPOSIT_ACCOUNT_REQUIRED',
  HAS_SUSPENDED_ACCOUNTS = 'HAS_SUSPENDED_ACCOUNTS',
  OTHER = 'OTHER',
  PAYMENT_INSTRUMENT_REQUIRED = 'PAYMENT_INSTRUMENT_REQUIRED',
  SIM_BILLING_ACCOUNT_REQUIRED = 'SIM_BILLING_ACCOUNT_REQUIRED',
  TOS_ALREADY_ACCEPTED = 'TOS_ALREADY_ACCEPTED',
  TOS_NOT_CHANGED = 'TOS_NOT_CHANGED',
}

export type FeeRateSettings = {
  __typename?: 'FeeRateSettings'
  feeFixed?: Maybe<Scalars['Int']['output']>
  /** @deprecated Use subtypes instead */
  feeFixedCnp?: Maybe<Scalars['Int']['output']>
  feeFixedCpoc?: Maybe<Scalars['Int']['output']>
  feeFixedIntlPbl?: Maybe<Scalars['Int']['output']>
  feeFixedIntlXinv?: Maybe<Scalars['Int']['output']>
  feeFixedIntlZinv?: Maybe<Scalars['Int']['output']>
  feeFixedMoto?: Maybe<Scalars['Int']['output']>
  feeFixedPbl?: Maybe<Scalars['Int']['output']>
  feeFixedVt?: Maybe<Scalars['Int']['output']>
  feeFixedXinv?: Maybe<Scalars['Int']['output']>
  feeFixedZinv?: Maybe<Scalars['Int']['output']>
  feePercent?: Maybe<Scalars['Int']['output']>
  /** @deprecated Use subtypes instead */
  feePercentCnp?: Maybe<Scalars['Int']['output']>
  feePercentCpoc?: Maybe<Scalars['Int']['output']>
  feePercentIntlPbl?: Maybe<Scalars['Int']['output']>
  feePercentIntlXinv?: Maybe<Scalars['Int']['output']>
  feePercentIntlZinv?: Maybe<Scalars['Int']['output']>
  feePercentMoto?: Maybe<Scalars['Int']['output']>
  feePercentPbl?: Maybe<Scalars['Int']['output']>
  feePercentVt?: Maybe<Scalars['Int']['output']>
  feePercentXinv?: Maybe<Scalars['Int']['output']>
  feePercentZinv?: Maybe<Scalars['Int']['output']>
}

export type FeesSurcharge = {
  __typename?: 'FeesSurcharge'
  feePercent?: Maybe<Scalars['Int']['output']>
  surchargeEnabled?: Maybe<Scalars['Boolean']['output']>
  surchargeFullFees?: Maybe<Scalars['Boolean']['output']>
  surchargePercent?: Maybe<Scalars['Int']['output']>
}

export type FeesSurchargeFixed = {
  __typename?: 'FeesSurchargeFixed'
  feeFixed?: Maybe<Scalars['Int']['output']>
  feePercent?: Maybe<Scalars['Int']['output']>
  surchargeEnabled?: Maybe<Scalars['Boolean']['output']>
  surchargeFullFees?: Maybe<Scalars['Boolean']['output']>
  surchargePercent?: Maybe<Scalars['Int']['output']>
}

export type FeesSurchargeFixedInput = {
  feeFixed?: InputMaybe<Scalars['Int']['input']>
  feePercent?: InputMaybe<Scalars['Int']['input']>
  surchargeEnabled?: InputMaybe<Scalars['Boolean']['input']>
  surchargeFullFees?: InputMaybe<Scalars['Boolean']['input']>
  surchargePercent?: InputMaybe<Scalars['Int']['input']>
}

export type FeesSurchargeFixedIntl = {
  __typename?: 'FeesSurchargeFixedIntl'
  feeFixed?: Maybe<Scalars['Int']['output']>
  feeFixedIntl?: Maybe<Scalars['Int']['output']>
  feePercent?: Maybe<Scalars['Int']['output']>
  feePercentIntl?: Maybe<Scalars['Int']['output']>
  surchargeEnabled?: Maybe<Scalars['Boolean']['output']>
  surchargeFullFees?: Maybe<Scalars['Boolean']['output']>
  surchargePercent?: Maybe<Scalars['Int']['output']>
}

export type FeesSurchargeFixedIntlInput = {
  feeFixed?: InputMaybe<Scalars['Int']['input']>
  feeFixedIntl?: InputMaybe<Scalars['Int']['input']>
  feePercent?: InputMaybe<Scalars['Int']['input']>
  feePercentIntl?: InputMaybe<Scalars['Int']['input']>
  surchargeEnabled?: InputMaybe<Scalars['Boolean']['input']>
  surchargeFullFees?: InputMaybe<Scalars['Boolean']['input']>
  surchargePercent?: InputMaybe<Scalars['Int']['input']>
}

export type FileColumnMapping = {
  category: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  gst: Scalars['String']['input']
  name: Scalars['String']['input']
  price: Scalars['String']['input']
  referenceNumber?: InputMaybe<Scalars['String']['input']>
  sku?: InputMaybe<Scalars['String']['input']>
}

export type FileUploadUrl = {
  __typename?: 'FileUploadUrl'
  expire: Scalars['AWSDateTime']['output']
  fileName: Scalars['String']['output']
  uploadUrl: Scalars['String']['output']
}

export type FinaliseCustomerKYCResult = {
  __typename?: 'FinaliseCustomerKYCResult'
  result: CustomerKYCResult
  uploadDocument?: Maybe<Array<OnboardingUploadDocumentType>>
}

export type ForcedLogoff = {
  __typename?: 'ForcedLogoff'
  customerUuid: Scalars['ID']['output']
  reason: Scalars['String']['output']
}

export type FundsTransfer = {
  __typename?: 'FundsTransfer'
  amount?: Maybe<Money>
  endDate?: Maybe<Scalars['AWSDateTime']['output']>
  frequency?: Maybe<FundsTransferFrequency>
  frequencyUnit?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['ID']['output']>
  /**   Map to recipientUuid or Account details if payeeType is EXTERNAL */
  payeeAccountUuid?: Maybe<Scalars['ID']['output']>
  payeeReference?: Maybe<Scalars['String']['output']>
  payeeType?: Maybe<FundsTransferPayeeType>
  payerAccountUuid?: Maybe<Scalars['ID']['output']>
  payerReference?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['AWSDateTime']['output']>
  status?: Maybe<ScheduledTransferStatus>
}

export enum FundsTransferFrequency {
  DAILY = 'DAILY',
  IMMEDIATE = 'IMMEDIATE',
  MONTHLY = 'MONTHLY',
  ONE_OFF = 'ONE_OFF',
  WEEKLY = 'WEEKLY',
}

export type FundsTransferInput = {
  amount: MoneyInput
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  forceDeOut?: InputMaybe<Scalars['Boolean']['input']>
  frequency?: InputMaybe<FundsTransferFrequency>
  frequencyUnit?: InputMaybe<Scalars['Int']['input']>
  /**   Map to recipientUuid or Account details if payeeType is EXTERNAL */
  payeeAccountUuid: Scalars['ID']['input']
  payeeReference?: InputMaybe<Scalars['String']['input']>
  payeeType?: InputMaybe<FundsTransferPayeeType>
  payerAccountUuid: Scalars['ID']['input']
  payerReference?: InputMaybe<Scalars['String']['input']>
  /**   Required for payeeType of EXTERNAL */
  paymentInstrumentType?: InputMaybe<PaymentInstrumentType>
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export enum FundsTransferPayeeType {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export type GenerateBVCheckTokenResponse = {
  __typename?: 'GenerateBVCheckTokenResponse'
  token?: Maybe<Scalars['String']['output']>
}

export type GetBankingAccountStatementsInput = {
  debitCardAccountUuid: Scalars['ID']['input']
  statementType: AccountStatementType
}

export type GetCatalogAttributeSetsInput = {
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
}

export type GetCatalogCategoriesInput = {
  filter?: InputMaybe<CatalogCategoryFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<CatalogCategorySortInput>
}

export type GetCatalogCategoryInput = {
  categoryUuid: Scalars['ID']['input']
}

export type GetCatalogDiscountsInput = {
  filter?: InputMaybe<CatalogDiscountFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
}

export type GetCatalogImageUploadInput = {
  fileName: Scalars['String']['input']
  type: CatalogImageType
}

export type GetCatalogItemInput = {
  itemUuid: Scalars['ID']['input']
}

export type GetCatalogItemsInput = {
  filter?: InputMaybe<CatalogItemFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<CatalogItemSortInput>
}

export type GetCatalogItemsToReassignReportingCategoryInput = {
  categoryUuid: Scalars['String']['input']
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
}

export type GetCatalogItemsWithOneCategoryInput = {
  categoryUuid: Scalars['String']['input']
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
}

export type GetCatalogModifierSetInput = {
  modifierSetUuid: Scalars['ID']['input']
}

export type GetCatalogModifierSetsInput = {
  filter?: InputMaybe<CatalogModifierSetFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
}

export type GetCategoriesFilterInput = {
  idFilter?: InputMaybe<GetCategoryFilterInput>
  name?: InputMaybe<Scalars['String']['input']>
  textSearchFilter?: InputMaybe<Scalars['String']['input']>
}

export enum GetCategoriesSortColumnName {
  ItemsNumber = 'ItemsNumber',
  Name = 'Name',
}

export type GetCategoriesSortInput = {
  ascending?: InputMaybe<Scalars['Boolean']['input']>
  columnName?: InputMaybe<GetCategoriesSortColumnName>
}

export type GetCategoryFilterInput = {
  categoryUuids?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type GetInvoicesAmountFilterInput = {
  columnName?: InputMaybe<Scalars['String']['input']>
  from?: InputMaybe<Scalars['String']['input']>
  to?: InputMaybe<Scalars['String']['input']>
}

export type GetInvoicesByContactInput = {
  contactUuid: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  nextToken?: InputMaybe<Scalars['String']['input']>
}

export type GetInvoicesDateFilterInput = {
  columnName?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['Int']['input']>
  startDate?: InputMaybe<Scalars['Int']['input']>
}

export type GetInvoicesFilterInput = {
  amountFilter?: InputMaybe<GetInvoicesAmountFilterInput>
  dateFilter?: InputMaybe<GetInvoicesDateFilterInput>
  payerContactUuidFilter?: InputMaybe<Scalars['String']['input']>
  statusFilter?: InputMaybe<GetInvoicesStatusFilterInput>
  textSearchFilter?: InputMaybe<Scalars['String']['input']>
}

export type GetInvoicesInput = {
  filter?: InputMaybe<GetInvoicesFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  nextToken?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<GetInvoicesSortInput>
}

export type GetInvoicesSortInput = {
  ascending?: InputMaybe<Scalars['Boolean']['input']>
  columnName?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type GetInvoicesStatusFilterInput = {
  values?: InputMaybe<Array<InvoiceStatus>>
}

export type GetItemFilterInput = {
  categoryUuid?: InputMaybe<Scalars['ID']['input']>
  categoryUuidFilter?: InputMaybe<GetCategoryFilterInput>
  itemTypesFilter?: InputMaybe<Array<CatalogItemType>>
  name?: InputMaybe<Scalars['String']['input']>
  priceFilter?: InputMaybe<GetItemsPriceFilterInput>
  /**   these 2 fields are no longer used, keep it for FE for backwards compatible */
  recentlyUsed?: InputMaybe<Scalars['Boolean']['input']>
  textSearchFilter?: InputMaybe<Scalars['String']['input']>
}

export enum GetItemsPriceFilterColumnName {
  price = 'price',
}

export type GetItemsPriceFilterInput = {
  columnName?: InputMaybe<GetItemsPriceFilterColumnName>
  from?: InputMaybe<Scalars['Int']['input']>
  to?: InputMaybe<Scalars['Int']['input']>
}

export enum GetItemsSortColumnName {
  Name = 'Name',
  Price = 'Price',
}

export type GetItemsSortInput = {
  ascending?: InputMaybe<Scalars['Boolean']['input']>
  columnName?: InputMaybe<GetItemsSortColumnName>
}

export type GetNotificationsFilterInput = {
  textSearchFilter?: InputMaybe<Scalars['String']['input']>
}

export type GetNotificationsInput = {
  filter?: InputMaybe<GetNotificationsFilterInput>
  limit?: InputMaybe<Scalars['Int']['input']>
  nextToken?: InputMaybe<Scalars['String']['input']>
}

export type GetOrdersFilterInput = {
  siteUuid?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<OrderStatus>
}

export type GetOrdersInput = {
  filter?: InputMaybe<GetOrdersFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
}

export type HelperFields = {
  __typename?: 'HelperFields'
  hasNoPlaceOfBusiness?: Maybe<Scalars['Boolean']['output']>
  isNotRegulated?: Maybe<Scalars['Boolean']['output']>
  settledSum?: Maybe<SettledSumValue>
  trustee?: Maybe<TrusteeType>
}

export type HelperFieldsInput = {
  hasNoPlaceOfBusiness?: InputMaybe<Scalars['Boolean']['input']>
  isNotRegulated?: InputMaybe<Scalars['Boolean']['input']>
  settledSum?: InputMaybe<SettledSumValue>
  trustee?: InputMaybe<TrusteeType>
}

export type HlLocation = {
  __typename?: 'HlLocation'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  number: Scalars['String']['output']
}

export type HlPosConfiguration = {
  __typename?: 'HlPosConfiguration'
  clientId: Scalars['String']['output']
  organisationId: Scalars['String']['output']
  organisationName?: Maybe<Scalars['String']['output']>
  venues: Array<Maybe<HlVenue>>
}

export type HlPosPairConfig = {
  locationId?: InputMaybe<Scalars['String']['input']>
  siteUuid: Scalars['ID']['input']
  stationId?: InputMaybe<Scalars['String']['input']>
  venueId: Scalars['String']['input']
}

export type HlPosPairConfiguration = {
  __typename?: 'HlPosPairConfiguration'
  locationId?: Maybe<Scalars['String']['output']>
  siteUuid: Scalars['ID']['output']
  stationId?: Maybe<Scalars['String']['output']>
  venueId: Scalars['String']['output']
}

export type HlSettings = {
  __typename?: 'HlSettings'
  billingSummary: Scalars['Boolean']['output']
}

export type HlSettingsInput = {
  billingSummary?: InputMaybe<Scalars['Boolean']['input']>
}

export type HlVenue = {
  __typename?: 'HlVenue'
  id: Scalars['String']['output']
  locations?: Maybe<Array<HlLocation>>
  name: Scalars['String']['output']
}

export type Icon = {
  __typename?: 'Icon'
  animation?: Maybe<Scalars['String']['output']>
  colour?: Maybe<Scalars['String']['output']>
  /**   Unused by contacts, use images */
  image?: Maybe<Scalars['String']['output']>
  images?: Maybe<Array<Image>>
  letter?: Maybe<Scalars['String']['output']>
}

export type IconEventInput = {
  animation?: InputMaybe<Scalars['String']['input']>
  colour?: InputMaybe<Scalars['String']['input']>
  image?: InputMaybe<Scalars['String']['input']>
  images?: InputMaybe<Array<ImageInput>>
  letter?: InputMaybe<Scalars['String']['input']>
}

export type IconInput = {
  colour?: InputMaybe<Scalars['String']['input']>
  /**   Unused by contacts, use getContactImageUploadForm */
  image?: InputMaybe<Scalars['String']['input']>
  letter?: InputMaybe<Scalars['String']['input']>
}

export type IdvAttempts = {
  __typename?: 'IdvAttempts'
  driversLicence?: Maybe<Scalars['Int']['output']>
  medicareCard?: Maybe<Scalars['Int']['output']>
  passport?: Maybe<Scalars['Int']['output']>
}

export type Image = {
  __typename?: 'Image'
  size: ImageSize
  url: Scalars['String']['output']
}

export type ImageInput = {
  size: ImageSize
  url: Scalars['String']['input']
}

export enum ImageSize {
  Large = 'Large',
  Medium = 'Medium',
  Native = 'Native',
  Small = 'Small',
  Thumbnail = 'Thumbnail',
}

export type InitSecureSessionRequestInput = {
  clientPublicKey: Scalars['String']['input']
}

export type InitialCustomerData = {
  __typename?: 'InitialCustomerData'
  abn?: Maybe<Scalars['String']['output']>
  acn?: Maybe<Scalars['String']['output']>
  companyProfileData?: Maybe<CompanyProfileData>
  companyTrustName?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  dob?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  middleName?: Maybe<Scalars['String']['output']>
  postcode?: Maybe<Scalars['String']['output']>
  roles?: Maybe<Array<Scalars['String']['output']>>
  state?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  suburb?: Maybe<Scalars['String']['output']>
  temporaryId?: Maybe<Scalars['String']['output']>
}

export type InitialCustomerDataInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  acn?: InputMaybe<Scalars['String']['input']>
  companyProfileData?: InputMaybe<CompanyProfileData>
  companyTrustName?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  dob?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  middleName?: InputMaybe<Scalars['String']['input']>
  postcode?: InputMaybe<Scalars['String']['input']>
  roles?: InputMaybe<Array<Scalars['String']['input']>>
  state?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  suburb?: InputMaybe<Scalars['String']['input']>
  temporaryId?: InputMaybe<Scalars['String']['input']>
}

export type InitiateSavingsAccountSecureSession = {
  __typename?: 'InitiateSavingsAccountSecureSession'
  secureSessionId: Scalars['String']['output']
  serverPublicKey: Scalars['String']['output']
}

export type IntFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  contains?: InputMaybe<Scalars['Int']['input']>
  eq?: InputMaybe<Scalars['Int']['input']>
  ge?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  le?: InputMaybe<Scalars['Int']['input']>
  lt?: InputMaybe<Scalars['Int']['input']>
  ne?: InputMaybe<Scalars['Int']['input']>
  notContains?: InputMaybe<Scalars['Int']['input']>
}

export type InterestTaxSummary = {
  __typename?: 'InterestTaxSummary'
  downloadLink: Scalars['String']['output']
  endDate: Scalars['AWSDateTime']['output']
  expiry: Scalars['AWSDateTime']['output']
  reissued: Scalars['Boolean']['output']
  startDate: Scalars['AWSDateTime']['output']
}

export type InvalidBpayPaymentReason = {
  __typename?: 'InvalidBpayPaymentReason'
  errorDescription: Scalars['String']['output']
  responseCode: Scalars['String']['output']
}

export type InvitedBy = {
  __typename?: 'InvitedBy'
  customerUuid: Scalars['ID']['output']
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  middleName?: Maybe<Scalars['String']['output']>
}

export type Invoice = {
  __typename?: 'Invoice'
  activities?: Maybe<Array<InvoiceActivity>>
  createdTime?: Maybe<Scalars['Int']['output']>
  customer?: Maybe<InvoiceCustomer>
  discount?: Maybe<InvoiceDiscount>
  dueAmount?: Maybe<Scalars['String']['output']>
  dueDate?: Maybe<Scalars['AWSDateTime']['output']>
  email?: Maybe<InvoiceEmail>
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  items?: Maybe<Array<InvoiceItem>>
  itemsApplyTax: Scalars['Boolean']['output']
  itemsTaxInclusive: Scalars['Boolean']['output']
  message?: Maybe<Scalars['String']['output']>
  milestones?: Maybe<Array<InvoiceMilestone>>
  notes?: Maybe<Scalars['String']['output']>
  paidAmount?: Maybe<Scalars['String']['output']>
  paidTime?: Maybe<Scalars['Int']['output']>
  payerContactName?: Maybe<Scalars['String']['output']>
  paymentLink?: Maybe<Scalars['String']['output']>
  payments?: Maybe<Array<InvoicePayment>>
  referenceNumber: Scalars['String']['output']
  requiredEmailUpdateBeforeSend?: Maybe<Array<Scalars['String']['output']>>
  requiredPhoneUpdateBeforeSend?: Maybe<Array<Scalars['String']['output']>>
  sendSchedule?: Maybe<InvoiceSendSchedule>
  sentTime?: Maybe<Scalars['Int']['output']>
  siteSettings?: Maybe<ZellerInvoiceSite>
  sms?: Maybe<InvoiceSMS>
  startDate?: Maybe<Scalars['AWSDateTime']['output']>
  status: InvoiceStatus
  subtotalAmount?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  totalAmount?: Maybe<Scalars['String']['output']>
  totalDiscount?: Maybe<Scalars['String']['output']>
  totalGst?: Maybe<Scalars['String']['output']>
  totalSurcharge?: Maybe<Scalars['String']['output']>
}

export enum InvoiceAction {
  CANCELLED = 'CANCELLED',
  CNP_PAYMENT = 'CNP_PAYMENT',
  CREATE = 'CREATE',
  MANUAL_PAYMENT = 'MANUAL_PAYMENT',
  PAYMENT_DUE = 'PAYMENT_DUE',
  RE_SEND_INVOICE = 'RE_SEND_INVOICE',
  SEND_AUTO_REMINDER = 'SEND_AUTO_REMINDER',
  SEND_EMAIL_INVOICE = 'SEND_EMAIL_INVOICE',
  SEND_INVOICE = 'SEND_INVOICE',
  SEND_MANUAL_REMINDER = 'SEND_MANUAL_REMINDER',
  SEND_SCHEDULED_INVOICE = 'SEND_SCHEDULED_INVOICE',
  SEND_SMS_INVOICE = 'SEND_SMS_INVOICE',
  UPDATE = 'UPDATE',
}

export type InvoiceActivity = {
  __typename?: 'InvoiceActivity'
  balance?: Maybe<Scalars['String']['output']>
  cnpTxnRefNum?: Maybe<Scalars['String']['output']>
  completedTime?: Maybe<Scalars['AWSDateTime']['output']>
  contactName?: Maybe<Scalars['String']['output']>
  contactUuid?: Maybe<Scalars['ID']['output']>
  dueDate?: Maybe<Scalars['AWSDateTime']['output']>
  entityUuid?: Maybe<Scalars['ID']['output']>
  failureReason?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  invoiceEmailDeliveryResult?: Maybe<InvoiceDeliveryResult>
  invoiceSmsDeliveryResult?: Maybe<InvoiceDeliveryResult>
  paidAmount?: Maybe<Scalars['String']['output']>
  reminderIndex?: Maybe<Scalars['String']['output']>
  status: InvoiceActivityStatus
  title: Scalars['String']['output']
  type: InvoiceAction
}

export type InvoiceActivityInput = {
  balance?: InputMaybe<Scalars['String']['input']>
  cnpTxnRefNum?: InputMaybe<Scalars['String']['input']>
  completedTime?: InputMaybe<Scalars['AWSDateTime']['input']>
  contactName?: InputMaybe<Scalars['String']['input']>
  contactUuid?: InputMaybe<Scalars['ID']['input']>
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  failureReason?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  paidAmount?: InputMaybe<Scalars['String']['input']>
  reminderIndex?: InputMaybe<Scalars['String']['input']>
  status: InvoiceActivityStatus
  title: Scalars['String']['input']
  type: InvoiceAction
}

export enum InvoiceActivityStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  SCHEDULED = 'SCHEDULED',
  SKIPPED = 'SKIPPED',
}

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection'
  invoices?: Maybe<Array<Invoice>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type InvoiceCustomer = {
  __typename?: 'InvoiceCustomer'
  attentionContact?: Maybe<InvoiceCustomerContact>
  deliveryAddress?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  payerContact?: Maybe<InvoiceCustomerContact>
  payerEmail?: Maybe<Scalars['String']['output']>
}

export type InvoiceCustomerContact = {
  __typename?: 'InvoiceCustomerContact'
  businessName?: Maybe<Scalars['String']['output']>
  contactType: ContactType
  contactUuid: Scalars['ID']['output']
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  lastRevisionTime?: Maybe<Scalars['AWSDateTime']['output']>
  status: RevisionStatus
  updatedTime?: Maybe<Scalars['AWSDateTime']['output']>
}

export type InvoiceCustomerContactInput = {
  businessName?: InputMaybe<Scalars['String']['input']>
  contactType: ContactType
  contactUuid: Scalars['ID']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  lastRevisionTime?: InputMaybe<Scalars['AWSDateTime']['input']>
  status: RevisionStatus
  updatedTime?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type InvoiceCustomerInput = {
  attentionContact?: InputMaybe<InvoiceCustomerContactInput>
  deliveryAddress?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  payerContact?: InputMaybe<InvoiceCustomerContactInput>
  payerEmail?: InputMaybe<Scalars['String']['input']>
}

export type InvoiceCustomerPreviewInput = {
  attentionContact?: InputMaybe<PartialInvoicePreviewCustomerContactInput>
  payerContact?: InputMaybe<PartialInvoicePreviewCustomerContactInput>
  payerEmail?: InputMaybe<Scalars['String']['input']>
}

export type InvoiceDeliveryResult = {
  __typename?: 'InvoiceDeliveryResult'
  confirmationDateISO?: Maybe<Scalars['String']['output']>
  failed: Scalars['Boolean']['output']
  reason?: Maybe<Scalars['String']['output']>
}

export type InvoiceDiscount = {
  __typename?: 'InvoiceDiscount'
  config: InvoiceDiscountConfig
  value: Scalars['String']['output']
}

export enum InvoiceDiscountConfig {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export type InvoiceDiscountInput = {
  config?: InputMaybe<InvoiceDiscountConfig>
  value?: InputMaybe<Scalars['String']['input']>
}

export type InvoiceEmail = {
  __typename?: 'InvoiceEmail'
  body?: Maybe<Scalars['String']['output']>
  enabled?: Maybe<Scalars['Boolean']['output']>
  recipients?: Maybe<InvoiceEmailRecipients>
  subject?: Maybe<Scalars['String']['output']>
}

export type InvoiceEmailInput = {
  body?: InputMaybe<Scalars['String']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  recipients?: InputMaybe<InvoiceEmailRecipientsInput>
  subject?: InputMaybe<Scalars['String']['input']>
}

export type InvoiceEmailRecipients = {
  __typename?: 'InvoiceEmailRecipients'
  bcc?: Maybe<Array<Scalars['String']['output']>>
  cc?: Maybe<Array<Scalars['String']['output']>>
  recipient: Scalars['String']['output']
  sendMeCopy?: Maybe<Scalars['Boolean']['output']>
}

export type InvoiceEmailRecipientsInput = {
  bcc?: InputMaybe<Array<Scalars['String']['input']>>
  cc?: InputMaybe<Array<Scalars['String']['input']>>
  recipient?: InputMaybe<Scalars['String']['input']>
  sendMeCopy?: InputMaybe<Scalars['Boolean']['input']>
}

export type InvoiceInput = {
  activities?: InputMaybe<Array<InvoiceActivityInput>>
  createdTime?: InputMaybe<Scalars['Int']['input']>
  customer?: InputMaybe<InvoiceCustomerInput>
  discount?: InputMaybe<InvoiceDiscountInput>
  dueAmount?: InputMaybe<Scalars['String']['input']>
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  email?: InputMaybe<InvoiceEmailInput>
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  items?: InputMaybe<Array<InvoiceItemInput>>
  itemsApplyTax: Scalars['Boolean']['input']
  itemsTaxInclusive: Scalars['Boolean']['input']
  message?: InputMaybe<Scalars['String']['input']>
  milestones?: InputMaybe<Array<InvoiceMilestoneInput>>
  notes?: InputMaybe<Scalars['String']['input']>
  paidAmount?: InputMaybe<Scalars['String']['input']>
  paidTime?: InputMaybe<Scalars['Int']['input']>
  payerContactName?: InputMaybe<Scalars['String']['input']>
  paymentLink?: InputMaybe<Scalars['String']['input']>
  payments?: InputMaybe<Array<InvoicePaymentInput>>
  referenceNumber: Scalars['String']['input']
  sendSchedule?: InputMaybe<InvoiceSendScheduleInput>
  sentTime?: InputMaybe<Scalars['Int']['input']>
  siteSettings?: InputMaybe<ZellerInvoiceSiteInput>
  sms?: InputMaybe<InvoiceSMSInput>
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  status: InvoiceStatus
  subtotalAmount?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  totalAmount?: InputMaybe<Scalars['String']['input']>
  totalDiscount?: InputMaybe<Scalars['String']['input']>
  totalGst?: InputMaybe<Scalars['String']['input']>
  totalSurcharge?: InputMaybe<Scalars['String']['input']>
}

export type InvoiceItem = {
  __typename?: 'InvoiceItem'
  catalogItem?: Maybe<InvoiceItemCatalogReference>
  description?: Maybe<Scalars['String']['output']>
  discount?: Maybe<InvoiceDiscount>
  discountAmount?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  includeCalculation?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  orderIndex?: Maybe<Scalars['Int']['output']>
  price: Scalars['String']['output']
  quantity: Scalars['Float']['output']
  taxes?: Maybe<Array<InvoiceTax>>
  unit: InvoiceItemUnit
  updatedTime?: Maybe<Scalars['AWSDateTime']['output']>
}

export type InvoiceItemCatalogReference = {
  __typename?: 'InvoiceItemCatalogReference'
  id: Scalars['ID']['output']
  lastRevisionTime?: Maybe<Scalars['AWSDateTime']['output']>
  status: RevisionStatus
  updatedTime?: Maybe<Scalars['AWSDateTime']['output']>
}

export type InvoiceItemCatalogReferenceInput = {
  id: Scalars['ID']['input']
  lastRevisionTime?: InputMaybe<Scalars['AWSDateTime']['input']>
  status: RevisionStatus
  updatedTime?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type InvoiceItemInput = {
  catalogItem?: InputMaybe<InvoiceItemCatalogReferenceInput>
  description?: InputMaybe<Scalars['String']['input']>
  discount?: InputMaybe<InvoiceDiscountInput>
  discountAmount?: InputMaybe<Scalars['String']['input']>
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  includeCalculation?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  orderIndex?: InputMaybe<Scalars['Int']['input']>
  price: Scalars['String']['input']
  quantity: Scalars['Float']['input']
  taxes?: InputMaybe<Array<InvoiceTaxInput>>
  unit: InvoiceItemUnit
  updatedTime?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export enum InvoiceItemUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
  QUANTITY = 'QUANTITY',
}

export type InvoiceMilestone = {
  __typename?: 'InvoiceMilestone'
  amount: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  dueDate: Scalars['AWSDateTime']['output']
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  startDate: Scalars['AWSDateTime']['output']
  status: InvoiceMilestoneStatus
  type: InvoiceMilestoneType
}

export type InvoiceMilestoneInput = {
  amount?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  status?: InputMaybe<InvoiceMilestoneStatus>
  type?: InputMaybe<InvoiceMilestoneType>
}

export enum InvoiceMilestoneStatus {
  CANCELLED = 'CANCELLED',
  NULL = 'NULL',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
}

export enum InvoiceMilestoneType {
  DEPOSIT = 'DEPOSIT',
  FINAL = 'FINAL',
  MILESTONE = 'MILESTONE',
}

export type InvoicePayment = {
  __typename?: 'InvoicePayment'
  amount: Scalars['String']['output']
  entityShortId?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  localPaymentTime?: Maybe<Scalars['AWSDateTime']['output']>
  notes?: Maybe<Scalars['String']['output']>
  paymentTimeISO: Scalars['String']['output']
  rrn?: Maybe<Scalars['String']['output']>
  surchargedAmount?: Maybe<Scalars['String']['output']>
  surchargedGst?: Maybe<Scalars['String']['output']>
  type: InvoicePaymentType
}

export type InvoicePaymentInput = {
  amount: Scalars['String']['input']
  entityShortId?: InputMaybe<Scalars['String']['input']>
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  localPaymentTime?: InputMaybe<Scalars['AWSDateTime']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  paymentTimeISO: Scalars['String']['input']
  rrn?: InputMaybe<Scalars['String']['input']>
  surchargedAmount?: InputMaybe<Scalars['String']['input']>
  surchargedGst?: InputMaybe<Scalars['String']['input']>
  type: InvoicePaymentType
}

export enum InvoicePaymentType {
  CNP = 'CNP',
  MANUAL = 'MANUAL',
}

export type InvoicePdfCustomisation = {
  __typename?: 'InvoicePdfCustomisation'
  accentColours?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  defaultMessage?: Maybe<Scalars['String']['output']>
  logos?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  selectedColor?: Maybe<Scalars['String']['output']>
  selectedLogo?: Maybe<Scalars['String']['output']>
  selectedTemplateType?: Maybe<PdfTemplateType>
  termsAndConditions?: Maybe<Scalars['String']['output']>
}

export type InvoicePdfCustomisationInput = {
  accentColours?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  defaultMessage?: InputMaybe<Scalars['String']['input']>
  logos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  selectedColor?: InputMaybe<Scalars['String']['input']>
  selectedLogo?: InputMaybe<Scalars['String']['input']>
  selectedTemplateType: PdfTemplateType
  termsAndConditions?: InputMaybe<Scalars['String']['input']>
}

export type InvoicePdfCustomisationPreviewInput = {
  accentColours?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  defaultMessage?: InputMaybe<Scalars['String']['input']>
  logos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  selectedColor?: InputMaybe<Scalars['String']['input']>
  selectedLogo?: InputMaybe<Scalars['String']['input']>
  selectedTemplateType?: InputMaybe<PdfTemplateType>
  termsAndConditions?: InputMaybe<Scalars['String']['input']>
}

export type InvoicePdfDownload = {
  __typename?: 'InvoicePdfDownload'
  downloadLink: Scalars['String']['output']
  expire: Scalars['AWSDateTime']['output']
}

export type InvoiceRecordPaymentInput = {
  amount: Scalars['String']['input']
  notes?: InputMaybe<Scalars['String']['input']>
  referenceNumber: Scalars['String']['input']
  sendReceiptToCustomer?: InputMaybe<Scalars['Boolean']['input']>
}

export type InvoiceReminder = {
  __typename?: 'InvoiceReminder'
  contactUuid: Scalars['ID']['output']
  dueDate: Scalars['AWSDateTime']['output']
  id: Scalars['ID']['output']
  payerContactName: Scalars['String']['output']
  status: InvoiceActivityStatus
}

export type InvoiceSMS = {
  __typename?: 'InvoiceSMS'
  enabled?: Maybe<Scalars['Boolean']['output']>
  payerContactPhoneNumber?: Maybe<Scalars['String']['output']>
}

export type InvoiceSMSInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  payerContactPhoneNumber?: InputMaybe<Scalars['String']['input']>
}

export type InvoiceSendSchedule = {
  __typename?: 'InvoiceSendSchedule'
  enabled: Scalars['Boolean']['output']
  sendDate: Scalars['AWSDateTime']['output']
}

export type InvoiceSendScheduleInput = {
  enabled: Scalars['Boolean']['input']
  sendDate: Scalars['AWSDateTime']['input']
}

export enum InvoiceStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  ENDED = 'ENDED',
  ERROR = 'ERROR',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PART_PAID = 'PART_PAID',
  SCHEDULED = 'SCHEDULED',
  SEND_FAILED = 'SEND_FAILED',
  SENT = 'SENT',
}

export type InvoiceTax = {
  __typename?: 'InvoiceTax'
  enabled: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  percent?: Maybe<Scalars['Int']['output']>
}

export type InvoiceTaxInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  percent?: InputMaybe<Scalars['Int']['input']>
}

export type IosCardProvisioningRequestResult = {
  __typename?: 'IosCardProvisioningRequestResult'
  activationData: Scalars['String']['output']
  encryptedData: Scalars['String']['output']
  ephemeralPublicKey: Scalars['String']['output']
}

export type IssuingAccount = DebitCardAccountV3 | SavingsAccountV2

export type IssuingAccountBalance = {
  __typename?: 'IssuingAccountBalance'
  balance: Money
  date: Scalars['AWSDate']['output']
}

export type Item = {
  __typename?: 'Item'
  categories?: Maybe<Array<ItemCategory>>
  createdTime?: Maybe<Scalars['Int']['output']>
  description?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  price: Scalars['Int']['output']
  productId?: Maybe<Scalars['ID']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  revenue?: Maybe<Scalars['Int']['output']>
  sales?: Maybe<Scalars['Int']['output']>
  sites?: Maybe<Array<ItemSite>>
  sku?: Maybe<Scalars['String']['output']>
  status?: Maybe<ItemStatus>
  taxes?: Maybe<Array<ItemTax>>
}

export type ItemBatchUpdate = {
  __typename?: 'ItemBatchUpdate'
  entityUuid: Scalars['ID']['output']
  items?: Maybe<Array<Item>>
}

export type ItemBatchUpdateInput = {
  entityUuid: Scalars['ID']['input']
  items?: InputMaybe<Array<ItemInput>>
}

export type ItemCategory = {
  __typename?: 'ItemCategory'
  color: Scalars['String']['output']
  createdTime?: Maybe<Scalars['Int']['output']>
  description?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  itemsNumber?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  revenue?: Maybe<Scalars['Int']['output']>
  sales?: Maybe<Scalars['Int']['output']>
  sites?: Maybe<Array<ItemSite>>
  status?: Maybe<ItemCategoryStatus>
}

export type ItemCategoryBatchUpdate = {
  __typename?: 'ItemCategoryBatchUpdate'
  categories?: Maybe<Array<ItemCategory>>
  entityUuid: Scalars['ID']['output']
}

export type ItemCategoryBatchUpdateInput = {
  categories?: InputMaybe<Array<ItemCategoryInput>>
  entityUuid: Scalars['ID']['input']
}

export type ItemCategoryConnection = {
  __typename?: 'ItemCategoryConnection'
  categories?: Maybe<Array<ItemCategory>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type ItemCategoryInput = {
  color: Scalars['String']['input']
  createdTime?: InputMaybe<Scalars['Int']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  itemsNumber?: InputMaybe<Scalars['Int']['input']>
  name: Scalars['String']['input']
  revenue?: InputMaybe<Scalars['Int']['input']>
  sales?: InputMaybe<Scalars['Int']['input']>
  sites?: InputMaybe<Array<ItemSiteInput>>
  status?: InputMaybe<ItemCategoryStatus>
}

export enum ItemCategoryStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type ItemConnection = {
  __typename?: 'ItemConnection'
  items?: Maybe<Array<Item>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type ItemExportDownload = {
  __typename?: 'ItemExportDownload'
  downloadLink: Scalars['String']['output']
  expire: Scalars['AWSDateTime']['output']
}

export enum ItemExportFormat {
  CSV = 'CSV',
  XLSX = 'XLSX',
}

export type ItemImport = {
  __typename?: 'ItemImport'
  entityUuid: Scalars['ID']['output']
  failureReason?: Maybe<Scalars['String']['output']>
  fileName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  info?: Maybe<ItemImportInfo>
  status?: Maybe<ItemImportStatus>
}

export type ItemImportFileMetadata = {
  columnMapping?: InputMaybe<FileColumnMapping>
}

export type ItemImportInfo = {
  __typename?: 'ItemImportInfo'
  failed?: Maybe<Scalars['Int']['output']>
  remaining?: Maybe<Scalars['Int']['output']>
  total?: Maybe<Scalars['Int']['output']>
}

export type ItemImportInfoInput = {
  failed?: InputMaybe<Scalars['Int']['input']>
  remaining?: InputMaybe<Scalars['Int']['input']>
  total?: InputMaybe<Scalars['Int']['input']>
}

export type ItemImportInput = {
  entityUuid: Scalars['ID']['input']
  failureReason?: InputMaybe<Scalars['String']['input']>
  fileName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  info?: InputMaybe<ItemImportInfoInput>
  status?: InputMaybe<ItemImportStatus>
}

export enum ItemImportStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type ItemInput = {
  categories?: InputMaybe<Array<ItemCategoryInput>>
  createdTime?: InputMaybe<Scalars['Int']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  image?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  price: Scalars['Int']['input']
  productId?: InputMaybe<Scalars['ID']['input']>
  referenceNumber?: InputMaybe<Scalars['String']['input']>
  revenue?: InputMaybe<Scalars['Int']['input']>
  sales?: InputMaybe<Scalars['Int']['input']>
  sites?: InputMaybe<Array<ItemSiteInput>>
  sku?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<ItemStatus>
  taxes?: InputMaybe<Array<ItemTaxInput>>
}

export type ItemSettings = {
  __typename?: 'ItemSettings'
  entityUuid: Scalars['ID']['output']
  itemsApplyTax: Scalars['Boolean']['output']
  itemsTaxInclusive: Scalars['Boolean']['output']
}

export type ItemSettingsUpdateInput = {
  itemsApplyTax?: InputMaybe<Scalars['Boolean']['input']>
  itemsTaxInclusive?: InputMaybe<Scalars['Boolean']['input']>
}

export type ItemSite = {
  __typename?: 'ItemSite'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ItemSiteInput = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export enum ItemStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

export type ItemTax = {
  __typename?: 'ItemTax'
  enabled: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  percent?: Maybe<Scalars['Int']['output']>
}

export type ItemTaxInput = {
  enabled: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  percent?: InputMaybe<Scalars['Int']['input']>
}

export type KYCInitialData = {
  __typename?: 'KYCInitialData'
  attempts?: Maybe<KYCInitialDataAttemptsType>
  documents?: Maybe<Array<Maybe<DocumentType>>>
  isAgreed?: Maybe<Scalars['Boolean']['output']>
  personalData?: Maybe<PersonalData>
}

export type KYCInitialDataAttemptsInput = {
  DRIVING_LICENCE?: InputMaybe<Scalars['Int']['input']>
  MEDICARE_CARD?: InputMaybe<Scalars['Int']['input']>
  PASSPORT?: InputMaybe<Scalars['Int']['input']>
}

export type KYCInitialDataAttemptsType = {
  __typename?: 'KYCInitialDataAttemptsType'
  DRIVING_LICENCE?: Maybe<Scalars['Int']['output']>
  MEDICARE_CARD?: Maybe<Scalars['Int']['output']>
  PASSPORT?: Maybe<Scalars['Int']['output']>
}

export type KYCInitialDataInput = {
  attempts?: InputMaybe<KYCInitialDataAttemptsInput>
  documents?: InputMaybe<Array<InputMaybe<DocumentType>>>
  isAgreed?: InputMaybe<Scalars['Boolean']['input']>
  personalData?: InputMaybe<PersonalDataInput>
}

export enum KycCheckpoint {
  IDV = 'IDV',
  SELFIE_VERIFICATION = 'SELFIE_VERIFICATION',
}

export type KycCheckpointResponse = {
  __typename?: 'KycCheckpointResponse'
  error?: Maybe<CommonError>
  result?: Maybe<KycCheckpointResponseResult>
}

export type KycCheckpointResponseErrors = {
  __typename?: 'KycCheckpointResponseErrors'
  type?: Maybe<CommonErrorType>
}

export type KycCheckpointResponseResult = {
  __typename?: 'KycCheckpointResponseResult'
  id?: Maybe<Scalars['ID']['output']>
  kycCheckpoints: Array<KycCheckpoint>
}

export enum KycStatus {
  IN_REVIEW = 'IN_REVIEW',
  NOT_REQUIRED = 'NOT_REQUIRED',
  RC_ABANDONED = 'RC_ABANDONED',
  RC_REJECTED = 'RC_REJECTED',
  REQUIRED = 'REQUIRED',
  VERIFIED = 'VERIFIED',
}

export type Label = {
  __typename?: 'Label'
  id: Scalars['ID']['output']
  isEditable: Scalars['Boolean']['output']
  labelText: Scalars['String']['output']
  type: LabelType
}

export enum LabelType {
  BUSINESS = 'BUSINESS',
  PERSON = 'PERSON',
}

export type ListFilterInput = {
  in: Array<Scalars['String']['input']>
}

export type Location = {
  __typename?: 'Location'
  accuracy?: Maybe<Scalars['Int']['output']>
  location?: Maybe<Scalars['String']['output']>
  timestampLocal?: Maybe<Scalars['AWSDateTime']['output']>
}

export type LocationInput = {
  accuracy?: InputMaybe<Scalars['Int']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  timestampLocal?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type MarkAsReadUnreadInput = {
  notificationUuid: Scalars['ID']['input']
}

export type MedicareInput = {
  colour: VerifyMedicareCardColours
  expiry: Scalars['String']['input']
  middleName?: InputMaybe<Scalars['String']['input']>
  number: Scalars['String']['input']
  position: Scalars['Int']['input']
}

export type MembersFilters = {
  __typename?: 'MembersFilters'
  beneficialOwner?: Maybe<Scalars['Boolean']['output']>
  beneficiary?: Maybe<Scalars['Boolean']['output']>
  chair?: Maybe<Scalars['Boolean']['output']>
  director?: Maybe<Scalars['Boolean']['output']>
  partner?: Maybe<Scalars['Boolean']['output']>
  secretary?: Maybe<Scalars['Boolean']['output']>
  settlor?: Maybe<Scalars['Boolean']['output']>
  treasurer?: Maybe<Scalars['Boolean']['output']>
  trustee?: Maybe<Scalars['Boolean']['output']>
}

export type MembersFiltersInput = {
  beneficialOwner?: InputMaybe<Scalars['Boolean']['input']>
  beneficiary?: InputMaybe<Scalars['Boolean']['input']>
  chair?: InputMaybe<Scalars['Boolean']['input']>
  director?: InputMaybe<Scalars['Boolean']['input']>
  partner?: InputMaybe<Scalars['Boolean']['input']>
  secretary?: InputMaybe<Scalars['Boolean']['input']>
  settlor?: InputMaybe<Scalars['Boolean']['input']>
  treasurer?: InputMaybe<Scalars['Boolean']['input']>
  trustee?: InputMaybe<Scalars['Boolean']['input']>
}

export type MerchantAddress = {
  __typename?: 'MerchantAddress'
  country?: Maybe<Scalars['String']['output']>
  postcode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street1?: Maybe<Scalars['String']['output']>
  street2?: Maybe<Scalars['String']['output']>
  suburb?: Maybe<Scalars['String']['output']>
}

export type MerchantBasicInfo = {
  __typename?: 'MerchantBasicInfo'
  appearAs?: Maybe<Scalars['String']['output']>
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type MerchantBasicInfoInput = {
  appearAs?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<IconEventInput>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type MerchantDetails = {
  __typename?: 'MerchantDetails'
  abn?: Maybe<Scalars['String']['output']>
  address?: Maybe<MerchantAddress>
  addressUnformatted?: Maybe<Scalars['String']['output']>
  appearAs?: Maybe<Scalars['String']['output']>
  category?: Maybe<EntityCategories>
  dataSource?: Maybe<MerchantDetailsSource>
  email?: Maybe<Scalars['String']['output']>
  hours?: Maybe<Scalars['String']['output']>
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  location?: Maybe<Scalars['String']['output']>
  locationAccuracy?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  rating?: Maybe<Scalars['Float']['output']>
  subcategory?: Maybe<Scalars['ID']['output']>
  subcategoryDetails?: Maybe<Subcategory>
  url?: Maybe<Scalars['String']['output']>
}

export type MerchantDetailsPartialInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  appearAs?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<IconInput>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export enum MerchantDetailsSource {
  LWC = 'LWC',
  ZELLER = 'ZELLER',
}

export enum MigrateZtaAccountsError {
  ALREADY_MIGRATED = 'ALREADY_MIGRATED',
  ALREADY_STARTED = 'ALREADY_STARTED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  OTHER = 'OTHER',
  OUTSIDE_WINDOW = 'OUTSIDE_WINDOW',
}

export type MigrateZtaAccountsResult = {
  __typename?: 'MigrateZtaAccountsResult'
  error?: Maybe<MigrateZtaAccountsError>
  submitted: Scalars['Boolean']['output']
}

export enum MobileModel {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export type ModifierSetsInput = {
  modifierSetUuid: Scalars['ID']['input']
  newOrdinal: Scalars['Int']['input']
}

export type Money = {
  __typename?: 'Money'
  currency: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type MoneyInput = {
  currency: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type MotoSettings = {
  __typename?: 'MotoSettings'
  defaultEntryMethod?: Maybe<Scalars['Boolean']['output']>
  enabled?: Maybe<Scalars['Boolean']['output']>
  requiresPin?: Maybe<Scalars['Boolean']['output']>
}

export type MotoSettingsInput = {
  defaultEntryMethod?: InputMaybe<Scalars['Boolean']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  requiresPin?: InputMaybe<Scalars['Boolean']['input']>
}

export type Mutation = {
  __typename?: 'Mutation'
  abortSimCancellation: Scalars['Boolean']['output']
  activateDebitCard?: Maybe<ActivateDebitCardResult>
  activateSim: Scalars['Boolean']['output']
  addCouponToCheckout: Scalars['Boolean']['output']
  addEntitySubcategory: Scalars['ID']['output']
  addEntityTag: Scalars['Boolean']['output']
  addItemsToCart: CartDetails
  /**   Create a label */
  addLabel: Label
  addMarketingModalSeen: Scalars['Boolean']['output']
  addThirdPartyBankAccount: ThirdPartyBankAccount
  assignCustomerToSite: Scalars['Boolean']['output']
  assignDeviceToSite: Scalars['Boolean']['output']
  cancelCpocTransaction: Scalars['Boolean']['output']
  cancelDebitCard: Scalars['Boolean']['output']
  cancelInvoice: Invoice
  cancelOrder: Order
  cancelSim: Scalars['Boolean']['output']
  checkForAbusiveDescription: CheckForAbusiveDescriptionResult
  closeDebitCardAccount: Scalars['Boolean']['output']
  completeConnectionOAuthFlow: Connection
  completeEmailChange?: Maybe<CompleteEmailChangeResult>
  configureXeroBankfeeds: XeroBankfeedsConfiguration
  configureXeroPaymentServices: XeroPaymentServicesConfiguration
  confirmEntityDetailsInitial: Entity
  confirmProvisionedCardOnDevice: Scalars['Boolean']['output']
  createAndSendPayByLink: Ecommerce
  createBVCheck: Scalars['Boolean']['output']
  createCart: CartDetails
  createCatalogAttributeSet?: Maybe<CatalogAttributeSet>
  createCatalogCategory: CatalogCategory
  createCatalogDiscount: CatalogDiscount
  createCatalogItem: CatalogItem
  createCatalogModifierSet: CatalogModifierSet
  /**
   *   Create a contact and link to an existing contact
   *  The parameter linkedContactUuid means the linked contact uuid is the (business) contact link
   */
  createContact: ContactResponse
  /**
   *   Create two linked contacts. It is used to create a business and a person and link them
   *  the response contact is a two length array includes both created contact. The order in the array is [contact1, contact2].
   *  This is a many to many connection though contact1 is seen as the business contact
   */
  createContacts: Array<ContactResponse>
  createCustomer?: Maybe<Array<Customer>>
  createDebitCardAccount: DebitCardAccountResult
  createEmbeddedCheckout: Scalars['String']['output']
  createInterimBankingAccountStatement: BankingAccountStatement
  createInvoice: Invoice
  createItem: Item
  createItemCategory: ItemCategory
  createOrder: Order
  /**   create payment instrument - only BSB is supported */
  createPaymentInstrument: Scalars['ID']['output']
  /** @deprecated use createSavingsAccountV2 */
  createSavingsAccount: DebitCardAccountResult
  createSavingsAccountV2: SavingsAccountV2
  createSavingsAccountV3: SavingsAccountV2
  createSite: Site
  createSoletraderEntity: Entity
  createTransactionNotes: Scalars['Boolean']['output']
  createTransferFundsRecipient: TransferFundsRecipient
  createUniqueDeviceName: Scalars['String']['output']
  createVirtualTerminalRecord: Ecommerce
  deleteAllCatalogItems: Scalars['Boolean']['output']
  deleteAllCatalogItemsAndCategories: Scalars['Boolean']['output']
  deleteAllItems: Scalars['Boolean']['output']
  deleteAllItemsAndCategories: Scalars['Boolean']['output']
  deleteCatalogAttributeSet?: Maybe<CatalogAttributeSet>
  deleteCatalogCategory: CatalogCategory
  deleteCatalogDiscount: CatalogDiscount
  deleteCatalogItem: CatalogItem
  deleteCatalogModifierSet: CatalogModifierSet
  /**   Delete a contact */
  deleteContact: Scalars['Boolean']['output']
  deleteCustomer: Scalars['Boolean']['output']
  deleteDevicePushToken: Scalars['Boolean']['output']
  deleteInvoice: Invoice
  deleteItem: Item
  deleteItemCategory: ItemCategory
  /**   Delete a label */
  deleteLabel: Scalars['Boolean']['output']
  deleteSite: Scalars['Boolean']['output']
  deleteTransactionNotes: Scalars['Boolean']['output']
  /**   Not yet implemented */
  deleteTransferFundDebitCards: Scalars['Boolean']['output']
  disconnectConnection: Connection
  exportCatalogItems: CatalogItemsExportDownload
  exportItems: ItemExportDownload
  finaliseCustomerKYC?: Maybe<FinaliseCustomerKYCResult>
  finaliseEntityOnboarding: EntityOnboardingResults
  generateBVCheckToken: GenerateBVCheckTokenResponse
  generateCpocSessionToken: Scalars['String']['output']
  generatePanToken: Scalars['String']['output']
  generatePinToken: Scalars['String']['output']
  generateShopToken: Scalars['String']['output']
  getDeviceUuidMobile: DeviceUuidMobileResponse
  identityForcedLogoff?: Maybe<ForcedLogoff>
  identityPhoneChallenge: PhoneRegistration
  identityPhoneChallengeVerify: PhoneVerification
  identityPhoneRegister: PhoneRegistration
  identityPhoneRegisterMfa: PhoneRegistration
  identityPhoneVerify: PhoneVerification
  initialDocumentVerificationKeyExchange: DocumentVerificationServerKey
  initiateSavingsAccountSecureSession: InitiateSavingsAccountSecureSession
  /**   link contact with cardholder */
  linkContactWithCardholder: Scalars['Boolean']['output']
  /**   link debit card account transaction with sender */
  linkContactWithDebitCardAccountTransactionSender: Scalars['Boolean']['output']
  /**   link contact with subcategory */
  linkContactWithSubcategory: Scalars['Boolean']['output']
  /**   link two contacts */
  linkContacts: Scalars['Boolean']['output']
  /**   link payment instrument - only BSB is supported */
  linkPaymentInstrumentWithContact: Scalars['Boolean']['output']
  lockDebitCard: Scalars['Boolean']['output']
  markAllAsRead: Scalars['Boolean']['output']
  markAsRead: Scalars['Boolean']['output']
  markAsUnread: Scalars['Boolean']['output']
  markInvoiceAsPaid: Invoice
  migrateZtaAccounts: MigrateZtaAccountsResult
  notifyAttestationFailure: Scalars['Boolean']['output']
  /**   Complete the cardholder opt out receipts request */
  optOutReceipts: CompleteOptOutReceiptsResult
  pairHlPos: Array<HlPosPairConfiguration>
  pairOraclePos: Scalars['Boolean']['output']
  personaliseDebitCardAccount: DebitCardAccountResult
  /** @deprecated use personaliseSavingsAccountV2 */
  personaliseSavingsAccount: DebitCardAccountResult
  personaliseSavingsAccountV2: SavingsAccountV2
  pinSelectExecuteDebitCard: Scalars['Boolean']['output']
  pinSelectInitiateDebitCard: PinSelectInitiateDebitCardResult
  pinSelectSetupDebitCard: Scalars['Boolean']['output']
  publishCatalogAttributeSetUpdate?: Maybe<CatalogAttributeSet>
  publishCatalogCategoryUpdate?: Maybe<CatalogCategoryBatchUpdate>
  publishCatalogDiscountUpdate?: Maybe<CatalogDiscount>
  publishCatalogItemUpdate?: Maybe<CatalogItemBatchUpdate>
  publishCatalogModifierSetUpdate?: Maybe<CatalogModifierSet>
  /**   publish mutations */
  publishCatalogSettingsUpdate?: Maybe<CatalogSettings>
  publishContactUpdateEvent?: Maybe<Contact>
  publishCustomerVerificationResult: VerifyCustomerDocumentsResults
  /** @deprecated use publishDebitCardAccountUpdateEventV3 and publishSavingsAccountUpdateEventV2 */
  publishDebitCardAccountUpdateEvent: DebitCardAccountV2Update
  publishDebitCardAccountUpdateEventV3: DebitCardAccountV3
  publishDebitCardTransactionUpdateEvent: DebitCardTransactionV2Update
  publishDebitCardTransactionsExport: DebitCardTransactionsDownload
  publishDebitCardUpdateEvent: DebitCardV2Update
  publishDepositUpdateEvent: Deposit
  publishDeviceSettingsUpdateEvent: DeviceSettings
  publishEntityFullSearchResult: EntitySearchResultsFull
  publishEntityInitialSearchResult: EntitySearchResultsInitial
  publishInvoiceListUpdate: Invoice
  publishItemCategoryUpdate?: Maybe<ItemCategoryBatchUpdate>
  publishItemImportUpdate: ItemImport
  publishItemUpdate?: Maybe<ItemBatchUpdate>
  publishNotificationUpdate: Notification
  publishOrderUpdate?: Maybe<Order>
  publishSavingsAccountUpdateEventV2: SavingsAccountV2
  publishSimUpdateEvent: Sim
  publishTransactionExport: TransactionDownload
  publishTransactionUpdateEvent: Transaction
  publishUnreadNotificationCountUpdate: UnreadNotificationCountSubscription
  publishVirtualTerminalRecordUpdateEvent?: Maybe<Ecommerce>
  publishZellerInvoiceSettingsUpdate?: Maybe<UpdatedZellerInvoiceSettings>
  recordPayment: Invoice
  refundTransaction: RefundTransactionResponse
  removeCartItem?: Maybe<CartDetails>
  /**   Delete a image from contact and s3 */
  removeContactImage: Scalars['Boolean']['output']
  removeCustomerFromSite: Scalars['Boolean']['output']
  removeCustomerIcon: Scalars['Boolean']['output']
  removeDebitCardTxnAttachment: Scalars['Boolean']['output']
  removeDeviceFromSite: Scalars['Boolean']['output']
  removeEntitySubcategory: Scalars['Boolean']['output']
  removeEntityTag: Scalars['Boolean']['output']
  removeProvisionedCard: Scalars['Boolean']['output']
  removeSiteLogo: Scalars['Boolean']['output']
  removeThirdPartyBankAccount: Scalars['Boolean']['output']
  removeTransactionImage: Scalars['Boolean']['output']
  reorderCatalogModifierSets: Scalars['Boolean']['output']
  reportLostDebitCard: ReportLostDebitCardResult
  requestCpocTransaction: CpocTransactionResponse
  requestCustomerDocumentVerification: Scalars['Boolean']['output']
  requestCustomerDocumentVerificationV2: Scalars['Boolean']['output']
  requestEmailChange?: Maybe<RequestEmailChangeResult>
  requestNewDebitCard: RequestNewDebitCardResult
  requestProvisioningOfCardAndroid?: Maybe<AndroidCardProvisioningRequestResult>
  requestProvisioningOfCardIos?: Maybe<IosCardProvisioningRequestResult>
  retrySendBankfeedsTransactions: Scalars['Boolean']['output']
  reverseCpocTransaction: CpocReverseTransactionResponse
  reverseCpocTransactionV2: CpocReverseTransactionResponse
  saveAndSendInvoice: Invoice
  saveEntityOnboardingDetails: Scalars['Boolean']['output']
  saveEntityOnboardingErrors: Scalars['Boolean']['output']
  /**   Select the billing account for the entity */
  selectBillingAccount: Scalars['Boolean']['output']
  /**
   *   Select the deposit account for the entity or remit to Zeller card account
   *  ID is thirdPartyBankAccountUuid or debitCardAccountUuid if remitToCard is true
   */
  selectDepositAccount: Scalars['Boolean']['output']
  sendInviteEmail: Scalars['Boolean']['output']
  sendInvoice: Invoice
  sendPayByLink: Scalars['Boolean']['output']
  sendReceipt: Scalars['Boolean']['output']
  sendReminder: Invoice
  setEztaTermsOfService: SetEztaTermsOfServiceResult
  skipReminder: Invoice
  submitDynamicCrnBpayPayment: SubmitBpayPaymentResponse
  submitStaticCrnBpayPayment: SubmitBpayPaymentResponse
  /**   tag and untag contact, a list of all tags needs to be provided */
  tagContact: Scalars['Boolean']['output']
  transferFundsDebitCardAccount: TransferFundsDebitCardAccountResult
  transferRemittanceNotification: TransferRemittanceNotificationResponse
  triggerManualBankfeedsSync: Scalars['Boolean']['output']
  /**   unlink contact with cardholder */
  unlinkContactWithCardholder: Scalars['Boolean']['output']
  /**   unlink debit card account transaction with sender */
  unlinkContactWithDebitCardAccountTransactionSender: Scalars['Boolean']['output']
  /**   unlink contact with subcategory */
  unlinkContactWithSubcategory: Scalars['Boolean']['output']
  /**   unlink two contacts */
  unlinkContacts: Scalars['Boolean']['output']
  /**   unlink payment instrument - only BSB is supported */
  unlinkPaymentInstrumentFromContact: Scalars['Boolean']['output']
  unlockDebitCard: Scalars['Boolean']['output']
  unpairOraclePos: Scalars['Boolean']['output']
  updateCartItem: CartDetails
  updateCatalogAttributeSet?: Maybe<CatalogAttributeSet>
  updateCatalogCategory: CatalogCategory
  updateCatalogDiscount: CatalogDiscount
  updateCatalogItem: CatalogItem
  updateCatalogModifierSet: CatalogModifierSet
  updateCatalogSettings: CatalogSettings
  /**   Update a contact */
  updateContact: Scalars['Boolean']['output']
  updateCorporateCardVelocityControl?: Maybe<UpdateVelocityControlResponseType>
  updateCustomer: Scalars['Boolean']['output']
  updateCustomerEntityMapping: Scalars['Boolean']['output']
  updateCustomerNotificationSettings: CustomerNotificationSettings
  updateDebitCard: Scalars['Boolean']['output']
  updateDebitCardTransactionAnnotations: Scalars['Boolean']['output']
  updateDeviceInformation: Scalars['Boolean']['output']
  updateDeviceName: Scalars['Boolean']['output']
  updateDevicePushToken: Scalars['Boolean']['output']
  updateDeviceSettings: Scalars['Boolean']['output']
  updateDevices: Scalars['Boolean']['output']
  updateEntity: Scalars['Boolean']['output']
  updateEntitySubcategory: Scalars['Boolean']['output']
  updateInvoice: Invoice
  updateInvoiceNotes: Invoice
  updateItem: Item
  updateItemCategory: ItemCategory
  updateItemSettings: ItemSettings
  updateKycCheckpoint: KycCheckpointResponse
  /**   Update a label */
  updateLabel: Scalars['Boolean']['output']
  /**   update payment instrument - only BSB is supported */
  updatePaymentInstrument: Scalars['Boolean']['output']
  updateScreensaver: Scalars['Boolean']['output']
  updateSimDevice: Scalars['Boolean']['output']
  updateSite: Scalars['Boolean']['output']
  updateThirdPartyBankAccount: Scalars['Boolean']['output']
  updateTransactionNotes: Scalars['Boolean']['output']
  /**   Not yet implemented */
  updateTransferFundDebitCards: Scalars['Boolean']['output']
  updateVisibleTabs: Scalars['Boolean']['output']
  updateXeroSiteSettings: Scalars['Boolean']['output']
  updateZellerInvoiceSettings: Scalars['Boolean']['output']
  voidPayByLink: Scalars['Boolean']['output']
}

export type MutationabortSimCancellationArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  simId: Scalars['ID']['input']
}

export type MutationactivateDebitCardArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: ActivateDebitCardInput
}

export type MutationactivateSimArgs = {
  deviceUuid?: InputMaybe<Scalars['ID']['input']>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  simId: Scalars['ID']['input']
}

export type MutationaddCouponToCheckoutArgs = {
  cartId: Scalars['String']['input']
  couponCode: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationaddEntitySubcategoryArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: EntitySubcategoryInput
}

export type MutationaddEntityTagArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  tag: Scalars['String']['input']
}

export type MutationaddItemsToCartArgs = {
  cartId: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: ShopProductsInput
}

export type MutationaddLabelArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  labelText: Scalars['String']['input']
  type: LabelType
}

export type MutationaddMarketingModalSeenArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  marketingModalName: Scalars['String']['input']
}

export type MutationaddThirdPartyBankAccountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateThirdPartyBankAccountInput
  useDepositAccount?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationassignCustomerToSiteArgs = {
  customerUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  siteUuid: Scalars['ID']['input']
}

export type MutationassignDeviceToSiteArgs = {
  deviceUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  siteUuid: Scalars['ID']['input']
}

export type MutationcancelCpocTransactionArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  reason: TransactionCancelReason
  transactionDetails: CpocTransactionInput
}

export type MutationcancelDebitCardArgs = {
  cardId: DebitCardIdInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationcancelInvoiceArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  referenceNumber: Scalars['String']['input']
}

export type MutationcancelOrderArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationcancelSimArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  simId: Scalars['ID']['input']
}

export type MutationcheckForAbusiveDescriptionArgs = {
  description: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationcloseDebitCardAccountArgs = {
  debitCardAccountUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationcompleteConnectionOAuthFlowArgs = {
  connectionType: ConnectionType
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  url: Scalars['String']['input']
}

export type MutationcompleteEmailChangeArgs = {
  input?: InputMaybe<CompleteEmailChangeInput>
}

export type MutationconfigureXeroBankfeedsArgs = {
  config: XeroBankfeedsConfigInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationconfigureXeroPaymentServicesArgs = {
  config: XeroPaymentServicesConfigInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationconfirmEntityDetailsInitialArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: ConfirmEntityDetailsInitialInput
}

export type MutationconfirmProvisionedCardOnDeviceArgs = {
  cardUuid: Scalars['ID']['input']
  deviceType: CardProvisioningDeviceType
  deviceUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  tokenReferenceId: Scalars['ID']['input']
  walletName: CardProvisioningWalletName
}

export type MutationcreateAndSendPayByLinkArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateAndSendPayByLinkInput
}

export type MutationcreateBVCheckArgs = {
  usePhotoCapture?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationcreateCartArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: ShopProductsInput
}

export type MutationcreateCatalogAttributeSetArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateCatalogAttributeSetInput
}

export type MutationcreateCatalogCategoryArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<CreateCatalogCategoryInput>
}

export type MutationcreateCatalogDiscountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateCatalogDiscountInput
}

export type MutationcreateCatalogItemArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateCatalogItemInput
}

export type MutationcreateCatalogModifierSetArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateCatalogModifierSetInput
}

export type MutationcreateContactArgs = {
  contact: ContactInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  linkedContactUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationcreateContactsArgs = {
  contact1: ContactInput
  contact2: ContactInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationcreateCustomerArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: Array<CreateCustomerInput>
}

export type MutationcreateDebitCardAccountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateDebitCardAccountInput
}

export type MutationcreateEmbeddedCheckoutArgs = {
  cartId: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  userData?: InputMaybe<UserDataInput>
}

export type MutationcreateInterimBankingAccountStatementArgs = {
  debitCardAccountUuid: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  type: AccountStatementType
}

export type MutationcreateInvoiceArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateInvoiceInput
}

export type MutationcreateItemArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateItemInput
}

export type MutationcreateItemCategoryArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateItemCategoryInput
}

export type MutationcreateOrderArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<CreateOrderInput>
}

export type MutationcreatePaymentInstrumentArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  payload: CreatePaymentInstrumentInput
}

export type MutationcreateSavingsAccountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateSavingsAccountInput
}

export type MutationcreateSavingsAccountV2Args = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateSavingsAccountInput
}

export type MutationcreateSavingsAccountV3Args = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateSavingsAccountV3Input
}

export type MutationcreateSiteArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateSiteInput
}

export type MutationcreateSoletraderEntityArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
}

export type MutationcreateTransactionNotesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  notes: Scalars['String']['input']
  transactionUuid: Scalars['ID']['input']
}

export type MutationcreateTransferFundsRecipientArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateTransferFundsRecipientInput
}

export type MutationcreateUniqueDeviceNameArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: DeviceNameInput
}

export type MutationcreateVirtualTerminalRecordArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CreateVirtualTerminalRecordInput
}

export type MutationdeleteAllCatalogItemsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationdeleteAllCatalogItemsAndCategoriesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationdeleteAllItemsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationdeleteAllItemsAndCategoriesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationdeleteCatalogAttributeSetArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateCatalogAttributeSetInput
}

export type MutationdeleteCatalogCategoryArgs = {
  categoryUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  newCategoryUuid?: InputMaybe<Scalars['ID']['input']>
  reportingCategoryUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationdeleteCatalogDiscountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: ReferenceNumberCatalogDiscountInput
}

export type MutationdeleteCatalogItemArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateCatalogItemInput
}

export type MutationdeleteCatalogModifierSetArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateCatalogModifierSetInput
}

export type MutationdeleteContactArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationdeleteCustomerArgs = {
  customerUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationdeleteDevicePushTokenArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: DeleteDevicePushTokenInput
}

export type MutationdeleteInvoiceArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  referenceNumber: Scalars['String']['input']
}

export type MutationdeleteItemArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  referenceNumber: Scalars['String']['input']
}

export type MutationdeleteItemCategoryArgs = {
  categoryUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  newCategoryUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationdeleteLabelArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationdeleteSiteArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  siteUuid: Scalars['ID']['input']
}

export type MutationdeleteTransactionNotesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  transactionUuid: Scalars['ID']['input']
}

export type MutationdeleteTransferFundDebitCardsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  scheduledTransferUuid: Scalars['ID']['input']
}

export type MutationdisconnectConnectionArgs = {
  connectionType: ConnectionType
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationexportCatalogItemsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<CatalogItemFilterInput>
  format: CatalogItemExportFormat
}

export type MutationexportItemsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<GetItemFilterInput>
  format: ItemExportFormat
}

export type MutationfinaliseEntityOnboardingArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationgenerateCpocSessionTokenArgs = {
  deviceUuid?: InputMaybe<Scalars['ID']['input']>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationgeneratePanTokenArgs = {
  debitCardId: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationgeneratePinTokenArgs = {
  debitCardId: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationgenerateShopTokenArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: ShopTokenInput
}

export type MutationgetDeviceUuidMobileArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: DeviceUuidMobileInput
}

export type MutationidentityForcedLogoffArgs = {
  customerUuid: Scalars['ID']['input']
  reason: Scalars['String']['input']
}

export type MutationidentityPhoneChallengeVerifyArgs = {
  code: Scalars['String']['input']
}

export type MutationidentityPhoneRegisterArgs = {
  phone: Scalars['String']['input']
}

export type MutationidentityPhoneRegisterMfaArgs = {
  phone: Scalars['String']['input']
}

export type MutationidentityPhoneVerifyArgs = {
  code: Scalars['String']['input']
}

export type MutationinitialDocumentVerificationKeyExchangeArgs = {
  input: DocumentVerificationClientPublicKeyInput
}

export type MutationinitiateSavingsAccountSecureSessionArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: InitSecureSessionRequestInput
}

export type MutationlinkContactWithCardholderArgs = {
  cardholderUuid: Scalars['ID']['input']
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationlinkContactWithDebitCardAccountTransactionSenderArgs = {
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  senderUuid: Scalars['ID']['input']
}

export type MutationlinkContactWithSubcategoryArgs = {
  category: EntityCategories
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  subcategoryUuid: Scalars['ID']['input']
}

export type MutationlinkContactsArgs = {
  contact1Uuid: Scalars['ID']['input']
  contact2Uuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationlinkPaymentInstrumentWithContactArgs = {
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  type: PaymentInstrumentType
}

export type MutationlockDebitCardArgs = {
  cardId: DebitCardIdInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationmarkAllAsReadArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationmarkAsReadArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: MarkAsReadUnreadInput
}

export type MutationmarkAsUnreadArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: MarkAsReadUnreadInput
}

export type MutationmarkInvoiceAsPaidArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  referenceNumber: Scalars['String']['input']
}

export type MutationnotifyAttestationFailureArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: NotifyAttestationFailureInput
}

export type MutationoptOutReceiptsArgs = {
  code: Scalars['String']['input']
  id: Scalars['String']['input']
}

export type MutationpairHlPosArgs = {
  config: Array<HlPosPairConfig>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationpairOraclePosArgs = {
  config: OraclePosPairConfigInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationpersonaliseDebitCardAccountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: PersonaliseDebitCardAccountInput
}

export type MutationpersonaliseSavingsAccountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: PersonaliseSavingsAccountInput
}

export type MutationpersonaliseSavingsAccountV2Args = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: PersonaliseSavingsAccountInput
}

export type MutationpinSelectExecuteDebitCardArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: PinSelectDebitCardInput
}

export type MutationpinSelectInitiateDebitCardArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: PinSelectInitiateDebitCardInput
}

export type MutationpinSelectSetupDebitCardArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: PinSelectDebitCardInput
}

export type MutationpublishCatalogAttributeSetUpdateArgs = {
  input: PublishCatalogAttributeSetUpdateInput
}

export type MutationpublishCatalogCategoryUpdateArgs = {
  input: CatalogCategoryBatchUpdateInput
}

export type MutationpublishCatalogDiscountUpdateArgs = {
  input: PublishCatalogDiscountUpdateInput
}

export type MutationpublishCatalogItemUpdateArgs = {
  input: CatalogItemBatchUpdateInput
}

export type MutationpublishCatalogModifierSetUpdateArgs = {
  input: PublishCatalogModifierSetUpdateInput
}

export type MutationpublishCatalogSettingsUpdateArgs = {
  input: PublishCatalogSettingsUpdateInput
}

export type MutationpublishContactUpdateEventArgs = {
  input?: InputMaybe<SubscriptionContactInput>
}

export type MutationpublishCustomerVerificationResultArgs = {
  input: VerifyCustomerDocumentsResultsInput
}

export type MutationpublishDebitCardAccountUpdateEventArgs = {
  debitCardAccount: DebitCardAccountV2UpdateEventInput
}

export type MutationpublishDebitCardAccountUpdateEventV3Args = {
  debitCardAccount: DebitCardAccountV3UpdateEventInput
}

export type MutationpublishDebitCardTransactionUpdateEventArgs = {
  transaction: DebitCardTransactionV2EventInput
}

export type MutationpublishDebitCardTransactionsExportArgs = {
  input: DebitCardTransactionsDownloadInput
}

export type MutationpublishDebitCardUpdateEventArgs = {
  debitCard: DebitCardV2EventInput
}

export type MutationpublishDepositUpdateEventArgs = {
  deposit: DepositInput
}

export type MutationpublishDeviceSettingsUpdateEventArgs = {
  deviceSettings: DeviceSettingsInput
}

export type MutationpublishEntityFullSearchResultArgs = {
  input: EntitySearchResultsFullInput
}

export type MutationpublishEntityInitialSearchResultArgs = {
  input: EntitySearchResultsInitialInput
}

export type MutationpublishInvoiceListUpdateArgs = {
  input: InvoiceInput
}

export type MutationpublishItemCategoryUpdateArgs = {
  input: ItemCategoryBatchUpdateInput
}

export type MutationpublishItemImportUpdateArgs = {
  input: ItemImportInput
}

export type MutationpublishItemUpdateArgs = {
  input: ItemBatchUpdateInput
}

export type MutationpublishNotificationUpdateArgs = {
  input: NotificationUpdateSubscriptionInput
}

export type MutationpublishOrderUpdateArgs = {
  input: PublishOrderUpdateInput
}

export type MutationpublishSavingsAccountUpdateEventV2Args = {
  savingsAccount: SavingsAccountV2UpdateEventInput
}

export type MutationpublishSimUpdateEventArgs = {
  input: SimInput
}

export type MutationpublishTransactionExportArgs = {
  transactionDownload: TransactionDownloadInput
}

export type MutationpublishTransactionUpdateEventArgs = {
  transaction: TransactionInput
}

export type MutationpublishUnreadNotificationCountUpdateArgs = {
  input: UnreadNotificationCountSubscriptionInput
}

export type MutationpublishVirtualTerminalRecordUpdateEventArgs = {
  input?: InputMaybe<SubscriptionEcommerceRecordInput>
}

export type MutationpublishZellerInvoiceSettingsUpdateArgs = {
  input: PublishZellerInvoiceSettingsUpdateInput
}

export type MutationrecordPaymentArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: InvoiceRecordPaymentInput
}

export type MutationrefundTransactionArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: RefundTransactionInput
}

export type MutationremoveCartItemArgs = {
  cartId: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  itemId: Scalars['ID']['input']
}

export type MutationremoveContactImageArgs = {
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationremoveCustomerFromSiteArgs = {
  customerUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  siteUuid: Scalars['ID']['input']
}

export type MutationremoveCustomerIconArgs = {
  customerUuid: Scalars['ID']['input']
}

export type MutationremoveDebitCardTxnAttachmentArgs = {
  documentUuid: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  transactionUuid: Scalars['ID']['input']
}

export type MutationremoveDeviceFromSiteArgs = {
  deviceUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  siteUuid: Scalars['ID']['input']
}

export type MutationremoveEntitySubcategoryArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  subcategoryUuid: Scalars['ID']['input']
}

export type MutationremoveEntityTagArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  tag: Scalars['String']['input']
}

export type MutationremoveProvisionedCardArgs = {
  digitalWalletTokenUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  reason?: InputMaybe<Scalars['String']['input']>
}

export type MutationremoveSiteLogoArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  siteUuid: Scalars['ID']['input']
}

export type MutationremoveThirdPartyBankAccountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  thirdPartyBankAccountUuid: Scalars['ID']['input']
}

export type MutationremoveTransactionImageArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  fileUuid: Scalars['String']['input']
  transactionUuid: Scalars['ID']['input']
}

export type MutationreorderCatalogModifierSetsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: ReorderModifierSetsInput
}

export type MutationreportLostDebitCardArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: ReportLostDebitCardInput
}

export type MutationrequestCpocTransactionArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  transaction: CpocTransactionInput
}

export type MutationrequestCustomerDocumentVerificationArgs = {
  input: VerifyCustomerDocumentsInput
}

export type MutationrequestCustomerDocumentVerificationV2Args = {
  input: VerifyCustomerDocumentsV2Input
}

export type MutationrequestEmailChangeArgs = {
  input?: InputMaybe<RequestEmailChangeInput>
}

export type MutationrequestNewDebitCardArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: RequestNewDebitCardInput
}

export type MutationrequestProvisioningOfCardAndroidArgs = {
  appVersion: Scalars['String']['input']
  cardUuid: Scalars['ID']['input']
  deviceId: Scalars['ID']['input']
  deviceType: CardProvisioningDeviceType
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  walletAccountId: Scalars['ID']['input']
}

export type MutationrequestProvisioningOfCardIosArgs = {
  appVersion: Scalars['String']['input']
  cardUuid: Scalars['ID']['input']
  certificates?: InputMaybe<Array<Scalars['String']['input']>>
  deviceType: CardProvisioningDeviceType
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  nonce: Scalars['String']['input']
  nonceSignature: Scalars['String']['input']
}

export type MutationretrySendBankfeedsTransactionsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationreverseCpocTransactionArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  transaction: CpocReverseTransactionInput
}

export type MutationreverseCpocTransactionV2Args = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  transaction: CpocReverseTransactionV2Input
}

export type MutationsaveAndSendInvoiceArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: SaveAndSendInvoiceInput
}

export type MutationsaveEntityOnboardingDetailsArgs = {
  input: EntityOnboardingDetailsInput
}

export type MutationsaveEntityOnboardingErrorsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: Array<EntityOnboardingErrorsInput>
}

export type MutationselectBillingAccountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: SelectBillingAccountInput
}

export type MutationselectDepositAccountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  remitToCard: Scalars['Boolean']['input']
}

export type MutationsendInviteEmailArgs = {
  customerUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationsendInvoiceArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  referenceNumber: Scalars['String']['input']
}

export type MutationsendPayByLinkArgs = {
  contact: PayByContactInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationsendReceiptArgs = {
  input: SendReceiptInput
}

export type MutationsendReminderArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: SendReminderInput
}

export type MutationsetEztaTermsOfServiceArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: SetEztaTermsOfServiceInput
}

export type MutationskipReminderArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: SkipReminderInput
}

export type MutationsubmitDynamicCrnBpayPaymentArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: SubmitDynamicCrnBpayPaymentInput
}

export type MutationsubmitStaticCrnBpayPaymentArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: SubmitStaticCrnBpayPaymentInput
}

export type MutationtagContactArgs = {
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  tagNames: Array<Scalars['String']['input']>
}

export type MutationtransferFundsDebitCardAccountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: FundsTransferInput
}

export type MutationtransferRemittanceNotificationArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: TransferRemittanceNotificationInput
}

export type MutationtriggerManualBankfeedsSyncArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationunlinkContactWithCardholderArgs = {
  cardholderUuid: Scalars['ID']['input']
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationunlinkContactWithDebitCardAccountTransactionSenderArgs = {
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  senderUuid: Scalars['ID']['input']
}

export type MutationunlinkContactWithSubcategoryArgs = {
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  subcategoryUuid: Scalars['ID']['input']
}

export type MutationunlinkContactsArgs = {
  contact1Uuid: Scalars['ID']['input']
  contact2Uuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationunlinkPaymentInstrumentFromContactArgs = {
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  type: PaymentInstrumentType
}

export type MutationunlockDebitCardArgs = {
  cardId: DebitCardIdInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationunpairOraclePosArgs = {
  config: OraclePosUnpairConfigInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationupdateCartItemArgs = {
  cartId: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: ShopProductInput
  itemId: Scalars['ID']['input']
}

export type MutationupdateCatalogAttributeSetArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateCatalogAttributeSetInput
}

export type MutationupdateCatalogCategoryArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateCatalogCategoryInput
}

export type MutationupdateCatalogDiscountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateCatalogDiscountInput
}

export type MutationupdateCatalogItemArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateCatalogItemInput
}

export type MutationupdateCatalogModifierSetArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateCatalogModifierSetInput
}

export type MutationupdateCatalogSettingsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CatalogSettingsUpdateInput
}

export type MutationupdateContactArgs = {
  contact: ContactUpdateInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationupdateCorporateCardVelocityControlArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateDebitCardInput
}

export type MutationupdateCustomerArgs = {
  customer: UpdateCustomerInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationupdateCustomerEntityMappingArgs = {
  input: UpdateCustomerEntityMappingInput
}

export type MutationupdateCustomerNotificationSettingsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateCustomerNotificationSettingsInput
}

export type MutationupdateDebitCardArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateDebitCardInput
}

export type MutationupdateDebitCardTransactionAnnotationsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: DebitCardTransactionAnnotations
}

export type MutationupdateDeviceInformationArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: DeviceStatusInput
}

export type MutationupdateDeviceNameArgs = {
  deviceUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
}

export type MutationupdateDevicePushTokenArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateDevicePushTokenInput
}

export type MutationupdateDeviceSettingsArgs = {
  deviceSettings: UpdateDeviceSettingsInput
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type MutationupdateDevicesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: SiteDevicesSettingsInput
  siteUuid: Scalars['ID']['input']
}

export type MutationupdateEntityArgs = {
  entity: EntityInput
}

export type MutationupdateEntitySubcategoryArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  subcategory: Scalars['String']['input']
  subcategoryUuid: Scalars['ID']['input']
}

export type MutationupdateInvoiceArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateInvoiceInput
}

export type MutationupdateInvoiceNotesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<UpdatedInvoiceNotesInput>
}

export type MutationupdateItemArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<UpdateItemInput>
}

export type MutationupdateItemCategoryArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateItemCategoryInput
}

export type MutationupdateItemSettingsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: ItemSettingsUpdateInput
}

export type MutationupdateKycCheckpointArgs = {
  kycCheckpoints: Array<KycCheckpoint>
}

export type MutationupdateLabelArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  labelText: Scalars['String']['input']
}

export type MutationupdatePaymentInstrumentArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  payload: UpdatePaymentInstrumentInput
  type: PaymentInstrumentType
}

export type MutationupdateScreensaverArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: ScreensaverInput
}

export type MutationupdateSimDeviceArgs = {
  deviceUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  simId: Scalars['ID']['input']
}

export type MutationupdateSiteArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateSiteInput
}

export type MutationupdateThirdPartyBankAccountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateThirdPartyBankAccountInput
}

export type MutationupdateTransactionNotesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  notes: Scalars['String']['input']
  transactionUuid: Scalars['ID']['input']
}

export type MutationupdateTransferFundDebitCardsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: updateTransferFundInput
}

export type MutationupdateVisibleTabsArgs = {
  input: UpdateVisibleTabsInput
}

export type MutationupdateXeroSiteSettingsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateXeroSiteSettingsInput
}

export type MutationupdateZellerInvoiceSettingsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateZellerInvoiceSettingsInput
}

export type MutationvoidPayByLinkArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type NetworkDeviceSettings = {
  __typename?: 'NetworkDeviceSettings'
  cellularEnabled?: Maybe<Scalars['Boolean']['output']>
  cellularNetwork?: Maybe<Scalars['String']['output']>
  ethernetEnabled?: Maybe<Scalars['Boolean']['output']>
  wifiEnabled?: Maybe<Scalars['Boolean']['output']>
  wifiSsid?: Maybe<Scalars['String']['output']>
}

export type NetworkDeviceSettingsInput = {
  cellularEnabled?: InputMaybe<Scalars['Boolean']['input']>
  cellularNetwork?: InputMaybe<Scalars['String']['input']>
  ethernetEnabled?: InputMaybe<Scalars['Boolean']['input']>
  wifiEnabled?: InputMaybe<Scalars['Boolean']['input']>
  wifiSsid?: InputMaybe<Scalars['String']['input']>
}

export type Notification = {
  __typename?: 'Notification'
  affectedCustomer?: Maybe<NotificationCustomer>
  byCustomer?: Maybe<NotificationCustomer>
  category: NotificationCategory
  customerUuid?: Maybe<Scalars['ID']['output']>
  deepLinkId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  isImportant?: Maybe<Scalars['Boolean']['output']>
  isRead: Scalars['Boolean']['output']
  isReadTimestamp?: Maybe<Scalars['String']['output']>
  message?: Maybe<NotificationMessage>
  subCategory: NotificationSubCategory
  timestamp: Scalars['AWSDateTime']['output']
}

export enum NotificationCategory {
  CHARGEBACK = 'CHARGEBACK',
  CONNECTIVITY_SIM_CARDS = 'CONNECTIVITY_SIM_CARDS',
  CONTACTS = 'CONTACTS',
  DASHBOARD = 'DASHBOARD',
  HELP = 'HELP',
  MARKETING = 'MARKETING',
  ONBOARDING = 'ONBOARDING',
  PAYMENT = 'PAYMENT',
  REPORTS = 'REPORTS',
  SECURITY = 'SECURITY',
  SETTINGS_CONNECTION = 'SETTINGS_CONNECTION',
  SETTINGS_PROFILES = 'SETTINGS_PROFILES',
  SETTLEMENTS = 'SETTLEMENTS',
  VIRTUAL_TERMINAL = 'VIRTUAL_TERMINAL',
  ZELLER_DEBIT_CARD = 'ZELLER_DEBIT_CARD',
  ZELLER_DEVICES = 'ZELLER_DEVICES',
  ZELLER_TRANSACTION_ACCOUNT = 'ZELLER_TRANSACTION_ACCOUNT',
}

export type NotificationChannelSettings = {
  __typename?: 'NotificationChannelSettings'
  appDashboard?: Maybe<NotificationPreference>
  email?: Maybe<NotificationPreference>
  hubspot?: Maybe<NotificationPreference>
  mobilePush?: Maybe<NotificationPreference>
  sms?: Maybe<NotificationPreference>
  webPush?: Maybe<NotificationPreference>
}

export type NotificationChannelSettingsInput = {
  appDashboard?: InputMaybe<NotificationPreferenceInput>
  email?: InputMaybe<NotificationPreferenceInput>
  hubspot?: InputMaybe<NotificationPreferenceInput>
  mobilePush?: InputMaybe<NotificationPreferenceInput>
  sms?: InputMaybe<NotificationPreferenceInput>
  webPush?: InputMaybe<NotificationPreferenceInput>
}

export type NotificationConnection = {
  __typename?: 'NotificationConnection'
  nextToken?: Maybe<Scalars['String']['output']>
  notifications?: Maybe<Array<Notification>>
}

export type NotificationCustomer = {
  __typename?: 'NotificationCustomer'
  customerUuid?: Maybe<Scalars['ID']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  lastname?: Maybe<Scalars['String']['output']>
  middlename?: Maybe<Scalars['String']['output']>
}

export type NotificationCustomerInput = {
  customerUuid?: InputMaybe<Scalars['ID']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  middlename?: InputMaybe<Scalars['String']['input']>
}

export type NotificationMessage = {
  __typename?: 'NotificationMessage'
  accountName?: Maybe<Scalars['String']['output']>
  accountUuid?: Maybe<Scalars['ID']['output']>
  amount?: Maybe<Scalars['Float']['output']>
  body?: Maybe<Scalars['String']['output']>
  browser?: Maybe<Scalars['String']['output']>
  cardName?: Maybe<Scalars['String']['output']>
  cardUuid?: Maybe<Scalars['ID']['output']>
  ctaUrl?: Maybe<Scalars['String']['output']>
  deviceOs?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  recipientName?: Maybe<Scalars['String']['output']>
  siteName?: Maybe<Scalars['String']['output']>
  siteUuid?: Maybe<Scalars['ID']['output']>
  title?: Maybe<Scalars['String']['output']>
  transactionReference?: Maybe<Scalars['String']['output']>
  transactionUuid?: Maybe<Scalars['ID']['output']>
}

export type NotificationMessageInput = {
  accountName?: InputMaybe<Scalars['String']['input']>
  accountUuid?: InputMaybe<Scalars['ID']['input']>
  amount?: InputMaybe<Scalars['Float']['input']>
  body?: InputMaybe<Scalars['String']['input']>
  browser?: InputMaybe<Scalars['String']['input']>
  cardName?: InputMaybe<Scalars['String']['input']>
  cardUuid?: InputMaybe<Scalars['ID']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  deviceOs?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<Scalars['String']['input']>
  recipientName?: InputMaybe<Scalars['String']['input']>
  siteName?: InputMaybe<Scalars['String']['input']>
  siteUuid?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  transactionReference?: InputMaybe<Scalars['String']['input']>
  transactionUuid?: InputMaybe<Scalars['ID']['input']>
}

export type NotificationPreference = {
  __typename?: 'NotificationPreference'
  enabled?: Maybe<Scalars['Boolean']['output']>
  hidden?: Maybe<Scalars['Boolean']['output']>
  mutable?: Maybe<Scalars['Boolean']['output']>
}

export type NotificationPreferenceInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type NotificationSettings = {
  __typename?: 'NotificationSettings'
  channels?: Maybe<NotificationChannelSettings>
  status?: Maybe<NotificationSettingsStatus>
}

export type NotificationSettingsInput = {
  channels?: InputMaybe<NotificationChannelSettingsInput>
  status?: InputMaybe<NotificationSettingsStatus>
}

export enum NotificationSettingsStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export enum NotificationSettingsType {
  CUSTOMER = 'CUSTOMER',
  DEFAULT = 'DEFAULT',
}

export enum NotificationSubCategory {
  ADMIN_USER_REMOVED = 'ADMIN_USER_REMOVED',
  CARD_PIN_CHANGED = 'CARD_PIN_CHANGED',
  DEBITCARD_PURCHASE_TRANSACTION_DECLINED = 'DEBITCARD_PURCHASE_TRANSACTION_DECLINED',
  DEBITCARD_PURCHASE_TRANSACTION_PROCESSED = 'DEBITCARD_PURCHASE_TRANSACTION_PROCESSED',
  EMAIL_UPDATED = 'EMAIL_UPDATED',
  FIRST_TRANSFER_TO_NEW_ACCOUNT = 'FIRST_TRANSFER_TO_NEW_ACCOUNT',
  MANAGER_USER_REMOVED = 'MANAGER_USER_REMOVED',
  NEW_DEVICE_LOGIN = 'NEW_DEVICE_LOGIN',
  PASSWORD_UPDATED = 'PASSWORD_UPDATED',
  PHONE_UPDATED = 'PHONE_UPDATED',
  SETTLEMENT_ACCOUNT_CHANGE = 'SETTLEMENT_ACCOUNT_CHANGE',
  SITE_PIN_CHANGED = 'SITE_PIN_CHANGED',
  USER_INVITED_TO_BE_ADMIN = 'USER_INVITED_TO_BE_ADMIN',
  USER_PERMISSION_UPDATED_TO_ADMIN = 'USER_PERMISSION_UPDATED_TO_ADMIN',
  VIRTUAL_TERMINAL_EMAIL_LINK_SEND_FAILED = 'VIRTUAL_TERMINAL_EMAIL_LINK_SEND_FAILED',
  VIRTUAL_TERMINAL_LINK_PAYMENT_RECEIVED = 'VIRTUAL_TERMINAL_LINK_PAYMENT_RECEIVED',
  VIRTUAL_TERMINAL_SMS_LINK_SEND_FAILED = 'VIRTUAL_TERMINAL_SMS_LINK_SEND_FAILED',
  WELCOME = 'WELCOME',
  WHAT_IS_NEW_AT_ZELLER = 'WHAT_IS_NEW_AT_ZELLER',
  ZELLER_INVOICE_PAID = 'ZELLER_INVOICE_PAID',
  ZTA_LARGE_AMOUNT_TRANSFER_OUT = 'ZTA_LARGE_AMOUNT_TRANSFER_OUT',
}

export type NotificationUpdateSubscriptionInput = {
  affectedCustomer?: InputMaybe<NotificationCustomerInput>
  byCustomer?: InputMaybe<NotificationCustomerInput>
  category: NotificationCategory
  customerUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  isImportant?: InputMaybe<Scalars['Boolean']['input']>
  isRead: Scalars['Boolean']['input']
  isReadTimestamp?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<NotificationMessageInput>
  subCategory: NotificationSubCategory
  timestamp: Scalars['AWSDateTime']['input']
}

export type NotifyAttestationFailureInput = {
  category: AttestationErrorCategory
  deviceUuid: Scalars['String']['input']
  didDeviceSucceedPaymentsOnboarding?: InputMaybe<Scalars['Boolean']['input']>
  platform: AttestationPlatform
  platformErrorIdentifier?: InputMaybe<Scalars['String']['input']>
  recoverable: Scalars['Boolean']['input']
}

/**   New Payment Platform Detail */
export type NppDetails = {
  __typename?: 'NppDetails'
  payId?: Maybe<Scalars['String']['output']>
}

/**   New Payment Platform Detail Input */
export type NppDetailsInput = {
  payId: Scalars['String']['input']
}

export enum OaepHashingAlgorithm {
  SHA256 = 'SHA256',
  SHA512 = 'SHA512',
}

export enum OnboardingFlowType {
  ASSOCIATION = 'ASSOCIATION',
  COMPANY = 'COMPANY',
  COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND',
  GOVERNMENT = 'GOVERNMENT',
  INDIVIDUAL = 'INDIVIDUAL',
  INDIVIDUAL_NO_ABN = 'INDIVIDUAL_NO_ABN',
  PARTNERSHIP = 'PARTNERSHIP',
  TRUST = 'TRUST',
}

export enum OnboardingStatus {
  ALT_BOS_ESTABLISHED = 'ALT_BOS_ESTABLISHED',
  BEN_ESTABLISHED = 'BEN_ESTABLISHED',
  BOS_ESTABLISHED = 'BOS_ESTABLISHED',
  BUSINESS_REG_COLLECTED = 'BUSINESS_REG_COLLECTED',
  BV_COMPLETE = 'BV_COMPLETE',
  BV_ERROR = 'BV_ERROR',
  CHAIR_ESTABLISHED = 'CHAIR_ESTABLISHED',
  DIRECTORS_ESTABLISHED = 'DIRECTORS_ESTABLISHED',
  DOC_UPLOADED = 'DOC_UPLOADED',
  ENTITY_ADDRESS1 = 'ENTITY_ADDRESS1',
  ENTITY_ADDRESS2 = 'ENTITY_ADDRESS2',
  ENTITY_CATEGORY = 'ENTITY_CATEGORY',
  ENTITY_ESTABLISHED = 'ENTITY_ESTABLISHED',
  ENTITY_REVENUE = 'ENTITY_REVENUE',
  FINALISING_ONBOARDING = 'FINALISING_ONBOARDING',
  GOVERNMENT_ROLE_ESTABLISHED = 'GOVERNMENT_ROLE_ESTABLISHED',
  IDV_COMPLETE = 'IDV_COMPLETE',
  IDV_REQUIRED = 'IDV_REQUIRED',
  MORE_INFO_COLLECTED = 'MORE_INFO_COLLECTED',
  NONE = 'NONE',
  ONBOARDED = 'ONBOARDED',
  PARTNERS_ESTABLISHED = 'PARTNERS_ESTABLISHED',
  PHONE_COMPLETE = 'PHONE_COMPLETE',
  RC_ABANDONED = 'RC_ABANDONED',
  RC_DEPLATFORMED = 'RC_DEPLATFORMED',
  RC_ONBOARDED = 'RC_ONBOARDED',
  RC_REJECTED = 'RC_REJECTED',
  RC_REVIEW = 'RC_REVIEW',
  REVIEW = 'REVIEW',
  SECRETARY_ESTABLISHED = 'SECRETARY_ESTABLISHED',
  SETTLORS_ESTABLISHED = 'SETTLORS_ESTABLISHED',
  TRADING_NAME_ESTABLISHED = 'TRADING_NAME_ESTABLISHED',
  TREASURE_ESTABLISHED = 'TREASURE_ESTABLISHED',
  TRUSTEES_ESTABLISHED = 'TRUSTEES_ESTABLISHED',
}

export enum OnboardingUploadDocumentType {
  IDENTITY_DOCUMENT = 'IDENTITY_DOCUMENT',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  VISA_LABEL = 'VISA_LABEL',
}

export type OraclePosCertificate = {
  __typename?: 'OraclePosCertificate'
  expire: Scalars['AWSDateTime']['output']
  id: Scalars['ID']['output']
  passphrase: Scalars['String']['output']
}

export type OraclePosConfiguration = {
  __typename?: 'OraclePosConfiguration'
  certificatePassphrase: Scalars['String']['output']
  hostUrl: Scalars['String']['output']
  merchantId: Scalars['String']['output']
}

export type OraclePosConfigurationV2 = {
  __typename?: 'OraclePosConfigurationV2'
  certificate?: Maybe<OraclePosCertificate>
  hostUrl: Scalars['String']['output']
  merchantId: Scalars['String']['output']
  secondaryCertificate?: Maybe<OraclePosCertificate>
}

export type OraclePosPairConfigInput = {
  deviceUuid: Scalars['String']['input']
  overrideConfig?: InputMaybe<Scalars['Boolean']['input']>
  workstationId: Scalars['String']['input']
}

export type OraclePosSettings = {
  __typename?: 'OraclePosSettings'
  workstationId: Scalars['String']['output']
}

export type OraclePosSettingsInput = {
  workstationId: Scalars['String']['input']
}

export type OraclePosUnpairConfigInput = {
  deviceUuid: Scalars['String']['input']
}

export type Order = {
  __typename?: 'Order'
  createdTime: Scalars['Int']['output']
  discounts?: Maybe<Array<OrderDiscount>>
  dueAmount?: Maybe<Money>
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  items?: Maybe<Array<OrderItem>>
  paidAmount?: Maybe<Money>
  paidTime?: Maybe<Scalars['Int']['output']>
  payments?: Maybe<Array<OrderPayment>>
  referenceNumber: Scalars['String']['output']
  siteName: Scalars['String']['output']
  siteUuid: Scalars['String']['output']
  status: OrderStatus
  subtotalAmount?: Maybe<Money>
  totalAmount?: Maybe<Money>
  totalDiscount?: Maybe<Money>
  totalGst?: Maybe<Money>
  totalSurcharge?: Maybe<Money>
  totalTips?: Maybe<Money>
}

export type OrderCatalogModifier = {
  __typename?: 'OrderCatalogModifier'
  catalogModifierListUuid: Scalars['ID']['output']
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  price?: Maybe<Scalars['Int']['output']>
}

/**   ================ publishOrderUpdate mutation input types ================ */
export type OrderCatalogModifierPublisherInput = {
  catalogModifierListUuid: Scalars['ID']['input']
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  image?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  price?: InputMaybe<Scalars['Int']['input']>
}

export type OrderDiscount = {
  __typename?: 'OrderDiscount'
  catalogDiscountUuid?: Maybe<Scalars['ID']['output']>
  config: CatalogDiscountConfig
  discountedAmount: Money
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  ordinal: Scalars['Int']['output']
  type?: Maybe<CatalogDiscountType>
  value: Scalars['String']['output']
}

export type OrderDiscountPublisherInput = {
  catalogDiscountUuid?: InputMaybe<Scalars['ID']['input']>
  config: CatalogDiscountConfig
  discountedAmount: Scalars['String']['input']
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<CatalogDiscountType>
  value: Scalars['String']['input']
}

export type OrderItem = {
  __typename?: 'OrderItem'
  catalogItem?: Maybe<CatalogItem>
  description?: Maybe<Scalars['String']['output']>
  discounts?: Maybe<Array<Maybe<OrderDiscount>>>
  id: Scalars['ID']['output']
  modifiers?: Maybe<Array<OrderItemModifier>>
  /**
   *   the instance is saved as a nested object in the order
   *  and it cannot be edited. Same as invoice works.
   */
  name: Scalars['String']['output']
  ordinal: Scalars['Int']['output']
  price: Money
  quantity: Scalars['Float']['output']
  subtotalAmount?: Maybe<Money>
  taxes?: Maybe<Array<CatalogTax>>
  totalAmount?: Maybe<Money>
  type: OrderItemType
  unit: CatalogUnit
  variantName?: Maybe<Scalars['String']['output']>
}

export type OrderItemAttribute = {
  __typename?: 'OrderItemAttribute'
  attributeName: Scalars['String']['output']
  attributeValue: Scalars['String']['output']
}

export type OrderItemModifier = {
  __typename?: 'OrderItemModifier'
  catalogModifier?: Maybe<OrderCatalogModifier>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  ordinal: Scalars['Int']['output']
  price: Money
  quantity: Scalars['Float']['output']
  unit: CatalogUnit
}

export type OrderItemModifierPublisherInput = {
  catalogModifier?: InputMaybe<OrderCatalogModifierPublisherInput>
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
  price: MoneyInput
  quantity: Scalars['Float']['input']
  unit: CatalogUnit
}

export type OrderItemPublisherInput = {
  catalogItem?: InputMaybe<CatalogItemInput>
  description?: InputMaybe<Scalars['String']['input']>
  discounts?: InputMaybe<Array<InputMaybe<OrderDiscountPublisherInput>>>
  id: Scalars['ID']['input']
  modifiers?: InputMaybe<Array<OrderItemModifierPublisherInput>>
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
  price: MoneyInput
  quantity: Scalars['Float']['input']
  subtotalAmount?: InputMaybe<MoneyInput>
  taxes?: InputMaybe<Array<CatalogTaxInput>>
  totalAmount?: InputMaybe<MoneyInput>
  type: OrderItemType
  unit: CatalogUnit
  variantName?: InputMaybe<Scalars['String']['input']>
}

export enum OrderItemType {
  ONE_TIME = 'ONE_TIME',
  SINGLE = 'SINGLE',
  VARIANT = 'VARIANT',
}

export type OrderPayment = {
  __typename?: 'OrderPayment'
  entityUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  status: OrderPaymentStatus
  timestamp: Scalars['String']['output']
  transactionUuid: Scalars['ID']['output']
}

export type OrderPaymentPublisherInput = {
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  status: OrderPaymentStatus
  timestamp: Scalars['String']['input']
  transactionUuid: Scalars['ID']['input']
}

export enum OrderPaymentStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export enum OrderStatus {
  CANCELLED = 'CANCELLED',
  OPEN = 'OPEN',
  PAID = 'PAID',
  PART_PAID = 'PART_PAID',
}

export type OrderTableConnection = {
  __typename?: 'OrderTableConnection'
  nextToken?: Maybe<Scalars['String']['output']>
  orders?: Maybe<Array<Order>>
}

export type OrderedTabInput = {
  entityUuid: Scalars['String']['input']
}

export type OtherTaxSettings = {
  __typename?: 'OtherTaxSettings'
  name?: Maybe<Scalars['String']['output']>
  percent?: Maybe<Scalars['Int']['output']>
}

export type OtherTaxSettingsInput = {
  name?: InputMaybe<Scalars['String']['input']>
  percent?: InputMaybe<Scalars['Int']['input']>
}

export type OutstandingTransactionRequirementConfig = {
  __typename?: 'OutstandingTransactionRequirementConfig'
  accountingCategory: Scalars['Boolean']['output']
  attachments: Scalars['Boolean']['output']
  category: Scalars['Boolean']['output']
  note: Scalars['Boolean']['output']
}

export type OutstandingTransactionRequirementConfigInput = {
  accountingCategory?: InputMaybe<Scalars['Boolean']['input']>
  attachments?: InputMaybe<Scalars['Boolean']['input']>
  category?: InputMaybe<Scalars['Boolean']['input']>
  note?: InputMaybe<Scalars['Boolean']['input']>
}

export type PartialInvoiceCustomerContactInput = {
  businessName?: InputMaybe<Scalars['String']['input']>
  contactType: ContactType
  contactUuid: Scalars['ID']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
}

export type PartialInvoiceCustomerInput = {
  attentionContact?: InputMaybe<PartialInvoiceCustomerContactInput>
  payerContact?: InputMaybe<PartialInvoiceCustomerContactInput>
  payerEmail?: InputMaybe<Scalars['String']['input']>
}

export type PartialInvoiceItemInput = {
  description?: InputMaybe<Scalars['String']['input']>
  discount?: InputMaybe<InvoiceDiscountInput>
  includeCalculation?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  orderIndex?: InputMaybe<Scalars['Int']['input']>
  price: Scalars['String']['input']
  quantity: Scalars['Float']['input']
  taxes?: InputMaybe<Array<InvoiceTaxInput>>
  unit: InvoiceItemUnit
  updatedTime?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type PartialInvoicePreviewCustomerContactInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  address?: InputMaybe<ContactAddressInput>
  businessName?: InputMaybe<Scalars['String']['input']>
  contactType?: InputMaybe<ContactType>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
}

export type PassportInput = {
  country: Scalars['String']['input']
  dateOfIssue?: InputMaybe<Scalars['AWSDate']['input']>
  expiry?: InputMaybe<Scalars['AWSDate']['input']>
  number: Scalars['String']['input']
}

export type PayByContactInput = {
  contactDetails: Scalars['String']['input']
  contactMethod: ContactMethod
}

export type PaymentInstrument = {
  __typename?: 'PaymentInstrument'
  bankAccountDetails?: Maybe<BankAccountDetails>
  contact?: Maybe<Contact>
  contactUuid?: Maybe<Scalars['ID']['output']>
  details: PaymentInstrumentDetails
  id: Scalars['ID']['output']
  status: PaymentInstrumentStatus
}

export type PaymentInstrumentBpayDynamicCrnDetails = {
  __typename?: 'PaymentInstrumentBpayDynamicCrnDetails'
  billerCode: Scalars['String']['output']
  billerName: Scalars['String']['output']
  crnLengths: Array<Scalars['Int']['output']>
  nickname?: Maybe<Scalars['String']['output']>
}

export type PaymentInstrumentBpayDynamicCrnDetailsInput = {
  billerCode: Scalars['String']['input']
  billerName: Scalars['String']['input']
  crnLengths: Array<Scalars['Int']['input']>
  nickname: Scalars['String']['input']
}

export type PaymentInstrumentBpayStaticCrnDetails = {
  __typename?: 'PaymentInstrumentBpayStaticCrnDetails'
  billerCode: Scalars['String']['output']
  billerName: Scalars['String']['output']
  crn: Scalars['String']['output']
  crnLengths: Array<Scalars['Int']['output']>
  nickname?: Maybe<Scalars['String']['output']>
}

export type PaymentInstrumentBpayStaticCrnDetailsInput = {
  billerCode: Scalars['String']['input']
  billerName: Scalars['String']['input']
  crn: Scalars['String']['input']
  crnLengths: Array<Scalars['Int']['input']>
  nickname: Scalars['String']['input']
}

export type PaymentInstrumentDetails =
  | BankAccountDetails
  | BpayDetails
  | NppDetails
  | PaymentInstrumentBpayDynamicCrnDetails
  | PaymentInstrumentBpayStaticCrnDetails

export type PaymentInstrumentFilterInput = {
  contactUuid?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  type?: InputMaybe<StringFilterInput>
}

export enum PaymentInstrumentStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  PRIMARY = 'PRIMARY',
}

export enum PaymentInstrumentType {
  BPAY = 'BPAY',
  BPAY_DYNAMIC_CRN = 'BPAY_DYNAMIC_CRN',
  BPAY_STATIC_CRN = 'BPAY_STATIC_CRN',
  BSB = 'BSB',
  NPP = 'NPP',
}

export type PaymentLimits = {
  __typename?: 'PaymentLimits'
  maximum: Scalars['String']['output']
  minimum: Scalars['String']['output']
}

export type PaymentLimitsInput = {
  maximum?: InputMaybe<Scalars['String']['input']>
  minimum?: InputMaybe<Scalars['String']['input']>
}

export type PaymentSettings = {
  __typename?: 'PaymentSettings'
  cnpPaymentLimits: PaymentLimits
  cpocPaymentLimits: PaymentLimits
  motoPaymentLimits: PaymentLimits
  paymentLimits: PaymentLimits
}

export type PaymentSettingsLimits = {
  __typename?: 'PaymentSettingsLimits'
  maximum?: Maybe<Scalars['Int']['output']>
  minimum?: Maybe<Scalars['Int']['output']>
}

export type PaymentsNotificationSettings = {
  __typename?: 'PaymentsNotificationSettings'
  invoicePaid?: Maybe<NotificationSettings>
  virtualTerminalEmailLinkSendFailed?: Maybe<NotificationSettings>
  virtualTerminalPaymentReceived?: Maybe<NotificationSettings>
  virtualTerminalSmsLinkSendFailed?: Maybe<NotificationSettings>
}

export type PaymentsNotificationSettingsInput = {
  invoicePaid?: InputMaybe<NotificationSettingsInput>
  virtualTerminalEmailLinkSendFailed?: InputMaybe<NotificationSettingsInput>
  virtualTerminalPaymentReceived?: InputMaybe<NotificationSettingsInput>
  virtualTerminalSmsLinkSendFailed?: InputMaybe<NotificationSettingsInput>
}

export enum PdfTemplateType {
  CLASSIC = 'CLASSIC',
  MINIMAL = 'MINIMAL',
  STRONG = 'STRONG',
}

export type PersonalData = {
  __typename?: 'PersonalData'
  dob?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  middleName?: Maybe<Scalars['String']['output']>
  postcode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  suburb?: Maybe<Scalars['String']['output']>
}

export type PersonalDataInput = {
  dob?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  middleName?: InputMaybe<Scalars['String']['input']>
  postcode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  suburb?: InputMaybe<Scalars['String']['input']>
}

export type PersonaliseDebitCardAccountInput = {
  icon: IconInput
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  /**   v2 is deprecated */
  v2?: InputMaybe<Scalars['Boolean']['input']>
}

export type PersonaliseSavingsAccountInput = {
  displayName?: InputMaybe<Scalars['String']['input']>
  savingsAccountUuid: Scalars['ID']['input']
}

export type PhoneRegistration = {
  __typename?: 'PhoneRegistration'
  codeSent: Scalars['Boolean']['output']
  validUntil: Scalars['String']['output']
}

export type PhoneVerification = {
  __typename?: 'PhoneVerification'
  codeVerified: Scalars['Boolean']['output']
}

export type PinSelectDebitCardInput = {
  encryptedData: Scalars['String']['input']
  encryptedEphemeralKey: Scalars['String']['input']
  iv: Scalars['String']['input']
  oeapHashingAlgorithm: OaepHashingAlgorithm
  publicKeyFingerprint: Scalars['String']['input']
  sessionId: Scalars['String']['input']
}

export type PinSelectInitiateDebitCardInput = {
  cardId: DebitCardIdInput
  mode: PinSelectOtpMode
}

export type PinSelectInitiateDebitCardResult = {
  __typename?: 'PinSelectInitiateDebitCardResult'
  expiresAt: Scalars['AWSDateTime']['output']
  publicKeyCertificate: Scalars['String']['output']
  result: Scalars['Boolean']['output']
  sessionId: Scalars['String']['output']
}

export enum PinSelectOtpMode {
  email = 'email',
  sms = 'sms',
}

export type PosLocation = {
  __typename?: 'PosLocation'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  number: Scalars['String']['output']
}

export type PosLocationInput = {
  id: Scalars['String']['input']
  name: Scalars['String']['input']
  number: Scalars['String']['input']
}

export enum PosMethod {
  CLOUD = 'CLOUD',
  CLOUD_ASYNC = 'CLOUD_ASYNC',
  LAN = 'LAN',
  POS_CONNECTOR = 'POS_CONNECTOR',
}

export enum PosMode {
  INTEGRATED = 'INTEGRATED',
  INTERFACED = 'INTERFACED',
  PAY_AT_TABLE = 'PAY_AT_TABLE',
}

export type PosSettings = {
  __typename?: 'PosSettings'
  active?: Maybe<Source>
  clientAddress?: Maybe<Scalars['String']['output']>
  connectionMethod?: Maybe<PosMethod>
  /** @deprecated No longer supported */
  customIdleText?: Maybe<Scalars['String']['output']>
  /** @deprecated No longer supported */
  customIdleTextUsed?: Maybe<Scalars['Boolean']['output']>
  exitRequiresPin?: Maybe<Scalars['Boolean']['output']>
  /**   Non-null when active = HL_POS */
  hlSettings?: Maybe<HlSettings>
  ipAddress?: Maybe<Scalars['String']['output']>
  mode?: Maybe<PosMode>
  /**   Non-null when active = ORACLE_POS */
  oracleSettings?: Maybe<OraclePosSettings>
  port?: Maybe<Scalars['Int']['output']>
  posReceipt?: Maybe<Scalars['Boolean']['output']>
  posRegisterName?: Maybe<Scalars['String']['output']>
  posSoftwareName?: Maybe<Scalars['String']['output']>
  posVenue?: Maybe<PosVenue>
  statusUpdates?: Maybe<Scalars['Boolean']['output']>
  /**   Non-null when active = ZELLER_POS */
  zellerPosSettings?: Maybe<ZellerPosSettings>
}

export type PosSettingsInput = {
  active?: InputMaybe<Source>
  clientAddress?: InputMaybe<Scalars['String']['input']>
  connectionMethod?: InputMaybe<PosMethod>
  customIdleText?: InputMaybe<Scalars['String']['input']>
  customIdleTextUsed?: InputMaybe<Scalars['Boolean']['input']>
  exitRequiresPin?: InputMaybe<Scalars['Boolean']['input']>
  hlSettings?: InputMaybe<HlSettingsInput>
  ipAddress?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<PosMode>
  oracleSettings?: InputMaybe<OraclePosSettingsInput>
  port?: InputMaybe<Scalars['Int']['input']>
  posReceipt?: InputMaybe<Scalars['Boolean']['input']>
  posRegisterName?: InputMaybe<Scalars['String']['input']>
  posSoftwareName?: InputMaybe<Scalars['String']['input']>
  posVenue?: InputMaybe<PosVenueInput>
  statusUpdates?: InputMaybe<Scalars['Boolean']['input']>
  zellerPosSettings?: InputMaybe<ZellerPosSettingsInput>
}

export type PosSystem = {
  __typename?: 'PosSystem'
  connectionMethod: PosMethod
  name: Scalars['String']['output']
  source: Source
}

export type PosTransactionMerchantDetails = {
  __typename?: 'PosTransactionMerchantDetails'
  acquirerId?: Maybe<Scalars['Int']['output']>
  cardAcceptorId?: Maybe<Scalars['String']['output']>
  cardAcceptorNameLocation?: Maybe<Scalars['String']['output']>
  mcc?: Maybe<Scalars['String']['output']>
  terminalId?: Maybe<Scalars['String']['output']>
}

export type PosVenue = {
  __typename?: 'PosVenue'
  id: Scalars['String']['output']
  locations?: Maybe<Array<PosLocation>>
  name: Scalars['String']['output']
}

export type PosVenueInput = {
  id: Scalars['String']['input']
  locations?: InputMaybe<Array<PosLocationInput>>
  name: Scalars['String']['input']
}

export type PresignedPostUpload = {
  __typename?: 'PresignedPostUpload'
  /**   Json string of form fields which needs to be included in the post upload as parsed form data */
  formFields: Scalars['String']['output']
  /**   S3 Bucket url for the post */
  url: Scalars['String']['output']
}

export type PreviewInvoiceInput = {
  customer?: InputMaybe<PartialInvoiceCustomerInput>
  discount?: InputMaybe<InvoiceDiscountInput>
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  email?: InputMaybe<InvoiceEmailInput>
  items?: InputMaybe<Array<PartialInvoiceItemInput>>
  itemsTaxInclusive?: InputMaybe<Scalars['Boolean']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  paidAmount?: InputMaybe<Scalars['String']['input']>
  payerContactName?: InputMaybe<Scalars['String']['input']>
  referenceNumber?: InputMaybe<Scalars['String']['input']>
  sms?: InputMaybe<InvoiceSMSInput>
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  subtotalAmount?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  totalAmount?: InputMaybe<Scalars['String']['input']>
  totalDiscount?: InputMaybe<Scalars['String']['input']>
  totalGst?: InputMaybe<Scalars['String']['input']>
  totalSurcharge?: InputMaybe<Scalars['String']['input']>
}

export type PreviewZellerInvoiceSettingsInput = {
  businessAddress?: InputMaybe<Scalars['String']['input']>
  customisation?: InputMaybe<InvoicePdfCustomisationPreviewInput>
  discountsEnabled?: InputMaybe<Scalars['Boolean']['input']>
  pdfIncludesAddress?: InputMaybe<Scalars['Boolean']['input']>
}

export type PreviewZellerSettingsInput = {
  address?: InputMaybe<SiteAddressInput>
  invoice?: InputMaybe<PreviewZellerInvoiceSettingsInput>
  name?: InputMaybe<Scalars['String']['input']>
  receipt?: InputMaybe<ReceiptSettingsInput>
  surchargesTaxes?: InputMaybe<SurchargesTaxesSettingsInput>
  type?: InputMaybe<SiteType>
}

export type ProductTourStatus = {
  __typename?: 'ProductTourStatus'
  showAdminMerchantPortalWelcome?: Maybe<Scalars['Boolean']['output']>
  showCatalogueItemsWelcome?: Maybe<Scalars['Boolean']['output']>
  /** @deprecated no longer required */
  showContactAccountTransferInstructions?: Maybe<Scalars['Boolean']['output']>
  showCorporateCardsAdminWelcome?: Maybe<Scalars['Boolean']['output']>
  showCorporateCardsMayOffer?: Maybe<Scalars['Boolean']['output']>
  showCorporateCardsSettingsWalkthrough?: Maybe<Scalars['Boolean']['output']>
  showCorporateCardsWalkthrough?: Maybe<Scalars['Boolean']['output']>
  showCustomScreensaverPromo?: Maybe<Scalars['Boolean']['output']>
  showInvoiceApril?: Maybe<Scalars['Boolean']['output']>
  showInvoiceInstructions?: Maybe<Scalars['Boolean']['output']>
  showInvoiceSendViaInfo?: Maybe<Scalars['Boolean']['output']>
  showInvoicesCustomisationWelcome?: Maybe<Scalars['Boolean']['output']>
  showInvoicesScheduleSendWelcome?: Maybe<Scalars['Boolean']['output']>
  showInvoicesSendBySmsWelcome?: Maybe<Scalars['Boolean']['output']>
  showInvoicesWelcome?: Maybe<Scalars['Boolean']['output']>
  showInvoicingCustomisationSettingsWelcome?: Maybe<
    Scalars['Boolean']['output']
  >
  showItemInstructions?: Maybe<Scalars['Boolean']['output']>
  showItemsWelcome?: Maybe<Scalars['Boolean']['output']>
  showNotificationsWelcome?: Maybe<Scalars['Boolean']['output']>
  showOnboardingShop?: Maybe<Scalars['Boolean']['output']>
  showSavingsAccountMarch?: Maybe<Scalars['Boolean']['output']>
  showSavingsAccountMay?: Maybe<Scalars['Boolean']['output']>
  showSavingsAccountWelcome?: Maybe<Scalars['Boolean']['output']>
  showTapToPayInstructions?: Maybe<Scalars['Boolean']['output']>
  showTapToPayMayJune?: Maybe<Scalars['Boolean']['output']>
}

export type Promotion = {
  __typename?: 'Promotion'
  amount: Scalars['Int']['output']
  code: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type PublishCatalogAttributeSetUpdateInput = {
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  status: CatalogItemStatus
  values?: InputMaybe<Array<InputMaybe<CatalogAttributeValueInput>>>
}

export type PublishCatalogDiscountUpdateInput = {
  config: CatalogDiscountConfig
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  referenceNumber: Scalars['ID']['input']
  siteUuidList?: InputMaybe<Array<Scalars['ID']['input']>>
  sitesNumber: Scalars['Int']['input']
  status: CatalogItemStatus
  type: CatalogDiscountType
  value: Scalars['String']['input']
}

export type PublishCatalogModifierSetUpdateInput = {
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  invoicesEnabled: Scalars['Boolean']['input']
  modifiers?: InputMaybe<Array<CatalogModifierInput>>
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
  selectionRequired: Scalars['Boolean']['input']
  siteUuidList?: InputMaybe<Array<Scalars['ID']['input']>>
  sitesNumber: Scalars['Int']['input']
  status: CatalogItemStatus
}

export type PublishCatalogSettingsUpdateInput = {
  autoSkuEnabled: Scalars['Boolean']['input']
  entityUuid: Scalars['ID']['input']
  itemsApplyTax: Scalars['Boolean']['input']
  itemsTaxInclusive: Scalars['Boolean']['input']
}

export type PublishOrderUpdateInput = {
  createdTime: Scalars['Int']['input']
  discounts?: InputMaybe<Array<OrderDiscountPublisherInput>>
  dueAmount?: InputMaybe<MoneyInput>
  entityUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
  items?: InputMaybe<Array<OrderItemPublisherInput>>
  paidAmount?: InputMaybe<MoneyInput>
  paidTime?: InputMaybe<Scalars['Int']['input']>
  payments?: InputMaybe<Array<OrderPaymentPublisherInput>>
  referenceNumber: Scalars['String']['input']
  siteName: Scalars['String']['input']
  siteUuid: Scalars['String']['input']
  status: OrderStatus
  subtotalAmount?: InputMaybe<MoneyInput>
  totalAmount?: InputMaybe<MoneyInput>
  totalDiscount?: InputMaybe<MoneyInput>
  totalGst?: InputMaybe<MoneyInput>
  totalSurcharge?: InputMaybe<MoneyInput>
  totalTips?: InputMaybe<MoneyInput>
}

export type PublishZellerInvoiceSettingsUpdateInput = {
  bccEmail?: InputMaybe<Scalars['String']['input']>
  businessAddress?: InputMaybe<Scalars['String']['input']>
  customisation?: InputMaybe<InvoicePdfCustomisationInput>
  discountsEnabled?: InputMaybe<Scalars['Boolean']['input']>
  entityUuid: Scalars['ID']['input']
  pdfIncludesAddress?: InputMaybe<Scalars['Boolean']['input']>
  reminderOnDue?: InputMaybe<Scalars['Boolean']['input']>
  remindersDaysAfterDue?: InputMaybe<Array<Scalars['Int']['input']>>
  remindersDaysBeforeDue?: InputMaybe<Array<Scalars['Int']['input']>>
  sendBccCopy?: InputMaybe<Scalars['Boolean']['input']>
  sendEmailByDefault?: InputMaybe<Scalars['Boolean']['input']>
  sendSmsByDefault?: InputMaybe<Scalars['Boolean']['input']>
  siteUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type Query = {
  __typename?: 'Query'
  checkForAdditionalEntityInfo: Scalars['Boolean']['output']
  checkSensitiveAccess: Scalars['Boolean']['output']
  /**   Export a CSV file of the entity contacts */
  exportContacts: Download
  exportDeposit: DepositDownload
  exportDepositStatement: DepositDownload
  exportSimBillingStatement: SimBillingStatementDownload
  getAffectedCatalogItemVariantsCount: AffectedCatalogItemVariantsCount
  getBankingAccountStatements: Array<BankingAccountStatement>
  getBankingMigrationState: EntityBankingMigrationState
  getBillingAccount?: Maybe<BillingAccount>
  getBpayBillerDetail: BpayBillerDetail
  getBpayRestrictedBillers: Array<BpayBiller>
  getCartDetails: CartDetails
  getCashFlowAllCategoriesTotal: CashFlowAllCategoriesTotal
  getCashFlowCategoriesNetAmounts: CashFlowCategoriesNetAmounts
  getCashFlowContactsNetAmounts: CashFlowContactsNetAmounts
  getCashFlowSubcategoriesNetAmounts?: Maybe<CashFlowSubcategoriesNetAmounts>
  getCashFlowTopTenContacts: CashFlowTopTenContactTotal
  getCashFlowTotalAmounts: CashFlowTotalAmountsAmounts
  getCatalogAttributeSets: CatalogAttributeSetConnection
  getCatalogCategories: CatalogCategoryConnection
  getCatalogCategory: CatalogCategory
  getCatalogDiscount: CatalogDiscount
  getCatalogDiscounts: CatalogDiscountConnection
  getCatalogImageUploadDetails: CatalogImageUploadDetails
  getCatalogImportFileUploadUrl: CatalogFileUploadUrl
  getCatalogItem: CatalogItem
  getCatalogItems: CatalogItemConnection
  getCatalogItemsToReassignReportingCategory: CatalogItemConnection
  getCatalogItemsToReassignReportingCategoryCount: Scalars['Int']['output']
  getCatalogItemsWithOneCategory?: Maybe<CatalogItemConnection>
  getCatalogItemsWithOneCategoryCount: Scalars['Int']['output']
  getCatalogModifierSet: CatalogModifierSet
  getCatalogModifierSets: CatalogModifierSetConnection
  getCatalogSettings: CatalogSettings
  getConnectionState: Connection
  getContact: Contact
  /**   Generate presigned Post form data to upload image to s3 bucket */
  getContactImageUploadForm: PresignedPostUpload
  /**   Contact Transaction totals by cardholder */
  getContactTransactionTotals?: Maybe<ContactTransactionTotals>
  getContacts: ContactConnection
  getContactsByBpayBillerCode: Array<Contact>
  getCustomer: Customer
  getCustomerDocumentUploadUrl: Scalars['String']['output']
  getCustomerEntityMapping: CustomerEntityMapping
  getCustomerIconUploadForm: PresignedPostUpload
  getCustomerMarketing: CustomerMarketing
  getCustomers: Array<Maybe<Customer>>
  getCustomisationInvoicePreview: InvoicePdfDownload
  /**
   *   timeZone (IANA format)
   *  There is the range of the date only accept if it is less than 3 months calendar range and the end date should not be more than 2 day in future
   *  Without debitCardAccountUuid - Returns all account
   * @deprecated use getIssuingAccountBalances
   */
  getDebitCardAccountBalances?: Maybe<Array<DebitCardAccountBalance>>
  /** @deprecated use getDebitCardAccountV3 or getSavingsAccountV2 */
  getDebitCardAccountV2?: Maybe<DebitCardAccountV2>
  getDebitCardAccountV3?: Maybe<DebitCardAccountV3>
  /** @deprecated use getDebitCardAccountsV3 and getSavingsAccountsV2 */
  getDebitCardAccountsV2: DebitCardAccountConnectionV2
  getDebitCardAccountsV3: DebitCardAccountConnectionV3
  /**   timeZone (IANA format) defaults to 'Australia/Melbourne' if it is not provided. */
  getDebitCardTransactionSummary: DebitCardTransactionDownload
  getDebitCardTransactionV2: DebitCardTransactionV2
  getDebitCardTransactions: DebitCardAccountTransactionsConnection
  getDebitCardTransactionsV2: DebitCardTransactionConnectionV2
  /** @deprecated This query is deprecated. Use getPaymentInstrument */
  getDebitCardTransferRecipient: TransferFundsRecipient
  /** @deprecated This query is deprecated. Use getPaymentInstruments */
  getDebitCardTransferRecipients: TransferFundsRecipientsConnection
  getDebitCardTxnAttachmentDownloadUrls: Array<
    Maybe<DebitCardTxnAttachmentDownloadResult>
  >
  getDebitCardTxnAttachmentUploadUrls: Array<
    Maybe<DebitCardTxnAttachmentUploadUrls>
  >
  getDebitCardV2: DebitCardV2
  getDebitCardsV2: DebitCardV2Connection
  getDeposit?: Maybe<Deposit>
  getDepositTotals: DepositTotals
  getDeposits: DepositConnection
  getDeviceInformation: DeviceInformation
  getDeviceSettings: DeviceSettings
  getDigitalWalletToken: DigitalWalletToken
  getDocumentUploadUrls: Array<Maybe<DocumentUploadUrls>>
  getEntity: Entity
  getEntityAddressTimeZone: Scalars['String']['output']
  getEntityDailyLimit: EntityDailyLimit
  getEntityDocumentUploadUrl: Scalars['String']['output']
  getEntityDocumentUploadUrls: Array<Maybe<EntityDocumentUploadUrls>>
  getEntitySavingsAccountProduct?: Maybe<EntitySavingsAccountProduct>
  getEntitySubcategories: Array<Maybe<Subcategory>>
  getEntityTags: Array<Scalars['String']['output']>
  getHlPosConfiguration?: Maybe<HlPosConfiguration>
  getHlPosPairConfiguration: Array<HlPosPairConfiguration>
  getInterestTaxSummaries: Array<InterestTaxSummary>
  getInvoice: Invoice
  getInvoiceDraftContactUsage: Scalars['Int']['output']
  getInvoicePdf: InvoicePdfDownload
  getInvoicePreview: InvoicePdfDownload
  getInvoiceSettingsLogoUploadUrl: Scalars['String']['output']
  getInvoices: InvoiceConnection
  getInvoicesByContact: InvoiceConnection
  getIssuingAccount?: Maybe<IssuingAccount>
  getIssuingAccountBalances?: Maybe<Array<IssuingAccountBalance>>
  getItem: Item
  getItemCategories: ItemCategoryConnection
  getItemCategory: ItemCategory
  getItemCountForGstSetting: Scalars['Int']['output']
  getItemImportFileUploadUrl: FileUploadUrl
  getItemSettings: ItemSettings
  getItemV2: Item
  getItems: ItemConnection
  getItemsWithOneCategory?: Maybe<ItemConnection>
  getLabels: Array<Maybe<Label>>
  getMerchantDetails?: Maybe<MerchantDetails>
  getNotificationSettings: CustomerNotificationSettings
  getNotifications: NotificationConnection
  /**   change certificateId to required once FE is migrated. */
  getOraclePosCertificateDownload: S3PresignedUrlDownload
  /** @deprecated use getOraclePosConfigurationV2 */
  getOraclePosConfiguration?: Maybe<OraclePosConfiguration>
  getOraclePosConfigurationV2?: Maybe<OraclePosConfigurationV2>
  getOrder: Order
  getOrders: OrderTableConnection
  /**   get Payment instrument */
  getPaymentInstrument: PaymentInstrument
  /**   get payment instruments with filter */
  getPaymentInstruments: Array<PaymentInstrument>
  /**
   *   get payment instrument without a contact
   *  ContactUuid and status in the filter will be ignored
   */
  getPaymentInstrumentsWithoutContact: Array<PaymentInstrument>
  getPosSystemsSupported: Array<Maybe<PosSystem>>
  getPromotion: Promotion
  getReceiptPdfPresignedUrl: S3PresignedUrlDownload
  getReceiptSettingsLogoUploadUrl: Scalars['String']['output']
  getRecentlyUsedLineItems?: Maybe<Array<InvoiceItem>>
  getReferrerName?: Maybe<Scalars['String']['output']>
  getSavingsAccountV2?: Maybe<SavingsAccountV2>
  /** @deprecated use getSavingsAccountsV2 */
  getSavingsAccounts: Array<Maybe<DebitCardAccountV2>>
  getSavingsAccountsV2: SavingsAccountConnectionV2
  getScreensaverLogoUploadDetails: ScreensaverLogoUploadDetails
  getSim: Sim
  getSimBills: SimBillConnection
  getSims: SimConnection
  getSite: Site
  getSites: SiteConnection
  getSubscriptionUrl: AppSyncDomain
  getThirdPartyBankAccount: ThirdPartyBankAccount
  getThirdPartyBankAccounts: ThirdPartyBankAccountConnection
  getTransaction: Transaction
  getTransactionImageUploadUrls: Array<TransactionImageUploadUrls>
  /** @deprecated use getTransactionBigIntTotals */
  getTransactionTotals?: Maybe<Array<TransactionTotals>>
  getTransactionTotalsBigInt?: Maybe<Array<TransactionTotalsBigInt>>
  getTransactions: TransactionConnection
  /**   Not yet implemented */
  getTransferFundDebitCard?: Maybe<FundsTransfer>
  /**   Not yet implemented */
  getTransferFundDebitCards: Array<Maybe<FundsTransfer>>
  getTransferRemittancePdf: TransferRemittancePdfDownload
  getUnreadNotificationCount: Scalars['Int']['output']
  getVirtualTerminalRecord: Ecommerce
  getVirtualTerminalRecords: EcommerceConnection
  getXeroBankfeedsConfiguration: XeroBankfeedsConfiguration
  getXeroBrandingThemes: Array<XeroTheme>
  getXeroOrganisations: Array<XeroOrganisation>
  getXeroPaymentServicesConfiguration: XeroPaymentServicesConfiguration
  getXeroSiteSettings: XeroCnpSite
  getZellerInvoiceSettings: ZellerInvoiceSite
  isSupportedLocation: Scalars['Boolean']['output']
  requireBVCheck: Scalars['Boolean']['output']
  requiredOnboardingDocumentsUpload: Scalars['Boolean']['output']
  retrieveEntityOnboardingDetails: EntityOnboardingDetails
  searchBpayBillers: BpayBillerSearchResponse
  searchBusinessIdentifier?: Maybe<EntitySearchResultsInitial>
  validateBpayPayment: ValidateBpayPaymentResponse
}

/**   Deprecated */
export type QuerycheckForAdditionalEntityInfoArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QueryexportContactsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<ContactFilterInput>
}

/**   Deprecated */
export type QueryexportDepositArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter: DepositFilterInput
  format: DepositExportFormat
}

/**   Deprecated */
export type QueryexportDepositStatementArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter: DepositFilterInput
  format: DepositStatementExportFormat
}

/**   Deprecated */
export type QueryexportSimBillingStatementArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter: BillFilterInput
  format: SimBillingStatementExportFormat
}

/**   Deprecated */
export type QuerygetAffectedCatalogItemVariantsCountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: UpdateCatalogAttributeSetInput
}

/**   Deprecated */
export type QuerygetBankingAccountStatementsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetBankingAccountStatementsInput
}

/**   Deprecated */
export type QuerygetBankingMigrationStateArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetBillingAccountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetBpayBillerDetailArgs = {
  billerCode: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetCartDetailsArgs = {
  cartId: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetCashFlowAllCategoriesTotalArgs = {
  accountUuid?: InputMaybe<Scalars['String']['input']>
  date: Scalars['AWSDate']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  reportType: CashFlowReportType
  timeZone?: InputMaybe<Scalars['String']['input']>
  transactionDirection: TransactionDirection
}

/**   Deprecated */
export type QuerygetCashFlowCategoriesNetAmountsArgs = {
  accountUuid?: InputMaybe<Scalars['String']['input']>
  date: Scalars['AWSDate']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  reportType: CashFlowReportType
  sortBy?: InputMaybe<CashFlowNetAmountSortBy>
  sortOrder?: InputMaybe<SortOrder>
  timeZone?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerygetCashFlowContactsNetAmountsArgs = {
  accountUuid?: InputMaybe<Scalars['String']['input']>
  date: Scalars['AWSDate']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  reportType: CashFlowReportType
  sortBy?: InputMaybe<CashFlowNetAmountSortBy>
  sortOrder?: InputMaybe<SortOrder>
  timeZone?: InputMaybe<Scalars['String']['input']>
  transactionDirection: TransactionDirection
}

/**   Deprecated */
export type QuerygetCashFlowSubcategoriesNetAmountsArgs = {
  accountUuid?: InputMaybe<Scalars['String']['input']>
  category: EntityCategories
  date: Scalars['AWSDate']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  reportType: CashFlowReportType
  sortBy?: InputMaybe<CashFlowNetAmountSortBy>
  sortOrder?: InputMaybe<SortOrder>
  timeZone?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerygetCashFlowTopTenContactsArgs = {
  accountUuid?: InputMaybe<Scalars['String']['input']>
  date: Scalars['AWSDate']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  reportType: CashFlowReportType
  timeZone?: InputMaybe<Scalars['String']['input']>
  transactionDirection: TransactionDirection
}

/**   Deprecated */
export type QuerygetCashFlowTotalAmountsArgs = {
  accountUuid?: InputMaybe<Scalars['ID']['input']>
  date: Scalars['AWSDate']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  timeZone?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerygetCatalogAttributeSetsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetCatalogAttributeSetsInput
}

/**   Deprecated */
export type QuerygetCatalogCategoriesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<GetCatalogCategoriesInput>
}

/**   Deprecated */
export type QuerygetCatalogCategoryArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<GetCatalogCategoryInput>
}

/**   Deprecated */
export type QuerygetCatalogDiscountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<ReferenceNumberCatalogDiscountInput>
}

/**   Deprecated */
export type QuerygetCatalogDiscountsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetCatalogDiscountsInput
}

/**   Deprecated */
export type QuerygetCatalogImageUploadDetailsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetCatalogImageUploadInput
}

/**   Deprecated */
export type QuerygetCatalogImportFileUploadUrlArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  fileMeta?: InputMaybe<CatalogImportFileMetadata>
  fileName: Scalars['String']['input']
}

/**   Deprecated */
export type QuerygetCatalogItemArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetCatalogItemInput
}

/**   Deprecated */
export type QuerygetCatalogItemsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetCatalogItemsInput
}

/**   Deprecated */
export type QuerygetCatalogItemsToReassignReportingCategoryArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetCatalogItemsToReassignReportingCategoryInput
}

/**   Deprecated */
export type QuerygetCatalogItemsToReassignReportingCategoryCountArgs = {
  categoryUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetCatalogItemsWithOneCategoryArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetCatalogItemsWithOneCategoryInput
}

/**   Deprecated */
export type QuerygetCatalogItemsWithOneCategoryCountArgs = {
  categoryUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetCatalogModifierSetArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetCatalogModifierSetInput
}

/**   Deprecated */
export type QuerygetCatalogModifierSetsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetCatalogModifierSetsInput
}

/**   Deprecated */
export type QuerygetCatalogSettingsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetConnectionStateArgs = {
  connectionType: ConnectionType
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetContactArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetContactImageUploadFormArgs = {
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetContactTransactionTotalsArgs = {
  cardholderUuid?: InputMaybe<Scalars['ID']['input']>
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetContactsArgs = {
  contactType?: InputMaybe<ContactType>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<ContactFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerygetContactsByBpayBillerCodeArgs = {
  billerCode: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetCustomerArgs = {
  customerUuid?: InputMaybe<Scalars['ID']['input']>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetCustomerDocumentUploadUrlArgs = {
  customerUuid?: InputMaybe<Scalars['ID']['input']>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetCustomerEntityMappingArgs = {
  filter?: InputMaybe<CustomerEntityMappingFilterInput>
}

/**   Deprecated */
export type QuerygetCustomerIconUploadFormArgs = {
  customerUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetCustomerMarketingArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetCustomersArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetCustomisationInvoicePreviewArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: CustomisationPreviewInvoiceInput
}

/**   Deprecated */
export type QuerygetDebitCardAccountBalancesArgs = {
  debitCardAccountUuid?: InputMaybe<Scalars['ID']['input']>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  range: DateRange
  timeZone: Scalars['String']['input']
}

/**   Deprecated */
export type QuerygetDebitCardAccountV2Args = {
  debitCardAccountUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetDebitCardAccountV3Args = {
  debitCardAccountUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetDebitCardAccountsV2Args = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<DebitCardAccountV2NextTokenInput>
}

/**   Deprecated */
export type QuerygetDebitCardAccountsV3Args = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerygetDebitCardTransactionSummaryArgs = {
  debitCardTransactionUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  timeZone?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerygetDebitCardTransactionV2Args = {
  debitCardTransactionUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetDebitCardTransactionsArgs = {
  debitCardId: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<DebitCardTransactionFilterInput>
  isOutstanding?: InputMaybe<Scalars['Boolean']['input']>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerygetDebitCardTransactionsV2Args = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<DebitCardTransactionFilterInputV2>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<DebitCardTransactionV2NextTokenInput>
}

/**   Deprecated */
export type QuerygetDebitCardTransferRecipientArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  recipientUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetDebitCardTransferRecipientsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetDebitCardTxnAttachmentDownloadUrlsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  transactionUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetDebitCardTxnAttachmentUploadUrlsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  fileNames: Array<InputMaybe<Scalars['String']['input']>>
  transactionUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetDebitCardV2Args = {
  cardId: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetDebitCardsV2Args = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<DebitCardFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<DebitCardV2NextTokenInput>
}

/**   Deprecated */
export type QuerygetDepositArgs = {
  depositUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetDepositTotalsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter: DepositFilterInput
}

/**   Deprecated */
export type QuerygetDepositsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<DepositFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<DepositNextTokenInput>
}

/**   Deprecated */
export type QuerygetDeviceInformationArgs = {
  deviceUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetDeviceSettingsArgs = {
  deviceUuid?: InputMaybe<Scalars['ID']['input']>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetDigitalWalletTokenArgs = {
  digitalWalletTokenUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetDocumentUploadUrlsArgs = {
  documents: Array<InputMaybe<DocumentUploadFiles>>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  subject?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerygetEntityArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetEntityAddressTimeZoneArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetEntityDailyLimitArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetEntityDocumentUploadUrlArgs = {
  entityUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetEntityDocumentUploadUrlsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  fileNames: Array<InputMaybe<Scalars['String']['input']>>
  subject?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerygetEntitySavingsAccountProductArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetEntitySubcategoriesArgs = {
  category: EntityCategories
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetEntityTagsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetHlPosConfigurationArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetHlPosPairConfigurationArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetInterestTaxSummariesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  savingsAccountUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetInvoiceArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  referenceNumber: Scalars['String']['input']
}

/**   Deprecated */
export type QuerygetInvoiceDraftContactUsageArgs = {
  contactUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetInvoicePdfArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  referenceNumber: Scalars['String']['input']
}

/**   Deprecated */
export type QuerygetInvoicePreviewArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: PreviewInvoiceInput
}

/**   Deprecated */
export type QuerygetInvoiceSettingsLogoUploadUrlArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetInvoicesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetInvoicesInput
}

/**   Deprecated */
export type QuerygetInvoicesByContactArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetInvoicesByContactInput
}

/**   Deprecated */
export type QuerygetIssuingAccountArgs = {
  accountUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetIssuingAccountBalancesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  issuingAccountUuid?: InputMaybe<Scalars['ID']['input']>
  range: DateRange
  timeZone: Scalars['String']['input']
}

/**   Deprecated */
export type QuerygetItemArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  itemUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetItemCategoriesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<GetCategoriesFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<GetCategoriesSortInput>
}

/**   Deprecated */
export type QuerygetItemCategoryArgs = {
  categoryUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetItemCountForGstSettingArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  gstEnabled: Scalars['Boolean']['input']
}

/**   Deprecated */
export type QuerygetItemImportFileUploadUrlArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  fileMeta?: InputMaybe<ItemImportFileMetadata>
  fileName: Scalars['String']['input']
}

/**   Deprecated */
export type QuerygetItemV2Args = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  referenceNumber: Scalars['String']['input']
}

/**   Deprecated */
export type QuerygetItemsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<GetItemFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<GetItemsSortInput>
}

/**   Deprecated */
export type QuerygetItemsWithOneCategoryArgs = {
  categoryUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetLabelsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<LabelType>
}

/**   Deprecated */
export type QuerygetMerchantDetailsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  merchantUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetNotificationSettingsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetNotificationsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: GetNotificationsInput
}

/**   Deprecated */
export type QuerygetOraclePosCertificateDownloadArgs = {
  certificateId?: InputMaybe<Scalars['ID']['input']>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetOraclePosConfigurationArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetOraclePosConfigurationV2Args = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetOrderArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetOrdersArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<GetOrdersInput>
}

/**   Deprecated */
export type QuerygetPaymentInstrumentArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetPaymentInstrumentsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<PaymentInstrumentFilterInput>
}

/**   Deprecated */
export type QuerygetPaymentInstrumentsWithoutContactArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<PaymentInstrumentFilterInput>
}

/**   Deprecated */
export type QuerygetPosSystemsSupportedArgs = {
  source: Source
}

/**   Deprecated */
export type QuerygetPromotionArgs = {
  code: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetReceiptPdfPresignedUrlArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  rrn: Scalars['String']['input']
  transactionUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetReceiptSettingsLogoUploadUrlArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  fileName?: InputMaybe<Scalars['String']['input']>
  siteUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetRecentlyUsedLineItemsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetReferrerNameArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  referralCode: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetSavingsAccountV2Args = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  savingsAccountUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetSavingsAccountsV2Args = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerygetScreensaverLogoUploadDetailsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  fileName: Scalars['String']['input']
  siteUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetSimArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  simId: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetSimBillsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<BillFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<SimBillNextTokenInput>
}

/**   Deprecated */
export type QuerygetSimsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetSiteArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  siteUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetSitesArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<SiteFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<SiteNextTokenInput>
}

/**   Deprecated */
export type QuerygetThirdPartyBankAccountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  thirdPartyBankAccountUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetThirdPartyBankAccountsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  nextToken?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerygetTransactionArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  transactionUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetTransactionImageUploadUrlsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  fileNames: Array<Scalars['String']['input']>
  transactionUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetTransactionTotalsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter: TransactionFilterInput
  totalsType: TransactionTotalsType
}

/**   Deprecated */
export type QuerygetTransactionTotalsBigIntArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter: TransactionFilterInput
  totalsType: TransactionTotalsType
}

/**   Deprecated */
export type QuerygetTransactionsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<TransactionFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<TransactionNextTokenInput>
}

/**   Deprecated */
export type QuerygetTransferFundDebitCardArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  scheduledTransferUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetTransferFundDebitCardsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  payerAccountUuid: Scalars['ID']['input']
}

/**   Deprecated */
export type QuerygetTransferRemittancePdfArgs = {
  dcaTransactionUuid: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetUnreadNotificationCountArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetVirtualTerminalRecordArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['String']['input']
}

/**   Deprecated */
export type QuerygetVirtualTerminalRecordsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<VirtualTerminalRecordFilterInput>
  limit: Scalars['Int']['input']
  nextToken?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerygetXeroBankfeedsConfigurationArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetXeroBrandingThemesArgs = {
  connectionType: ConnectionType
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  xeroOrganisationUuid: Scalars['String']['input']
}

/**   Deprecated */
export type QuerygetXeroOrganisationsArgs = {
  connectionType: ConnectionType
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetXeroPaymentServicesConfigurationArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetXeroSiteSettingsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerygetZellerInvoiceSettingsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QueryisSupportedLocationArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QueryrequiredOnboardingDocumentsUploadArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QueryretrieveEntityOnboardingDetailsArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QuerysearchBpayBillersArgs = {
  billerCode: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  pageToken?: InputMaybe<Scalars['String']['input']>
}

/**   Deprecated */
export type QuerysearchBusinessIdentifierArgs = {
  businessIdentifier: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

/**   Deprecated */
export type QueryvalidateBpayPaymentArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  input: ValidateBpayPaymentInput
}

export enum ReceiptNotificationType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export type ReceiptSettings = {
  __typename?: 'ReceiptSettings'
  address1?: Maybe<Scalars['String']['output']>
  address2?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  facebook?: Maybe<Scalars['String']['output']>
  instagram?: Maybe<Scalars['String']['output']>
  logo?: Maybe<Scalars['String']['output']>
  logoMonochrome?: Maybe<Scalars['String']['output']>
  merchantCopy?: Maybe<Scalars['Boolean']['output']>
  message?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  number?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  printDeclinedTransaction?: Maybe<Scalars['Boolean']['output']>
  printLogo?: Maybe<Scalars['Boolean']['output']>
  printSocials?: Maybe<Scalars['Boolean']['output']>
  qrCode?: Maybe<Scalars['Boolean']['output']>
  returnsMessage?: Maybe<Scalars['String']['output']>
  twitter?: Maybe<Scalars['String']['output']>
  website?: Maybe<Scalars['String']['output']>
}

export type ReceiptSettingsInput = {
  address1?: InputMaybe<Scalars['String']['input']>
  address2?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  facebook?: InputMaybe<Scalars['String']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<Scalars['String']['input']>
  logoMonochrome?: InputMaybe<Scalars['String']['input']>
  merchantCopy?: InputMaybe<Scalars['Boolean']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  number?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  printDeclinedTransaction?: InputMaybe<Scalars['Boolean']['input']>
  printLogo?: InputMaybe<Scalars['Boolean']['input']>
  printSocials?: InputMaybe<Scalars['Boolean']['input']>
  qrCode?: InputMaybe<Scalars['Boolean']['input']>
  returnsMessage?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
}

export type ReferenceNumberCatalogDiscountInput = {
  referenceNumber: Scalars['ID']['input']
}

export enum RefundPinType {
  REFUND_PIN = 'REFUND_PIN',
  SITE_PIN = 'SITE_PIN',
}

export type RefundTransactionInput = {
  amount: Scalars['Int']['input']
  currency: Scalars['String']['input']
  /**   required for Zeller App to populate channel */
  deviceUuid?: InputMaybe<Scalars['ID']['input']>
  originalTransactionUuid: Scalars['ID']['input']
  timestamp: Scalars['AWSDateTime']['input']
  timestampLocal: Scalars['AWSDateTime']['input']
  timezone?: InputMaybe<Scalars['String']['input']>
  transactionUuid: Scalars['ID']['input']
}

export type RefundTransactionResponse = {
  __typename?: 'RefundTransactionResponse'
  approvalCode?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  responseCode?: Maybe<Scalars['String']['output']>
  rrn?: Maybe<Scalars['String']['output']>
  status?: Maybe<RefundTransactionStatus>
}

export enum RefundTransactionStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
}

export type RegulatorBody = {
  __typename?: 'RegulatorBody'
  name?: Maybe<Scalars['String']['output']>
  referenceNumber?: Maybe<Scalars['String']['output']>
  type?: Maybe<RegulatorBodyType>
}

export type RegulatorBodyInput = {
  name?: InputMaybe<Scalars['String']['input']>
  referenceNumber?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<RegulatorBodyType>
}

export enum RegulatorBodyType {
  ACT = 'ACT',
  COMMONWEALTH = 'COMMONWEALTH',
  NSW = 'NSW',
  NT = 'NT',
  QLD = 'QLD',
  SA = 'SA',
  TAS = 'TAS',
  VIC = 'VIC',
  WA = 'WA',
}

export type ReorderModifierSetsInput = {
  modifierSets: Array<ModifierSetsInput>
}

export type ReportLostDebitCardInput = {
  address?: InputMaybe<DebitCardAddressInput>
  cardId: DebitCardIdInput
  colour?: InputMaybe<DebitCardColour>
  replacementRequired: Scalars['Boolean']['input']
}

export type ReportLostDebitCardResult = {
  __typename?: 'ReportLostDebitCardResult'
  /**   Bank 2.0 field */
  lostCard?: Maybe<DebitCardV2>
  /**
   *   deprecated
   * @deprecated reference is deprecated in EZTA
   */
  reference?: Maybe<Scalars['String']['output']>
  /**   Bank 2.0 field */
  replacementCard?: Maybe<DebitCardV2>
  result: Scalars['Boolean']['output']
}

export type RequestEmailChangeInput = {
  newEmail: Scalars['String']['input']
}

export type RequestEmailChangeResult = {
  __typename?: 'RequestEmailChangeResult'
  message?: Maybe<Scalars['String']['output']>
  status: RequestEmailChangeStatus
}

export enum RequestEmailChangeStatus {
  FAILED = 'FAILED',
  MFA_REQUIRED = 'MFA_REQUIRED',
  VERIFICATION_EMAIL_SENT = 'VERIFICATION_EMAIL_SENT',
}

export type RequestNewDebitCardInput = {
  accessibleProfile?: InputMaybe<Scalars['Boolean']['input']>
  accountUuid?: InputMaybe<Scalars['String']['input']>
  address?: InputMaybe<DebitCardAddressInput>
  cardholderUuid?: InputMaybe<Scalars['String']['input']>
  colour: DebitCardColour
  /**   EZTA Account */
  format?: InputMaybe<DebitCardFormat>
  nickname?: InputMaybe<Scalars['String']['input']>
  productType?: InputMaybe<DebitCardProductType>
  velocityControl?: InputMaybe<VelocityControlCreateInput>
}

export type RequestNewDebitCardResult = {
  __typename?: 'RequestNewDebitCardResult'
  card?: Maybe<DebitCardV2>
  reference?: Maybe<Scalars['String']['output']>
  result: Scalars['Boolean']['output']
}

export enum RevisionStatus {
  DELETED = 'DELETED',
  NO_CHANGE = 'NO_CHANGE',
  UNLINKED = 'UNLINKED',
  UPDATED = 'UPDATED',
}

export type S3PresignedUrlDownload = {
  __typename?: 'S3PresignedUrlDownload'
  downloadLink: Scalars['String']['output']
  expire: Scalars['AWSDateTime']['output']
}

export type SaveAndSendInvoiceInput = {
  customer?: InputMaybe<UpdateInvoiceCustomerInput>
  discount?: InputMaybe<InvoiceDiscountInput>
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  email?: InputMaybe<InvoiceEmailInput>
  items?: InputMaybe<Array<UpdateInvoiceItemInput>>
  itemsApplyTax?: InputMaybe<Scalars['Boolean']['input']>
  itemsTaxInclusive?: InputMaybe<Scalars['Boolean']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  milestones?: InputMaybe<Array<InvoiceMilestoneInput>>
  notes?: InputMaybe<Scalars['String']['input']>
  referenceNumber?: InputMaybe<Scalars['String']['input']>
  sendSchedule?: InputMaybe<InvoiceSendScheduleInput>
  sms?: InputMaybe<InvoiceSMSInput>
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SavingsAccountConnectionV2 = {
  __typename?: 'SavingsAccountConnectionV2'
  accounts: Array<Maybe<SavingsAccountV2>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export type SavingsAccountDetails = {
  __typename?: 'SavingsAccountDetails'
  baseInterestRate?: Maybe<Scalars['String']['output']>
  bonusInterestRate?: Maybe<Scalars['String']['output']>
  bonusRateEndsAt?: Maybe<Scalars['AWSDate']['output']>
  effectiveInterestRate?: Maybe<Scalars['String']['output']>
  savingsAccountProductType?: Maybe<SavingsAccountProductType>
  tfnProvided?: Maybe<Scalars['Boolean']['output']>
}

export type SavingsAccountDetailsEventInput = {
  baseInterestRate: Scalars['String']['input']
  bonusInterestRate?: InputMaybe<Scalars['String']['input']>
  bonusRateEndsAt?: InputMaybe<Scalars['AWSDate']['input']>
  effectiveInterestRate: Scalars['String']['input']
  savingsAccountProductType: SavingsAccountProductType
  tfnProvided: Scalars['Boolean']['input']
}

export type SavingsAccountProduct = {
  __typename?: 'SavingsAccountProduct'
  id?: Maybe<Scalars['ID']['output']>
  interestThreshold?: Maybe<Money>
}

export enum SavingsAccountProductType {
  BZSA001 = 'BZSA001',
  SZSA001 = 'SZSA001',
}

export enum SavingsAccountStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  SUSPENDED = 'SUSPENDED',
}

export type SavingsAccountV2 = {
  __typename?: 'SavingsAccountV2'
  accountClosureAt?: Maybe<Scalars['AWSDateTime']['output']>
  accountCreationAt?: Maybe<Scalars['AWSDateTime']['output']>
  accountDetails?: Maybe<BankAccountDetails>
  balance?: Maybe<Money>
  entityUuid: Scalars['ID']['output']
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  savingsAccountDetails?: Maybe<SavingsAccountDetails>
  /**   additional resolver */
  savingsAccountProduct?: Maybe<SavingsAccountProduct>
  status: SavingsAccountStatus
}

export type SavingsAccountV2UpdateEventInput = {
  accountClosureAt?: InputMaybe<Scalars['AWSDateTime']['input']>
  accountCreationAt?: InputMaybe<Scalars['AWSDateTime']['input']>
  accountDetails?: InputMaybe<BankAccountDetailsEventInput>
  balance?: InputMaybe<MoneyInput>
  entityUuid: Scalars['ID']['input']
  icon?: InputMaybe<IconEventInput>
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  savingsAccountDetails?: InputMaybe<SavingsAccountDetailsEventInput>
  status: SavingsAccountStatus
}

export enum ScheduledTransferStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
  EXECUTED = 'EXECUTED',
  EXPIRED = 'EXPIRED',
  SUSPENDED = 'SUSPENDED',
  SUSPENDED_NEXT = 'SUSPENDED_NEXT',
}

export type SchemeSettings = {
  __typename?: 'SchemeSettings'
  name?: Maybe<CardScheme>
}

export type SchemeSettingsInput = {
  name?: InputMaybe<CardScheme>
}

export type ScreenDeviceSettings = {
  __typename?: 'ScreenDeviceSettings'
  brightness?: Maybe<Scalars['Int']['output']>
  communicationsTimer?: Maybe<Scalars['Int']['output']>
  inactivityTimer?: Maybe<Scalars['Int']['output']>
  notHibernateWhenPluggedIn?: Maybe<Scalars['Boolean']['output']>
  pinEntryTimer?: Maybe<Scalars['Int']['output']>
  sleepEnabled?: Maybe<Scalars['Boolean']['output']>
  sleepTimer?: Maybe<Scalars['Int']['output']>
  standbyEnabled?: Maybe<Scalars['Boolean']['output']>
  standbyTimer?: Maybe<Scalars['Int']['output']>
  theme?: Maybe<Theme>
}

export type ScreenDeviceSettingsInput = {
  brightness?: InputMaybe<Scalars['Int']['input']>
  communicationsTimer?: InputMaybe<Scalars['Int']['input']>
  inactivityTimer?: InputMaybe<Scalars['Int']['input']>
  notHibernateWhenPluggedIn?: InputMaybe<Scalars['Boolean']['input']>
  pinEntryTimer?: InputMaybe<Scalars['Int']['input']>
  sleepEnabled?: InputMaybe<Scalars['Boolean']['input']>
  sleepTimer?: InputMaybe<Scalars['Int']['input']>
  standbyEnabled?: InputMaybe<Scalars['Boolean']['input']>
  standbyTimer?: InputMaybe<Scalars['Int']['input']>
  theme?: InputMaybe<Theme>
}

export type ScreensaverInput = {
  customColours: Array<Scalars['String']['input']>
  logoUuids: Array<Scalars['String']['input']>
  primaryColour: Scalars['String']['input']
  primaryLogoUuid: Scalars['String']['input']
  siteUuid: Scalars['ID']['input']
}

export type ScreensaverLogoUploadDetails = {
  __typename?: 'ScreensaverLogoUploadDetails'
  expireDate: Scalars['AWSDateTime']['output']
  logoUuid: Scalars['ID']['output']
  presignedDetails: ScreensaverPresignedDetails
}

export type ScreensaverPresignedDetails = {
  __typename?: 'ScreensaverPresignedDetails'
  jsonFields: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type SecurityNotificationSettings = {
  __typename?: 'SecurityNotificationSettings'
  cardPinChanged?: Maybe<NotificationSettings>
  emailChanged?: Maybe<NotificationSettings>
  firstTransferToNewAccount?: Maybe<NotificationSettings>
  newDeviceLoggedIn?: Maybe<NotificationSettings>
  passwordChanged?: Maybe<NotificationSettings>
  phoneNumberChanged?: Maybe<NotificationSettings>
  settlementAccountChanged?: Maybe<NotificationSettings>
  sitePinChanged?: Maybe<NotificationSettings>
  ztaLargeAmountTransferredOut?: Maybe<NotificationSettings>
}

export type SecurityNotificationSettingsInput = {
  cardPinChanged?: InputMaybe<NotificationSettingsInput>
  emailChanged?: InputMaybe<NotificationSettingsInput>
  firstTransferToNewAccount?: InputMaybe<NotificationSettingsInput>
  newDeviceLoggedIn?: InputMaybe<NotificationSettingsInput>
  passwordChanged?: InputMaybe<NotificationSettingsInput>
  phoneNumberChanged?: InputMaybe<NotificationSettingsInput>
  settlementAccountChanged?: InputMaybe<NotificationSettingsInput>
  sitePinChanged?: InputMaybe<NotificationSettingsInput>
  ztaLargeAmountTransferredOut?: InputMaybe<NotificationSettingsInput>
}

export type SelectBillingAccountInput = {
  accountUuid: Scalars['ID']['input']
  type: BillingAccountType
}

export type SendReceiptInput = {
  mode: SendReceiptMode
  newEmail?: InputMaybe<Scalars['String']['input']>
  newPhone?: InputMaybe<Scalars['String']['input']>
  transactionUuid: Scalars['ID']['input']
}

export enum SendReceiptMode {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export type SendReminderInput = {
  referenceNumber: Scalars['String']['input']
  reminderUuid?: InputMaybe<Scalars['ID']['input']>
}

export type SetEztaTermsOfServiceInput = {
  accepted: Scalars['Boolean']['input']
  paymentInstrumentUuid?: InputMaybe<Scalars['ID']['input']>
  thirdPartyDepositUuid?: InputMaybe<Scalars['ID']['input']>
  thirdPartySimBillingAccountUuid?: InputMaybe<Scalars['ID']['input']>
}

export type SetEztaTermsOfServiceResult = {
  __typename?: 'SetEztaTermsOfServiceResult'
  entity: Entity
  errors?: Maybe<Array<EztaTermsOfServiceError>>
}

export enum SettledSumValue {
  LessThan10k = 'LessThan10k',
  MoreOrEqual10k = 'MoreOrEqual10k',
}

export type ShopProduct = {
  __typename?: 'ShopProduct'
  productId: Scalars['ID']['output']
  quantity?: Maybe<Scalars['Int']['output']>
  variantId?: Maybe<Scalars['ID']['output']>
}

export type ShopProductInput = {
  productId: Scalars['ID']['input']
  quantity?: InputMaybe<Scalars['Int']['input']>
  variantId?: InputMaybe<Scalars['ID']['input']>
}

export type ShopProducts = {
  __typename?: 'ShopProducts'
  products: Array<ShopProduct>
}

export type ShopProductsInput = {
  products: Array<ShopProductInput>
}

export type ShopTokenInput = {
  allowedCorsOrigins: Array<Scalars['String']['input']>
  channelId: Scalars['Int']['input']
  expiresAt: Scalars['Int']['input']
}

export type Sim = {
  __typename?: 'Sim'
  billingAmount?: Maybe<Scalars['Int']['output']>
  billingStatus: SimBillingStatus
  deactivateDate?: Maybe<Scalars['AWSDateTime']['output']>
  deviceName?: Maybe<Scalars['String']['output']>
  deviceUuid?: Maybe<Scalars['ID']['output']>
  entityUuid?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  lastSuccessfulChargeDate?: Maybe<Scalars['AWSDateTime']['output']>
  nextBillingDate?: Maybe<Scalars['AWSDateTime']['output']>
  siteName?: Maybe<Scalars['String']['output']>
  siteUuid?: Maybe<Scalars['ID']['output']>
  state: SimState
  subscriptionExpirationDate?: Maybe<Scalars['AWSDateTime']['output']>
  subscriptionTermsAccepted: Scalars['Boolean']['output']
  subscriptionTermsAcceptedDate?: Maybe<Scalars['AWSDateTime']['output']>
}

export type SimBill = {
  __typename?: 'SimBill'
  amount: Scalars['Int']['output']
  billingAccountName: Scalars['String']['output']
  billingAccountUuid: Scalars['ID']['output']
  chargeFailedReason?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** @deprecated isRefund deprecated. Use isRefunded instead */
  isRefund?: Maybe<Scalars['Boolean']['output']>
  isRefunded?: Maybe<Scalars['Boolean']['output']>
  originalChargeUuid?: Maybe<Scalars['ID']['output']>
  refundBillUuid?: Maybe<Scalars['ID']['output']>
  simId: Scalars['ID']['output']
  siteName?: Maybe<Scalars['String']['output']>
  siteUuid?: Maybe<Scalars['ID']['output']>
  status: BillStatus
  taxAmount?: Maybe<Scalars['Int']['output']>
  timestamp: Scalars['AWSDateTime']['output']
}

export type SimBillConnection = {
  __typename?: 'SimBillConnection'
  bills: Array<Maybe<SimBill>>
  nextToken?: Maybe<SimBillNextToken>
}

export type SimBillNextToken = {
  __typename?: 'SimBillNextToken'
  entityUuid?: Maybe<Scalars['ID']['output']>
  id?: Maybe<Scalars['ID']['output']>
  type: Scalars['String']['output']
}

export type SimBillNextTokenInput = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  type: Scalars['String']['input']
}

export type SimBillingStatementDownload = {
  __typename?: 'SimBillingStatementDownload'
  downloadLink: Scalars['String']['output']
  expire: Scalars['AWSDateTime']['output']
}

export enum SimBillingStatementExportFormat {
  CSV = 'CSV',
  PDF = 'PDF',
}

export enum SimBillingStatus {
  CANCELLED = 'CANCELLED',
  CANCELLED_FAILED_PAYMENT = 'CANCELLED_FAILED_PAYMENT',
  CANCEL_PENDING = 'CANCEL_PENDING',
  NULL = 'NULL',
  OVERDUE = 'OVERDUE',
  SUBSCRIBED = 'SUBSCRIBED',
  TRIAL = 'TRIAL',
}

export type SimConnection = {
  __typename?: 'SimConnection'
  nextToken?: Maybe<Scalars['ID']['output']>
  sims: Array<Maybe<Sim>>
}

export type SimInput = {
  billingAmount?: InputMaybe<Scalars['Int']['input']>
  billingStatus: SimBillingStatus
  deactivateDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  deviceName?: InputMaybe<Scalars['String']['input']>
  deviceUuid?: InputMaybe<Scalars['ID']['input']>
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  lastSuccessfulChargeDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  nextBillingDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  siteName?: InputMaybe<Scalars['String']['input']>
  siteUuid?: InputMaybe<Scalars['ID']['input']>
  state: SimState
  subscriptionExpirationDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  subscriptionTermsAccepted: Scalars['Boolean']['input']
  subscriptionTermsAcceptedDate?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export enum SimState {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
}

/**   Device */
export type Site = {
  __typename?: 'Site'
  address?: Maybe<SiteAddress>
  /**   getCustomersBySiteResolver */
  customers?: Maybe<Array<Customer>>
  /**   getDevicesBySiteResolver */
  devices?: Maybe<Array<Device>>
  discountPin?: Maybe<Scalars['String']['output']>
  discountPinType?: Maybe<DiscountPinType>
  discountRequiresPin?: Maybe<Scalars['Boolean']['output']>
  features?: Maybe<SiteFeatures>
  id: Scalars['ID']['output']
  moto?: Maybe<MotoSettings>
  name: Scalars['String']['output']
  pin?: Maybe<Scalars['String']['output']>
  receipt?: Maybe<ReceiptSettings>
  refundPin?: Maybe<Scalars['String']['output']>
  refundPinType?: Maybe<RefundPinType>
  refundRequiresPin?: Maybe<Scalars['Boolean']['output']>
  schemes?: Maybe<Array<Maybe<SchemeSettings>>>
  schemesMoto?: Maybe<Array<Maybe<SchemeSettings>>>
  screensaver?: Maybe<SiteScreensaver>
  surchargesTaxes?: Maybe<SurchargesTaxesSettings>
  tipping?: Maybe<TipSettings>
  type?: Maybe<SiteType>
  vtEnabled?: Maybe<Scalars['Boolean']['output']>
}

export type SiteAddress = {
  __typename?: 'SiteAddress'
  country?: Maybe<Scalars['String']['output']>
  postcode?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  suburb?: Maybe<Scalars['String']['output']>
}

export type SiteAddressInput = {
  country?: InputMaybe<Scalars['String']['input']>
  postcode?: InputMaybe<Scalars['String']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  suburb?: InputMaybe<Scalars['String']['input']>
}

export type SiteColumnMapping = {
  columnHeader: Scalars['String']['input']
  siteUuid: Scalars['ID']['input']
}

export type SiteConnection = {
  __typename?: 'SiteConnection'
  nextToken?: Maybe<SiteNextToken>
  sites: Array<Maybe<Site>>
}

export type SiteDeviceSettings = {
  __typename?: 'SiteDeviceSettings'
  discountPin?: Maybe<Scalars['String']['output']>
  discountPinType?: Maybe<DiscountPinType>
  discountRequiresPin?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  pin: Scalars['String']['output']
  refundPin?: Maybe<Scalars['String']['output']>
  refundPinType?: Maybe<RefundPinType>
  refundRequiresPin?: Maybe<Scalars['Boolean']['output']>
  screensaver?: Maybe<DeviceScreensaver>
  siteUuid: Scalars['ID']['output']
  type?: Maybe<SiteType>
}

export type SiteDeviceSettingsInput = {
  discountPin?: InputMaybe<Scalars['String']['input']>
  discountPinType?: InputMaybe<DiscountPinType>
  discountRequiresPin?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  pin: Scalars['String']['input']
  refundPin?: InputMaybe<Scalars['String']['input']>
  refundPinType?: InputMaybe<RefundPinType>
  refundRequiresPin?: InputMaybe<Scalars['Boolean']['input']>
  screensaver?: InputMaybe<DeviceScreensaverInput>
  siteUuid: Scalars['ID']['input']
  type?: InputMaybe<SiteType>
}

export type SiteDevicesSettingsInput = {
  posSettings?: InputMaybe<PosSettingsInput>
  screen?: InputMaybe<ScreenDeviceSettingsInput>
}

export type SiteFeatures = {
  __typename?: 'SiteFeatures'
  declineSoundEnabled: Scalars['Boolean']['output']
  restrictReportAccessEnabled: Scalars['Boolean']['output']
  splitPaymentEnabled: Scalars['Boolean']['output']
}

export type SiteFeaturesInput = {
  declineSoundEnabled?: InputMaybe<Scalars['Boolean']['input']>
  restrictReportAccessEnabled?: InputMaybe<Scalars['Boolean']['input']>
  splitPaymentEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type SiteFilterInput = {
  siteName?: InputMaybe<StringFilterInput>
  vtEnabled?: InputMaybe<BoolFilterInput>
}

export type SiteInput = {
  address?: InputMaybe<SiteAddressInput>
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  type?: InputMaybe<SiteType>
}

export type SiteNextToken = {
  __typename?: 'SiteNextToken'
  siteNameSortKey: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type SiteNextTokenInput = {
  siteNameSortKey: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type SiteScreensaver = {
  __typename?: 'SiteScreensaver'
  customColours: Array<Scalars['String']['output']>
  logos: Array<SiteScreensaverLogo>
  primaryColour: Scalars['String']['output']
  primaryLogoUrl: Scalars['String']['output']
  primaryLogoUuid: Scalars['String']['output']
}

export type SiteScreensaverLogo = {
  __typename?: 'SiteScreensaverLogo'
  logoUuid: Scalars['String']['output']
  url: Scalars['String']['output']
}

export enum SiteType {
  CNP_XERO_INVOICE = 'CNP_XERO_INVOICE',
  CNP_ZELLER_INVOICE = 'CNP_ZELLER_INVOICE',
  FIXED = 'FIXED',
  MOBILE = 'MOBILE',
}

export type SkipReminderInput = {
  referenceNumber: Scalars['String']['input']
  reminderUuid: Scalars['ID']['input']
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum Source {
  DASHBOARD = 'DASHBOARD',
  HL_POS = 'HL_POS',
  LINKLY = 'LINKLY',
  MOBILE_PHONE = 'MOBILE_PHONE',
  ORACLE_POS = 'ORACLE_POS',
  PAY_BY_LINK = 'PAY_BY_LINK',
  QUICKPOS = 'QUICKPOS',
  STANDALONE = 'STANDALONE',
  VIRTUAL_TERMINAL = 'VIRTUAL_TERMINAL',
  XERO_INVOICE = 'XERO_INVOICE',
  ZELLER_INVOICE = 'ZELLER_INVOICE',
  ZELLER_POS = 'ZELLER_POS',
}

export enum SourceFilter {
  DASHBOARD = 'DASHBOARD',
  INVOICE = 'INVOICE',
  POINT_OF_SALE = 'POINT_OF_SALE',
  TERMINAL = 'TERMINAL',
  ZELLER_ADMIN = 'ZELLER_ADMIN',
  ZELLER_APP = 'ZELLER_APP',
}

export type SplitPayment = {
  __typename?: 'SplitPayment'
  id: Scalars['ID']['output']
  portions?: Maybe<Scalars['Int']['output']>
  targetAmount: Scalars['Int']['output']
  transactions: Array<Transaction>
  type: SplitPaymentType
}

export type SplitPaymentInput = {
  id: Scalars['ID']['input']
  portions?: InputMaybe<Scalars['Int']['input']>
  targetAmount: Scalars['Int']['input']
  type: SplitPaymentType
}

export enum SplitPaymentType {
  AMOUNT = 'AMOUNT',
  ITEMS = 'ITEMS',
  PORTION = 'PORTION',
}

export type StandInRule = {
  __typename?: 'StandInRule'
  field?: Maybe<Scalars['String']['output']>
  operation?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['String']['output']>
}

export type StandInRuleInput = {
  field?: InputMaybe<Scalars['String']['input']>
  operation?: InputMaybe<Scalars['String']['input']>
  value?: InputMaybe<Scalars['String']['input']>
}

export type StringEqualInput = {
  eq?: InputMaybe<Scalars['String']['input']>
}

export type StringFilterInput = {
  beginsWith?: InputMaybe<Scalars['String']['input']>
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  contains?: InputMaybe<Scalars['String']['input']>
  eq?: InputMaybe<Scalars['String']['input']>
  ge?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  le?: InputMaybe<Scalars['String']['input']>
  lt?: InputMaybe<Scalars['String']['input']>
  ne?: InputMaybe<Scalars['String']['input']>
  notContains?: InputMaybe<Scalars['String']['input']>
}

export type Subcategory = {
  __typename?: 'Subcategory'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  predefined: Scalars['Boolean']['output']
}

export type SubcategoryInput = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  predefined: Scalars['Boolean']['input']
}

export type SubmitBpayPaymentResponse = {
  __typename?: 'SubmitBpayPaymentResponse'
  result?: Maybe<SubmitBpayPaymentResponseResult>
}

export type SubmitBpayPaymentResponseResult = {
  __typename?: 'SubmitBpayPaymentResponseResult'
  amount: Money
  billerCode: Scalars['String']['output']
  billerName: Scalars['String']['output']
  crn: Scalars['String']['output']
  debitCardAccountUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
  paymentInstrumentUuid: Scalars['ID']['output']
}

export type SubmitDynamicCrnBpayPaymentInput = {
  amount: MoneyInput
  debitCardAccountUuid: Scalars['ID']['input']
  icrn: Scalars['String']['input']
  paymentInstrumentUuid: Scalars['String']['input']
}

export type SubmitStaticCrnBpayPaymentInput = {
  amount: MoneyInput
  debitCardAccountUuid: Scalars['ID']['input']
  paymentInstrumentUuid: Scalars['String']['input']
}

export type Subscription = {
  __typename?: 'Subscription'
  /**
   *   timeZone (IANA format) defaults to 'Australia/Melbourne' if it is not provided.
   *  filter is string (workaround for subscription) of type DebitCardTransactionFilterInputV2
   */
  exportDebitCardTransactions?: Maybe<DebitCardTransactionsDownload>
  /**
   *   Get a download link for exporting transactions. The maximum time to get transaction download link is 15 mniutes but Subscription doesn't terminate from server side, that means FE needs to handle the timeout.
   *  filter: Due to appsync subscription limtation, we can't use customer object as the input parameter. The filter is a type of string which is JSON string from TransactionFilterInput
   */
  exportTransaction?: Maybe<TransactionDownload>
  identityForcedLogoff?: Maybe<ForcedLogoff>
  onCatalogAttributeSetUpdate?: Maybe<CatalogAttributeSet>
  onCatalogCategoryUpdate?: Maybe<CatalogCategoryBatchUpdate>
  onCatalogDiscountUpdate?: Maybe<CatalogDiscount>
  onCatalogItemUpdate?: Maybe<CatalogItemBatchUpdate>
  onCatalogModifierSetUpdate?: Maybe<CatalogModifierSet>
  onCatalogSettingsUpdate?: Maybe<CatalogSettings>
  onContactUpdate?: Maybe<Contact>
  /** @deprecated use onDebitCardAccountUpdateV3 and onSavingsAccountUpdateV2 */
  onDebitCardAccountUpdate?: Maybe<DebitCardAccountV2Update>
  onDebitCardAccountUpdateV3?: Maybe<DebitCardAccountV3>
  onDebitCardTransactionUpdate?: Maybe<DebitCardTransactionV2Update>
  onDebitCardUpdate?: Maybe<DebitCardV2Update>
  onDepositUpdate?: Maybe<Deposit>
  onDeviceSettingsUpdate?: Maybe<DeviceSettings>
  onInvoiceListUpdate?: Maybe<Invoice>
  onItemCategoryUpdate?: Maybe<ItemCategoryBatchUpdate>
  onItemImportUpdate?: Maybe<ItemImport>
  onItemUpdate?: Maybe<ItemBatchUpdate>
  onNotificationUpdate?: Maybe<Notification>
  onOrderUpdate?: Maybe<Order>
  onSavingsAccountUpdateV2?: Maybe<SavingsAccountV2>
  onSimUpdate?: Maybe<Sim>
  onTransactionUpdate?: Maybe<Transaction>
  onUnreadNotificationCountUpdate?: Maybe<UnreadNotificationCountSubscription>
  onVirtualTerminalRecordUpdate?: Maybe<Ecommerce>
  onZellerInvoiceSettingsUpdate?: Maybe<UpdatedZellerInvoiceSettings>
  searchEntityDetailsFull?: Maybe<EntitySearchResultsFull>
  searchEntityDetailsInitial?: Maybe<EntitySearchResultsInitial>
  verifyCustomerDocuments?: Maybe<VerifyCustomerDocumentsResults>
}

export type SubscriptionexportDebitCardTransactionsArgs = {
  customerUuid: Scalars['ID']['input']
  entityUuid: Scalars['ID']['input']
  filter: Scalars['String']['input']
  format: DebitCardTransactionsExportFormat
  timeZone?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionexportTransactionArgs = {
  customerUuid: Scalars['ID']['input']
  entityUuid: Scalars['ID']['input']
  filter: Scalars['String']['input']
  format: TransactionExportFormat
}

export type SubscriptionidentityForcedLogoffArgs = {
  customerUuid: Scalars['ID']['input']
}

export type SubscriptiononCatalogAttributeSetUpdateArgs = {
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononCatalogCategoryUpdateArgs = {
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononCatalogDiscountUpdateArgs = {
  entityUuid: Scalars['ID']['input']
  siteUuid?: InputMaybe<Scalars['ID']['input']>
}

export type SubscriptiononCatalogItemUpdateArgs = {
  entityUuid: Scalars['ID']['input']
  siteUuid?: InputMaybe<Scalars['ID']['input']>
}

export type SubscriptiononCatalogModifierSetUpdateArgs = {
  entityUuid: Scalars['ID']['input']
  siteUuid?: InputMaybe<Scalars['ID']['input']>
}

export type SubscriptiononCatalogSettingsUpdateArgs = {
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononContactUpdateArgs = {
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononDebitCardAccountUpdateArgs = {
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononDebitCardAccountUpdateV3Args = {
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononDebitCardTransactionUpdateArgs = {
  debitCardAccountUuid?: InputMaybe<Scalars['ID']['input']>
  debitCardId?: InputMaybe<Scalars['ID']['input']>
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononDebitCardUpdateArgs = {
  customerUuid?: InputMaybe<Scalars['ID']['input']>
  debitCardAccountUuid?: InputMaybe<Scalars['ID']['input']>
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononDepositUpdateArgs = {
  entityUuid: Scalars['ID']['input']
  siteUuid?: InputMaybe<Scalars['ID']['input']>
}

export type SubscriptiononDeviceSettingsUpdateArgs = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type SubscriptiononInvoiceListUpdateArgs = {
  entityUuid: Scalars['ID']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  status?: InputMaybe<InvoiceStatus>
}

export type SubscriptiononItemCategoryUpdateArgs = {
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononItemImportUpdateArgs = {
  entityUuid: Scalars['ID']['input']
  fileName?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptiononItemUpdateArgs = {
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononNotificationUpdateArgs = {
  customerUuid: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type SubscriptiononOrderUpdateArgs = {
  entityUuid: Scalars['ID']['input']
  siteUuids?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SubscriptiononSavingsAccountUpdateV2Args = {
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononSimUpdateArgs = {
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononTransactionUpdateArgs = {
  deviceUuid?: InputMaybe<Scalars['ID']['input']>
  entityUuid: Scalars['ID']['input']
  siteUuids?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SubscriptiononUnreadNotificationCountUpdateArgs = {
  customerUuid: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type SubscriptiononVirtualTerminalRecordUpdateArgs = {
  entityUuid: Scalars['ID']['input']
}

export type SubscriptiononZellerInvoiceSettingsUpdateArgs = {
  entityUuid: Scalars['ID']['input']
  siteUuid: Scalars['ID']['input']
}

export type SubscriptionsearchEntityDetailsFullArgs = {
  entityUuid: Scalars['ID']['input']
}

export type SubscriptionsearchEntityDetailsInitialArgs = {
  businessIdentifier: Scalars['String']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type SubscriptionverifyCustomerDocumentsArgs = {
  customerUuid: Scalars['ID']['input']
  entityUuid?: InputMaybe<Scalars['ID']['input']>
}

export type SubscriptionContactInput = {
  businessName?: InputMaybe<Scalars['String']['input']>
  contactType: ContactType
  contactUuid?: InputMaybe<Scalars['String']['input']>
  entityUuid: Scalars['ID']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  lastName?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionEcommerceRecordInput = {
  amount?: InputMaybe<Scalars['Int']['input']>
  amountDue?: InputMaybe<Scalars['Int']['input']>
  contactDetails?: InputMaybe<Scalars['String']['input']>
  contactError?: InputMaybe<Scalars['String']['input']>
  contactStatus?: InputMaybe<EcommerceContactStatus>
  contactType?: InputMaybe<ContactMethod>
  contactUuid: Scalars['String']['input']
  createdTimestamp?: InputMaybe<Scalars['AWSDateTime']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  customerUuid?: InputMaybe<Scalars['String']['input']>
  entityUuid: Scalars['String']['input']
  id: Scalars['ID']['input']
  originalContactName: Scalars['String']['input']
  paidAmount?: InputMaybe<Scalars['Int']['input']>
  paymentLink?: InputMaybe<Scalars['String']['input']>
  reference: Scalars['String']['input']
  siteName: Scalars['String']['input']
  siteUuid: Scalars['String']['input']
  status: EcommerceStatus
  surcharge?: InputMaybe<Scalars['Int']['input']>
  taxAmounts?: InputMaybe<Array<InputMaybe<TaxAmountInput>>>
  timestamp: Scalars['AWSDateTime']['input']
  timestampSent?: InputMaybe<Scalars['AWSDateTime']['input']>
  transactionNotes?: InputMaybe<Scalars['String']['input']>
  transactionUuid?: InputMaybe<Scalars['ID']['input']>
  type: EcommerceType
}

export type SurchargeInput = {
  surchargeEnabled?: InputMaybe<Scalars['Boolean']['input']>
  surchargeFullFees?: InputMaybe<Scalars['Boolean']['input']>
  surchargePercent?: InputMaybe<Scalars['Int']['input']>
}

export type SurchargesTaxesInput = {
  feesSurchargeCpoc?: InputMaybe<SurchargeInput>
  feesSurchargePbl?: InputMaybe<SurchargeInput>
  feesSurchargeVt?: InputMaybe<SurchargeInput>
  feesSurchargeXinv?: InputMaybe<SurchargeInput>
  feesSurchargeZinv?: InputMaybe<SurchargeInput>
  gstEnabled?: InputMaybe<Scalars['Boolean']['input']>
  surchargeEnabled?: InputMaybe<Scalars['Boolean']['input']>
  surchargeEnabledCnp?: InputMaybe<Scalars['Boolean']['input']>
  surchargeEnabledMoto?: InputMaybe<Scalars['Boolean']['input']>
  surchargeFullFees?: InputMaybe<Scalars['Boolean']['input']>
  surchargeFullFeesCnp?: InputMaybe<Scalars['Boolean']['input']>
  surchargeFullFeesMoto?: InputMaybe<Scalars['Boolean']['input']>
  surchargePercent?: InputMaybe<Scalars['Int']['input']>
  surchargePercentCnp?: InputMaybe<Scalars['Int']['input']>
  surchargePercentMoto?: InputMaybe<Scalars['Int']['input']>
  taxes?: InputMaybe<Array<InputMaybe<OtherTaxSettingsInput>>>
}

export type SurchargesTaxesSettings = {
  __typename?: 'SurchargesTaxesSettings'
  /** @deprecated Use subtypes instead */
  feeFixedCnp?: Maybe<Scalars['Int']['output']>
  feePercent?: Maybe<Scalars['Int']['output']>
  /** @deprecated Use subtypes instead */
  feePercentCnp?: Maybe<Scalars['Int']['output']>
  feePercentMoto?: Maybe<Scalars['Int']['output']>
  feesSurchargeCp?: Maybe<FeesSurchargeFixed>
  feesSurchargeCpoc?: Maybe<FeesSurchargeFixed>
  feesSurchargeMoto?: Maybe<FeesSurchargeFixed>
  feesSurchargePbl?: Maybe<FeesSurchargeFixedIntl>
  feesSurchargeVt?: Maybe<FeesSurchargeFixed>
  feesSurchargeXinv?: Maybe<FeesSurchargeFixedIntl>
  feesSurchargeZinv?: Maybe<FeesSurchargeFixedIntl>
  gstEnabled?: Maybe<Scalars['Boolean']['output']>
  gstPercent?: Maybe<Scalars['Int']['output']>
  surchargeEnabled?: Maybe<Scalars['Boolean']['output']>
  /** @deprecated Use subtypes instead */
  surchargeEnabledCnp?: Maybe<Scalars['Boolean']['output']>
  surchargeEnabledMoto?: Maybe<Scalars['Boolean']['output']>
  surchargeFullFees?: Maybe<Scalars['Boolean']['output']>
  /** @deprecated Use subtypes instead */
  surchargeFullFeesCnp?: Maybe<Scalars['Boolean']['output']>
  surchargeFullFeesMoto?: Maybe<Scalars['Boolean']['output']>
  surchargePercent?: Maybe<Scalars['Int']['output']>
  /** @deprecated Use subtypes instead */
  surchargePercentCnp?: Maybe<Scalars['Int']['output']>
  surchargePercentMoto?: Maybe<Scalars['Int']['output']>
  taxes?: Maybe<Array<Maybe<OtherTaxSettings>>>
}

export type SurchargesTaxesSettingsInput = {
  feeFixedCnp?: InputMaybe<Scalars['Int']['input']>
  feePercent?: InputMaybe<Scalars['Int']['input']>
  feePercentCnp?: InputMaybe<Scalars['Int']['input']>
  feePercentMoto?: InputMaybe<Scalars['Int']['input']>
  feesSurchargeCp?: InputMaybe<FeesSurchargeFixedInput>
  feesSurchargeCpoc?: InputMaybe<FeesSurchargeFixedInput>
  feesSurchargeMoto?: InputMaybe<FeesSurchargeFixedInput>
  feesSurchargeXinv?: InputMaybe<FeesSurchargeFixedIntlInput>
  feesSurchargeZinv?: InputMaybe<FeesSurchargeFixedIntlInput>
  gstEnabled?: InputMaybe<Scalars['Boolean']['input']>
  gstPercent?: InputMaybe<Scalars['Int']['input']>
  surchargeEnabled?: InputMaybe<Scalars['Boolean']['input']>
  surchargeEnabledCnp?: InputMaybe<Scalars['Boolean']['input']>
  surchargeEnabledMoto?: InputMaybe<Scalars['Boolean']['input']>
  surchargeFullFees?: InputMaybe<Scalars['Boolean']['input']>
  surchargeFullFeesCnp?: InputMaybe<Scalars['Boolean']['input']>
  surchargeFullFeesMoto?: InputMaybe<Scalars['Boolean']['input']>
  surchargePercent?: InputMaybe<Scalars['Int']['input']>
  surchargePercentCnp?: InputMaybe<Scalars['Int']['input']>
  surchargePercentMoto?: InputMaybe<Scalars['Int']['input']>
  taxes?: InputMaybe<Array<InputMaybe<OtherTaxSettingsInput>>>
}

export type TaxAmount = {
  __typename?: 'TaxAmount'
  amount: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type TaxAmountBigInt = {
  __typename?: 'TaxAmountBigInt'
  amount: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type TaxAmountInput = {
  amount: Scalars['Int']['input']
  name: Scalars['String']['input']
}

export enum TaxRate {
  BAS_EXCLUDED = 'BAS_EXCLUDED',
  GST_FREE_EXPENSE = 'GST_FREE_EXPENSE',
  GST_FREE_INCOME = 'GST_FREE_INCOME',
  GST_ON_EXPENSE = 'GST_ON_EXPENSE',
  GST_ON_IMPORTS = 'GST_ON_IMPORTS',
  GST_ON_INCOME = 'GST_ON_INCOME',
}

export enum Theme {
  DARK_THEME = 'DARK_THEME',
  GREY_THEME = 'GREY_THEME',
  LIGHT_THEME = 'LIGHT_THEME',
}

export type ThirdPartyBankAccount = {
  __typename?: 'ThirdPartyBankAccount'
  accountBsb?: Maybe<Scalars['String']['output']>
  accountName?: Maybe<Scalars['String']['output']>
  accountNumber?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type ThirdPartyBankAccountConnection = {
  __typename?: 'ThirdPartyBankAccountConnection'
  accounts: Array<Maybe<ThirdPartyBankAccount>>
  nextToken?: Maybe<Scalars['String']['output']>
}

export enum ThreeDSOutcome {
  FAILED = 'FAILED',
  PASSED = 'PASSED',
}

export type TipSettings = {
  __typename?: 'TipSettings'
  customTipAllowed?: Maybe<Scalars['Boolean']['output']>
  enabled?: Maybe<Scalars['Boolean']['output']>
  tipPercent1?: Maybe<Scalars['Int']['output']>
  tipPercent2?: Maybe<Scalars['Int']['output']>
  tipPercent3?: Maybe<Scalars['Int']['output']>
}

export type TipSettingsInput = {
  customTipAllowed?: InputMaybe<Scalars['Boolean']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  tipPercent1?: InputMaybe<Scalars['Int']['input']>
  tipPercent2?: InputMaybe<Scalars['Int']['input']>
  tipPercent3?: InputMaybe<Scalars['Int']['input']>
}

export type Transaction = {
  __typename?: 'Transaction'
  adjustAmount?: Maybe<Scalars['Int']['output']>
  amount: Scalars['Int']['output']
  caid?: Maybe<Scalars['String']['output']>
  cardMedia?: Maybe<CardMedia>
  cardholderEmail?: Maybe<Scalars['String']['output']>
  cardholderName?: Maybe<Scalars['String']['output']>
  cardholderPhone?: Maybe<Scalars['String']['output']>
  cardholderUuid?: Maybe<Scalars['ID']['output']>
  catid?: Maybe<Scalars['String']['output']>
  channel?: Maybe<Channel>
  /**   getContactByCardholder resolver */
  contact?: Maybe<Contact>
  customerName?: Maybe<Scalars['String']['output']>
  customerUuid?: Maybe<Scalars['ID']['output']>
  depositDate?: Maybe<Scalars['AWSDateTime']['output']>
  /**   getDepositShortId resolver */
  depositShortId?: Maybe<Scalars['String']['output']>
  depositUuid?: Maybe<Scalars['ID']['output']>
  deposited?: Maybe<Scalars['Boolean']['output']>
  deviceModel?: Maybe<Scalars['String']['output']>
  deviceName?: Maybe<Scalars['String']['output']>
  deviceUuid?: Maybe<Scalars['ID']['output']>
  emvAid?: Maybe<Scalars['String']['output']>
  emvAppName?: Maybe<Scalars['String']['output']>
  emvTagsPrint?: Maybe<Scalars['String']['output']>
  entityUuid: Scalars['ID']['output']
  externalReference?: Maybe<Scalars['String']['output']>
  externalReferenceUrl?: Maybe<Scalars['String']['output']>
  feeAmount?: Maybe<Scalars['Int']['output']>
  feeCharged?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  images?: Maybe<Array<TransactionImageDownloadUrls>>
  isoProcessingCode?: Maybe<Scalars['String']['output']>
  issuer?: Maybe<TransactionIssuer>
  location?: Maybe<Location>
  maskedPan?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  posName?: Maybe<Scalars['String']['output']>
  reference?: Maybe<Scalars['String']['output']>
  refundTransactions?: Maybe<Array<Transaction>>
  refunded?: Maybe<Scalars['Boolean']['output']>
  refundedAmount?: Maybe<Scalars['Int']['output']>
  refundedTransaction?: Maybe<Transaction>
  refundedTransactionUuid?: Maybe<Scalars['ID']['output']>
  responseCode?: Maybe<Scalars['String']['output']>
  responseDescription?: Maybe<Scalars['String']['output']>
  reversed?: Maybe<Scalars['Boolean']['output']>
  review?: Maybe<Scalars['Boolean']['output']>
  saleAmount: Scalars['Int']['output']
  scheme: CardScheme
  siteName?: Maybe<Scalars['String']['output']>
  siteUuid: Scalars['ID']['output']
  source?: Maybe<Source>
  sourceFilter?: Maybe<SourceFilter>
  splitPayment?: Maybe<SplitPayment>
  splitPaymentUuid?: Maybe<Scalars['ID']['output']>
  stan?: Maybe<Scalars['String']['output']>
  status: TransactionStatus
  surchargeAmount: Scalars['Int']['output']
  taxAmounts?: Maybe<Array<TaxAmount>>
  threeDSOutcome?: Maybe<ThreeDSOutcome>
  timestamp: Scalars['AWSDateTime']['output']
  tipAmount: Scalars['Int']['output']
  type: TransactionType
}

export enum TransactionCancelReason {
  CARD_DECLINED = 'CARD_DECLINED',
  COMMUNICATIONS_FAILURE = 'COMMUNICATIONS_FAILURE',
  INVALID_RESPONSE = 'INVALID_RESPONSE',
  PENDING_REVERSAL_FAILURE = 'PENDING_REVERSAL_FAILURE',
  RESPONSE_TIMEOUT = 'RESPONSE_TIMEOUT',
  SIGNATURE_MISMATCH = 'SIGNATURE_MISMATCH',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  USER_CANCELLED = 'USER_CANCELLED',
}

export type TransactionConnection = {
  __typename?: 'TransactionConnection'
  nextToken?: Maybe<TransactionNextToken>
  transactions: Array<Maybe<Transaction>>
}

export enum TransactionDirection {
  EXPENSE = 'EXPENSE',
  INCOME = 'INCOME',
}

export type TransactionDirectionTotalAmounts = {
  __typename?: 'TransactionDirectionTotalAmounts'
  expense: Money
  income: Money
  noOfExpense: Scalars['Int']['output']
  noOfIncome: Scalars['Int']['output']
}

/**
 *   The response parameter of exportTransaction subscription
 *  downloadLink is a pre-signed url points to s3 bucket file
 *  expire is the expire time of the downloadLink, it lasts 1 hour
 */
export type TransactionDownload = {
  __typename?: 'TransactionDownload'
  /**   The requested customerUuid which is used by subscription. FE doesn't need to subscribe this value. */
  customerUuid: Scalars['ID']['output']
  /**   The download link is a presigned URL from s3 object. The file name is a uuid plus some random number, FE better to rename the file. */
  downloadLink?: Maybe<Scalars['String']['output']>
  /**   The requested entityUuid which is used by subscription. FE doesn't need to subscribe this value. */
  entityUuid: Scalars['ID']['output']
  /**   The error includes error message if something wrong happens. Unlike `Query`, subscription doesn't response the error in `errors` field of the payload. */
  error?: Maybe<Scalars['String']['output']>
  /**   The expire date time of the download link */
  expire?: Maybe<Scalars['AWSDateTime']['output']>
  /**   The requested filter which is used by subscription. FE doesn't need to subscribe this value. */
  filter: Scalars['String']['output']
  /**   The requested format which is used by subscription. FE doesn't need to subscribe this value. */
  format: TransactionExportFormat
}

export type TransactionDownloadInput = {
  customerUuid: Scalars['ID']['input']
  downloadLink?: InputMaybe<Scalars['String']['input']>
  entityUuid: Scalars['ID']['input']
  error?: InputMaybe<Scalars['String']['input']>
  expire?: InputMaybe<Scalars['AWSDateTime']['input']>
  filter: Scalars['String']['input']
  format: TransactionExportFormat
}

export enum TransactionExportFormat {
  CSV = 'CSV',
  PDF = 'PDF',
  XLSX = 'XLSX',
}

export type TransactionFilterExcludeInput = {
  cardholderUuid?: InputMaybe<StringFilterInput>
}

export type TransactionFilterInput = {
  amount?: InputMaybe<IntFilterInput>
  and?: InputMaybe<Array<InputMaybe<TransactionFilterInput>>>
  channel?: InputMaybe<StringFilterInput>
  contactUuid?: InputMaybe<ListFilterInput>
  depositShortId?: InputMaybe<StringEqualInput>
  depositUuid?: InputMaybe<StringFilterInput>
  deposited?: InputMaybe<BoolFilterInput>
  deviceUuid?: InputMaybe<StringFilterInput>
  maskedPan?: InputMaybe<StringFilterInput>
  /**   for cardholderGsi */
  not?: InputMaybe<TransactionFilterExcludeInput>
  or?: InputMaybe<Array<InputMaybe<TransactionFilterInput>>>
  panToken?: InputMaybe<StringFilterInput>
  par?: InputMaybe<StringFilterInput>
  posName?: InputMaybe<StringFilterInput>
  reference?: InputMaybe<StringFilterInput>
  refunded?: InputMaybe<BoolFilterInput>
  reversed?: InputMaybe<BoolFilterInput>
  review?: InputMaybe<BoolFilterInput>
  scheme?: InputMaybe<StringFilterInput>
  siteUuid?: InputMaybe<StringFilterInput>
  source?: InputMaybe<StringFilterInput>
  sourceFilter?: InputMaybe<ListFilterInput>
  splitPaymentUuid?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  timestamp?: InputMaybe<StringFilterInput>
  type?: InputMaybe<StringFilterInput>
}

export type TransactionImageDownloadUrls = {
  __typename?: 'TransactionImageDownloadUrls'
  downloadUrl: Scalars['String']['output']
  expireDate: Scalars['AWSDateTime']['output']
  fileName: Scalars['String']['output']
  fileUuid: Scalars['String']['output']
}

export type TransactionImageUploadUrls = {
  __typename?: 'TransactionImageUploadUrls'
  expireDate: Scalars['AWSDateTime']['output']
  fileName: Scalars['String']['output']
  fileUuid: Scalars['String']['output']
  uploadUrl: Scalars['String']['output']
}

export type TransactionInput = {
  adjustAmount?: InputMaybe<Scalars['Int']['input']>
  amount: Scalars['Int']['input']
  caid?: InputMaybe<Scalars['String']['input']>
  cardMedia?: InputMaybe<CardMedia>
  cardholderEmail?: InputMaybe<Scalars['String']['input']>
  cardholderName?: InputMaybe<Scalars['String']['input']>
  cardholderPhone?: InputMaybe<Scalars['String']['input']>
  cardholderUuid?: InputMaybe<Scalars['ID']['input']>
  catid?: InputMaybe<Scalars['String']['input']>
  channel?: InputMaybe<Channel>
  customerUuid?: InputMaybe<Scalars['ID']['input']>
  depositDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  depositUuid?: InputMaybe<Scalars['ID']['input']>
  deposited?: InputMaybe<Scalars['Boolean']['input']>
  deviceModel?: InputMaybe<Scalars['String']['input']>
  deviceName?: InputMaybe<Scalars['String']['input']>
  deviceUuid?: InputMaybe<Scalars['ID']['input']>
  emvAid?: InputMaybe<Scalars['String']['input']>
  entityUuid: Scalars['ID']['input']
  externalReference?: InputMaybe<Scalars['String']['input']>
  externalReferenceUrl?: InputMaybe<Scalars['String']['input']>
  feeAmount?: InputMaybe<Scalars['Int']['input']>
  feeCharged?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  isoProcessingCode?: InputMaybe<Scalars['String']['input']>
  issuer?: InputMaybe<TransactionIssuer>
  location?: InputMaybe<LocationInput>
  maskedPan?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  posName?: InputMaybe<Scalars['String']['input']>
  reference?: InputMaybe<Scalars['String']['input']>
  refunded?: InputMaybe<Scalars['Boolean']['input']>
  refundedAmount?: InputMaybe<Scalars['Int']['input']>
  refundedTransactionUuid?: InputMaybe<Scalars['ID']['input']>
  responseCode?: InputMaybe<Scalars['String']['input']>
  responseDescription?: InputMaybe<Scalars['String']['input']>
  reversed?: InputMaybe<Scalars['Boolean']['input']>
  review?: InputMaybe<Scalars['Boolean']['input']>
  saleAmount: Scalars['Int']['input']
  scheme: CardScheme
  siteName?: InputMaybe<Scalars['String']['input']>
  siteUuid: Scalars['ID']['input']
  source?: InputMaybe<Source>
  sourceFilter?: InputMaybe<SourceFilter>
  splitPayment?: InputMaybe<SplitPaymentInput>
  splitPaymentUuid?: InputMaybe<Scalars['ID']['input']>
  stan?: InputMaybe<Scalars['String']['input']>
  status: TransactionStatus
  surchargeAmount: Scalars['Int']['input']
  taxAmounts?: InputMaybe<Array<TaxAmountInput>>
  threeDSOutcome?: InputMaybe<ThreeDSOutcome>
  timestamp: Scalars['AWSDateTime']['input']
  tipAmount: Scalars['Int']['input']
  type: TransactionType
}

export enum TransactionIssuer {
  AFTERPAY = 'AFTERPAY',
}

export type TransactionNextToken = {
  __typename?: 'TransactionNextToken'
  entityUuid?: Maybe<Scalars['ID']['output']>
  id?: Maybe<Scalars['ID']['output']>
  type: Scalars['String']['output']
}

export type TransactionNextTokenInput = {
  entityUuid?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  type: Scalars['String']['input']
}

export enum TransactionStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  DELETED = 'DELETED',
  PROCESSING = 'PROCESSING',
}

export type TransactionTotals = {
  __typename?: 'TransactionTotals'
  cancelledAmount?: Maybe<Scalars['Int']['output']>
  countPurchases: Scalars['Int']['output']
  countRefunds: Scalars['Int']['output']
  declinedAmount?: Maybe<Scalars['Int']['output']>
  noResponseAmount?: Maybe<Scalars['Int']['output']>
  period?: Maybe<Scalars['AWSDateTime']['output']>
  periodLabel?: Maybe<Scalars['String']['output']>
  purchaseAmount: Scalars['Int']['output']
  refundAmount: Scalars['Int']['output']
  surchargeAmount: Scalars['Int']['output']
  taxAmounts?: Maybe<Array<TaxAmount>>
  tipAmount: Scalars['Int']['output']
  totalAmount: Scalars['Int']['output']
  totalsType: TransactionTotalsType
}

export type TransactionTotalsBigInt = {
  __typename?: 'TransactionTotalsBigInt'
  cancelledAmount?: Maybe<Scalars['String']['output']>
  countPurchases: Scalars['String']['output']
  countRefunds: Scalars['String']['output']
  declinedAmount?: Maybe<Scalars['String']['output']>
  feeAmount: Scalars['String']['output']
  noResponseAmount?: Maybe<Scalars['String']['output']>
  period?: Maybe<Scalars['AWSDateTime']['output']>
  periodLabel?: Maybe<Scalars['String']['output']>
  purchaseAmount: Scalars['String']['output']
  refundAmount: Scalars['String']['output']
  saleAmount: Scalars['String']['output']
  surchargeAmount: Scalars['String']['output']
  taxAmounts?: Maybe<Array<TaxAmountBigInt>>
  tipAmount: Scalars['String']['output']
  totalAmount: Scalars['String']['output']
  totalAmountMinusFees: Scalars['String']['output']
  totalsType: TransactionTotalsType
}

export enum TransactionTotalsType {
  DAILY = 'DAILY',
  DAILY_SUMMARY = 'DAILY_SUMMARY',
  HOURLY = 'HOURLY',
  HOURLY_SUMMARY = 'HOURLY_SUMMARY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export enum TransactionType {
  ACCOUNTVERIFY = 'ACCOUNTVERIFY',
  ADJUSTMENT = 'ADJUSTMENT',
  CASHOUT = 'CASHOUT',
  DEPOSIT = 'DEPOSIT',
  PREAUTH = 'PREAUTH',
  PURCHASE = 'PURCHASE',
  PURCHASEADVICE = 'PURCHASEADVICE',
  REFUND = 'REFUND',
  REFUNDADVICE = 'REFUNDADVICE',
  REVERSAL = 'REVERSAL',
}

export enum TransferErrorCode {
  DAILY_TRANSFER_LIMIT_EXCEEDED = 'DAILY_TRANSFER_LIMIT_EXCEEDED',
}

export type TransferFundsDebitCardAccountResult = {
  __typename?: 'TransferFundsDebitCardAccountResult'
  amount: Money
  debitCardAccountUuid?: Maybe<Scalars['ID']['output']>
  /**   The Deep Backend did not return this value at this moment, for placeholder only */
  entityUuid?: Maybe<Scalars['String']['output']>
  error?: Maybe<TransferErrorCode>
  fallbackToDe?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  payeeDetails?: Maybe<DebitCardTransactionCounterparty>
  payerDetails?: Maybe<DebitCardTransactionCounterparty>
  reference?: Maybe<Scalars['String']['output']>
  referencePayee?: Maybe<Scalars['String']['output']>
  result: Scalars['Boolean']['output']
  /**   Not yet implemented */
  scheduledTransferUuid?: Maybe<Scalars['ID']['output']>
  status: DebitCardTransactionStatusV2
  /**   The Deep Backend did not return this value at this moment, for placeholder only */
  timestamp?: Maybe<Scalars['AWSDateTime']['output']>
  transferInUuid?: Maybe<Scalars['ID']['output']>
  transferType?: Maybe<DebitCardTransactionTypeV2>
}

export type TransferFundsRecipient = {
  __typename?: 'TransferFundsRecipient'
  /**   Not yet in use, using BSB + AccountNumber + Account Name */
  accountDetails?: Maybe<BankAccountDetails>
  /**
   *   Deprecate in v2
   *  Replaced by accountDetails in future
   */
  accountName?: Maybe<Scalars['String']['output']>
  /**
   *   Deprecate in v2
   *  Replaced by accountDetails in future
   */
  accountNumber?: Maybe<Scalars['String']['output']>
  /**   Not yet in use */
  bpayDetails?: Maybe<BpayDetails>
  /**
   *   Deprecate in v2
   *  Replaced by accountDetails in future
   */
  bsb?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  nickname?: Maybe<Scalars['String']['output']>
  /**   Not yet in use */
  nppDetails?: Maybe<NppDetails>
}

export type TransferFundsRecipientsConnection = {
  __typename?: 'TransferFundsRecipientsConnection'
  nextToken?: Maybe<Scalars['ID']['output']>
  recipients: Array<Maybe<TransferFundsRecipient>>
}

export enum TransferRemittanceErrorEnum {
  RECIPIENT_EMAIL_NOT_FOUND = 'RECIPIENT_EMAIL_NOT_FOUND',
  RECIPIENT_MOBILE_NOT_FOUND = 'RECIPIENT_MOBILE_NOT_FOUND',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  TRANSACTION_NOTIFICATION_PERIOD_EXPIRED = 'TRANSACTION_NOTIFICATION_PERIOD_EXPIRED',
}

export type TransferRemittanceNotificationInput = {
  dcaTransactionUuid: Scalars['String']['input']
  isSenderNotified: Scalars['Boolean']['input']
  recipientEmail?: InputMaybe<Scalars['AWSEmail']['input']>
  recipientMobile?: InputMaybe<Scalars['AWSPhone']['input']>
  type: ReceiptNotificationType
}

export type TransferRemittanceNotificationResponse = {
  __typename?: 'TransferRemittanceNotificationResponse'
  error?: Maybe<TransferRemittanceErrorEnum>
  isTransferRemittanceQueued: Scalars['Boolean']['output']
}

export type TransferRemittancePdfDownload = {
  __typename?: 'TransferRemittancePdfDownload'
  downloadLink?: Maybe<Scalars['String']['output']>
  error?: Maybe<TransferRemittancePdfErrorEnum>
  expire?: Maybe<Scalars['AWSDateTime']['output']>
}

export enum TransferRemittancePdfErrorEnum {
  TRANSACTION_NOT_FOUND = 'TRANSACTION_NOT_FOUND',
}

export enum TrusteeType {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL',
}

export type UnreadNotificationCountSubscription = {
  __typename?: 'UnreadNotificationCountSubscription'
  count?: Maybe<Scalars['Int']['output']>
  customerUuid: Scalars['ID']['output']
  id: Scalars['ID']['output']
}

export type UnreadNotificationCountSubscriptionInput = {
  count?: InputMaybe<Scalars['Int']['input']>
  customerUuid: Scalars['ID']['input']
  id: Scalars['ID']['input']
}

export type UpdateCatalogAttributeSetInput = {
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  values?: InputMaybe<Array<InputMaybe<UpdateCatalogAttributeValueInput>>>
}

export type UpdateCatalogAttributeValueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  ordinal: Scalars['Int']['input']
  value: Scalars['String']['input']
}

export type UpdateCatalogCategoryInput = {
  color?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  displayLabel?: InputMaybe<Scalars['Boolean']['input']>
  id: Scalars['ID']['input']
  images?: InputMaybe<Array<Scalars['ID']['input']>>
  items?: InputMaybe<Array<Scalars['ID']['input']>>
  label?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCatalogDiscountInput = {
  config?: InputMaybe<CatalogDiscountConfig>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  siteAssignment?: InputMaybe<CatalogDiscountSiteAssignment>
  /**   required when siteAssignment = SELECTED_SITES */
  sites?: InputMaybe<Array<Scalars['ID']['input']>>
  type?: InputMaybe<CatalogDiscountType>
  /**   supports up to 2 decimal places when config is 'PERCENTAGE' (e.g. '0.01', '100.00', '12.12', etc) and whole positive numbers (e.g 0, 874, etc, entityUuid: ID) when config is 'AMOUNT' */
  value?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCatalogItemInput = {
  attributeSets?: InputMaybe<Array<CreateCatalogItemAttributeSetsInput>>
  attributeSetsEnabled?: InputMaybe<Scalars['Boolean']['input']>
  available?: InputMaybe<Scalars['Boolean']['input']>
  categories?: InputMaybe<Array<Scalars['ID']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  displayLabel?: InputMaybe<Scalars['Boolean']['input']>
  gtin?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  images?: InputMaybe<Array<Scalars['ID']['input']>>
  invoicesEnabled?: InputMaybe<Scalars['Boolean']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  modifierSets?: InputMaybe<Array<CreateCatalogItemModifiersInput>>
  modifiersEnabled?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  price?: InputMaybe<Scalars['String']['input']>
  reportingCategoryUuid?: InputMaybe<Scalars['ID']['input']>
  siteAssignment?: InputMaybe<CatalogSiteAssignment>
  sites?: InputMaybe<Array<Scalars['ID']['input']>>
  sku?: InputMaybe<Scalars['String']['input']>
  taxes?: InputMaybe<Array<CatalogTaxInput>>
  variants?: InputMaybe<Array<UpdateCatalogItemVariantInput>>
}

export type UpdateCatalogItemVariantInput = {
  attributeValueUuids: Array<Scalars['ID']['input']>
  available?: InputMaybe<Scalars['Boolean']['input']>
  gtin?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  images?: InputMaybe<Array<Scalars['ID']['input']>>
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
  price: Scalars['String']['input']
  sku?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCatalogModifierInput = {
  id: Scalars['ID']['input']
  images?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  ordinal?: InputMaybe<Scalars['Int']['input']>
  price?: InputMaybe<Scalars['String']['input']>
}

export type UpdateCatalogModifierSetInput = {
  id: Scalars['ID']['input']
  invoicesEnabled?: InputMaybe<Scalars['Boolean']['input']>
  modifiers?: InputMaybe<Array<UpdateCatalogModifierInput>>
  name?: InputMaybe<Scalars['String']['input']>
  selectionRequired?: InputMaybe<Scalars['Boolean']['input']>
  siteAssignment?: InputMaybe<CatalogSiteAssignment>
  sites?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UpdateCustomerEntityMappingInput = {
  defaultEntityUuid: Scalars['String']['input']
}

export type UpdateCustomerInput = {
  abn?: InputMaybe<Scalars['String']['input']>
  acn?: InputMaybe<Scalars['String']['input']>
  address?: InputMaybe<CustomerAddressInput>
  assignSites?: InputMaybe<Array<Scalars['String']['input']>>
  beneficialOwner?: InputMaybe<Scalars['Boolean']['input']>
  beneficialOwnerAlt?: InputMaybe<Scalars['Boolean']['input']>
  beneficiary?: InputMaybe<Scalars['Boolean']['input']>
  ceo?: InputMaybe<Scalars['Boolean']['input']>
  chair?: InputMaybe<Scalars['Boolean']['input']>
  companyProfileData?: InputMaybe<CompanyProfileData>
  companyTrustName?: InputMaybe<Scalars['String']['input']>
  director?: InputMaybe<Scalars['Boolean']['input']>
  dob?: InputMaybe<Scalars['AWSDate']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  financialContact?: InputMaybe<Scalars['Boolean']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  generalContact?: InputMaybe<Scalars['Boolean']['input']>
  governmentRole?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  lastname?: InputMaybe<Scalars['String']['input']>
  middlename?: InputMaybe<Scalars['String']['input']>
  nickname?: InputMaybe<Scalars['String']['input']>
  partner?: InputMaybe<Scalars['Boolean']['input']>
  permissions?: InputMaybe<CustomerPermissionsInput>
  phone?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<CustomerRole>
  secretary?: InputMaybe<Scalars['Boolean']['input']>
  settlor?: InputMaybe<Scalars['Boolean']['input']>
  shareholder?: InputMaybe<Scalars['Boolean']['input']>
  showAdminMerchantPortalWelcome?: InputMaybe<Scalars['Boolean']['input']>
  showCatalogueItemsWelcome?: InputMaybe<Scalars['Boolean']['input']>
  showCorporateCardsAdminWelcome?: InputMaybe<Scalars['Boolean']['input']>
  showCorporateCardsMayOffer?: InputMaybe<Scalars['Boolean']['input']>
  showCorporateCardsSettingsWalkthrough?: InputMaybe<
    Scalars['Boolean']['input']
  >
  showCorporateCardsWalkthrough?: InputMaybe<Scalars['Boolean']['input']>
  showCustomScreensaverPromo?: InputMaybe<Scalars['Boolean']['input']>
  showInvoiceApril?: InputMaybe<Scalars['Boolean']['input']>
  showInvoiceInstructions?: InputMaybe<Scalars['Boolean']['input']>
  showInvoiceSendViaInfo?: InputMaybe<Scalars['Boolean']['input']>
  showInvoicesCustomisationWelcome?: InputMaybe<Scalars['Boolean']['input']>
  showInvoicesScheduleSendWelcome?: InputMaybe<Scalars['Boolean']['input']>
  showInvoicesSendBySmsWelcome?: InputMaybe<Scalars['Boolean']['input']>
  showInvoicesWelcome?: InputMaybe<Scalars['Boolean']['input']>
  showInvoicingCustomisationSettingsWelcome?: InputMaybe<
    Scalars['Boolean']['input']
  >
  showItemInstructions?: InputMaybe<Scalars['Boolean']['input']>
  showItemsWelcome?: InputMaybe<Scalars['Boolean']['input']>
  showNotificationsWelcome?: InputMaybe<Scalars['Boolean']['input']>
  showOnboardingShop?: InputMaybe<Scalars['Boolean']['input']>
  showSavingsAccountMarch?: InputMaybe<Scalars['Boolean']['input']>
  showSavingsAccountMay?: InputMaybe<Scalars['Boolean']['input']>
  showSavingsAccountWelcome?: InputMaybe<Scalars['Boolean']['input']>
  showTapToPayInstructions?: InputMaybe<Scalars['Boolean']['input']>
  showTapToPayMayJune?: InputMaybe<Scalars['Boolean']['input']>
  treasurer?: InputMaybe<Scalars['Boolean']['input']>
  trustee?: InputMaybe<Scalars['Boolean']['input']>
  type?: InputMaybe<EntityType>
  unassignSites?: InputMaybe<Array<Scalars['String']['input']>>
}

export type UpdateCustomerNotificationSettingsInput = {
  cards?: InputMaybe<CardsNotificationSettingsInput>
  payments?: InputMaybe<PaymentsNotificationSettingsInput>
  security?: InputMaybe<SecurityNotificationSettingsInput>
  users?: InputMaybe<UsersNotificationSettingsInput>
}

export type UpdateDebitCardInput = {
  /**   Deprecate, use debitCardUuid instead */
  cardId?: InputMaybe<DebitCardIdInput>
  cardUuid?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /**   Deprecate - Not in use */
  owner?: InputMaybe<Scalars['String']['input']>
  velocityControl?: InputMaybe<VelocityControlUpdateInput>
}

export type UpdateDeviceNameInput = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type UpdateDevicePushTokenInput = {
  deviceUuid: Scalars['ID']['input']
  pushToken: Scalars['String']['input']
}

export type UpdateDeviceSettingsInput = {
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  network?: InputMaybe<NetworkDeviceSettingsInput>
  posSettings?: InputMaybe<PosSettingsInput>
  screen?: InputMaybe<ScreenDeviceSettingsInput>
}

export type UpdateInvoiceCustomerInput = {
  attentionContactStatus?: InputMaybe<RevisionStatus>
  attentionContactUuid?: InputMaybe<Scalars['ID']['input']>
  payerContactStatus?: InputMaybe<RevisionStatus>
  payerContactUuid?: InputMaybe<Scalars['ID']['input']>
  payerEmail?: InputMaybe<Scalars['String']['input']>
}

export type UpdateInvoiceInput = {
  customer?: InputMaybe<UpdateInvoiceCustomerInput>
  discount?: InputMaybe<InvoiceDiscountInput>
  dueDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  email?: InputMaybe<InvoiceEmailInput>
  items?: InputMaybe<Array<UpdateInvoiceItemInput>>
  itemsApplyTax?: InputMaybe<Scalars['Boolean']['input']>
  itemsTaxInclusive?: InputMaybe<Scalars['Boolean']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  milestones?: InputMaybe<Array<InvoiceMilestoneInput>>
  notes?: InputMaybe<Scalars['String']['input']>
  referenceNumber: Scalars['String']['input']
  sendSchedule?: InputMaybe<InvoiceSendScheduleInput>
  sms?: InputMaybe<InvoiceSMSInput>
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateInvoiceItemInput = {
  catalogItemUuid?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  discount?: InputMaybe<InvoiceDiscountInput>
  id?: InputMaybe<Scalars['ID']['input']>
  includeCalculation?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  orderIndex?: InputMaybe<Scalars['Int']['input']>
  price?: InputMaybe<Scalars['String']['input']>
  quantity?: InputMaybe<Scalars['Float']['input']>
  taxes?: InputMaybe<Array<InvoiceTaxInput>>
  unit?: InputMaybe<InvoiceItemUnit>
  updatedTime?: InputMaybe<Scalars['AWSDateTime']['input']>
}

export type UpdateItemCategoryInput = {
  color?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  sites?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UpdateItemInput = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  image?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  price?: InputMaybe<Scalars['Int']['input']>
  productId?: InputMaybe<Scalars['String']['input']>
  sites?: InputMaybe<Array<Scalars['ID']['input']>>
  sku?: InputMaybe<Scalars['String']['input']>
  taxes?: InputMaybe<Array<ItemTaxInput>>
}

export type UpdatePaymentInstrumentBpayDetailsInput = {
  nickname?: InputMaybe<Scalars['String']['input']>
}

export type UpdatePaymentInstrumentInput = {
  bpayDetails?: InputMaybe<UpdatePaymentInstrumentBpayDetailsInput>
  status?: InputMaybe<PaymentInstrumentStatus>
}

export type UpdateSiteInput = {
  address?: InputMaybe<SiteAddressInput>
  discountPin?: InputMaybe<Scalars['String']['input']>
  discountPinType?: InputMaybe<DiscountPinType>
  discountRequiresPin?: InputMaybe<Scalars['Boolean']['input']>
  features?: InputMaybe<SiteFeaturesInput>
  id: Scalars['ID']['input']
  moto?: InputMaybe<MotoSettingsInput>
  name?: InputMaybe<Scalars['String']['input']>
  pin?: InputMaybe<Scalars['String']['input']>
  receipt?: InputMaybe<ReceiptSettingsInput>
  refundPin?: InputMaybe<Scalars['String']['input']>
  refundPinType?: InputMaybe<RefundPinType>
  refundRequiresPin?: InputMaybe<Scalars['Boolean']['input']>
  schemes?: InputMaybe<Array<InputMaybe<SchemeSettingsInput>>>
  schemesMoto?: InputMaybe<Array<InputMaybe<SchemeSettingsInput>>>
  surchargesTaxes?: InputMaybe<SurchargesTaxesInput>
  tipping?: InputMaybe<TipSettingsInput>
  type?: InputMaybe<SiteType>
  vtEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateThirdPartyBankAccountInput = {
  accountBsb?: InputMaybe<Scalars['String']['input']>
  accountName?: InputMaybe<Scalars['String']['input']>
  accountNumber?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateVelocityControlResponseType = {
  __typename?: 'UpdateVelocityControlResponseType'
  amountLimit: Money
  maxTransactionValue: Money
  velocityControlUuid: Scalars['ID']['output']
  velocityWindow: VelocityWindowEnum
}

export type UpdateVisibleTabsInput = {
  orderedTabs: Array<OrderedTabInput>
}

export type UpdateXeroSiteSettingsInput = {
  address?: InputMaybe<SiteAddressInput>
  name?: InputMaybe<Scalars['String']['input']>
  receipt?: InputMaybe<ReceiptSettingsInput>
  schemesCnp?: InputMaybe<Array<SchemeSettingsInput>>
  surchargesTaxes?: InputMaybe<SurchargesTaxesInput>
}

export type UpdateZellerInvoiceSettingsInput = {
  address?: InputMaybe<SiteAddressInput>
  invoice?: InputMaybe<ZellerInvoiceSettingsInput>
  name?: InputMaybe<Scalars['String']['input']>
  receipt?: InputMaybe<ReceiptSettingsInput>
  schemesCnp?: InputMaybe<Array<SchemeSettingsInput>>
  surchargesTaxes?: InputMaybe<SurchargesTaxesInput>
}

export type UpdatedInvoiceNotesInput = {
  notes?: InputMaybe<Scalars['String']['input']>
  referenceNumber: Scalars['String']['input']
}

export type UpdatedZellerInvoiceSettings = {
  __typename?: 'UpdatedZellerInvoiceSettings'
  bccEmail?: Maybe<Scalars['String']['output']>
  businessAddress?: Maybe<Scalars['String']['output']>
  customisation?: Maybe<InvoicePdfCustomisation>
  discountsEnabled?: Maybe<Scalars['Boolean']['output']>
  entityUuid: Scalars['ID']['output']
  pdfIncludesAddress?: Maybe<Scalars['Boolean']['output']>
  reminderOnDue?: Maybe<Scalars['Boolean']['output']>
  remindersDaysAfterDue?: Maybe<Array<Scalars['Int']['output']>>
  remindersDaysBeforeDue?: Maybe<Array<Scalars['Int']['output']>>
  sendBccCopy?: Maybe<Scalars['Boolean']['output']>
  sendEmailByDefault?: Maybe<Scalars['Boolean']['output']>
  sendSmsByDefault?: Maybe<Scalars['Boolean']['output']>
  siteUuid: Scalars['ID']['output']
}

export type UserDataInput = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
}

export type UsersNotificationSettings = {
  __typename?: 'UsersNotificationSettings'
  adminUserRemoved?: Maybe<NotificationSettings>
  managerUserRemoved?: Maybe<NotificationSettings>
  userGivenAdminPermission?: Maybe<NotificationSettings>
  userInvitedToBeAdmin?: Maybe<NotificationSettings>
}

export type UsersNotificationSettingsInput = {
  adminUserRemoved?: InputMaybe<NotificationSettingsInput>
  managerUserRemoved?: InputMaybe<NotificationSettingsInput>
  userGivenAdminPermission?: InputMaybe<NotificationSettingsInput>
  userInvitedToBeAdmin?: InputMaybe<NotificationSettingsInput>
}

export type ValidateBpayPaymentInput = {
  amount: MoneyInput
  billerCode: Scalars['String']['input']
  crn: Scalars['String']['input']
}

export type ValidateBpayPaymentResponse = {
  __typename?: 'ValidateBpayPaymentResponse'
  invalidReason?: Maybe<InvalidBpayPaymentReason>
  isValid: Scalars['Boolean']['output']
}

export type VelocityControlCreateInput = {
  amountLimit: MoneyInput
  idempotencyKey: Scalars['ID']['input']
  maxTransactionValue: MoneyInput
  timeZone?: InputMaybe<Scalars['String']['input']>
  velocityWindow: VelocityWindowEnum
}

export type VelocityControlEventInput = {
  amountLimit: MoneyInput
  availableAmount: MoneyInput
  maxTransactionValue: MoneyInput
  modifiedBy?: InputMaybe<VelocityControlModifiedByInputType>
  resetsAt: Scalars['AWSDateTime']['input']
  timeZone?: InputMaybe<Scalars['String']['input']>
  totalSpentAmount: MoneyInput
  velocityControlUuid: Scalars['ID']['input']
  velocityWindow: VelocityWindowEnum
}

export type VelocityControlModifiedByInputType = {
  actingCustomerUuid: Scalars['String']['input']
  updatedAt: Scalars['AWSDateTime']['input']
}

export type VelocityControlModifiedByType = {
  __typename?: 'VelocityControlModifiedByType'
  actingCustomerUuid: Scalars['ID']['output']
  updatedAt: Scalars['AWSDateTime']['output']
}

export type VelocityControlType = {
  __typename?: 'VelocityControlType'
  amountLimit: Money
  availableAmount: Money
  maxTransactionValue: Money
  modifiedBy?: Maybe<VelocityControlModifiedByType>
  resetsAt: Scalars['AWSDateTime']['output']
  timeZone?: Maybe<Scalars['String']['output']>
  totalSpentAmount: Money
  velocityControlUuid: Scalars['ID']['output']
  velocityWindow: VelocityWindowEnum
}

export type VelocityControlUpdateInput = {
  amountLimit: MoneyInput
  maxTransactionValue: MoneyInput
  resetBudget?: InputMaybe<Scalars['Boolean']['input']>
  timeZone?: InputMaybe<Scalars['String']['input']>
  velocityWindow: VelocityWindowEnum
}

export enum VelocityWindowEnum {
  DAY = 'DAY',
  FORTNIGHT = 'FORTNIGHT',
  LIFETIME = 'LIFETIME',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export type VerifyCustomerDocumentsInput = {
  address?: InputMaybe<CustomerAddressInput>
  customerUuid: Scalars['ID']['input']
  dob?: InputMaybe<Scalars['AWSDate']['input']>
  /**   New Fields for V2 */
  documentType?: InputMaybe<DocumentType>
  driversLicence?: InputMaybe<DriversLicenceInput>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  medicare?: InputMaybe<MedicareInput>
  middleName?: InputMaybe<Scalars['String']['input']>
  passport?: InputMaybe<PassportInput>
}

export type VerifyCustomerDocumentsResults = {
  __typename?: 'VerifyCustomerDocumentsResults'
  customerUuid: Scalars['ID']['output']
  documentType?: Maybe<DocumentType>
  error?: Maybe<Scalars['String']['output']>
  result?: Maybe<VerifyResult>
}

export type VerifyCustomerDocumentsResultsInput = {
  customerUuid: Scalars['ID']['input']
  documentType?: InputMaybe<DocumentType>
  error?: InputMaybe<Scalars['String']['input']>
  result?: InputMaybe<VerifyResult>
}

export type VerifyCustomerDocumentsV2Input = {
  address: CustomerAddressInput
  dob: Scalars['AWSDate']['input']
  document: Scalars['String']['input']
  documentMac: Scalars['String']['input']
  documentType: DocumentType
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  middleName?: InputMaybe<Scalars['String']['input']>
}

export enum VerifyMedicareCardColours {
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
}

export enum VerifyResult {
  ACCEPTED = 'ACCEPTED',
  ERROR = 'ERROR',
  NOT_VERIFIED = 'NOT_VERIFIED',
  REJECTED = 'REJECTED',
}

export enum VerifyState {
  ACT = 'ACT',
  NSW = 'NSW',
  NT = 'NT',
  QLD = 'QLD',
  SA = 'SA',
  TAS = 'TAS',
  VIC = 'VIC',
  WA = 'WA',
}

export type VirtualTerminalRecordFilterInput = {
  amount?: InputMaybe<IntFilterInput>
  and?: InputMaybe<Array<InputMaybe<VirtualTerminalRecordFilterInput>>>
  contactUuid?: InputMaybe<StringFilterInput>
  or?: InputMaybe<Array<InputMaybe<VirtualTerminalRecordFilterInput>>>
  reference?: InputMaybe<StringFilterInput>
  siteUuid?: InputMaybe<StringFilterInput>
  status?: InputMaybe<StringFilterInput>
  timestamp?: InputMaybe<StringFilterInput>
  type?: InputMaybe<StringFilterInput>
}

export type XeroBankfeedsConfigInput = {
  bankAccountsEnabled: Array<BankfeedEnabledBankAccountInput>
  xeroOrganisationUuid?: InputMaybe<Scalars['String']['input']>
}

export type XeroBankfeedsConfiguration = {
  __typename?: 'XeroBankfeedsConfiguration'
  bankAccounts: Array<EnabledAccountDetails>
  connection: Connection
  lastDataSyncDateTime?: Maybe<Scalars['AWSDateTime']['output']>
  organisationName?: Maybe<Scalars['String']['output']>
}

export type XeroCnpSite = {
  __typename?: 'XeroCnpSite'
  address?: Maybe<SiteAddress>
  /**   getCustomersByXeroSiteResolver */
  customers?: Maybe<Array<Customer>>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  receipt?: Maybe<ReceiptSettings>
  schemesCnp?: Maybe<Array<SchemeSettings>>
  surchargesTaxes?: Maybe<SurchargesTaxesSettings>
  type: SiteType
}

export type XeroCnpSiteInput = {
  address?: InputMaybe<Address>
  businessName?: InputMaybe<Scalars['String']['input']>
}

export type XeroOrganisation = {
  __typename?: 'XeroOrganisation'
  baseCurrency?: Maybe<Scalars['String']['output']>
  class?: Maybe<Scalars['String']['output']>
  countryCode?: Maybe<Scalars['String']['output']>
  createdDateUTC?: Maybe<Scalars['String']['output']>
  defaultPurchasesTax?: Maybe<Scalars['String']['output']>
  defaultSalesTax?: Maybe<Scalars['String']['output']>
  edition?: Maybe<Scalars['String']['output']>
  financialYearEndDay?: Maybe<Scalars['Int']['output']>
  financialYearEndMonth?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['String']['output']>
  legalName?: Maybe<Scalars['String']['output']>
  lineOfBusiness?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  organisationEntityType?: Maybe<Scalars['String']['output']>
  organisationID?: Maybe<Scalars['String']['output']>
  organisationType?: Maybe<Scalars['String']['output']>
  paysTax?: Maybe<Scalars['Boolean']['output']>
  salesTaxBasis?: Maybe<Scalars['String']['output']>
  salesTaxPeriod?: Maybe<Scalars['String']['output']>
  shortCode?: Maybe<Scalars['String']['output']>
}

export type XeroPaymentServicesConfigInput = {
  enabledThemes: Array<EnabledThemeInput>
  site: XeroCnpSiteInput
  xeroOrganisationUuid: Scalars['String']['input']
}

export type XeroPaymentServicesConfiguration = {
  __typename?: 'XeroPaymentServicesConfiguration'
  connection: Connection
  enabledThemes: Array<EnabledTheme>
  xeroOrganisationUuid?: Maybe<Scalars['String']['output']>
}

export type XeroTheme = {
  __typename?: 'XeroTheme'
  brandingThemeId?: Maybe<Scalars['String']['output']>
  createdDateUTC?: Maybe<Scalars['String']['output']>
  logoUrl?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  sortOrder?: Maybe<Scalars['Int']['output']>
  themeType?: Maybe<Scalars['String']['output']>
}

export type ZellerInvoiceSettings = {
  __typename?: 'ZellerInvoiceSettings'
  bccEmail?: Maybe<Scalars['String']['output']>
  businessAddress?: Maybe<Scalars['String']['output']>
  customisation?: Maybe<InvoicePdfCustomisation>
  discountsEnabled?: Maybe<Scalars['Boolean']['output']>
  pdfIncludesAddress: Scalars['Boolean']['output']
  reminderOnDue: Scalars['Boolean']['output']
  remindersDaysAfterDue: Array<Scalars['Int']['output']>
  remindersDaysBeforeDue: Array<Scalars['Int']['output']>
  sendBccCopy: Scalars['Boolean']['output']
  sendEmailByDefault?: Maybe<Scalars['Boolean']['output']>
  sendSmsByDefault?: Maybe<Scalars['Boolean']['output']>
}

export type ZellerInvoiceSettingsInput = {
  bccEmail?: InputMaybe<Scalars['String']['input']>
  businessAddress?: InputMaybe<Scalars['String']['input']>
  customisation?: InputMaybe<InvoicePdfCustomisationInput>
  discountsEnabled?: InputMaybe<Scalars['Boolean']['input']>
  pdfIncludesAddress?: InputMaybe<Scalars['Boolean']['input']>
  reminderOnDue?: InputMaybe<Scalars['Boolean']['input']>
  remindersDaysAfterDue?: InputMaybe<Array<Scalars['Int']['input']>>
  remindersDaysBeforeDue?: InputMaybe<Array<Scalars['Int']['input']>>
  sendBccCopy?: InputMaybe<Scalars['Boolean']['input']>
  sendEmailByDefault?: InputMaybe<Scalars['Boolean']['input']>
  sendSmsByDefault?: InputMaybe<Scalars['Boolean']['input']>
}

export type ZellerInvoiceSite = {
  __typename?: 'ZellerInvoiceSite'
  address?: Maybe<SiteAddress>
  /**   getCustomersByZellerSiteResolver */
  customers?: Maybe<Array<Customer>>
  id: Scalars['ID']['output']
  invoice: ZellerInvoiceSettings
  name: Scalars['String']['output']
  /**   getCnpPaymentLimitResolver */
  paymentLimits?: Maybe<PaymentSettingsLimits>
  receipt?: Maybe<ReceiptSettings>
  schemesCnp?: Maybe<Array<SchemeSettings>>
  surchargesTaxes?: Maybe<SurchargesTaxesSettings>
  type: SiteType
}

export type ZellerInvoiceSiteInput = {
  address?: InputMaybe<SiteAddressInput>
  id: Scalars['ID']['input']
  invoice: ZellerInvoiceSettingsInput
  name: Scalars['String']['input']
  receipt?: InputMaybe<ReceiptSettingsInput>
  schemesCnp?: InputMaybe<Array<SchemeSettingsInput>>
  surchargesTaxes?: InputMaybe<SurchargesTaxesSettingsInput>
  type: SiteType
}

export enum ZellerPosFavouriteType {
  CATEGORY = 'CATEGORY',
  ITEM = 'ITEM',
}

export type ZellerPosFavourites = {
  __typename?: 'ZellerPosFavourites'
  id: Scalars['ID']['output']
  type: ZellerPosFavouriteType
}

export type ZellerPosFavouritesInput = {
  id: Scalars['ID']['input']
  type: ZellerPosFavouriteType
}

export type ZellerPosSettings = {
  __typename?: 'ZellerPosSettings'
  /**   Ordered list */
  favourites: Array<ZellerPosFavourites>
}

export type ZellerPosSettingsInput = {
  favourites?: InputMaybe<Array<ZellerPosFavouritesInput>>
}

export type cashFlowPeriodicTotalAmount = {
  __typename?: 'cashFlowPeriodicTotalAmount'
  range: CashFlowReportRange
  transactionDirectionTotalAmounts: TransactionDirectionTotalAmounts
}

export enum invoiceSubscriptionAction {
  Cancel = 'Cancel',
  Create = 'Create',
  Delete = 'Delete',
  Update = 'Update',
}

export type updateTransferFundInput = {
  amount?: InputMaybe<MoneyInput>
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  frequency?: InputMaybe<FundsTransferFrequency>
  frequencyUnit?: InputMaybe<Scalars['Int']['input']>
  payeeReference?: InputMaybe<Scalars['String']['input']>
  payerReference?: InputMaybe<Scalars['String']['input']>
  scheduledTransferUuid: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['AWSDateTime']['input']>
  status?: InputMaybe<ScheduledTransferStatus>
}
