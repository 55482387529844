import { createContext, useContext } from 'react'

export type MultiEntityStoreContextType = {
  changeCurrentEntitySession: (entityUuid: string | null) => void
  removeEntitySession: (entityUuid: string) => void
  addEntitySession: (entityUuid: string) => void
}

export const MultiEntityStoreContext = createContext<
  MultiEntityStoreContextType | undefined
>(undefined)

export const useMultiEntityStore = () => {
  const context = useContext(MultiEntityStoreContext)

  if (!context) {
    throw new Error(
      'useMultiEntityStore must be used within MultiEntityStoreContext'
    )
  }

  return context
}
