import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { useSelectedEntityUuid } from '@npco/mp-utils-selected-entity'
import {
  rvNewUserType,
  rvSelectedUser,
  rvSiteDetails,
} from 'apps/component-merchant-portal/src/graphql/reactiveVariables'

import { isEmpty } from 'utils/isEmpty'
import { CustomerDetailsForm } from 'types/customers'
import { GetCustomers_getCustomers as Customer } from 'types/gql-types/GetCustomers'
import { SiteCache } from 'types/site'

import {
  modifyCachedCustomers,
  readCachedCustomer,
  updateCachedCustomers,
} from '../../../features/utils-customers-cache'

export const useCustomerUtils = () => {
  const { cache } = useApolloClient()
  const entityUuid = useSelectedEntityUuid()
  const updateCachedCustomer = useCallback(
    (sites: SiteCache[] = []) => {
      const customers = readCachedCustomer(cache, entityUuid)?.getCustomers
      if (customers) {
        const customer: Customer | undefined | null = customers?.find(
          (user) => user?.id === rvSelectedUser()
        )
        if (customer) {
          const newUserType = rvNewUserType()
          const updatedCustomer = {
            ...customer,
            id: customer.id,
            siteCount: sites.length,
          }
          if (newUserType) {
            updatedCustomer.role = newUserType
          }

          modifyCachedCustomers({
            cache,
            customerUpdate: [updatedCustomer],
            customerId: customer.id,
            entityUuid,
          })
        }
      }
    },
    [cache, entityUuid]
  )

  const deleteCustomerFromStorage = (customerId: string) => {
    const siteDetails = rvSiteDetails()
    const currentCustomers = siteDetails?.customers ?? []
    const customers = readCachedCustomer(cache, entityUuid)?.getCustomers

    if (customers) {
      const customersAfterDeletion = customers
        .filter(isEmpty)
        .filter((customer) => customer.id !== customerId)

      updateCachedCustomers({
        cache,
        customerUpdate: customersAfterDeletion,
        entityUuid,
      })

      cache.evict({
        id: cache.identify({ __typename: 'Customer', id: customerId }),
      })

      if (currentCustomers?.length > 0) {
        const newCustomers = currentCustomers.filter(
          (customer) => customer.id !== customerId
        )
        if (siteDetails) {
          rvSiteDetails({
            ...siteDetails,
            customers: newCustomers,
          })
        }
      }
      rvSelectedUser(undefined)
    }
  }

  const addCustomerToCache = (id: string, inputValues: CustomerDetailsForm) => {
    const {
      role,
      firstname,
      isInvitationPending,
      kycStatus,
      lastname,
      middlename,
      sites,
    } = inputValues
    const customers = readCachedCustomer(cache, entityUuid)?.getCustomers
    if (customers) {
      const newCustomer: Customer & { __typename: 'Customer' } = {
        __typename: 'Customer',
        id,
        role,
        firstname,
        isInvitationPending: isInvitationPending ?? null,
        kycStatus,
        lastname,
        middlename,
        siteCount: sites?.length ?? 0,
      }

      updateCachedCustomers({
        cache,
        customerUpdate: [newCustomer, ...customers],
        entityUuid,
      })
    }
  }

  return {
    updateCachedCustomer,
    deleteCustomerFromStorage,
    addCustomerToCache,
  }
}
