import { ApolloCache } from '@apollo/client'
import { Customer } from '@npco/mp-gql-types'
import { GetCustomers } from 'apps/component-merchant-portal/src/graphql/merchant-portal/queries/customers'
import { UpdateOption, updateWithOption } from 'features/utils-cache'

import { GetCustomers as GetCustomersResponse } from 'types/gql-types/GetCustomers'

import { UpdateCachedCustomerFragmentDoc } from './graphql/UpdateCachedCustomerFragment.generated'

export type CustomerUpdate = {
  __typename: 'Customer'
  id: string
  firstname?: UpdateOption<Customer['firstname']>
  lastname?: UpdateOption<Customer['lastname']>
  middlename?: UpdateOption<Customer['middlename']>
  role?: UpdateOption<Customer['role']>
  isInvitationPending?: UpdateOption<Customer['isInvitationPending']>
  kycStatus?: UpdateOption<Customer['kycStatus']>
  sites?: UpdateOption<Customer['sites']>
}

type UpdateCachedCustomersArguments = {
  cache: ApolloCache<unknown>
  customerUpdate: GetCustomersResponse['getCustomers']
  entityUuid: string
}

export const updateCachedCustomers = ({
  cache,
  customerUpdate,
  entityUuid,
}: UpdateCachedCustomersArguments) => {
  cache.writeQuery<GetCustomersResponse>({
    query: GetCustomers,
    variables: {
      entityUuid,
    },
    data: {
      getCustomers: customerUpdate,
    },
  })
}

export const modifyCachedCustomers = ({
  cache,
  customerUpdate,
  customerId,
}: UpdateCachedCustomersArguments & { customerId: string }) => {
  const customer = customerUpdate?.find((user) => user?.id === customerId)

  cache.updateFragment(
    {
      id: cache.identify({ __typename: 'Customer', id: customerId }),
      fragment: UpdateCachedCustomerFragmentDoc,
    },
    (data) => {
      if (!data) {
        return data
      }

      return {
        ...data,
        role: updateWithOption(customer?.role, data.role),
        siteCount: updateWithOption(customer?.siteCount, data.siteCount),
      }
    }
  )
}
