import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedShortEntityUuid } from '@npco/mp-utils-selected-entity'
import { useTranslations } from '@npco/utils-translations'
import {
  DecisionModal,
  Flex,
  InfoBox,
  INFOBOX_VARIANT,
  showErrorToast,
  showSuccessToast,
} from '@npco/zeller-design-system'

import { ROOT } from 'const/routes'
import { Checkbox } from 'components/Input/Checkbox'
import { translationsShared } from 'translations'

import { useItemsAnalytics } from '../../hooks/useItemsAnalytics'
import { useDeleteAllItems } from './hooks/useDeleteAllItems'
import { useDeleteAllItemsCategories } from './hooks/useDeleteAllItemsCategories'
import { translations } from './ItemDeleteAllModal.i18n'

type ToastLabelKeys = [
  'deleteAllSuccess' | 'deleteItemsSuccess',
  'deleteAllError' | 'deleteItemsError'
]

export const ItemDeleteAllModal = () => {
  const { trackDeleteAll } = useItemsAnalytics()

  const { deleteAllItems, isDeletingAllItems } = useDeleteAllItems()
  const { deleteAllItemsCategories, isDeletingAllItemsCategories } =
    useDeleteAllItemsCategories()

  const t = useTranslations(translations)
  const tShared = useTranslations(translationsShared)
  const navigate = useNavigate()
  const shortEntityId = useSelectedShortEntityUuid()
  const [isDeleteAll, setIsDeleteAll] = useState(true)

  const handleClose = useCallback(() => {
    navigate(ROOT.ORGS.ORG(shortEntityId).ITEM_MANAGEMENT.ITEMS.path)
  }, [navigate, shortEntityId])

  const handleDeleteItem = async () => {
    const [successLabelKey, errorLabelKey]: ToastLabelKeys = isDeleteAll
      ? ['deleteAllSuccess', 'deleteAllError']
      : ['deleteItemsSuccess', 'deleteItemsError']
    try {
      let isSuccess
      if (isDeleteAll) {
        const result = await deleteAllItemsCategories()
        isSuccess = result.data?.deleteAllItemsAndCategories
      } else {
        const result = await deleteAllItems()
        isSuccess = result.data?.deleteAllItems
      }

      if (isSuccess) {
        trackDeleteAll()
        showSuccessToast(t(successLabelKey))
        handleClose()
      } else {
        showErrorToast(t(errorLabelKey))
      }
    } catch (error) {
      showErrorToast(t(errorLabelKey))
    }
  }

  return (
    <DecisionModal
      isOpen
      isLoadingPrimary={isDeletingAllItems || isDeletingAllItemsCategories}
      onCancel={handleClose}
      onClickPrimary={handleDeleteItem}
      onClickSecondary={handleClose}
      primaryButtonLabel={tShared('confirm')}
      secondaryButtonLabel={tShared('cancel')}
      title={t('title')}
    >
      <Flex flexDirection="column" gridGap="24px">
        {t('body')}
        <Checkbox
          checked={isDeleteAll}
          onClick={() => setIsDeleteAll((val) => !val)}
          label={t('deleteAllCheckboxLabel')}
          name="delete-all"
        />
        {!isDeleteAll && (
          <InfoBox variant={INFOBOX_VARIANT.NEGATIVE}>
            <InfoBox.Message>{t('infoBoxMessage')}</InfoBox.Message>
          </InfoBox>
        )}
      </Flex>
    </DecisionModal>
  )
}
